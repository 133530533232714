import { Seat6MComponent } from './Laggage6M';
import { useState, useEffect } from 'react';
import { useTypedSelector } from '../reducers';

interface IProps {
  initialOpen: boolean;
  onClose: (result?: Seat6MComponent) => void;
  scope: string;
  configs: Seat6MComponent;
}

const tfaEndpont = window.ENV?.TFA_WEB_COMPONENTS_ENDPOINT;

const SeatsReservation: React.FC<IProps> = ({ initialOpen, onClose, scope, configs }) => {
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
  const { locale } = useTypedSelector(({ settings }) => settings);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = tfaEndpont!;
    script.onload = () => setScriptLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [initialOpen]);

  useEffect(() => {
    //@ts-ignore
    tuiCottonBall.pleaseTakeCareOf('flightancillarywebcomponent', scope, 'openSeatMap', configs);

    //@ts-ignore

    tuiCottonBall.cuddleMeIf(
      'flightancillarywebcomponent',
      scope,
      'seatSelectionChanged',
      (componentname, scope, eventname, firedPayload) => {
        //console.log(firedPayload);
      },
    );

    //@ts-ignore

    tuiCottonBall.cuddleMeIf(
      'flightancillarywebcomponent',
      scope,
      'seatSelectionFinished',
      (componentName, scope, eventName, firedPayload) => {
        //console.log(scope, 'Selection finished ', firedPayload);
        onClose(firedPayload);
      },
    );
  }, [scriptLoaded]);

  return (
    <div style={{ position: 'relative', zIndex: 1000 }}>
      <div id="componentContainer">
        {
          // @ts-ignore
          <tas-seat-map scope={scope} locale={locale.locale === 'de' ? 'de-DE' : 'en-US'}></tas-seat-map>
        }
      </div>
    </div>
  );
};

export default SeatsReservation;
