import { createContext, useState } from 'react';

interface Props {
  children: React.ReactNode;
}

export const LoadingContext = createContext({
  isLoading: false,
  message: '',
  showLoading: (message?: string) => {},
  closeLoading: () => {},
});

const LoadingProvider = ({ children }: Props) => {
  const showLoading = (message?: string) => {
    toggleLoading(prevState => {
      return {
        ...prevState,
        isLoading: true,
        message: message as string,
      };
    });
  };

  const closeLoading = () => {
    toggleLoading(prevState => {
      return {
        ...prevState,
        isLoading: false,
        message: '',
      };
    });
  };

  const loadingState = {
    isLoading: false,
    message: '',
    showLoading,
    closeLoading,
  };

  const [loading, toggleLoading] = useState(loadingState);

  return <LoadingContext.Provider value={loading}>{children}</LoadingContext.Provider>;
};

export default LoadingProvider;
