import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { apigeeEndpoint } from '../apis';
import { LoadingContext } from '../contexts/LoadingContextProvider';

export type UseIntegrateMarket = {
  integrateResponse?: any;
  integrateStatus?: any;
  integrateError?: any;
  setIntegrateMarketRequest: (integrateMarketRequest: IntegrateMarket) => void;
};

export type IntegrateMarket = {
  market: string;
  orderId: string;
};

export const useIntegrateMarket = (): UseIntegrateMarket => {
  const { showLoading, closeLoading } = useContext(LoadingContext);

  const [integrateStatus, setIntegrateStatus] = React.useState<string | undefined>('');
  const [integrateResponse, setIntegrateResponse] = React.useState<any>();
  const [integrateError, setIntegrateError] = React.useState<any>();
  const [integrateMarketRequest, setIntegrateMarketRequest] = useState<IntegrateMarket | undefined>(undefined);

  useEffect(() => {
    const integrateMarket = async (integrateMarketRequest: IntegrateMarket): Promise<void> => {
      try {
        showLoading('Please Wait');

        const response = await apigeeEndpoint.get(
          `integrate/market/${integrateMarketRequest.market}/${integrateMarketRequest.orderId}`,
        );

        if (response.data.Status === 'SUCCESS') {
          setIntegrateStatus('CONFIRMED');
          setIntegrateResponse(response.data.data ?? response.data);
          closeLoading();
        } else {
          setIntegrateStatus('FAILURE');
          // @ts-ignore
          setIntegrateError(Object.values(response.data));
          closeLoading();
        }
      } catch (error) {
        setIntegrateStatus('FAILURE');
        closeLoading();
      }
    };

    if (integrateMarketRequest) {
      void integrateMarket(integrateMarketRequest);
    }
  }, [integrateMarketRequest]);

  return {
    setIntegrateMarketRequest,
    integrateError,
    integrateResponse,
    integrateStatus,
  };
};
