import Header from './Header';
import { isIOS, isMobile } from 'react-device-detect';
import Footer from '../../../components/footer';
import { withRouter } from '../../../routes/withRouter';

const Vertical = props => {
  //set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add('ios-mobile-view-height');
  } else if (document.body.classList.contains('ios-mobile-view-height')) {
    document.body.classList.remove('ios-mobile-view-height');
  }

  return (
    <div className={`app-container`} style={{ height: '100%' }}>
      <div className="app-main-container">
        <div className="app-header" style={{ zIndex: 999 }}>
          <Header />
        </div>

        <main className="app-main-content-wrapper">
          <div className="app-main-content" style={{ margin: '0 auto' }}>
            {props.children}
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default withRouter(Vertical);
