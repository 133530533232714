import { GenderType } from '@tff/types/TFF/tff-flight-details';
import { format } from 'date-fns';
import { TFF } from '@tff/types';

/**
 * Sorts passengers by Type => adults first
 *
 * converts DOB to YYYY-mm-dd string
 *
 * transform Gender to meet type requirements
 *
 */
export const sortAndNormalize = (passengers: { [p: string]: TFF.Passenger }): TFF.Passenger[] => {
  return Object.values(passengers)
    .map(psg => {
      const genderGiven = (psg.Gender || '').toUpperCase() as GenderType; //Transform Gender to Uppercase if delivered, to match TFF types
      const Gender = genderGiven ? { Gender: genderGiven } : {};
      return {
        ...psg,
        ...Gender,
        DOB: psg.DOB ? format(new Date(psg.DOB), 'yyyy-MM-dd') : undefined,
      };
    })
    .sort((a, b) => {
      if (a.Type < b.Type) {
        return -1;
      }
      if (a.Type > b.Type) {
        return 1;
      }

      return 0;
    });
};

export default sortAndNormalize;
