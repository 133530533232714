import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const locale = localStorage.getItem('locale');

export interface ISettingsStoreState {
  navCollapsed: boolean;
  drawerType: string;
  channel: {
    channelId: string;
    name: string;
  };
  width: number;
  navigationStyle: string;
  horizontalNavPosition: string;
  darkTheme?: any;
  themeColor: string;
  locale: {
    languageId: string;
    locale: string;
    name: string;
    icon: string;
  };
}

const initialState: ISettingsStoreState = {
  navCollapsed: false,
  drawerType: 'fixed_drawer',
  channel: {
    channelId: 'CALLCENTER',
    name: 'Callcenter',
  },
  themeColor: 'indigo',
  darkTheme: false,
  width: 1280,
  navigationStyle: 'vertical_navigation',
  horizontalNavPosition: 'inside_the_header',
  locale: locale
    ? JSON.parse(locale)
    : {
        languageId: 'german',
        locale: 'de',
        name: 'German',
        icon: 'de',
      },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleCollapsedNav(state, action: PayloadAction<boolean>) {
      state.navCollapsed = action.payload;
    },
    updateWindowWidth(state, action: PayloadAction<number>) {
      state.width = action.payload;
    },
    switchLanguage(state, action: PayloadAction<any>) {
      state.locale = action.payload;
    },
    switchChannel(state, action: PayloadAction<any>) {
      state.channel = action.payload;
    },
    changeNavigationStyle(state, action: PayloadAction<string>) {
      state.navigationStyle = action.payload;
    },
    setHorizontalMenuPosition(state, action: PayloadAction<string>) {
      state.horizontalNavPosition = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase('@@router/LOCATION_CHANGE', state => {
      state.navCollapsed = false;
    });
  },
});

export const {
  toggleCollapsedNav,
  updateWindowWidth,
  switchLanguage,
  switchChannel,
  changeNavigationStyle,
  setHorizontalMenuPosition,
} = settingsSlice.actions;

export default settingsSlice.reducer;
