import { Route, Routes } from 'react-router-dom';
import Feeconfigs from './FeeConfigs/FeeConfigs';
import { withRouter } from '../withRouter';

export const FeeConfigsRoutes = () => {
  return (
    <div className="app-wrapper h-100" style={{ padding: '100px' }}>
      <Routes>
        <Route path="fees" element={<Feeconfigs />} />
      </Routes>
    </div>
  );
};

export default withRouter(FeeConfigsRoutes);
