import { Box, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { UserFamilyIcon } from '../icons';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIntl } from 'react-intl';
import PassengerTile from './PassengerTile';
import AccordionActions from '@mui/material/AccordionActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { TFF } from '@tff/types';
import sortAndNormalize from '../../util/sortAndNormalizePassengers';
import { PaxWithPhoneAndEmail } from './PassengersEditForm';
import CheckBox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useDivideBooking } from '../../hooks/use-divide-booking';
import BlueCheckedCheckBox from '../icons/BlueCheckedCheckbox';
import TUICheckbox from '../icons/TUICheckbox';
import CustomDialog from '../Dialogs';
import { useNavigate } from 'react-router-dom';

interface props {
  flightDetails: TFF.FlightDetails;
  allowEdit?: boolean;
  midocoOrderNo?: number;
  handleEditBtn?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '5px',
      borderColor: theme.palette.text.primary,
      margin: '10px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '10px 20px',
    },
    flexBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    iconWrapper: {
      height: 40,
      width: 40,
      backgroundColor: '#E2F3FE',
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    padding: {
      padding: theme.spacing(3),
    },
    cardsWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gridAutoFlow: 'row',
      gap: '16px',
    },
    acciorditonActionsRoot: {
      marginLeft: '0px',
      minHeight: '80px',
      padding: '20px',
    },
    editRoot: {
      display: 'flex',
      gap: '10px',
      '& button': {
        paddingTop: '5px',
      },
    },
    divideRoot: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      '& button': {
        paddingTop: '5px',
      },
    },
    dividePassengersMessage: {
      fontSize: '24px',
      color: theme.palette.primary.main,
      maxHeight: '31px',
    },
    dividePNRBox: {
      backgroundColor: '#E2F5FD',
      padding: '2px 16px 2px 5px',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      minWidth: '184px',
      maxHeight: '36px',
      margin: 'auto 15px auto',
      borderRadius: '6px',
    },
    dialogDivideActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '16px',
      width: '100%',
      padding: '16px 24px',
    },
  }),
);

const MuiAccordionSummary = withStyles({
  root: {},
  content: {
    margin: '0 !important',
  },
  expanded: {},
  expandIcon: {},
})(AccordionSummary);

const MuiAccordionDetails = withStyles({
  root: {
    backgroundColor: '#E2F3FE',
  },
})(AccordionDetails);

const MuiCheckBox = withStyles({
  root: {
    '&.Mui-disabled': {
      color: '#BABABA !important',
    },
    '& svg': {
      width: '25px',
      height: '25px',
    },
    padding: 'auto 0px',
    borderWidth: '2px',
  },
})(CheckBox);

const PassengerDetailCard: React.FC<props> = ({
  flightDetails,
  midocoOrderNo,
  handleEditBtn,
  allowEdit,
}): JSX.Element => {
  // transform passengers to match required types
  const [passengers] = useState(sortAndNormalize(flightDetails.OrderDetails.Passengers));
  const [divideToggle, setDivideToggle] = useState(false);
  const [divideSelectedPassengers, setDivideSelectedPassengers] = useState<string[]>([]);
  const [shouldShowDialog, setShouldShowDialog] = useState(false);
  const [result, setResult] = useState<{ result: string; type: 'error' | 'pnr' | 'stale' }>({
    result: '',
    type: 'stale',
  });
  const navigate = useNavigate();
  const { setDivideBookingRequest, divideResponse, divideStatus, divideError } = useDivideBooking();

  const passengerEditEnabled = allowEdit !== false; //keycloak.hasRealmRole('Role_IT_Administrator');
  const classes = useStyles();
  const [paxTypeCounts, setPaxTypeCounts] = React.useState({ adults: 0, children: 0, infants: 0 });
  const intl = useIntl();

  useEffect(() => {
    if (divideStatus === 'CONFIRMED') {
      setResult({ result: divideResponse?.dividedRecordLocator ?? '', type: 'pnr' });
      handleDivideClosing();
    } else if (divideStatus === 'FAILURE') {
      handleDialog();
      setResult({ result: divideError?.message ?? 'Internal server error', type: 'error' });
    } else if (divideStatus === 'IN_DIVIDING') {
      setResult({ result: '', type: 'stale' });
    }
  }, [divideStatus]);

  const getTicketNumber = (passenger: PaxWithPhoneAndEmail): string | null => {
    if (!flightDetails.OrderDetails) {
      return null;
    }

    const pax: PaxWithPhoneAndEmail | undefined = Object.values(flightDetails.OrderDetails.Passengers).find(
      p => p.LastName === passenger.LastName && p.FirstName === passenger.FirstName,
    );

    if (pax && flightDetails.OrderDetails.Fares[pax.Id]?.TicketNumber) {
      const ticketNumber: string = flightDetails.OrderDetails.Fares[pax.Id].TicketNumber!;
      return `Ticket-Nr.: ${ticketNumber.substring(0, 3)}-${ticketNumber.substring(3)}`;
    }

    return null;
  };

  const getParent = (paxId: string): string | undefined => {
    const idWithoutINF: string = paxId.replace(/_?INF/g, '');
    const parent = passengers.find(pass => pass.Id === idWithoutINF);
    if (parent) return `${parent.Title} ${parent.FirstName} ${parent.LastName}`;
    return undefined;
  };

  const handleEdit = (): void => {
    //resetMessages();
    if (handleEditBtn) {
      handleEditBtn();
    }
  };

  const toggleDivide = (): void => {
    setDivideToggle(prev => {
      let toggle: boolean = true;
      if (prev) {
        toggle = false;
        setDivideSelectedPassengers([]);
      }
      return toggle;
    });
  };

  const handleDialog = (): void => {
    setShouldShowDialog(prev => !prev);
  };

  const handleDivideSelection = (id: string): void => {
    if (!divideSelectedPassengers.find(pId => pId === id)) {
      setDivideSelectedPassengers(prev => {
        const newDivideSelectedPassengers = [...prev];
        passengers.map(pass => {
          if (pass.Id.includes(id)) newDivideSelectedPassengers.push(pass.Id);
          return undefined;
        });
        return newDivideSelectedPassengers;
      });
    } else {
      setDivideSelectedPassengers(prev => prev.filter(pId => pId !== id && pId !== `${id}_INF` && !pId.includes(id)));
    }
  };

  const handleDivideClosing = (): void => {
    handleDialog();
    toggleDivide();
  };

  const handleDivide = (): void => {
    setDivideBookingRequest({
      action: 'divide',
      bookingSource: 'TUI-NSK',
      midocoOrderNo: midocoOrderNo,
      recordLocator: flightDetails.OrderSummary?.RecordLocator!,
      passengerIds: divideSelectedPassengers,
      tfmPnr: flightDetails.OrderSummary?.TfmPnr,
    });
  };

  useEffect(() => {
    if (!passengers) return;
    let adultsCount = 0;
    let infantsCount = 0;
    let childrenCount = 0;
    passengers.forEach(passenger => {
      if (passenger.Type === 'ADT') {
        adultsCount += 1;
      } else if (passenger.Type === 'INF') {
        infantsCount += 1;
      } else if (passenger.Type === 'CHD') {
        childrenCount += 1;
      }
    });
    setPaxTypeCounts({
      adults: adultsCount,
      infants: infantsCount,
      children: childrenCount,
    });
  }, [passengers]);

  const renderHeader = (): JSX.Element => {
    const [adults, infants, children] = Object.values(paxTypeCounts);
    return (
      <div className={classes.flexBetween}>
        <div className={classes.flexCenter} style={{ paddingLeft: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div className={classes.iconWrapper}>
              <UserFamilyIcon />
            </div>
            <Typography component={'span'} variant="h6" color="primary" style={{ marginLeft: '10px' }}>
              <strong>{intl.formatMessage({ id: 'passengerDetails.title' }).toUpperCase()}</strong>
            </Typography>
          </div>
          <div className={classes.flexCenter}>
            <Typography component={'span'} variant="h6" color="primary" style={{ marginLeft: '20px' }}>
              {adults} {intl.formatMessage({ id: 'passengerDetails.adult' })}
            </Typography>
            <Typography component={'span'} variant="h6" color="primary" style={{ marginLeft: '20px' }}>
              {children}{' '}
              {children > 0
                ? intl.formatMessage({ id: 'passengerDetails.children' })
                : intl.formatMessage({ id: 'passengerDetails.child' })}
            </Typography>
            <Typography component={'span'} variant="h6" color="primary" style={{ marginLeft: '20px' }}>
              {infants}{' '}
              {infants > 0
                ? intl.formatMessage({ id: 'passengerDetails.infants' })
                : intl.formatMessage({ id: 'passengerDetails.infant' })}
            </Typography>
          </div>
          {((flightDetails.OrderSummary?.ChildRecordLocators &&
            flightDetails.OrderSummary.ChildRecordLocators.length > 0) ||
            flightDetails.OrderSummary?.ParentRecordLocator) && (
            <Box className={classes.dividePNRBox}>
              <InfoIcon sx={{ width: '32px', height: '32px' }} />
              <Typography sx={{ color: 'black', textDecoration: 'underline' }}>
                {intl.formatMessage({ id: 'edit.passenger.warningPnr' })}
              </Typography>
            </Box>
          )}
        </div>
      </div>
    );
  };

  const renderResultDialog = () => {
    return (
      <CustomDialog
        initialOpen={result?.type !== 'stale'}
        width="xs"
        onCancel={() => {
          if (result?.type === 'pnr') navigate('.');
          setResult({ result: '', type: 'stale' });
        }}
        cancelButtonText={`${
          result?.type === 'pnr'
            ? intl.formatMessage({ id: 'edit.passenger.reloadBooking' })
            : intl.formatMessage({ id: 'edit.passenger.closeBooking' })
        }`}
        confirmButtonText={intl.formatMessage({ id: 'edit.passenger.viewBooking' })}
        confirmButton={result?.type === 'pnr'}
        onConfirm={() => navigate(`../${result?.result}`)}
      >
        <Box sx={{ width: '374px', marginBottom: `${result.type === 'pnr' ? '15px' : '15px'}` }}>
          <Typography
            variant="h6"
            sx={{ fontSize: '18px', fontWeight: '700' }}
            color={result?.type === 'error' ? 'red' : 'green'}
          >
            {result?.type === 'pnr'
              ? intl.formatMessage({ id: 'edit.passenger.Congrats' })
              : intl.formatMessage({ id: 'edit.passenger.pnrProblem' })}
          </Typography>
          <Typography>
            {result?.type === 'pnr' ? (
              <>
                {intl.formatMessage({ id: 'edit.passenger.Pnr' })}{' '}
                <Typography component="span" sx={{ fontWeight: 'bold' }}>
                  {result.result}
                </Typography>{' '}
                {intl.formatMessage({ id: 'edit.passenger.pnrCreated' })}
              </>
            ) : (
              <Typography component="p" sx={{ fontWeight: 'bold' }}>
                {result?.result}
              </Typography>
            )}
          </Typography>
        </Box>
      </CustomDialog>
    );
  };

  const renderAcceptDialog = () => {
    return (
      <CustomDialog
        initialOpen={shouldShowDialog}
        onCancel={handleDialog}
        width="xs"
        confirmButtonText={intl.formatMessage({ id: 'popup.splitPassengersConfirmMessage.yes' }).toUpperCase()}
        title={
          <Typography component="h3" sx={{ fontSize: '24px', fontWeight: 700, lineHeight: '31.2px' }}>
            {intl.formatMessage({ id: 'popup.splitPassengersConfirmMessage' })}
            <Typography sx={{ fontSize: '18px', fontWeight: '400', lineHeight: '23.4px' }}>
              {intl.formatMessage({ id: 'popup.splitWarningSubTitle' })}
            </Typography>
          </Typography>
        }
        children={null}
        onConfirm={handleDivide}
      ></CustomDialog>
    );
  };

  return passengers ? (
    <>
      <Accordion className={classes.root}>
        <MuiAccordionSummary expandIcon={<ExpandMoreIcon />}>{renderHeader()}</MuiAccordionSummary>
        <MuiAccordionDetails sx={{ padding: '20px' }}>
          {divideToggle && (
            <Box
              sx={{
                minHeight: '51px',
              }}
            >
              <Typography className={classes.dividePassengersMessage} component="p">
                {intl.formatMessage({ id: 'edit.passenger.splitPassengersMessage' })}
              </Typography>
            </Box>
          )}
          <div className={classes.cardsWrapper}>
            {passengers &&
              passengers.map(p => (
                <PassengerTile
                  originalPassenger={p}
                  ticketNumber={getTicketNumber(p)}
                  key={p.Id}
                  associate={p.Id.includes('INF') ? getParent(p.Id) : undefined}
                >
                  {divideToggle &&
                    (p.Type !== 'INF' ? (
                      <MuiCheckBox
                        onChange={() => handleDivideSelection(p.Id)}
                        checked={!!divideSelectedPassengers.find(pId => p.Id === pId)}
                        disabled={
                          divideSelectedPassengers.find(pId => pId === p.Id)
                            ? false
                            : divideSelectedPassengers.length + 1 === passengers.length - paxTypeCounts.infants
                        }
                        disableRipple
                        disableTouchRipple
                        checkedIcon={<BlueCheckedCheckBox />}
                        icon={
                          <TUICheckbox
                            isChecked={false}
                            shouldDisabled={
                              divideSelectedPassengers.length + 1 === passengers.length - paxTypeCounts.infants &&
                              !divideSelectedPassengers.find(pId => pId === p.Id)
                            }
                          />
                        }
                      />
                    ) : (
                      <MuiCheckBox
                        checked={divideSelectedPassengers.some(pId => p.Id.match(pId))}
                        disabled={true}
                        disableRipple
                        disableTouchRipple
                        checkedIcon={<TUICheckbox isChecked={true} shouldDisabled={true} />}
                        icon={<TUICheckbox isChecked={false} shouldDisabled={true} />}
                      />
                    ))}
                </PassengerTile>
              ))}
          </div>
        </MuiAccordionDetails>{' '}
        {passengerEditEnabled && (
          <>
            <Divider></Divider>
            <AccordionActions className={classes.acciorditonActionsRoot}>
              {
                // @ts-ignore
                divideToggle ? (
                  <Box className={classes.divideRoot}>
                    <Button
                      variant="outlined"
                      disabled={flightDetails?.OrderSummary?.BookingStatus.toLowerCase() === 'cancelled'}
                      onClick={toggleDivide}
                      sx={{ height: '40px', color: '#09295D' }}
                    >
                      {intl.formatMessage({ id: 'edit.passenger.go' }) +
                        ' ' +
                        intl.formatMessage({ id: 'cancel.back' })}
                    </Button>
                    <Button
                      variant="contained"
                      disabled={
                        divideSelectedPassengers.length <= 0 ||
                        flightDetails?.OrderSummary?.BookingStatus.toLowerCase() === 'cancelled'
                      }
                      onClick={handleDialog}
                      sx={{
                        color: '#FFF !important',
                        backgroundColor: divideSelectedPassengers.length <= 0 ? '#00000066' : '#09295D',
                      }}
                    >
                      {intl.formatMessage({ id: 'flightChanges.acceptChanges' }).split(' ')[0].toUpperCase()}
                    </Button>
                  </Box>
                ) : (
                  <Box className={classes.editRoot}>
                    {flightDetails.OrderSummary?.SourceDetails?.System === 'TUI-NSK' &&
                      passengers.length > 1 &&
                      window.ENV?.ENABLE_SPLIT_BOOKING === true &&
                      !(paxTypeCounts.adults === 1 && paxTypeCounts.infants > 0) && (
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={flightDetails?.OrderSummary?.BookingStatus.toLowerCase() === 'cancelled'}
                          onClick={toggleDivide}
                          sx={{ height: '40px' }}
                        >
                          {intl.formatMessage({ id: 'edit.passenger.splitPassengers' })}
                        </Button>
                      )}
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={flightDetails?.OrderSummary?.BookingStatus.toLowerCase() === 'cancelled'}
                      onClick={handleEdit}
                    >
                      {intl.formatMessage({ id: 'edit.passenger.editPassengers' })}
                    </Button>
                  </Box>
                )
              }
            </AccordionActions>
          </>
        )}
      </Accordion>
      {renderAcceptDialog()}
      {renderResultDialog()}
    </>
  ) : (
    <></>
  );
};
export default PassengerDetailCard;
