import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { apigeeEndpoint } from '../../apis';
import { keycloak } from '../../keycloak';
import { IFee } from '@tff/types/TFF';

export enum FEE_STATES {
  UNKOWN = 'UNKOWN',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

type FeePayload = {
  fee?: IFee;
  fees?: IFee[];
  state: FEE_STATES;
  error?: any;
  feesLoading?: boolean;
};

export interface IFeeStoreState {
  fee?: IFee;
  fees: IFee[];
  state: FEE_STATES;
  error?: any;
  feesLoading?: boolean;
}

let initialState: IFeeStoreState = {
  fee: undefined,
  fees: [],
  state: FEE_STATES.UNKOWN,
  error: undefined,
  feesLoading: false,
};

export const saveFee = createAsyncThunk('fee/saveFee', async (fee: IFee, { rejectWithValue }) => {
  try {
    const response = await apigeeEndpoint.post('fee', {
      requestModel: fee,
      editor: keycloak.profile?.email,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      return rejectWithValue(response.data);
    }
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const loadFees = createAsyncThunk('fee/loadFees', async (_, { rejectWithValue }) => {
  try {
    const response = await apigeeEndpoint.get('fee');

    if (response.status === 200) {
      return response.data.data;
    } else {
      return rejectWithValue(response.data);
    }
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

const feeSlice = createSlice({
  name: 'fee',
  initialState,
  reducers: {
    setFeeState: (state, action: PayloadAction<{ state: FEE_STATES }>) => {
      state.state = action.payload.state;
    },
    setFee: (state, action: PayloadAction<FeePayload>) => {
      state.fee = action.payload.fee;
      state.state = action.payload.state;
      state.feesLoading = action.payload.feesLoading;
    },
    resetFee: state => {
      state.fee = undefined;
      state.state = FEE_STATES.UNKOWN;
      state.feesLoading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(saveFee.pending, (state, action) => {
        state.state = FEE_STATES.UNKOWN;
        state.feesLoading = true;
      })
      .addCase(saveFee.fulfilled, (state, action) => {
        state.fee = action.payload;
        state.state = FEE_STATES.FINISHED;
        state.feesLoading = false;
      })
      .addCase(saveFee.rejected, (state, action) => {
        state.error = action.error.message;
        state.state = FEE_STATES.ERROR;
        state.feesLoading = false;
      })
      .addCase(loadFees.pending, (state, action) => {
        state.state = FEE_STATES.UNKOWN;
        state.feesLoading = true;
      })
      .addCase(loadFees.fulfilled, (state, action) => {
        state.fees = action.payload;
        state.state = FEE_STATES.FINISHED;
        state.feesLoading = false;
      })
      .addCase(loadFees.rejected, (state, action) => {
        state.error = action.error.message;
        state.state = FEE_STATES.ERROR;
        state.feesLoading = false;
      });
  },
});

export const { setFeeState, setFee, resetFee } = feeSlice.actions;

export default feeSlice.reducer;
