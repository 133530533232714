import { Passenger, Journey, Segment, OrderDetails } from '@tff/types/TFF';
import { SsrPassenger, SsrSeat, SsrFlight, SsrLeg, Seat6MComponent, SeatConfig } from './Laggage6M';

let haveSeat: boolean = true;

const splitSeatValue = (inputString: string) => {
  const regex = /([0-9]+)([a-zA-Z]+)/;
  const match = inputString.match(regex);

  if (!match) {
    return null;
  }

  const [, numbers, alphabets] = match;
  return {
    row: parseInt(numbers)!,
    column: alphabets!,
  };
};

const createPassenger = (passenger: Passenger, orderDetails: OrderDetails): SsrPassenger | undefined => {
  const seats = passenger?.JourneyExtras?.filter(j => j.Seat !== undefined).map(j => j.Seat);
  // if (passenger.Type === 'INF') {
  //   return undefined;
  // }
  const pax: SsrPassenger = {
    id: passenger.Id,
    name: `${passenger.FirstName} ${passenger.LastName}`,
    ageType: passenger.Type === 'ADT' ? 'ADULT' : 'CHILD',
    seat: seats?.map(s => createSeat(s!, orderDetails)) ?? undefined,
  };
  return pax;
};

const createSeat = (seatValue: string, orderDetails: OrderDetails): SsrSeat | undefined => {
  const seatExtra = orderDetails?.Ancillaries?.[seatValue];
  if (!seatExtra) {
    haveSeat = false;
    return undefined;
  }

  const seat = { ...splitSeatValue(seatExtra.Value as string) };

  return {
    journeyOnd: seatExtra.JourneyOnd!,
    row: seat.row!,
    column: seat.column!,
    price: {
      amount: seatExtra.TotalAmount ?? 0,
      currencyCode: 'EUR',
    },
    seatProperties: [],
    isSelected: false,
  };
};

const createPassengersList = (orderDetails: OrderDetails): SsrPassenger[] => {
  const passengersList: SsrPassenger[] = [];
  Object.values(orderDetails.Passengers).forEach(passenger => {
    const seatPax = createPassenger(passenger, orderDetails);
    if (seatPax) {
      passengersList.push(seatPax);
    }
  });
  return passengersList;
};

const createFlightsForSeat = (journeys: Journey[], orderDetails: OrderDetails): SsrFlight[] => {
  return journeys.map(journey => {
    const legs: SsrLeg[] = Array.from(journey.Segments as Segment[]).map(segment => ({
      id: segment.Id,
      origin: segment.Origin,
      departureDate: segment.DepartureTime,
      destination: segment.Destination,
      arrivalDate: segment.ArrivalTime,
      carrierCode: segment.MarketingCarrier,
      flightNumber: segment.FlightNumber,
      cabinClassCode: journey.CabinClass,
    }));

    const ssrPassengers = createPassengersList(orderDetails);

    const passengers = ssrPassengers.map(passenger => ({
      ...passenger,
      seat: passenger.seat ? passenger.seat.filter(s => s?.journeyOnd === journey.Ond)[0] : undefined,
    }));

    return {
      id: journey.Id,
      promotionCode: '0009',
      bookingClassCode: journey.BookingClass,
      isHeaderItemVisible: true,
      legs,
      passengers,
      allPassengersHaveSeats: haveSeat,
    };
  });
};
export const create6MSeatComponentConfig = (journeys: Journey[], orderDetails: OrderDetails): Seat6MComponent => {
  console.log('create6MSeatComponentConfig');
  const config: SeatConfig = {
    showTuiCardInfo: true,
    clientKey: '6b9f7811-4747-46dc-b1f7-fe105353934f',
    displayMode: 'layer',
  };

  const response: Seat6MComponent = {
    config,
    flights: createFlightsForSeat(journeys, orderDetails),
  };

  return response;
};
