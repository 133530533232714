import * as React from 'react';
import { de } from 'date-fns/locale';
import { useTypedSelector } from '../../../reducers';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  Tooltip,
  FormHelperText,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { editModelItem } from './FormTypes';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

/**
 *
 * @param editModel The model that describes which input type should be rendered for each formItem prop;
 *  supported props in editModel:
 *
 * @param formItem The actual object that is to be edited;
 * @param schema yup validation schema;
 * @param formItemIndex The index that will be returned in onChange and onSubmit;
 * @param onSubmit callback with changed form item and index (formItem: {},index?:number)=>void;
 * @param formSubmitBtnRef React.Ref used to sumbit the form from parent example for use for multiple forms:
 *  refs definition in parent:
 *   const formSubmitBtnRefs= React.useMemo(
 *     () => arrayWithLengthOfFormItems.map(() => React.createRef()),
 *     [arrayWithLengthOfFormItems]
 *   );
 *  Ref use in parent (submit forms)
 *    formSubmitBtnRefs.forEach(ref => ref?.current.click());
 * @param margin prop of formControl component "dense" | "none" | "normal";
 * @param direction flex-direction of form input fields. column is for underneath 'row' | 'column';
 * @param onChange callback with changed form object and index(formItem:any,index?:number)=>void;
 * @param dontShowFunction (formItem) => boolean; If it is returning true, the form input will be skipped
 *
 * */
interface FormProps {
  editModel: editModelItem[];
  formItem: any;
  schema: any;
  style?: React.CSSProperties;
  formItemIndex?: number;
  onSubmit?: (formItem: {}, index?: number) => void;
  formSubmitBtnRef: React.Ref<any>;
  margin?: 'dense' | 'none' | 'normal';
  direction: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  onChange?: (formItem: any, index?: number) => void;
}

const calendarTranslations = {
  de: de, // {de} from 'date-fns/locale';
};

/**
 * A React form component to render a form based on a model and a schema
 * @param props
 *
 */
const FlexForm = (props: FormProps) => {
  const { style, formItem, editModel, schema, onSubmit, formSubmitBtnRef, margin, direction, formItemIndex, onChange } =
    props;

  // calendar translation setting
  const { locale } = useTypedSelector(({ settings }) => settings);

  // init form functions with pax schema and formItem
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: formItem,
  });

  const triggerSubmit = formData => {
    if (onSubmit) {
      onSubmit({ ...formItem, ...formData }, formItemIndex); //trigger submit handler of prop
    }
  };

  //make onchange object for editModelOnChange Functions (TitleChange->GenderChange)
  const onChangeActions = Object.fromEntries(
    editModel.filter(mItem => mItem.onChange).map(mItem => [mItem.fieldName, mItem.onChange]),
  );

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      //apply change functions of edit model
      if (name && typeof onChangeActions[name] === 'function') {
        // @ts-ignore
        onChangeActions[name](value, setValue);
      }
      if (onChange) {
        onChange({ ...formItem, ...getValues() }, formItemIndex);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]); //watches all form inputs

  /**
   * function to render the respective form input component
   * @typedef {Object} editModelItem
   */
  const inputSwitch = (editField: editModelItem) => {
    //return null if dontShowFunction returns true
    if (editField.dontShowFunction ? editField.dontShowFunction(formItem) : false) {
      return null;
    }

    const renderOptions = (options: string[]) => {
      return Object.values(options).map(option => {
        return (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        );
      });
    };

    const disabled = editField.disabled === true;

    switch (editField.type) {
      case 'select':
        return (
          <Controller
            name={editField.fieldName}
            control={control}
            defaultValue={formItem[editField.fieldName] ?? ''}
            render={({ field }) => {
              return (
                <FormControl variant="standard" fullWidth margin={margin}>
                  <InputLabel id="demo-simple-select-label">{editField.label}</InputLabel>
                  <Select
                    variant="standard"
                    disabled={disabled}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    fullWidth
                    value={field.value ?? ''}
                  >
                    {editField.options && renderOptions(editField.options)}
                  </Select>
                  <FormHelperText error>{errors[editField.fieldName]?.message?.toString()}</FormHelperText>
                </FormControl>
              );
            }}
          />
        );
      case 'date': //wrapped in div to support ref={} for tooltip
        return (
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns} localeText={calendarTranslations[locale.locale]}>
              <Controller
                name={editField.fieldName}
                control={control}
                defaultValue={formItem[editField.fieldName] ?? ''}
                render={({ field }) => {
                  return (
                    <DatePicker
                      disabled={disabled}
                      format="yyyy-MM-dd"
                      value={field.value ? new Date(field.value) : ''}
                      onChange={v => {
                        field.onChange(v);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          onBlur: field.onBlur,
                          variant: 'standard',
                          margin: margin,
                          id: 'date-picker-inline',
                          label: editField.label,
                          helperText: errors[editField.fieldName]?.message?.toString(),
                          error: errors[editField.fieldName] !== undefined,
                        },
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </div>
        );
      default:
        // all other editField.types are rendered as <TextField>
        return (
          <TextField
            variant="standard"
            type={editField.type ?? 'text'}
            margin={margin}
            fullWidth
            {...register(editField.fieldName, schema[editField.fieldName])}
            label={editField.label}
            helperText={errors[editField.fieldName]?.message?.toString()}
            error={errors[editField.fieldName] !== undefined}
            disabled={disabled}
          />
        );
    }
  };

  /**
   * A utility function that adds a tooltip to a React element
   * @param {React.ReactElement | null} content - the React element to add the tooltip to
   * @param {string | React.ReactNode} tooltip - the text or node to display in the tooltip
   * @returns {React.ReactNode} - the content with the tooltip added
   */
  const toolTipIt = (content: React.ReactElement | null, tooltip: string | React.ReactNode): React.ReactNode => {
    return !content || false || !tooltip ? (
      content
    ) : (
      <Tooltip title={<Typography variant="body1">{tooltip}</Typography>}>{content}</Tooltip>
    );
  };

  const formItems = editModel.map(editField => {
    return inputSwitch(editField) ? (
      <Grid item xs={direction === 'column' ? 12 : editField?.size || 2} key={editField.fieldName}>
        {toolTipIt(inputSwitch(editField), editField.tooltip?.toString())}
      </Grid>
    ) : null;
  });

  return (
    // noValidate to prevent default browser validation that shows messages in a total different design (in chrome executed before the useForm validation)
    <form style={style} noValidate autoComplete="off" onSubmit={handleSubmit(triggerSubmit)}>
      <Grid
        container
        direction={direction}
        spacing={2}
        justifyContent="flex-start"
        alignItems={direction === 'column' ? 'stretch' : 'flex-start'}
      >
        {formItems}
      </Grid>
      <button ref={formSubmitBtnRef} type="submit" style={{ display: 'none' }} />{' '}
      {/* easiest way to submit (with validation) form from parent otherwise it needs useImperativeHandle  */}
    </form>
  );
};

export default FlexForm;
