import * as React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { Button, MenuItem, Typography, Paper } from '@mui/material';
import { useIntl } from 'react-intl';
import { renderKeyboardDatePicker, renderSelectField } from '../../../components/forms/Fields';
import { IFeeFilter } from './filter-active-fee';
import { IntlMessages } from '../../../util';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

interface IProps {
  onFilter: (values: IFeeFilter) => void;
}

//Booking-Sources
export const airlineCodes = ['NSK', 'AF', 'KL', 'EK'];

//Actions
const feeActionsData = ['CANCEL', 'CHANGE'];

const FeeFilter: React.FC<IProps & InjectedFormProps<IFeeFilter, IProps, string>> = ({ onFilter, handleSubmit }) => {
  const intl = useIntl();

  const handleFilter = (values: IFeeFilter) => {
    if (values) {
      onFilter(values);
    }
  };

  return (
    <Paper className="app-wrapper p-3 m-3">
      <Typography color="primary" variant="h6">
        <strong>Filter</strong>
      </Typography>
      <Form onSubmit={handleSubmit(handleFilter)}>
        <div className="row">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Field
                    name="dateOfSimulation"
                    label="SimulationDate"
                    placeholder="SimulationsDatum eingeben"
                    component={renderKeyboardDatePicker}
                    fullWidth
                    required
                  />
                </LocalizationProvider>
              </div>
              <div className="col-md-2">
                <Field
                  name="airlineCode"
                  label={<IntlMessages id="campaign.form.booking-source" />}
                  required
                  placeholder={intl.formatMessage({ id: 'campaign.form.booking-source' })}
                  component={renderSelectField}
                  fullWidth
                >
                  {airlineCodes.map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Field>
              </div>
              <div className="col-md-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Field
                    name="bookingDate"
                    label="Buchungsdatum"
                    placeholder="Buchungsdatum auswählen"
                    component={renderKeyboardDatePicker}
                    fullWidth
                    required
                  />
                </LocalizationProvider>
              </div>
              <div className="col-md-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Field
                    name="travelDate"
                    label="Reisedatum"
                    placeholder="Reisedatum auswählen"
                    component={renderKeyboardDatePicker}
                    fullWidth
                    required
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <Field
                  name={'action'}
                  label="Aktion"
                  required
                  placeholder="Aktion auswählen"
                  component={renderSelectField}
                  fullWidth
                >
                  {feeActionsData.map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Field>
              </div>
              <div className="col-md-2">
                <Field
                  name={'channel'}
                  label={<IntlMessages id="pages.fees.campaign-config.channel" />}
                  required
                  placeholder={intl.formatMessage({ id: 'fee.form.select-channel' })}
                  component={renderSelectField}
                  fullWidth
                >
                  <MenuItem value="SELF_SERVICE_B2C">SELF_SERVICE_B2C</MenuItem>
                  <MenuItem value="CALLCENTER">CALLCENTER</MenuItem>
                </Field>
              </div>
              <div className="col-md-2">
                <Field
                  name="currency"
                  label={<IntlMessages id="fee.form.currency" />}
                  required
                  placeholder={intl.formatMessage({ id: 'fee.form.select-currency' })}
                  component={renderSelectField}
                  fullWidth
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                </Field>
              </div>
            </div>
          </div>
          <div className="col-md-2 d-flex align-items-center">
            <Button color="primary" variant="contained" style={{ marginRight: 10 }} type="submit" fullWidth>
              Filter starten
            </Button>
          </div>
        </div>
      </Form>
    </Paper>
  );
};

export default reduxForm<IFeeFilter, IProps>({
  form: 'filterForm',
  destroyOnUnmount: false,
  enableReinitialize: true,
  initialValues: {},
})(FeeFilter);
