import RetrieveList from './RetrieveList';

import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../withRouter';

export const RetrieveListRoutes = () => {
  return (
    <div className="app-wrapper h-100" style={{ padding: '100px' }}>
      <Routes>
        <Route path="/" element={<RetrieveList />} />
      </Routes>
    </div>
  );
};

export default withRouter(RetrieveListRoutes);
