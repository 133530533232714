import moment from 'moment';

export const timestampMillisToLocalDateString = (timestampAsString: string) => {
  const unixTimeMillis: number = +timestampAsString;
  const unixTimeSeconds = unixTimeMillis / 1000;
  const localMoment = moment.unix(unixTimeSeconds);
  return momentToDateString(localMoment);
};

export const timestampToLocalDateString = (timestampAsString: string) => {
  const timeStamp: number = +timestampAsString;
  const localMoment = moment.unix(timeStamp);
  return momentToDateString(localMoment);
};

export const dateStringToLocalDateString = (dateAsString: string) => {
  const date = new Date(dateAsString);
  const localMoment = moment(date);
  return momentToDateString(localMoment);
};

function momentToDateString(localMoment: moment.Moment) {
  return localMoment.locale('de').format('DD.MM.YYYY HH:mm');
}
