import * as yup from 'yup';
import { CabinClassEnum, CarrierEnum, ProductsEnum } from '../components/models';
import validateFormValues from './form-values-validator';

const schema = yup.object().shape({
  pnr: yup.string().test('len', 'Must be exactly 6 characters', val => val?.length === 6),
  product: yup.mixed<ProductsEnum>().oneOf(Object.values(ProductsEnum)),
  bookingSource: yup.array().max(Object.keys(CabinClassEnum).length).of(yup.mixed<CarrierEnum>()),
  origin: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .test('len', 'Must be exactly 3 characters', val => val?.length === 3),
  destination: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .test('len', 'Must be exactly 3 characters', val => val?.length === 3),
  outCabinClasses: yup.array().max(Object.keys(CabinClassEnum).length).of(yup.mixed<CabinClassEnum>()),
  departureDateFrom: yup.date(),
  departureDateTo: yup.date(),
  lastTicketingDate: yup.date(),
});

export const validate = validateFormValues(schema);
