import * as React from 'react';
import { useEffect, useContext, useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { apigeeEndpoint } from '../../apis';
import { useMutation, useQueryClient } from 'react-query';
import InvoluntaryChangeTable from './involuntary-change-table';
import { toast } from 'react-toastify';
import { LoadingContext } from '../../contexts/LoadingContextProvider';

/**
 *
 */
interface INdcForm {
  recordLocator: string;
  bookingSource: 'TUI-NDC-AF' | 'TUI-NDC-KL' | 'TUI-NDC-EK' | 'TUI-NDC-SQ';
  channel: 'TFM' | 'aircruiser';
  ndcBooking: string;
  createdTimestamp: Date;
}

const channels: string[] = ['TFM', 'aircruiser'];

enum BookingSourceEnum {
  AF = 'TUI-NDC-AF',
  KL = 'TUI-NDC-KL',
  EK = 'TUI-NDC-EK',
  SQ = 'TU-NDC-SQ',
}

const ndcSchema = yup.object().shape({
  recordLocator: yup
    .string()
    .required()
    .test('len', 'Must be exactly 6 characters', val => val?.length === 6),
  channel: yup.string().required(),
  ndcBooking: yup.string(),
  bookingSource: yup.string().required(),
});

const searchSchema = yup.object().shape({
  recordLocatorToSearch: yup.string().test('len', 'Must be exactly 6 characters', val => val?.length === 6),
});

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 auto',
      width: '100%',
      padding: '5%',
    },
    formRoot: {
      borderRadius: 0,
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      minWidth: 200,
      margin: '15px',
    },
    formLabel: {
      marginLeft: 10,
    },
    helperText: {
      color: 'red',
      fontSize: '16px',
    },
    tableRoot: {
      marginTop: '50px',
    },
    header: {
      margin: '20px',
      padding: '10px',
      color: 'white',
      backgroundColor: '#092A5E',
    },
  }),
);

/**
 * Send an Involuntary change request to the backend.
 * @param data
 */
const dispatchNdc = async (data: INdcForm) => {
  const response = await apigeeEndpoint.post('ndc/involuntarychange', data);
  return response.data;
};

const InvoluntaryChanges: React.FC = () => {
  const classes = useStyle();
  const queryClient = useQueryClient();
  const [ndcData, setNdcData] = useState([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { showLoading, closeLoading } = useContext(LoadingContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(ndcSchema),
  });

  const {
    register: registerSearch,
    handleSubmit: handleSearchSubmit,
    formState: { errors: searchErrors },
  } = useForm({
    resolver: yupResolver(searchSchema),
  });

  const loadData = async () => {
    await queryClient.fetchQuery(
      'tasks',
      async () => {
        showLoading('Tabelle wird geladen');
        const response = await apigeeEndpoint.get('ndc/task');
        setNdcData(response.data);
        closeLoading();
        return response.data;
      },
      {
        retry: 2,
        staleTime: 3000,
        cacheTime: 50000,
        initialData: () => {
          return queryClient.getQueryData('tasks');
        },
      },
    );
  };

  const loadSearchedData = (recordLocator: string) => {
    showLoading(`Nach ${recordLocator} suchen`);
    queryClient.fetchQuery(
      'involuntaryChanges',
      async () => {
        const response = await apigeeEndpoint.get(`ndc/task/INV%23${recordLocator}`);
        setNdcData(response.data);
        closeLoading();
        return response.data;
      },
      {
        retry: 2,
        staleTime: 3000,
        cacheTime: 50000,
        initialData: () => {
          return queryClient.getQueryData('involuntaryChanges');
        },
      },
    );
  };

  const { mutate, isLoading } = useMutation(dispatchNdc, {
    onSuccess: data => {
      if (data?.errorMessage) {
        toast.error(data.errorMessage);
      } else {
        toast.success('Data has been sent Successfully!');
        loadData();
      }
      setDisabled(false);
    },
    onError: (err: Error) => {
      toast.error(err.message.toString());
    },
    onSettled: () => {
      queryClient.invalidateQueries('involuntaryChanges');
    },
  });

  useEffect(() => {
    isLoading ? showLoading('Loading NDC Booking...') : closeLoading();
  }, [isLoading]);

  const submitForm = (data: { [x: string]: any }) => {
    const ndcForm: INdcForm = {
      recordLocator: data.recordLocator,
      bookingSource: data.bookingSource,
      ndcBooking: data.ndcBooking,
      channel: data.channel,
      createdTimestamp: new Date(),
    };
    mutate(ndcForm);
    reset();
    setDisabled(true);
  };

  const submitSearchForm = (data: { [x: string]: any }) => {
    const recordLocator = data.recordLocatorToSearch;
    loadSearchedData(recordLocator);
  };

  const NdcForm: React.FC = () => (
    <Grid container direction="row" justifyContent="space-around">
      <form onSubmit={handleSubmit(submitForm)}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary" className={classes.header}>
                Ndc Form
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between">
            <Grid item xs={4}>
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  {...register('recordLocator')}
                  type="text"
                  name="recordLocator"
                  variant="filled"
                  label="Recordlocator"
                  inputProps={{ maxLength: 6, style: { textTransform: 'uppercase' } }}
                />
                {errors.recordLocator && (
                  <FormHelperText className={classes.helperText}>
                    {errors.recordLocator?.message?.toString()}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel className={classes.formLabel}>Bookingsource</InputLabel>
                <Select {...register('bookingSource')} type="text" name="bookingSource" variant="filled">
                  {Object.values(BookingSourceEnum).map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {errors.bookingSource && (
                  <FormHelperText className={classes.helperText}>
                    {errors.bookingSource?.message?.toString()}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel className={classes.formLabel}>Channel</InputLabel>
                <Select {...register('channel')} type="text" name="channel" variant="filled">
                  {channels.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {errors.channel && (
                  <FormHelperText className={classes.helperText}>{errors.channel?.message?.toString()}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <TextField
                {...register('ndcBooking')}
                type="text"
                name="ndcBooking"
                variant="filled"
                label="Ndc Booking"
                multiline
                minRows={10}
                maxRows={20}
                style={{ margin: '20px', width: '95%' }}
              />
              {errors.ndcBooking && (
                <FormHelperText className={classes.helperText}>{errors.ndcBooking?.message?.toString()}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                id="submit"
                style={{ margin: '20px' }}
                disabled={disabled}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );

  const SearchForm: React.FC = () => (
    <Grid container direction="row" justifyContent="space-around">
      <form onSubmit={handleSearchSubmit(submitSearchForm)}>
        <Grid container>
          <Grid item xs={4}>
            <TextField
              {...registerSearch('recordLocatorToSearch')}
              type="text"
              name="recordLocatorToSearch"
              variant="filled"
              label="Recordlocator"
              fullWidth
              inputProps={{ maxLength: 6, style: { textTransform: 'uppercase' } }}
            />
            {searchErrors.recordLocatorToSearch && (
              <FormHelperText className={classes.helperText}>
                {searchErrors.recordLocatorToSearch?.message?.toString()}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={8}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              id="submit"
              style={{ marginRight: '20px', marginLeft: '20px', height: '56px' }}
            >
              Search
            </Button>
            <Button variant="contained" color="primary" size="large" onClick={loadData} style={{ height: '56px' }}>
              Reload Data
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );

  const renderTable = () => {
    if (ndcData.length === 0) {
      return <></>;
    }
    return <InvoluntaryChangeTable data={ndcData} />;
  };

  return (
    <div className={classes.root}>
      <SearchForm />
      <div className={classes.tableRoot}>{renderTable()}</div>
      <NdcForm />
    </div>
  );
};

export default InvoluntaryChanges;
