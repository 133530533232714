import { Typography, TextField, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { useState } from 'react';
import CustomButton from '../../components/Button';
import RegistrationHeaderCard from '../../components/RegistrationHeaderCard';

const services = ['Service-Center-Agent', 'Service-Center-Supervisor', 'Service-Center-Teamlead'];

const UserRegistration: React.FC = () => {
  const [marketsState, setMarketsState] = useState({
    Autria: { selected: false, name: 'austria' },
    Netherlands: { selected: false, name: 'netherlands' },
    Belgium: { selected: false, name: 'belgium' },
    Poland: { selected: false, name: 'poland' },
    Germany: { selected: false, name: 'germany' },
    Switzerland: { selected: false, name: 'switzerland' },
  });

  const [productsState, setProductsState] = useState({
    'tui.com/flug': { selected: false, name: 'tui.com/flug' },
    'Fly and Mix': { selected: false, name: 'flyAdnMix' },
    'X-TUI': { selected: false, name: 'x-tui' },
    'TRIPS Flight Only': { selected: false, name: 'tripsFlightOnly' },
    'TUI Ticket Shop': { selected: false, name: 'TuiTicketShop' },
    'TRIPS Package': { selected: false, name: 'tripsPackage' },
    Sparflug: { selected: false, name: 'sparflug' },
  });

  const useStyle = makeStyles((theme: Theme) =>
    createStyles({
      addEmployeeWrapper: {
        width: '60%',
        margin: '0 auto',
        marginTop: '40px',
        marginBottom: '40px',
        backgroundColor: '#E2F3FE',
        border: '1px solid #092A5E',
        borderRadius: '5px',
        minHeight: '500px',
        padding: '30px 150px',
      },
      backIconWrapper: {
        height: '36px',
        width: '36px',
        borderRadius: '18px',
        border: '2px solid #092A5E',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
    }),
  );

  const classes = useStyle();

  const renderMarkets = () => {
    return (
      <div>
        <Typography variant="h6" color="primary">
          <strong>My Markets</strong>
        </Typography>
        {Object.entries(marketsState).map(([name, value], index) => {
          const handleMarketsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setMarketsState({
              ...marketsState,
              [event.target.name]: { selected: event.target.checked, value: value.name },
            });
          };
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={value.selected}
                  onChange={handleMarketsChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name={name}
                />
              }
              label={name}
              key={index}
              style={{ color: '#092A5E', width: '160px' }}
            />
          );
        })}
      </div>
    );
  };

  const renderProducts = () => {
    return (
      <div>
        <Typography variant="h6" color="primary">
          <strong>My Products</strong>
        </Typography>
        {Object.entries(productsState).map(([name, value], index) => {
          const handleProductsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setProductsState({
              ...productsState,
              [event.target.name]: { selected: event.target.checked, value: value.name },
            });
          };
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={value.selected}
                  onChange={handleProductsChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name={name}
                />
              }
              label={name}
              key={index}
              style={{ color: '#092A5E', width: '160px' }}
            />
          );
        })}
      </div>
    );
  };

  const renderRegistration = () => {
    return (
      <div className={classes.addEmployeeWrapper}>
        <Typography color="primary" variant="h5" style={{ padding: '20px 0px' }}>
          SIGN-UP FORM
        </Typography>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-md-6">
            <TextField
              label="USER ROLE"
              placeholder="Select a role"
              variant="filled"
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '0px',
                },
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              select
              defaultValue={services[0]}
            >
              {services.map((service, index) => (
                <MenuItem key={index} value={service}>
                  {service}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="col-md-6">
            <TextField
              label="E-Mail"
              placeholder="max.mustermann@tui.com"
              variant="filled"
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '0px',
                },
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </div>
        </div>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-md-6">
            <TextField
              label="First Name"
              placeholder="Max"
              variant="filled"
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '0px',
                },
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </div>
          <div className="col-md-6">
            <TextField
              label="Last Name"
              placeholder="Mustermann"
              variant="filled"
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '0px',
                },
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </div>
        </div>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-md-6">{renderMarkets()}</div>
          <div className="col-md-6">{renderProducts()}</div>
        </div>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-md-6 d-flex align-items-center">
            <TextField
              label="Team Leads"
              placeholder="Select a role"
              variant="filled"
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '0px',
                },
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              select
              defaultValue="Greta Rastlos"
            >
              {['Greta Rastlos', 'Max Mustermann'].map((service, index) => (
                <MenuItem key={index} value={service}>
                  {service}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div style={{ marginRight: '5px' }}>
              <CustomButton variant="contained" color="primary" size="medium" onClick={() => alert('log out clicked')}>
                Log out
              </CustomButton>
            </div>
            <CustomButton variant="contained" color="danger" size="medium" onClick={() => alert('register clicked')}>
              register
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <RegistrationHeaderCard />
      {renderRegistration()}
    </div>
  );
};

export default UserRegistration;
