import DeferedPayment from '../../components/DeferedPayment';
import { keycloak } from '../../keycloak';
import { Navigate, Route, Routes } from 'react-router-dom';
import { withRouter } from '../withRouter';

export const DeferedPaymentRoutes = () => {
  const isItAdmin = keycloak.hasRealmRole('Role_IT_Administrator');
  const isBusinessAdmin = keycloak.hasRealmRole('Role_Business_Administrator');
  const isServiceCenterAgentIntern = keycloak.hasRealmRole('Role_Service_Center_Agent_Intern');
  const isServiceCenterSupervisorIntern = keycloak.hasRealmRole('Role_Service_Center_Supervisor_Intern');

  return (
    <div className="app-wrapper h-100" style={{ padding: '100px' }}>
      <Routes>
        <Route
          path="/"
          element={
            isServiceCenterAgentIntern || isServiceCenterSupervisorIntern || isItAdmin || isBusinessAdmin ? (
              <DeferedPayment />
            ) : (
              <Navigate to={'/tff/common/error-403'} />
            )
          }
        />
      </Routes>
    </div>
  );
};

export default withRouter(DeferedPaymentRoutes);
