import UserAdministration from './UserAdministration';
import { keycloak } from '../../keycloak';
import { Navigate, Routes, Route } from 'react-router-dom';
import { withRouter } from '../withRouter';

export const UserFormRoutes = () => {
  const isItAdmin = keycloak.hasRealmRole('Role_IT_Administrator');

  return (
    <div className="app-wrapper h-100">
      <Routes>
        <Route path="/" element={isItAdmin ? <UserAdministration /> : <Navigate to={'/tff/common/error-403'} />} />
      </Routes>
    </div>
  );
};

export default withRouter(UserFormRoutes);
