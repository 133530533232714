import { TypeOfSourceGroup } from '../../models';
import { TFF } from '@tff/types';
import * as React from 'react';
import { useContext, useState } from 'react';
import { format, isFuture } from 'date-fns';
import FlexForm from '../forms/FlexForm';
import { Grid, Card, CardContent, Typography, Chip } from '@mui/material';
import CustomButton from '../Button';
import { useIntl } from 'react-intl';
import { isEqual } from 'lodash';
//import ChangeFeeDummy from "./ChangeFeeDummy";
import { apigeeEndpoint } from '../../apis';
import { LoadingContext } from '../../contexts/LoadingContextProvider';
//import charsChanged from "../../util/charsChanged";
import mergeArrays from '../../util/mergeArraysOfObjectsByKeyString';
import * as yup from 'yup';
import { editModelItem, ValidationType, orderType } from '../forms/FlexForm/FormTypes';
import AlertMessage, { messageType } from '../AlertMessage';
import ChangesOverview from '../ChangesOverview';
import CustomDialog, { customDialogIProps } from '../Dialogs';
import backendCallOptions from '../../models/NameChangeApiOptions';
import { TitlesEnum } from '../../models/PaxAndContactsTitles';
import sortAndNormalize from '../../util/sortAndNormalizePassengers';
import changedOnly from '../../util/changedOnly';

interface props {
  sourceGroup: TypeOfSourceGroup;
  flightDetails: TFF.FlightDetails;
  onBack: () => void;
  midocoOrderNo?: number;
  onSuccess: (response: any) => void;
}

export type PaxWithPhoneAndEmail = TFF.Passenger & { PhoneNumber?: string; Email?: string };

type PaxFormValues = {
  FirstName: string;
  LastName: string;
  Title: string;
  DOB: string;
  PhoneNumber: string;
  Email: string;
  Gender: TFF.GenderType;
  Type: TFF.PaxType;
};

interface PaxValidationType extends ValidationType {
  testFunction: (newPax: TFF.Passenger, order: orderType) => boolean;
}
interface paxEditModelItem extends editModelItem {
  fieldName: keyof PaxFormValues;
  tooltip?: string | ((pax: TFF.Passenger) => string);
  onChange?: (value: TFF.Passenger, setValue: (name: string, value: unknown, config?: Object) => void) => void;
  onSubmit?: (value: TFF.Passenger, index?: number) => void;
  dontShowFunction?: (formItem: TFF.Passenger) => boolean;
  validation?: PaxValidationType;
}

const PassengerEditForm: React.FC<props> = ({ sourceGroup, flightDetails, onSuccess, onBack, midocoOrderNo }) => {
  const intl = useIntl();
  const { showLoading, closeLoading } = useContext(LoadingContext);

  // transform passengers to match required types
  const originPassengers = sortAndNormalize(flightDetails.OrderDetails.Passengers);

  const [untouchedPassengers] = useState<PaxWithPhoneAndEmail[]>([...originPassengers]);
  const [newPassengers, setNewPassengers] = useState<PaxWithPhoneAndEmail[]>([...originPassengers]);
  const [hasPassengerChangeTasks, setHasPassengerChangeTasks] = useState<boolean>(false);
  const [formsAreValid, setFormsAreValid] = useState<boolean>(true);
  const [showOverview, setShowOverview] = useState<boolean>(false);
  const [changesVerified, setChangesVerified] = useState<boolean>(false);
  const [message, setMessage] = useState<messageType | null | string>(null);
  const [showCustomDialog, setShowCustomDialog] = useState<customDialogIProps | null>(null);

  let validForms = 0; //count successfull validations
  const updateHasChanges = (changedPassenger, index) => {
    let changes = false;
    newPassengers[index] = changedPassenger;
    newPassengers.forEach((newPassenger, i) => {
      if (!isEqual(newPassenger, untouchedPassengers[i])) {
        changes = true;
        //stop for each if change is found
        return false;
      }
    });
    setHasPassengerChangeTasks(changes);
  };

  // prevents creating empty string values, if before rendering the form the values were undefined
  const removeEmptyAddables = (formData, index) => {
    for (let key in formData) {
      if (formData[key] === '' && untouchedPassengers[index][key] === undefined) {
        delete formData[key];
        delete newPassengers[index][key];
      }
    }
  };

  const handlePassengerFormSubmit = (changedPassenger, index) => {
    validForms++;
    setFormsAreValid(false);
    removeEmptyAddables(changedPassenger, index);
    if (changedPassenger.DOB instanceof Date) {
      //set date back to string
      changedPassenger.DOB = format(changedPassenger.DOB, 'yyyy-MM-dd');
    }

    newPassengers[index] = changedPassenger;
    if (validForms === untouchedPassengers.length) {
      //update state when all forms are valid
      setNewPassengers([...newPassengers]);
      setFormsAreValid(true);
      setShowOverview(true);
    }
    updateHasChanges(changedPassenger, index);
  };

  const handlePassengerFormChange = (changedPassenger, index) => {
    updateHasChanges(changedPassenger, index);
  };

  const handleBack = () => {
    if (showOverview) {
      setShowOverview(false);
      setChangesVerified(false);
      setMessage(null);
    } else {
      onBack();
    }
  };

  //Create form submit button refs to validate forms on confirm click
  const formSubmitBtnRefs = React.useMemo(
    () => untouchedPassengers.map(() => React.createRef()),
    [untouchedPassengers],
  );

  //todo: move backendCall to hooks to prevent duplicated code from contact change. Currently it's not clear how the call will look like at the end
  const backendCall = async (options: backendCallOptions): Promise<void> => {
    try {
      const modifiedPassengers = newPassengers
        // remove not changed passengers
        .filter((nP, index) => {
          return !isEqual(nP, untouchedPassengers[index]);
        })
        .map(newPax => {
          //remove unchanged props
          return {
            new: changedOnly(newPax, flightDetails.OrderDetails.Passengers[newPax.Id]),
            old: flightDetails.OrderDetails.Passengers[newPax.Id],
          };
        });

      showLoading(options.loadingMsg);

      const postData = {
        action: 'UPDATE_PASSENGERS',
        type: 'CONFIRM',
        recordLocator: flightDetails?.OrderSummary?.RecordLocator,
        bookingSource: flightDetails?.OrderSummary?.SourceDetails?.System,
        syncMidoco: true,
        midocoOrderNo: midocoOrderNo,
        modifiedPassengers: modifiedPassengers,
        ...options.postData,
      };

      const response = await apigeeEndpoint.post(`/passenger-amend`, postData);

      if (response.data.status === 'SUCCESS') {
        if (options.success) {
          options.success(response.data);
        } else {
          //default success
          setMessage({
            msg: 'Request successfull.', //'Changes approved. Click on \'SUBMIT\' to commit changes.',
            type: 'success',
          });
        }
      } else {
        setChangesVerified(false);
        setShowOverview(false);
        setMessage({
          msg:
            response.data.error?.Message ||
            response.data.error?.Value ||
            response.data.errorMessage ||
            response.data.error,
          type: 'error',
        });
      }
    } catch (error) {
      setChangesVerified(false);
      setMessage({
        msg: error.message,
        type: 'error',
      });
    } finally {
      closeLoading();
    }
  };

  const sendRequest = async () => {
    await backendCall({
      loadingMsg: intl.formatMessage({ id: 'edit.passenger.pleaseWait' }),
      postData: {
        syncMidoco: false,
        type: 'VALIDATE',
      },
      success: () => {
        setChangesVerified(true);
        setMessage({
          msg: intl.formatMessage({ id: 'edit.passenger.changesApproved' }),
          type: 'success',
        });
      },
    });
  };

  const sendUpdate = async () => {
    await backendCall({
      loadingMsg: intl.formatMessage({ id: 'edit.passenger.pleaseWait' }),
      success: responseData => {
        setChangesVerified(false);
        onSuccess(responseData);
      },
    });
  };

  const sendUpdateNDC = async () => {
    await backendCall({
      loadingMsg: intl.formatMessage({ id: 'edit.passenger.pleaseWait' }),
      postData: {
        syncMidoco: true,
        bookingSource: `${flightDetails?.OrderSummary?.SourceDetails?.System}-${flightDetails?.OrderSummary?.DesignatedAirlineCode}`,
        orderId: flightDetails?.OrderSummary?.BookingId,
        ndcAgencyDetails: {
          agencyId: flightDetails?.OrderSummary?.SourceDetails?.AgencyId,
          iataNumber: flightDetails?.OrderSummary?.SourceDetails?.IataNumber,
          officeId: flightDetails?.OrderSummary?.SourceDetails?.OfficeId,
        },
      },
      success: responseData => {
        onSuccess(responseData);
      },
    });
  };

  const confirmNDC = async () => {
    setShowCustomDialog({
      onConfirm: () => {
        setShowCustomDialog(null);
        sendUpdateNDC();
      },
      onCancel: () => setShowCustomDialog(null),
      children: intl.formatMessage({ id: 'edit.passenger.NDCwarning.text' }),
      title: intl.formatMessage({ id: 'edit.passenger.NDCwarning.title' }),
      initialOpen: true,
      confirmButtonText: intl.formatMessage({ id: 'edit.passenger.NDCwarning.send' }),
      cancelButtonText: intl.formatMessage({ id: 'edit.passenger.NDCwarning.cancel' }),
    });

    //sendUpdateNDC();
  };
  const handleEditDone = () => {
    validForms = 0;
    setMessage(null);

    formSubmitBtnRefs.forEach(ref => {
      try {
        // @ts-ignore
        ref?.current.click();
      } catch (er) {
        setMessage({
          type: 'error',
          msg: er.msg,
        });
      }
    });
  };

  const setGender = (title: string): 'MALE' | 'OTHER' | 'FEMALE' => {
    if (title === 'MR') {
      return 'MALE';
    } else if (title === 'MRS') {
      return 'FEMALE';
    }
    return 'OTHER';
  };

  const setTitle = (gender: string): 'MR' | 'MRS' | 'OTHER' => {
    if (gender === 'MALE') {
      return 'MR';
    } else if (gender === 'FEMALE') {
      return 'MRS';
    }
    return 'OTHER';
  };

  const dontShowFieldIfCHDorINF = passenger => {
    return passenger.Type === 'INF' || passenger.Type === 'CHD';
  };

  /**
   * The Edit Model for all sources is used to render the form
   * @typedef {Object} paxEditModelItem
   * @property {string} fieldName - The name of the field
   * @property {string} label - The label to be displayed for the field
   * @property {string} [type] - The type of the input element if not 'select' or 'date', 'select' -> MUI <Select />, 'date' -> MUI <KeyboardDatePicker/>
   * @property {string[]} [options] - The options available for the select input
   * @property {Function} [onChange] - The function to be called on change of the field
   * @property {boolean} [disabled] - If true, the field will be disabled
   * @property {string} [tooltip] - The tooltip to be displayed for the field
   * @property {Object} {validation} - Object for specific validations
   */
  const editModel: paxEditModelItem[] = [
    {
      fieldName: 'Title',
      label: intl.formatMessage({ id: 'edit.passenger.Title' }),
      type: 'select',
      size: 1,
      options: [''].concat(Object.values(TitlesEnum)),
      onChange: (psg, setValue) => {
        if (psg.Title) {
          const gender = setGender(psg.Title);
          if (psg.Gender !== gender) {
            setValue('Gender', gender);
            psg.Gender = gender;
          }
        }
      },
      //dontShowFunction: dontShowFieldIfCHDorINF,
    },
    {
      fieldName: 'Gender',
      label: intl.formatMessage({ id: 'edit.passenger.Gender' }),
      type: 'select',
      size: 2,
      options: ['FEMALE', 'MALE'],
      onChange: (psg, setValue) => {
        if (psg.Gender) {
          const title = setTitle(psg.Gender);
          if (psg.Title !== title) {
            setValue('Title', title);
            psg.Title = setTitle(psg.Gender);
          }
        }
      },
      //dontShowFunction: dontShowFieldIfCHDorINF,
    },
    {
      fieldName: 'FirstName',
      label: intl.formatMessage({ id: 'edit.passenger.FirstName' }),
      size: 4,
    },
    {
      fieldName: 'LastName',
      label: intl.formatMessage({ id: 'edit.passenger.LastName' }),
      size: 5,
    },
    /*{
      fieldName: 'Type',
      disabled: true,
      label: intl.formatMessage({ id: 'edit.passenger.type' }),
      tooltip: intl.formatMessage({ id: 'edit.passenger.paxChangeNotSupported' })
    },*/
    {
      fieldName: 'DOB',
      label: intl.formatMessage({ id: 'edit.passenger.DOB' }),
      type: 'date',
      /*tooltip: intl.formatMessage({id: 'edit.passenger.noPaxTypeChangeHint'})*/
    },
  ];

  type TypeOfSourceSpecificEditModel = {
    [key in TypeOfSourceGroup]?: paxEditModelItem[];
  };

  const sourceSpecificEditModels: TypeOfSourceSpecificEditModel = {
    TUI_NSK: [
      {
        fieldName: 'FirstName',
        size: 3,
      },
      {
        fieldName: 'LastName',
        size: 4,
      },
      {
        fieldName: 'Gender',
        size: 2,
      },
    ],
    TUI_NDC: [
      /*{
        fieldName: 'FirstName',
        validation: {
          testName: 'max-letter-change',
          testFailedMsg: intl.formatMessage({id: 'edit.passenger.max3Changes'}),//'Die Änderung darf max 3 Buchstaben aus Vor- und Nachnamen betreffen.',
          testFunction: (newPax,order) => {
            return charsChanged(newPax.FirstName,order.OrderDetails.Passengers[newPax.Id].FirstName) + charsChanged(newPax.LastName,order.OrderDetails.Passengers[newPax.Id].LastName) < 4;
          }
        }
      },
      {
        fieldName: 'LastName',
        validation: {
          testName: 'max-letter-change',
          testFailedMsg: intl.formatMessage({id: 'edit.passenger.max3Changes'}),
          testFunction: (newPax,order) => {
            //console.log(newPax,order);
            return charsChanged(newPax.FirstName,order.OrderDetails.Passengers[newPax.Id].FirstName) + charsChanged(newPax.LastName,order.OrderDetails.Passengers[newPax.Id].LastName) < 4;
          }
        }
      },*/
      {
        fieldName: 'Email',
        label: intl.formatMessage({ id: 'edit.passenger.Email' }),
        type: 'email',
        size: 4,
        addable: true,
        dontShowFunction: dontShowFieldIfCHDorINF,
      },
      {
        fieldName: 'PhoneNumber',
        label: intl.formatMessage({ id: 'edit.passenger.PhoneNumber' }),
        type: 'phone',
        addable: true,
        size: 5,
        dontShowFunction: dontShowFieldIfCHDorINF,
      },
      {
        fieldName: 'DOB',
        label: intl.formatMessage({ id: 'edit.passenger.DOB' }),
        type: 'date',
        size: 3,
        disabled: true,
        tooltip: intl.formatMessage({ id: 'edit.passenger.FieldNotProvidedBySource' }),
      },
    ],
  };

  //no carrier rules yet...
  // todo: clarify if carrier based validation is necessary or
  //  it should be not possible to apply a name change when different carriers are involved
  /*
  type TypeOfCarrierSpecificEditModel = {
    [key in TFF.TypeOfSource]?: (ValidationType & {testField: keyof PaxFormValues})[];
  }
  const carrierValidations:TypeOfCarrierSpecificEditModel = {
    "AF":[

    ]
  };*/

  const mergedEditModel = mergeArrays('fieldName', editModel, sourceSpecificEditModels[sourceGroup]);

  const notValidDOBMessage = intl.formatMessage({ id: 'edit.passenger.DOB.required' });

  // .phone() had a bug I was not able to localise. skip phone validation for now.
  //const  phoneSchema = yup.string().phone();

  let paxSchema = yup.object().shape({
    Title: yup.string().when({
      is: exists => !!exists, //only validate if rendered
      then: rule => rule.required(intl.formatMessage({ id: 'edit.passenger.Title.required' })),
    }),
    FirstName: yup
      .string()
      .required(intl.formatMessage({ id: 'edit.passenger.FirstName.required' }))
      .max(64, intl.formatMessage({ id: 'edit.max64' })),
    LastName: yup
      .string()
      .required(intl.formatMessage({ id: 'edit.passenger.LastName.required' }))
      .max(64, intl.formatMessage({ id: 'edit.max64' })),
    DOB: yup
      .date()
      .required(notValidDOBMessage)
      .test('test-date', intl.formatMessage({ id: 'edit.passenger.DOBinFuture' }), value => {
        return value !== undefined && !isFuture(value);
      })
      .typeError(intl.formatMessage({ id: 'edit.passenger.DOB.required' })),
    /* PhoneNumber: yup
      .string()
      .test('test-phone', intl.formatMessage({ id: 'edit.passenger.PhoneNumber.error' }), value => {
        if (value) return phoneSchema.isValidSync(value);
        return true;
      }),*/
    Email: yup.string().email(intl.formatMessage({ id: 'edit.passenger.Email.error' })), //matches(emailRegEx, intl.formatMessage({ id: 'edit.passenger.Email.error' })),
  });

  //get editModel validations and merge them into paxSchema
  mergedEditModel
    .filter(modelItem => modelItem.validation)
    .forEach(modelItemWithValidation => {
      if (modelItemWithValidation?.validation) {
        paxSchema = paxSchema.shape({
          [modelItemWithValidation.fieldName]: yup
            .string()
            .test(
              modelItemWithValidation.validation.testName,
              modelItemWithValidation.validation.testFailedMsg,
              function () {
                return modelItemWithValidation.validation.testFunction(this.parent, flightDetails);
              },
            ),
        });
      }
    });

  const isNDC = sourceGroup.includes('NDC');

  const isNSK = sourceGroup.includes('NSK');

  const ndcLabels = {
    Email: intl.formatMessage({ id: 'edit.passenger.Email' }),
    PhoneNumber: intl.formatMessage({ id: 'edit.passenger.PhoneNumber' }),
  };

  const globalLabels = {
    Title: intl.formatMessage({ id: 'edit.passenger.Title' }),
    FirstName: intl.formatMessage({ id: 'edit.passenger.FirstName' }),
    LastName: intl.formatMessage({ id: 'edit.passenger.LastName' }),
    DOB: intl.formatMessage({ id: 'edit.passenger.DOB' }),
    Gender: intl.formatMessage({ id: 'edit.passenger.Gender' }),
    Type: intl.formatMessage({ id: 'edit.passenger.type' }),
  };

  const OverviewLabels = isNDC
    ? {
        ...globalLabels,
        ...ndcLabels,
      }
    : globalLabels;

  return untouchedPassengers ? (
    <Grid container justifyContent="space-between" spacing={2}>
      {showOverview && (
        <>
          <ChangesOverview
            originalRecords={untouchedPassengers}
            newRecords={newPassengers}
            labels={{ ...OverviewLabels }}
          />
          {/*{isNDC() && <ChangeFeeDummy editedPassengers={newPassengers} originPassengers={untouchedPassengers}/>}*/}
        </>
      )}
      {!showOverview &&
        untouchedPassengers &&
        newPassengers.map((p, index) => (
          <Grid item xs={12} key={p.Id}>
            <Card>
              <CardContent>
                <Typography variant="body1" color="primary" display="inline">
                  {`${intl.formatMessage({ id: 'edit.passenger.passenger' })} #${index + 1}`}
                </Typography>{' '}
                <Chip label={p.Type} color="primary" />
                <FlexForm
                  style={{ marginTop: '15px' }}
                  onChange={handlePassengerFormChange}
                  formItemIndex={index}
                  direction="row"
                  margin="none"
                  schema={paxSchema}
                  editModel={mergedEditModel}
                  formSubmitBtnRef={formSubmitBtnRefs[index]}
                  formItem={newPassengers[index]}
                  onSubmit={handlePassengerFormSubmit}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      {/* the commit warning in jetBrains is a bug... :/ */}
      {showCustomDialog && <CustomDialog {...showCustomDialog} />}

      {message && (
        <Grid item xs={12}>
          <AlertMessage message={message} />
        </Grid>
      )}
      {!formsAreValid && (
        <Grid item xs={12}>
          <AlertMessage
            message={{ type: 'error', msg: intl.formatMessage({ id: 'edit.passenger.formsNotValidMessage' }) }}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <CustomButton variant="contained" color="primary" size="large" onClick={handleBack}>
          {intl.formatMessage({ id: 'edit.passenger.back' })}
        </CustomButton>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'right' }}>
        {/* First Form submit btn*/}
        {!showOverview && (
          <CustomButton
            variant="contained"
            color="danger"
            disabled={!hasPassengerChangeTasks}
            size="large"
            onClick={handleEditDone}
          >
            {
              //confirm data btn
              intl.formatMessage({ id: 'edit.passenger.next' })
            }
          </CustomButton>
        )}

        {/* First overview confirm btn NSK */}
        {showOverview && !changesVerified && isNSK && (
          <CustomButton
            variant="contained"
            color="danger"
            disabled={!hasPassengerChangeTasks}
            size="large"
            onClick={sendRequest}
          >
            {
              //confirm data btn
              intl.formatMessage({ id: 'edit.passenger.validate' })
            }
          </CustomButton>
        )}

        {/* Submit changes to NSK btn */}
        {showOverview && changesVerified && isNSK && (
          <CustomButton
            variant="contained"
            color="danger"
            disabled={!hasPassengerChangeTasks}
            size="large"
            onClick={sendUpdate}
          >
            {
              //confirm data btn
              intl.formatMessage({ id: 'edit.passenger.submit' })
            }
          </CustomButton>
        )}

        {/* Send changes to NDC */}
        {showOverview && isNDC && (
          <CustomButton
            variant="contained"
            color="danger"
            disabled={!hasPassengerChangeTasks}
            size="large"
            onClick={confirmNDC}
          >
            {
              //confirm data btn
              intl.formatMessage({ id: 'edit.passenger.submit' })
            }
          </CustomButton>
        )}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default PassengerEditForm;
