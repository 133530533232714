/**
 * Merge arrays of objects by value of a specified key and overwrite/enhance
 * @param {string} mergeKey - The key to be used as the identifier for merging the objects
 * @param {Array<Object>} arrays - The arrays of objects to be merged.
 * @returns {Array<Object>} - The merged array of objects
 */
const mergeArrays = (mergeKey: string, ...arrays) => {
  // Use filter() to remove any undefined arrays
  const validArrays = arrays.filter(array => array !== undefined);

  // Use reduce() to merge the valid arrays
  return validArrays.reduce((merged, array) => {
    array.forEach(object => {
      // Use findIndex() to check if an object with the same mergeKey already exists in the merged array
      const existingIndex = merged.findIndex(el => el[mergeKey] === object[mergeKey]);
      if (existingIndex !== -1) {
        // If an object with the same mergeKey already exists, overwrite the values
        merged[existingIndex] = { ...merged[existingIndex], ...object };
      } else {
        // If an object with the same mergeKey does not exist, push it to the merged array
        merged.push(object);
      }
    });
    return merged;
  }, []);
};
export default mergeArrays;
