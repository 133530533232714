import * as React from 'react';
import { FieldInputProps } from 'react-final-form';
import { TUI_LIGHT } from '../values/style-values';

interface TFFSelectInputProps {
  inputId: string;
  labelText: string;
  disabled?: boolean;
  children: React.ReactNode;
  inputFromRedux: FieldInputProps<any>;
}

export const TFFSelectInput: React.FC<TFFSelectInputProps> = ({
  inputId,
  labelText,
  disabled,
  children,
  inputFromRedux,
}) => {
  return (
    <label htmlFor={inputId} className={`input input-select${disabled ? ' disabled' : ''}`}>
      <span className="label">{labelText}</span>
      <span className="group">
        <select id={inputId} disabled={disabled} {...inputFromRedux}>
          {children}
        </select>
        <span className="icon-control">
          <span
            className="icon medium chevron-down"
            data-theme={TUI_LIGHT}
            style={{ color: 'var(--color-functional-neutral-900)' }}
          ></span>
        </span>
      </span>
    </label>
  );
};

export default TFFSelectInput;
