import { ILanguageItem } from './data';

type Props = {
  language: ILanguageItem;
  switchLanguage: (language: ILanguageItem) => void;
  handleRequestClose: () => void;
};

export const LanguageItemX = (props: Props) => {
  return <div></div>;
};
const LanguageItem = ({ language, switchLanguage, handleRequestClose }: Props) => {
  const { icon, name } = language;
  return (
    <li
      className="pointer"
      onClick={() => {
        handleRequestClose();
        switchLanguage(language);
      }}
    >
      <div className="d-flex align-items-center">
        <i className={`flag flag-24 flag-${icon}`} />
        <h4 className="mb-0" style={{ marginLeft: '16px' }}>
          {name}
        </h4>
      </div>
    </li>
  );
};

export default LanguageItem;
