import * as React from 'react';
import CustomDialog from '../../Dialogs';
import { Field, Form as FinalForm } from 'react-final-form';
import { Button, Grid, TextField, Typography } from '@mui/material';
import moment from 'moment';

export type ChangeForm = {
  ticketTimeLimit: string;
};

interface IProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (formValues: ChangeForm) => void;
}

const defaultDate = moment(new Date()).format('YYYY-MM-DDTHH:mm');

const ChangeDialog: React.FC<IProps> = ({ open, onCancel, onConfirm }) => {
  return (
    <CustomDialog initialOpen={open} form width="md">
      <FinalForm<ChangeForm>
        onSubmit={onConfirm}
        initialValues={{}}
        validateOnBlur={false}
        render={({ values }) => {
          return (
            <Grid container>
              <Grid item xs={12}>
                <Grid>
                  <Typography color="primary" variant="h6">
                    <strong>Changing Ticket Time Limit for selected Flights</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 10 }}>
                  <Field name="ticketTimeLimit">
                    {({ input }) => (
                      <TextField
                        variant="standard"
                        {...input}
                        label="Ticket Time Limit"
                        type="datetime-local"
                        //Default Date not Showing
                        defaultValue={defaultDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 0,
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid container spacing={2} justifyContent="flex-end" style={{ padding: 10 }}>
                  <Grid item xs={2}>
                    <Button color="secondary" onClick={onCancel} fullWidth>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={() => onConfirm(values)}
                      fullWidth
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      />
    </CustomDialog>
  );
};

export default ChangeDialog;
