import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useKeycloak } from '@react-keycloak/web';
import { IntlMessages } from '../../util';

export const UserInfo = () => {
  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);
  const { keycloak } = useKeycloak();

  const handleClick = (event: any) => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Avatar className="size-30 mr-2" onClick={handleClick} style={{ backgroundColor: '#DCDCDC' }}>
        <small>
          {keycloak?.profile ? keycloak.profile.firstName?.charAt(0) + '' + keycloak.profile.lastName?.charAt(0) : 'DU'}
        </small>
      </Avatar>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        {/*<MenuItem onClick={handleRequestClose}>*/}
        {/*  <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />*/}
        {/*  <IntlMessages id="popup.profile" />*/}
        {/*</MenuItem>*/}
        {/*<MenuItem onClick={handleRequestClose}>*/}
        {/*  <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />*/}
        {/*  <IntlMessages id="popup.setting" />*/}
        {/*</MenuItem>*/}
        <MenuItem
          onClick={() => {
            handleRequestClose();
            keycloak?.logout();
          }}
        >
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

          <IntlMessages id="global.logout" />
        </MenuItem>
      </Menu>
    </div>
  );
};
