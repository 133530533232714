import { format } from 'date-fns';
import { MUIDataTableMeta } from 'mui-datatables';
import { Link } from 'react-router-dom';
import { ITableColumns } from '../../models';
import { IntlMessages } from '../../util';

export const columnDefinition: ITableColumns[] = [
  {
    name: 'no',
    label: <IntlMessages id="pages.retrieveList.list.no" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (_value: string | number, tableMeta: MUIDataTableMeta) => {
        return `${tableMeta.rowIndex + 1}`;
      },
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap',
          position: 'sticky',
          left: '0',
          background: 'white',
          zIndex: 100,
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          whiteSpace: 'nowrap',
          position: 'sticky',
          left: '0',
          background: 'white',
          zIndex: 101,
        },
      }),
    },
  },
  {
    name: 'status',
    label: <IntlMessages id="pages.retrieveList.list.status" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
      },
    },
  },
  {
    name: 'recordLocator',
    label: <IntlMessages id="pages.retrieveList.list.bookingid" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number, tableMeta: MUIDataTableMeta) => {
        return <Link to={`/tff/order/${value}@${tableMeta.rowData[7]}`}>{value}</Link>;
      },
    },
  },
  {
    name: 'clientPnr',
    label: <IntlMessages id="pages.retrieveList.list.atcomId" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
      },
    },
  },

  {
    name: 'tfmPnr',
    label: <IntlMessages id="pages.retrieveList.list.tfmPnr" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number, tableMeta: MUIDataTableMeta) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
      },
    },
  },
  {
    name: 'bookingSource',
    label: <IntlMessages id="pages.retrieveList.list.bookingsrc" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
      },
    },
  },

  {
    name: 'ticketTimeLimit',
    label: <IntlMessages id="pages.retrieveList.list.ticketTimeLimit" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
          </div>
        );
      },
    },
  },

  {
    name: 'outDeparture',
    label: <IntlMessages id="pages.retrieveList.list.outDeparture" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
          </div>
        );
      },
    },
  },

  {
    name: 'returnDeparture',
    label: <IntlMessages id="pages.retrieveList.list.inDeparture" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return (
          <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
            {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
          </div>
        );
      },
    },
  },

  {
    name: 'createdAt',
    label: <IntlMessages id="pages.retrieveList.list.createdAt" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
          </div>
        );
      },
    },
  },

  {
    name: 'messageType',
    label: <IntlMessages id="ndc.table.messageType" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
      },
    },
  },

  {
    name: 'decision',
    label: <IntlMessages id="ndc.table.decision" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
      },
    },
  },
  {
    name: 'receivedAt',
    label: <IntlMessages id="ndc.table.received" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return (
          <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
            {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
          </div>
        );
      },
    },
  },

  {
    name: 'remindDate',
    label: <IntlMessages id="ndc.table.remindDate" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return (
          <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
            {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
          </div>
        );
      },
    },
  },
  {
    name: 'autoConfirmDate',
    label: <IntlMessages id="ndc.table.autoConfirmDate" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return (
          <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
            {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
          </div>
        );
      },
    },
  },
  {
    name: 'classification',
    label: <IntlMessages id="ndc.table.classification" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
      },
    },
  },
  {
    name: 'version',
    label: 'Version',
    options: {
      display: true,
      filter: true,
      filterList: [],
    },
  },
];
