import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { apigeeEndpoint } from '../../apis';
import { keycloak } from '../../keycloak';
import { ICampaign } from '@tff/types/TFF';

export enum CAMPAIGN_STATES {
  UNKOWN = 'UNKOWN',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

type CampaignPayload = {
  campaign?: ICampaign;
  state: CAMPAIGN_STATES;
  error?: any;
  campaignLoading?: boolean;
};

export interface ICampaignStoreState {
  campaign?: ICampaign;
  campaigns: ICampaign[];
  state: CAMPAIGN_STATES;
  error?: any;
  campaignLoading?: boolean;
}

let initialState: ICampaignStoreState = {
  campaign: undefined,
  campaigns: [],
  state: CAMPAIGN_STATES.UNKOWN,
  error: undefined,
  campaignLoading: false,
};

export const saveCampaign = createAsyncThunk(
  'campaign/saveCampaign',
  async (campaign: ICampaign, { rejectWithValue }) => {
    try {
      const response = await apigeeEndpoint.post('campaign', {
        requestModel: campaign,
        editor: keycloak.profile?.email,
      });

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const loadCampaigns = createAsyncThunk('campaign/loadCampaigns', async (_, { rejectWithValue }) => {
  try {
    const response = await apigeeEndpoint.get('campaign');

    if (response.status === 200) {
      return response.data.sort((a: ICampaign, b: ICampaign) =>
        (a.priority as number) < (b.priority as number) ? -1 : 1,
      );
    } else {
      return rejectWithValue(response.data);
    }
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignState: (state, action: PayloadAction<{ state: CAMPAIGN_STATES }>) => {
      state.state = action.payload.state;
    },
    setCampaign: (state, action: PayloadAction<CampaignPayload>) => {
      state.campaign = action.payload.campaign;
      state.state = CAMPAIGN_STATES.FINISHED;
      state.campaignLoading = false;
    },
    resetCampaign: state => {
      state.campaign = undefined;
      state.state = CAMPAIGN_STATES.UNKOWN;
      state.campaignLoading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(saveCampaign.pending, (state, action) => {
        state.state = CAMPAIGN_STATES.UNKOWN;
        state.campaignLoading = true;
      })
      .addCase(saveCampaign.fulfilled, (state, action) => {
        state.campaign = action.payload;
        state.state = CAMPAIGN_STATES.FINISHED;
        state.campaignLoading = false;
      })
      .addCase(saveCampaign.rejected, (state, action) => {
        state.error = action.error.message;
        state.state = CAMPAIGN_STATES.ERROR;
        state.campaignLoading = false;
      })
      .addCase(loadCampaigns.pending, (state, action) => {
        state.state = CAMPAIGN_STATES.UNKOWN;
        state.campaignLoading = true;
      })
      .addCase(loadCampaigns.fulfilled, (state, action) => {
        state.campaigns = action.payload;
        state.state = CAMPAIGN_STATES.FINISHED;
        state.campaignLoading = false;
      })
      .addCase(loadCampaigns.rejected, (state, action) => {
        state.error = action.payload;
        state.state = CAMPAIGN_STATES.ERROR;
        state.campaignLoading = false;
      });
  },
});

export const { setCampaignState, setCampaign, resetCampaign } = campaignSlice.actions;

export default campaignSlice.reducer;
