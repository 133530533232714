import * as React from 'react';
import TFFTextInput from '../../../styled-components/TFFTextInput';
import TFFSelectInput from '../../../styled-components/TFFSelectInput';
import { FieldInputProps } from 'react-final-form';

interface TFFTextInputProps {
  inputId: string;
  labelText: string;
  disabled?: boolean;
  input: FieldInputProps<any>;
  isBookingId: boolean;
}

export const renderTFFTextInput = ({ inputId, labelText, disabled, input, isBookingId }: TFFTextInputProps) => {
  return (
    <TFFTextInput
      inputId={inputId}
      labelText={labelText}
      disabled={disabled}
      inputFromRedux={input}
      isBookingId={isBookingId}
    />
  );
};

interface TFFSelectInputProps {
  inputId: string;
  labelText: string;
  disabled?: boolean;
  children: React.ReactNode;
  input: FieldInputProps<any>;
}

export const renderTFFSelectInput = ({ inputId, labelText, disabled, children, input }: TFFSelectInputProps) => {
  return (
    <TFFSelectInput
      inputId={inputId}
      labelText={labelText}
      disabled={disabled}
      children={children}
      inputFromRedux={input}
    />
  );
};
