import { TFF } from '@tff/types';

const swapBaggage = (paxId: string, newBaggage: TFF.SsrDetails, setSsrsToAdd, includedSsrs) => {
  setSsrsToAdd(prevState => {
    const newSsrs = prevState[paxId].reduce((acc: TFF.SsrDetails[], ssr) => {
      if (ssr.code.startsWith('BA') && ssr.segmentOnd === newBaggage.segmentOnd) {
        if (ssr.code.includes('remove')) {
          if (newBaggage.code === ssr.code.replace('-remove', '')) {
            return acc;
          } else {
            acc.push(ssr);
          }
        } else {
          acc.push(newBaggage);
        }
        if (
          includedSsrs[paxId]?.find(
            includedSsr => includedSsr.code === ssr.code && includedSsr.segmentOnd === ssr.segmentOnd,
          )
        ) {
          acc.push({
            ...ssr,
            code: ssr.code + '-remove',
          });
        }
      } else {
        acc.push(ssr);
      }
      return acc;
    }, []);
    return {
      ...prevState,
      [paxId]: newSsrs,
    };
  });
};

export const addSsrsToAdd = (paxId: string, addSsrs: TFF.SsrDetails, setSsrsToAdd, ssrsToAdd, includedSsrs) => {
  if (addSsrs.code.startsWith('BA')) {
    const existingBaggage = ssrsToAdd[paxId]?.find(ssr => {
      return ssr.code.startsWith('BA') && ssr.segmentOnd === addSsrs.segmentOnd;
    });
    if (existingBaggage) {
      swapBaggage(paxId, addSsrs, setSsrsToAdd, includedSsrs);
      return;
    }
  }
  setSsrsToAdd(prevState => {
    if (prevState[paxId].filter(ssr => ssr.code === addSsrs.code && ssr.segmentOnd === addSsrs.segmentOnd).length > 0) {
      return {
        ...prevState,
        [paxId]: prevState[paxId].map(ssr => {
          if (ssr.code === addSsrs.code && ssr.segmentOnd === addSsrs.segmentOnd) {
            return { ...ssr, amount: ssr.amount + 1, tuiRelated: true };
          }
          return ssr;
        }),
      };
    }
    return {
      ...prevState,
      [paxId]: [...(prevState[paxId] || []), addSsrs],
    };
  });
};
