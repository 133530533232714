import { ReactElement } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const getDisplayString = (sub: any) => {
  const arr = sub.split('-');
  if (arr.length > 1) {
    return arr[0].charAt(0).toUpperCase() + arr[0].slice(1) + ' ' + arr[1].charAt(0).toUpperCase() + arr[1].slice(1);
  } else {
    return sub.charAt(0).toUpperCase() + sub.slice(1);
  }
};

// @ts-ignore
const getUrlString = (path, sub, index) => {
  if (index === 0) {
    return '#/';
  } else {
    return '#/' + path.split(sub)[0] + sub;
  }
};

interface IProps {
  title: ReactElement;
  subtitle?: ReactElement;
}

const ContainerHeader = ({ title, subtitle }: IProps) => {
  const location = useLocation();
  const path = location.pathname.substr(1);
  const subPath = path.split('/');

  return (
    <>
      <div className="page-heading d-sm-flex flex-column">
        <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
          <h2 className="title mb-3 mb-sm-0">{title}</h2>

          <Breadcrumb className="mb-0" as="nav">
            {subPath.map((sub, index) => {
              return (
                <Breadcrumb.Item
                  active={subPath.length === index + 1}
                  as={subPath.length === index + 1 ? 'span' : 'span'}
                  key={index}
                  href={getUrlString(path, sub, index)}
                >
                  {getDisplayString(sub)}
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </div>
        <div className="subtitle d-sm-flex mt-2">{subtitle}</div>
      </div>
    </>
  );
};

export default ContainerHeader;
