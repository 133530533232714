import * as React from 'react';
import { Grid, Step, StepConnector, StepLabel, Stepper, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import CustomButton from '../Button';
import { TFF } from '@tff/types';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import { format } from 'date-fns';
import { CheckDifferences, DiffedResult } from '../../util/check-differences';
import ReactJson from 'react-json-view';
import { ValidateChanges } from '@tff/flight-validation';

import { useIntl } from 'react-intl';
import { TypeOfStatus } from '../../hooks/use-confirm-booking';
import { FlightLand, FlightTakeoff } from '@mui/icons-material';

const formatDate = (t?: string, pattern = 'dd.MM.yyyy - HH:mm'): string | undefined => {
  if (t) {
    return format(new Date(t), pattern);
  }

  return undefined;
};

export type DiffedSegment = Partial<TFF.Segment> & { BookingClass?: string; CabinClass?: string };

interface IProps {
  airports: TFF.IAirport[];
  journeysFromMidoco: TFF.Journey[];
  journeysFromSource: TFF.Journey[];
  confirmStatus?: TypeOfStatus;
  confirmError?: Error | undefined;
  onConfirmChangeFN: () => void;
  onConfirmBookingAfterAdustmentFN: () => void;
  onCancelBookingFN: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '5px',
      borderColor: theme.palette.text.primary,
      margin: '20px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: '#E2F3FE',
      zIndex: 1,
    },
    statusRoot: { padding: '10px' },
    changesRoot: {
      backgroundColor: 'white',
      padding: '10px',
      zIndex: -1,
    },
    trackList: {
      alignContent: 'center',
      alignItems: 'center',
      counterReset: 'stepCount',
      display: 'flex',
      justifyContent: 'space-around',
      margin: '10vh auto 20vh',
      marginLeft: '-10px',
      marginRight: '10px',
      zIndex: 2,
      marginTop: 0,
      marginBottom: 0,
    },
    trackListItem: {
      background: '#092A5E',
      color: 'black',
      content: '""',
      display: 'flex',
      flexGrow: 1,
      height: '.2em',
      lineHeight: '1em',
      margin: 0,
      position: 'relative',
      textAlign: 'right',
      zIndex: -1,
      '&::before': {
        color: 'white',
        background: '#092A5E',
        borderRadius: '50%',
        content: '""',
        height: '1.5em',
        left: '-1em',
        lineHeight: '1em',
        position: 'absolute',
        textAlign: 'center',
        top: '-.65em',
        width: '1.5em',
      },
      '&:last-child': {
        flexBasis: 0,
        flexGrow: 0,
        flexShrink: 1,
      },
    },
    trackLabel: {
      color: '#092A5E',
      marginTop: '-70px',
      marginLeft: '-20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    changedtrackLabel: {
      display: 'flex',
      marginLeft: '-18px',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
      marginTop: '-35px',
      height: '70px',
    },
    flexColumn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    detailsRoot: {
      display: 'flex',
    },
    flightNumberRoot: {
      display: 'flex',
      flexDirectiom: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    trackRoot: {
      margin: 0,
      padding: 0,
      '& .MuiStepper-root': {
        backgroundColor: 'transparent',
      },
    },
    redIcon: {
      color: 'red',
      width: '15px',
    },

    primaryIcon: {
      color: theme.palette.primary.main,
      width: '15px',
    },
    stepLabel: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiStepLabel-iconContainer': {
        paddingRight: 0,
      },
    },
  }),
);

interface JourneyTrackProps {
  diffedResult: DiffedResult;
  newStatus: boolean;
}

const useConnectorStyles = makeStyles({
  stepConnector: (labels: { label?: string; currentColor: string }[]) => {
    const styles = {};

    labels.forEach(({ currentColor }, index) => {
      if (index < labels.length - 1) {
        styles[`&:nth-child(${2 * index + 2})`] = { color: currentColor };
      }
    });

    return styles;
  },
  stepConnectorLine: {
    borderColor: 'currentColor',
  },
});

const FlightChanges: React.FC<IProps> = ({
  airports,
  journeysFromMidoco,
  journeysFromSource,
  confirmStatus,
  onConfirmBookingAfterAdustmentFN,
  confirmError,
  onConfirmChangeFN,
  onCancelBookingFN,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  // filter cancelledMidocoSegments:
  // this is required because Midoco can has more segments (state = 'XX') than the booking has.
  const journeyList: TFF.Journey[] = [];
  for (let journey of journeysFromMidoco) {
    const segmentList: TFF.Segment[] = [];
    for (let segment of journey.Segments) {
      if (segment.State === 'OK') {
        segment.State = 'HK';
        segmentList.push(segment);
      } else {
        segmentList.push(segment);
      }
    }
    if (segmentList.length) {
      journey.Segments = segmentList;
      journeyList.push(journey);
    }
  }
  journeysFromMidoco = journeyList;

  let journeyDiff: DiffedResult[] = [];

  if (!journeysFromSource || !journeysFromMidoco) {
    return null;
  }
  let journeyDiff2 = ValidateChanges(journeysFromMidoco, journeysFromSource, true);

  for (let i = 0; i < journeysFromMidoco.length; i++) {
    if (journeysFromMidoco[i].Segments.every(s => s.State === 'OK')) {
      journeyDiff.push(CheckDifferences(journeysFromMidoco[i], journeysFromSource[i]));
    }
  }

  const getAirport = (id?: string): string | undefined => {
    if (!airports) {
      return id;
    }
    const airport = airports.find(ap => ap.id === id);
    if (!airport) {
      return id;
    }
    return airport.nameDE;
  };

  const dateTimeToDisplay = (
    segmentA: DiffedSegment,
    segmentB: DiffedSegment,
    field: keyof Pick<DiffedSegment, 'DepartureTime' | 'ArrivalTime'>,
    validationType: 'DATE' | 'TIME',
    newStatus,
    additionalStyle?: { [key: string]: string },
  ): JSX.Element => {
    const pattern = validationType === 'DATE' ? 'dd.MM.yyyy' : 'HH:mm';
    const dateTimeA = segmentA && segmentA[field] ? formatDate(segmentA[field]!, pattern) : undefined;
    const dateTimeB = segmentB && segmentB[field] ? formatDate(segmentB[field]!, pattern) : undefined;
    let textToUse = dateTimeA;
    let color = '#092A5E';

    if (newStatus && dateTimeA !== dateTimeB) {
      color = 'red';
    }

    // if (newStatus && segmentB && segmentB[field]) {
    //   const changedDateTime = formatDate(segmentB[field]!, pattern);
    //   console.log(dateTimeA, changedDateTime, newStatus);
    //   textToUse = changedDateTime ? changedDateTime : dateTimeA;
    // }

    return (
      <span
        style={{
          color,
          ...additionalStyle,
        }}
      >
        {textToUse}
      </span>
    );
  };

  const textToDisplay = (
    segmentA: DiffedSegment,
    segmentB: DiffedSegment,
    field: keyof Pick<
      DiffedSegment & { BookingClass: string },
      'Origin' | 'Destination' | 'MarketingCarrier' | 'OperatingCarrier' | 'FlightNumber'
    >,
    newStatus,
    funcToReturn?: (id?: string) => string | undefined,
    additionalStyle?: { [key: string]: string },
  ): JSX.Element => {
    let origTextA = segmentA && segmentA[field] ? segmentA[field] : undefined;
    let origTextB = segmentB && segmentB[field] ? segmentB[field] : undefined;

    let color = '#092A5E';
    let defaulttext = origTextA ?? origTextB;
    if (newStatus && ((origTextA && !origTextB) || (origTextB && origTextA !== origTextB))) {
      color = 'red';
    }

    if (field === 'OperatingCarrier' && defaulttext) {
      defaulttext = `${intl.formatMessage({ id: 'flightChanges.operatedBy' })} (${defaulttext})`;
    }

    return (
      <span
        style={{
          color,
          ...additionalStyle,
        }}
      >
        {funcToReturn ? funcToReturn(defaulttext) : defaulttext}
      </span>
    );
  };

  const getLabels = (diffedResult: DiffedResult, newStatus = false) => {
    const bookedSegments = diffedResult.bookedJourney.Segments!;
    const modifiedSegments = diffedResult.modifiedJourney.Segments!;

    const newSegmentList: DiffedSegment[] = newStatus ? modifiedSegments : bookedSegments;
    const changedSegmentList: DiffedSegment[] = newStatus ? bookedSegments : modifiedSegments;

    const lastSegmentIndex = newSegmentList.length - 1;
    const lastSegmentChangedIndex = changedSegmentList.length - 1;

    const firstSegment: DiffedSegment = newSegmentList[0]!;
    const firstSegmentChanged: DiffedSegment = changedSegmentList[0]!;

    const lastSegment: DiffedSegment = newSegmentList[lastSegmentIndex]!;
    const lastSegmentChanged: DiffedSegment = changedSegmentList[lastSegmentChangedIndex]!;

    const firstItemStyle =
      newStatus && firstSegmentChanged?.Origin && firstSegmentChanged?.Origin !== firstSegment?.Origin
        ? 'red'
        : '#092A5E';
    const LastItemStyle =
      newStatus && lastSegmentChanged?.Destination && lastSegmentChanged?.Destination !== lastSegment?.Destination
        ? 'red'
        : '#092A5E';

    const segmentsLabels = newSegmentList.map((s, index) => ({
      label: s.Origin,
      currentColor:
        index === 0
          ? firstItemStyle
          : newStatus &&
            changedSegmentList[index]?.Destination &&
            changedSegmentList[index]?.Destination !== newSegmentList[index]?.Destination
          ? 'red'
          : '#092A5E',
      icon: index === 0 ? <FlightTakeoff color="primary" /> : null,
    }));
    segmentsLabels.push({
      label: lastSegment?.Destination,
      currentColor: LastItemStyle,
      icon: <FlightLand color="primary" />,
    });

    return segmentsLabels;
  };

  const JourneyTrack = (props: JourneyTrackProps) => {
    const { diffedResult, newStatus } = props;
    const labels = getLabels(diffedResult, newStatus);

    const connectorClasses = useConnectorStyles(labels);

    return (
      <div className={classes.trackRoot}>
        <Stepper
          activeStep={-1}
          connector={
            <StepConnector
              classes={{
                root: connectorClasses.stepConnector,
                line: connectorClasses.stepConnectorLine,
              }}
            />
          }
          style={{ padding: 0 }}
        >
          {labels.map(({ label, icon, currentColor }, index) => (
            <Step key={index}>
              <StepLabel
                StepIconProps={{
                  icon: '',
                  classes: { root: currentColor === 'red' ? classes.redIcon : classes.primaryIcon },
                }}
                className={classes.stepLabel}
                style={{ marginTop: icon ? '-45px' : '-18px' }}
              >
                {icon && icon}
                <div>{label}</div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  };

  const renderJourneyDetails = (diffedResult: DiffedResult, newStatus = false): JSX.Element => {
    const bookedSegments = diffedResult.bookedJourney.Segments!;
    const modifiedSegments = diffedResult.modifiedJourney.Segments!;

    const knownSegmentList: DiffedSegment[] = newStatus ? modifiedSegments : bookedSegments;
    const modifiedSegmentList: DiffedSegment[] = newStatus ? bookedSegments : modifiedSegments;

    const lastSegmentIndex = knownSegmentList.length - 1;
    const lastSegmentChangedIndex = modifiedSegmentList.length - 1;

    const firstSegment: DiffedSegment = knownSegmentList[0]!;
    const firstSegmentChanged: DiffedSegment = modifiedSegmentList[0]!;

    const lastSegment: DiffedSegment = knownSegmentList[lastSegmentIndex]!;
    const lastSegmentChanged: DiffedSegment = modifiedSegmentList[lastSegmentChangedIndex]!;

    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        style={{ paddingBottom: '40px', fontSize: '13pt' }}
      >
        <Grid item xs={3}>
          {`${intl.formatMessage({ id: 'flightChanges.bookingClass' })}:`} {diffedResult.bookedJourney.BookingClass}/
          {diffedResult.modifiedJourney.BookingClass}
          <Grid container direction="row" justifyContent="flex-start">
            {dateTimeToDisplay(firstSegment, firstSegmentChanged, 'DepartureTime', 'DATE', newStatus, {
              paddingRight: '10px',
            })}
            {dateTimeToDisplay(firstSegment, firstSegmentChanged, 'DepartureTime', 'TIME', newStatus)}
          </Grid>
          <Grid container direction="row" justifyContent="flex-start">
            {textToDisplay(firstSegment, firstSegmentChanged, 'Origin', newStatus, getAirport)}
          </Grid>
        </Grid>
        {knownSegmentList?.map((currentSegment, index) => {
          if (index !== knownSegmentList.length - 1) {
            const nextSegment = knownSegmentList[index + 1];
            const nextChangedSegment = modifiedSegmentList[index + 1];
            return (
              <Grid key={index} item xs={4}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item xs={6} alignContent="center">
                    <FlightLandIcon color="primary" style={{ marginRight: '2px' }} />
                    {dateTimeToDisplay(currentSegment, modifiedSegmentList[index], 'ArrivalTime', 'DATE', newStatus, {
                      paddingRight: '10px',
                    })}
                    {dateTimeToDisplay(currentSegment, modifiedSegmentList[index], 'ArrivalTime', 'TIME', newStatus)}
                  </Grid>
                  <Grid item xs={6} alignContent="center">
                    <FlightTakeoffIcon color="primary" style={{ marginRight: '2px' }} />
                    {dateTimeToDisplay(nextSegment, nextChangedSegment, 'DepartureTime', 'DATE', newStatus, {
                      paddingRight: '10px',
                    })}
                    {dateTimeToDisplay(nextSegment, nextChangedSegment, 'DepartureTime', 'TIME', newStatus)}
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center">
                  {textToDisplay(nextSegment, nextChangedSegment, 'Origin', newStatus, getAirport)}
                </Grid>
              </Grid>
            );
          }
          return <></>;
        })}
        <Grid item xs={3}>
          <Grid container direction="row" justifyContent="flex-end">
            {dateTimeToDisplay(lastSegment, lastSegmentChanged, 'ArrivalTime', 'DATE', newStatus, {
              paddingRight: '10px',
            })}
            {dateTimeToDisplay(lastSegment, lastSegmentChanged, 'ArrivalTime', 'TIME', newStatus)}
          </Grid>
          <Grid container direction="row" justifyContent="flex-end">
            {textToDisplay(lastSegment, lastSegmentChanged, 'Destination', newStatus, getAirport)}
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="space-around" alignContent="center">
          {knownSegmentList?.map((segment, index) => (
            <Typography variant="body1" color="primary" style={{ textAlign: 'center' }} key={index}>
              {textToDisplay(segment, modifiedSegmentList[index], 'MarketingCarrier', newStatus)}{' '}
              {textToDisplay(segment, modifiedSegmentList[index], 'FlightNumber', newStatus)}
              <br />
              <small>{textToDisplay(segment, modifiedSegmentList[index], 'OperatingCarrier', newStatus)}</small>
              <br />
            </Typography>
          ))}
        </Grid>
      </Grid>
    );
  };

  const renderChanges = (diffedJourney: DiffedResult, index: number): JSX.Element => {
    let result: JSX.Element = (
      <Typography variant="h5" color="primary">
        {`${intl.formatMessage({ id: 'flightChanges.noChanges' })} ${intl.formatMessage({
          id: 'flightChanges.in',
        })} ${intl.formatMessage({ id: 'flightChanges.flight' })} ${intl.formatMessage({
          id: 'flightChanges.no',
        })}`}{' '}
        {index + 1}
      </Typography>
    );

    if (diffedJourney.diff?.Segments?.flatMap(d => Object.keys(d)).length) {
      result = (
        <Grid item xs={12}>
          <Typography variant="h6" color="primary">
            <strong>NEW STATUS</strong>
          </Typography>
          <div style={{ minHeight: 200 }}>
            {renderJourneyDetails(diffedJourney, true)}
            <JourneyTrack diffedResult={diffedJourney} newStatus={true} />
          </div>
        </Grid>
      );
    }

    return result;
  };

  return journeysFromMidoco.length !== journeysFromSource.length ? (
    <Grid container direction="column" justifyContent="center" alignItems="baseline" className={classes.root}>
      <Grid item xs={12} className={classes.changesRoot}>
        <Typography variant="h5" color="primary">
          Something went wrong.
        </Typography>
        <Typography variant="subtitle1" color="primary">
          The number of Journeys original booked ({journeysFromMidoco.length}) do not match the numbers of Journeys in
          the changed booking ({journeysFromSource.length})!
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <>
      {journeyDiff.map((diff, index) => {
        return (
          <Grid
            key={index}
            container
            direction="column"
            justifyContent="center"
            alignItems="baseline"
            className={classes.root}
          >
            <Grid item xs={12} className={classes.statusRoot}>
              <Typography variant="h6" color="primary">
                <strong>
                  {`${intl.formatMessage({ id: 'flightChanges.flight' })} ${intl.formatMessage({
                    id: 'flightChanges.no',
                  })}`}{' '}
                  {index + 1}
                </strong>
              </Typography>
              {renderJourneyDetails(diff)}
              <JourneyTrack diffedResult={diff} newStatus={false} />
            </Grid>
            <Grid item xs={12} className={classes.changesRoot}>
              {renderChanges(diff, index)}
            </Grid>
          </Grid>
        );
      })}

      <Grid container direction="column" justifyContent="center" alignItems="baseline" className={classes.root}>
        <Grid item xs={12} className={classes.changesRoot}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid>
              <CustomButton
                variant="contained"
                color="danger"
                size="large"
                onClick={onCancelBookingFN}
                disabled={confirmStatus !== undefined}
                style={{ marginRight: '10px' }}
              >
                {intl.formatMessage({ id: 'flightChanges.cancelBooking' })}
              </CustomButton>
            </Grid>

            <Grid>
              <CustomButton
                variant="contained"
                color="primary"
                size="large"
                onClick={onConfirmBookingAfterAdustmentFN}
                disabled={confirmStatus !== undefined}
                style={{ marginRight: '10px' }}
              >
                {intl.formatMessage({ id: 'flightChanges.confirmAfterAdustment' })}
              </CustomButton>
              <CustomButton
                variant="contained"
                color="primary"
                size="large"
                onClick={onConfirmChangeFN}
                disabled={confirmStatus !== undefined}
              >
                {intl.formatMessage({ id: 'flightChanges.acceptChanges' })}
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {window.ENV?.DEBUG_MODE && (
        <>
          <ReactJson name={'DEBUG:Comparer old'} src={journeyDiff} collapsed={true} />
          <ReactJson name={'DEBUG:Comparer new'} src={journeyDiff2} collapsed={true} />
        </>
      )}
    </>
  );
};
export default FlightChanges;
