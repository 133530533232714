import * as React from 'react';

import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { ITableColumns } from '../../models';
import Table from '../Table';
import { MUIDataTableMeta } from 'mui-datatables';
import { IMidocoBookingInfoAttributeValue } from '@tff/types/Midoco/model';
import { Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactJson from 'react-json-view';

interface Props {
  midocoFetchedOrderList: IMidocoBookingInfoAttributeValue[];
  returnFromList: (string) => void;
}

const SearchOrderResultPage: React.FC<Props> = ({ midocoFetchedOrderList, returnFromList }) => {
  const intl = useIntl();

  const renderListOfItems = (bookingInfos: IMidocoBookingInfoAttributeValue[]) => {
    const columnsData: ITableColumns[] = [
      {
        name: 'no',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.no' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (_value: string, tableMeta: MUIDataTableMeta) => {
            return `${tableMeta.rowIndex + 1}`;
          },
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '0',
              background: 'white',
              zIndex: 100,
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '0',
              background: 'white',
              zIndex: 101,
            },
          }),
        },
      },
      {
        name: 'orderNo',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.orderNo' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (value: string | number) => {
            return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
          },
        },
      },
      {
        name: 'orderId',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.orderid' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
        },
      },
      {
        name: 'bookingId',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.bookingid' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (value: string | number, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                onClick={() => returnFromList(`${value}@${tableMeta.rowData[6]}`)}
                style={{ fontWeight: 'bolder', fontSize: '12pt' }}
              >
                {value}
              </Button>
            );
          },
        },
      },
      {
        name: 'invoiceCustomer',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.invoice' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (value: string | number) => {
            return String(value);
          },
        },
      },
      {
        name: 'noOfPassengers',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.pax' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (value: string | number) => {
            return String(value);
          },
        },
      },
      {
        name: 'tourOperator',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.touroperator' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (value: string | number) => {
            return String(value);
          },
        },
      },

      {
        name: 'startTravel',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.start' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (value: string | number) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
              </div>
            );
          },
        },
      },
      {
        name: 'endTravel',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.end' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (value: string | number) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
              </div>
            );
          },
        },
      },
      {
        name: 'creationUser',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.creationUser' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
        },
      },
      {
        name: 'creationDate',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.creationDate' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (value: string | number) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
              </div>
            );
          },
        },
      },
      {
        name: 'externalSystem',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.externalSystem' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
        },
      },
      {
        name: 'firstTraveller',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.firsttraveller' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (value: string | number) => {
            return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
          },
        },
      },
      {
        name: 'mediatorId',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.mediatorId' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (value: string | number) => {
            return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
          },
        },
      },
      {
        name: 'orgUnit',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.orgUnit' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (value: string | number) => {
            return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
          },
        },
      },
      {
        name: 'sellItemId',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.sellItemId' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (value: string | number) => {
            return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
          },
        },
      },
      {
        name: 'status',
        label: intl.formatMessage({ id: 'pages.orderSearchResultPage.list.status' }),
        options: {
          display: true,
          filter: true,
          filterList: [],
          customBodyRender: (value: string | number) => {
            return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
          },
        },
      },
    ];

    return (
      <>
        <div style={{ width: '80%', marginBottom: 40 }}>
          <Button
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: '20px 0 20px 0',
              alignSelf: 'self-start',
            }}
            onClick={returnFromList}
          >
            <div
              style={{
                height: '36px',
                width: '36px',
                borderRadius: '18px',
                border: '2px solid #092A5E',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ArrowBackIcon color="primary" />
            </div>
            <Typography color="primary" variant="h6" style={{ marginLeft: '10px' }}>
              {intl.formatMessage({ id: 'appModule.back' }).toUpperCase()}
            </Typography>
          </Button>
          <Table
            data={midocoFetchedOrderList}
            columnsData={columnsData}
            tableId="bookingDetailsTable"
            tableName={`${intl.formatMessage({ id: 'pages.orderSearchResultPage.list.headerStart' })} ${
              bookingInfos.length
            } ${intl.formatMessage({ id: 'pages.orderSearchResultPage.list.headerEnd' })}`}
          />
        </div>

        {window.ENV?.DEBUG_MODE && (
          <ReactJson
            name={'DEBUG:FlightDetails'}
            style={{ backgroundColor: 'white' }}
            src={midocoFetchedOrderList}
            collapsed={true}
          />
        )}
      </>
    );
  };

  if (midocoFetchedOrderList) {
    let MidocoBookingInfoArr: IMidocoBookingInfoAttributeValue[] | undefined;
    if (!Array.isArray(midocoFetchedOrderList)) {
      MidocoBookingInfoArr = [midocoFetchedOrderList];
    } else {
      MidocoBookingInfoArr = midocoFetchedOrderList;
    }
    return renderListOfItems(MidocoBookingInfoArr);
  } else {
    return <div>No items found, please start a new search </div>;
  }
};

export default SearchOrderResultPage;
