import { Route, Routes } from 'react-router-dom';
import Error403 from './403';
import Error404 from './404';
import Error500 from './500';
import { withRouter } from '../withRouter';

const commonRoutes = () => {
  return (
    <Routes>
      <Route path="error-403" element={<Error403 />} />
      <Route path="error-404" element={<Error404 />} />
      <Route path="error-500" element={<Error500 />} />
      <Route element={<Error404 />} />
    </Routes>
  );
};

export const CommonRoutes = withRouter(commonRoutes);
