import { createSlice } from '@reduxjs/toolkit';
import { act } from '@testing-library/react-hooks';

const initialState = {
  checkboxStates: {},
};

const checkboxesSlice = createSlice({
  name: 'checkboxes',
  initialState,
  reducers: {
    toggleCheckbox: (state, action) => {
      const { id } = action.payload;
      state.checkboxStates[id] = !state.checkboxStates[id];
    },
    resetCheckboxStates: state => {
      state.checkboxStates = {};
    },
  },
});

export const { toggleCheckbox, resetCheckboxStates } = checkboxesSlice.actions;
export default checkboxesSlice.reducer;
