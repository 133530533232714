import { TypeOfSourceGroup } from '../../models';
import { TFF } from '@tff/types';
import * as React from 'react';
import { useContext, useState } from 'react';
import FlexForm from '../forms/FlexForm';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import CustomButton from '../Button';
import { useIntl } from 'react-intl';
import { isEqual } from 'lodash';
import { apigeeEndpoint } from '../../apis';
import { LoadingContext } from '../../contexts/LoadingContextProvider';
import * as yup from 'yup';
import { editModelItem, ValidationType, orderType } from '../forms/FlexForm/FormTypes';
import AlertMessage, { messageType } from '../AlertMessage';
import ChangesOverview from '../ChangesOverview';
import backendCallOptions from '../../models/NameChangeApiOptions';
import { AddressTitlesEnum } from '../../models/PaxAndContactsTitles';
import changedOnly from '../../util/changedOnly';

interface props {
  sourceGroup: TypeOfSourceGroup;
  midocoDetails: TFF.FlightDetails;
  onBack: () => void;
  midocoOrderNo?: number;
  onSuccess: (response: any, l: TFF.Address) => void;
  flightDetails: TFF.FlightDetails;
}

type ContactFormValues = {
  City: string;
  CountryCode: string;
  FirstName: string;
  LastName: string;
  Mail?: string;
  MiddleName?: string;
  Salutation: string;
  Street: string;
  Telephone?: { PhoneNumber?: string };
  Title?: string;
  ZipCode: string;
};

interface ContactsValidationType extends ValidationType {
  testFunction: (newContact: TFF.Address, order: orderType) => boolean;
}
interface contactEditModelItem extends editModelItem {
  fieldName: keyof ContactFormValues | string;
  tooltip?: string | ((pax: TFF.Address) => string);
  onChange?: (value: TFF.Address, setValue: (name: string, value: unknown, config?: Object) => void) => void;
  onSubmit?: (value: TFF.Address, index?: number) => void;
  dontShowFunction?: (formItem: TFF.Address) => boolean;
  validation?: ContactsValidationType;
}

const ContactsEditForm: React.FC<props> = ({
  sourceGroup,
  midocoDetails,
  onSuccess,
  onBack,
  midocoOrderNo,
  flightDetails,
}) => {
  const intl = useIntl();
  const { showLoading, closeLoading } = useContext(LoadingContext);

  const dummyAdr: TFF.Address = {
    City: '',
    CountryCode: '',
    FirstName: '',
    LastName: '',
    Mail: '',
    Salutation: '',
    Street: '',
    Type: 'INVOICE_ADDRESS',
    ZipCode: '',
  };

  const isNDC = sourceGroup.includes('NDC');
  const isNSK = sourceGroup.includes('NSK');

  const originAddresses =
    midocoDetails.OrderDetails.Addresses?.filter(adr => adr.Type === 'INVOICE_ADDRESS').length === 0
      ? [dummyAdr]
      : midocoDetails.OrderDetails.Addresses?.filter(adr => adr.Type === 'INVOICE_ADDRESS') ?? [dummyAdr];
  const [untouchedContacts] = useState<TFF.Address[]>([...originAddresses]);
  const [newAddresses, setNewAddresses] = useState<TFF.Address[]>([...originAddresses]);
  const [hasAddressChangeTasks, setHasAddressChangeTasks] = useState<boolean>(false);
  const [formsAreValid, setFormsAreValid] = useState<boolean>(true);
  const [showOverview, setShowOverview] = useState<boolean>(false);
  const [changesVerified, setChangesVerified] = useState<boolean>(false);
  const [message, setMessage] = useState<messageType | null | string>(null);
  //const [showCustomDialog, setShowCustomDialog] = useState<customDialogIProps | null>(null);

  let validForms = 0; //count valid forms to check if any is not valid
  const updateHasChanges = (changedAddress, index) => {
    let changes = false;
    newAddresses[index] = changedAddress;
    newAddresses.forEach((newAddress, i) => {
      if (!isEqual(newAddress, untouchedContacts[i])) {
        changes = true;
        //stop for each if change is found
        return false;
      }
    });
    setHasAddressChangeTasks(changes);
  };

  const handleAddrFormSubmit = (changedAddress, index) => {
    validForms++;
    setFormsAreValid(false);
    newAddresses[index] = changedAddress;
    if (validForms === untouchedContacts.length) {
      //update state when all forms are valid
      setNewAddresses([...newAddresses]);
      setFormsAreValid(true);
      setShowOverview(true);
    }
    updateHasChanges(changedAddress, index);
  };

  const handlePassengerFormChange = (changedAddress, index) => {
    updateHasChanges(changedAddress, index);
  };

  const handleBack = () => {
    if (showOverview) {
      setShowOverview(false);
      setMessage(null);
    } else {
      onBack();
    }
  };

  //Create form submit button refs to validate forms on confirm click
  const formSubmitBtnRefs = React.useMemo(() => untouchedContacts.map(() => React.createRef()), [untouchedContacts]);

  const backendCall = async (options: backendCallOptions): Promise<void> => {
    try {
      //const filteredOldAddresses = untouchedContacts.filter((oA, index) => !isEqual(oA, newAddresses[index]));

      //only one address changable...
      const modifiedAddress = {
        new: changedOnly(
          {
            ...newAddresses[0],
          },
          untouchedContacts[0],
        ),
        old: untouchedContacts[0],
      };

      showLoading(options.loadingMsg);
      const postData = {
        action: 'UPDATE_CONTACTS',
        type: 'CONFIRM',
        recordLocator: midocoDetails?.OrderSummary?.RecordLocator,
        bookingSource: flightDetails?.OrderSummary?.SourceDetails?.System,
        syncMidoco: true,
        midocoOrderNo: midocoOrderNo,
        modifiedContact: modifiedAddress,
        ...options.postData,
      };

      const response = await apigeeEndpoint.post(`/passenger-amend`, postData);

      if (response.data.status === 'SUCCESS') {
        if (options.success) {
          options.success(response.data);
        } else {
          //default success
          setMessage({
            msg: 'Request successfull.', //'Changes approved. Click on \'SUBMIT\' to commit changes.',
            type: 'success',
          });
        }
      } else {
        setChangesVerified(false);
        setShowOverview(false);
        setMessage({
          msg: response.data.error?.Message || response.data.errorMessage,
          type: 'error',
        });
      }
    } catch (error) {
      console.error(error);
      setChangesVerified(false);
      setMessage({
        msg: error.message,
        type: 'error',
      });
    } finally {
      closeLoading();
    }
  };

  const sendRequest = async () => {
    await backendCall({
      loadingMsg: intl.formatMessage({ id: 'edit.passenger.pleaseWait' }),
      postData: {
        type: 'VALIDATE',
      },
      success: () => {
        setChangesVerified(true);
        setMessage({
          msg: intl.formatMessage({ id: 'edit.passenger.changesApproved' }),
          type: 'success',
        });
      },
    });
  };

  const sendUpdate = async () => {
    await backendCall({
      loadingMsg: intl.formatMessage({ id: 'edit.passenger.pleaseWait' }),
      success: responseData => {
        setChangesVerified(false);
        onSuccess(responseData, newAddresses[0]);
      },
    });
  };

  const sendUpdateNDC = async () => {
    await backendCall({
      loadingMsg: intl.formatMessage({ id: 'edit.passenger.pleaseWait' }),
      success: responseData => {
        onSuccess(responseData, newAddresses[0]);
      },
    });
  };

  /*const confirmNDC = async () => {
    setShowCustomDialog({
      onConfirm: () => {
        setShowCustomDialog(null);
        sendUpdateNDC();
      },
      onCancel: () => setShowCustomDialog(null),
      children: intl.formatMessage({ id: 'edit.passenger.NDCwarning.text' }),
      title: intl.formatMessage({ id: 'edit.passenger.NDCwarning.title' }),
      initialOpen: true,
      confirmButtonText: intl.formatMessage({ id: 'edit.passenger.NDCwarning.send' }),
      cancelButtonText: intl.formatMessage({ id: 'edit.passenger.NDCwarning.cancel' }),
    });

    //sendUpdateNDC();
  };*/
  const handleEditDone = () => {
    validForms = 0;
    setMessage(null);

    formSubmitBtnRefs.forEach(ref => {
      try {
        // @ts-ignore
        ref?.current.click();
      } catch (er) {
        setMessage({
          type: 'error',
          msg: er.msg,
        });
      }
    });
  };

  /**
   * The Edit Model for all sources is used to render the form
   * @typedef {Object} paxEditModelItem
   * @property {string} fieldName - The name of the field
   * @property {string} label - The label to be displayed for the field
   * @property {string} [type] - The type of the input element if not 'select' or 'date', 'select' -> MUI <Select />, 'date' -> MUI <KeyboardDatePicker/>
   * @property {string[]} [options] - The options available for the select input
   * @property {Function} [onChange] - The function to be called on change of the field
   * @property {boolean} [disabled] - If true, the field will be disabled
   * @property {string} [tooltip] - The tooltip to be displayed for the field
   * @property {Object} {validation} - Object for specific validations
   */
  const editModel: contactEditModelItem[] = [
    {
      fieldName: 'Salutation',
      label: intl.formatMessage({ id: 'edit.address.Salutation' }),
      type: 'select',
      size: 2,
      options: [''].concat(Object.values(AddressTitlesEnum)),
    },
    {
      fieldName: 'FirstName',
      label: intl.formatMessage({ id: 'edit.passenger.FirstName' }),
      size: 4,
    },
    {
      fieldName: 'LastName',
      label: intl.formatMessage({ id: 'edit.passenger.LastName' }),
      size: 4,
    },
    {
      fieldName: 'Street',
      label: intl.formatMessage({ id: 'edit.address.Street' }),
      size: 4,
    },
    {
      fieldName: 'ZipCode',
      label: intl.formatMessage({ id: 'edit.address.ZipCode' }),
      size: 2,
    },
    {
      fieldName: 'City',
      label: intl.formatMessage({ id: 'edit.address.City' }),
      size: 4,
    },
    {
      fieldName: 'Mail',
      type: 'email',
      label: intl.formatMessage({ id: 'edit.passenger.Email' }),
      size: 3,
    },
    {
      fieldName: 'Telephone.PhoneNumber',
      label: intl.formatMessage({ id: 'edit.address.PhoneNumber' }),
      size: 3,
    },
  ];

  let addressShema = yup.object().shape({
    Title: yup.string().when({
      is: exists => !!exists, //only validate if rendered
      then: rule => rule.required(intl.formatMessage({ id: 'edit.passenger.Title.required' })),
    }),
    FirstName: yup
      .string()
      .required(intl.formatMessage({ id: 'edit.passenger.FirstName.required' }))
      .max(64, intl.formatMessage({ id: 'edit.max64' })),
    LastName: yup
      .string()
      .required(intl.formatMessage({ id: 'edit.passenger.LastName.required' }))
      .max(64, intl.formatMessage({ id: 'edit.max64' })),
    Email: yup.string().email(intl.formatMessage({ id: 'edit.passenger.Email.error' })), //matches(emailRegEx, intl.formatMessage({ id: 'edit.passenger.Email.error' })),
  });

  const OverviewLabels = {
    Salutation: intl.formatMessage({ id: 'edit.address.Salutation' }),
    FirstName: intl.formatMessage({ id: 'edit.passenger.FirstName' }),
    LastName: intl.formatMessage({ id: 'edit.passenger.LastName' }),
    Street: intl.formatMessage({ id: 'edit.address.Street' }),
    ZipCode: intl.formatMessage({ id: 'edit.address.ZipCode' }),
    City: intl.formatMessage({ id: 'edit.address.City' }),
    Mail: intl.formatMessage({ id: 'edit.passenger.Email' }),
    'Telephone.PhoneNumber': intl.formatMessage({ id: 'edit.address.PhoneNumber' }),
  };

  return untouchedContacts ? (
    <Grid container justifyContent="space-between" spacing={2}>
      {showOverview && (
        <>
          <ChangesOverview
            originalRecords={untouchedContacts}
            newRecords={newAddresses}
            labels={{ ...OverviewLabels }}
          />
          {/*{isNDC() && <ChangeFeeDummy editedPassengers={newAddresses} originPassengers={untouchedContacts}/>}*/}
        </>
      )}
      {!showOverview &&
        untouchedContacts &&
        newAddresses.map((addr, index) => (
          <Grid item xs={12} key={index}>
            <Card>
              <CardContent>
                <Typography variant="body1" color="primary" display="inline">
                  {intl.formatMessage({ id: 'edit.address.editInvoiceAddress' })}
                </Typography>
                <FlexForm
                  style={{ marginTop: '15px' }}
                  onChange={handlePassengerFormChange}
                  formItemIndex={index}
                  direction="row"
                  margin="none"
                  schema={addressShema}
                  editModel={editModel}
                  formSubmitBtnRef={formSubmitBtnRefs[index]}
                  formItem={newAddresses[index]}
                  onSubmit={handleAddrFormSubmit}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      {/* the commit warning in jetBrains is a bug... :/ */}
      {/*{showCustomDialog && <CustomDialog {...showCustomDialog} />}*/}

      {message && (
        <Grid item xs={12}>
          <AlertMessage message={message} />
        </Grid>
      )}
      {!formsAreValid && (
        <Grid item xs={12}>
          <AlertMessage
            message={{ type: 'error', msg: intl.formatMessage({ id: 'edit.passenger.formsNotValidMessage' }) }}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <CustomButton variant="contained" color="primary" size="large" onClick={handleBack}>
          {intl.formatMessage({ id: 'edit.passenger.back' })}
        </CustomButton>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'right' }}>
        {/* First Form submit btn*/}
        {!showOverview && (
          <CustomButton
            variant="contained"
            color="danger"
            disabled={!hasAddressChangeTasks}
            size="large"
            onClick={handleEditDone}
          >
            {
              //confirm data btn
              intl.formatMessage({ id: 'edit.passenger.next' })
            }
          </CustomButton>
        )}

        {/* First overview confirm btn NSK */}
        {showOverview && !changesVerified && isNSK && (
          <CustomButton
            variant="contained"
            color="danger"
            disabled={!hasAddressChangeTasks}
            size="large"
            onClick={sendRequest}
          >
            {
              //confirm data btn
              intl.formatMessage({ id: 'edit.passenger.validate' })
            }
          </CustomButton>
        )}

        {/* Submit changes to NSK btn */}
        {showOverview && changesVerified && isNSK && (
          <CustomButton
            variant="contained"
            color="danger"
            disabled={!hasAddressChangeTasks}
            size="large"
            onClick={sendUpdate}
          >
            {
              //confirm data btn
              intl.formatMessage({ id: 'edit.passenger.submit' })
            }
          </CustomButton>
        )}

        {/* Send changes to NDC */}
        {showOverview && isNDC && (
          <CustomButton
            variant="contained"
            color="danger"
            disabled={!hasAddressChangeTasks}
            size="large"
            onClick={sendUpdateNDC}
          >
            {
              //confirm data btn
              intl.formatMessage({ id: 'edit.passenger.submit' })
            }
          </CustomButton>
        )}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default ContactsEditForm;
