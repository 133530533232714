import { configureStore } from '@reduxjs/toolkit';

import reducers from '../reducers';

// @ts-ignore

// let _createdStore: Store<CombinedState<{ router: RouterState<unknown>; }>, LocationChangeAction<unknown>> ;
let _createdStore: any;

const configuredStore = (): void => {
  _createdStore = configureStore({
    reducer: reducers(),
  });
};

/**
 * return a created instance of an Store
 */
export const createdStore = () => {
  if (!_createdStore) {
    configuredStore();
  }
  return _createdStore;
};

export default createdStore;
