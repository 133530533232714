import * as React from 'react';
import { useEffect, useContext } from 'react';
import { Retrieve } from '@tff/types/TFF';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { apigeeEndpoint } from '../apis';

export type UpdateRetrieveDbProps = {
  itemsToUpdate: Retrieve[];
  propsToUpdate: Partial<Retrieve>;
};

export type TypeOfUpdateRetrieveDbStatus = 'IN_PROGRESS' | 'FINISH' | 'FAILURE';
export type TypeOfRetrieveUpdateStatus = 'SUCCEED' | 'FAILED';

export type RetrieveDbServiceResponse = {
  updateRetrieveList?: Retrieve[];
  updateRetrieveDbError?: any;
  updateRetrieveDbStatus?: TypeOfUpdateRetrieveDbStatus;
  resetUpdateRetrieveDbState: () => void;
};

//
export const useUpdateRetrieveDb = (updateRetrieveDbProps?: UpdateRetrieveDbProps): RetrieveDbServiceResponse => {
  const [updateRetrieveDbError, setUpdateRetrieveDbError] = React.useState(undefined);
  const [updateRetrieveList, setUpdateRetrieveList] = React.useState<Retrieve[] | undefined>(undefined);
  const [updateRetrieveDbStatus, setUpdateRetrieveDbStatus] = React.useState<TypeOfUpdateRetrieveDbStatus | undefined>(
    undefined,
  );
  const { showLoading, closeLoading } = useContext(LoadingContext);

  const resetUpdateRetrieveDbState = (): void => {
    setUpdateRetrieveList(undefined);
    setUpdateRetrieveDbStatus(undefined);
    setUpdateRetrieveDbError(undefined);
  };

  useEffect(() => {
    const updateRetrievesAtBackend = async (
      itemsToUpdate: Retrieve[],
      propsToUpdate: Partial<Retrieve>,
    ): Promise<void> => {
      try {
        showLoading(`Update items at retrieve DB`);
        setUpdateRetrieveDbStatus('IN_PROGRESS');

        itemsToUpdate.forEach(retrieve => {
          Object.keys(propsToUpdate).forEach(key => {
            retrieve[key] = propsToUpdate[key];
          });
        });

        const updatedRetrieveDbItems = await apigeeEndpoint.put<Retrieve[]>(`/retrieve-db`, itemsToUpdate);

        setUpdateRetrieveList(updatedRetrieveDbItems.data);
        setUpdateRetrieveDbStatus('FINISH');
      } catch (error) {
        setUpdateRetrieveDbStatus('FAILURE');
      } finally {
        closeLoading();
      }
    };

    if (
      updateRetrieveDbProps?.propsToUpdate.ticketTimeLimit &&
      updateRetrieveDbProps?.itemsToUpdate.length &&
      !updateRetrieveDbStatus
    ) {
      const { itemsToUpdate, propsToUpdate } = updateRetrieveDbProps;
      void updateRetrievesAtBackend(itemsToUpdate, propsToUpdate);
    }
  }, [updateRetrieveDbProps]);

  return {
    updateRetrieveList,
    updateRetrieveDbStatus,
    updateRetrieveDbError,
    resetUpdateRetrieveDbState,
  };
};
