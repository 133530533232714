import * as React from 'react';
import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { TextField, Typography } from '@mui/material';
import { Journey } from '@tff/types/TFF';

interface JsonFileUploaderProps {
  onJsonUpload: (json: any, type?: string) => void;
  initialJourneys: Journey[];
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 10,
    margin: 20,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    backgroundColor: '#E2F3FE',
  },
  uploadRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textAreaRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '20px',
  },
  textAreaButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
}));

const JsonFileUploader: React.FC<JsonFileUploaderProps> = ({ onJsonUpload, initialJourneys }) => {
  const classes = useStyles();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [message, setMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [showTextArea, setShowTextArea] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [input, setInput] = useState<string | undefined>(
    initialJourneys ? JSON.stringify(initialJourneys, null, 2) : undefined,
  );

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    const reader = new FileReader();
    reader.onload = event => {
      // const input = event.target?.result as string;
      // setInput(input);
    };
    reader.readAsText(uploadedFile as File);
    setSelectedFile(uploadedFile || null);
    setMessage('');
    setIsError(false);
  };

  const isJson = (value: string): boolean => {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setIsModified(true);
    setInput(event.target.value);
  };

  const handleAddInput = (type: string) => {
    if (input && input.length > 0) {
      if (isJson(input)) {
        onJsonUpload(JSON.parse(input), type);
        setShowTextArea(false);
        // setInput(initialJourneys ? JSON.stringify(initialJourneys, null, 2) : '');
        setSelectedFile(null);
        setIsModified(false);
      } else {
        setIsError(true);
        setMessage('Wrong Json format');
      }
    } else {
      setIsError(true);
      setMessage('No Input!');
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography color="primary" variant="h6">
          Upload or Add a JSON Flightdetail Object to test out the flight changes feature
        </Typography>
        <div className={classes.uploadRoot}>
          <label htmlFor="btn-upload">
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: 'none' }}
              type="file"
              onChange={handleFileUpload}
            />
            <Button className="btn-choose" variant="outlined" component="span">
              Choose Files
            </Button>
          </label>
          <Button
            color="secondary"
            variant="contained"
            component="span"
            onClick={() => setShowTextArea(prev => !prev)}
            style={{ color: 'white' }}
          >
            {`${showTextArea ? 'Hide' : 'Show'} Journeys`}
          </Button>
          <Typography color="primary" variant="h6">
            {selectedFile && selectedFile.name}
          </Typography>
          <Typography variant="subtitle2" className={`upload-message ${isError ? 'error' : ''}`}>
            {message}
          </Typography>
        </div>
      </div>
      {isModified && (
        <div className={classes.textAreaButtons}>
          <Button color="primary" variant="contained" component="span" onClick={() => handleAddInput('journeys')}>
            Apply Changes
          </Button>
        </div>
      )}
      {showTextArea && (
        <div className={classes.textAreaRoot}>
          <TextField
            label="Add your Flightdetails object here"
            multiline
            defaultValue={input}
            variant="outlined"
            onChange={handleTextChange}
          />
        </div>
      )}
    </div>
  );
};

export default JsonFileUploader;
