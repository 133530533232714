import { IconButton, adaptV4Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import AdministrationHeaderCard from '../../components/AdministrationHeaderCard';
import { createTheme, ThemeProvider, StyledEngineProvider, Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import UserForm, { Role } from './UserForm';
import _ from 'lodash';
import { useTypedSelector } from '../../reducers';
import { getRoles, ROLES_STATES } from '../../reducers/Roles/rolesSlice';
import { getUser, getUsers, USERS_STATES } from '../../reducers/Users/usersSlice';
import { LoadingContext } from '../../contexts/LoadingContextProvider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}

const UserAdministration: React.FC<IProps> = () => {
  const { users, user, usersLoading } = useTypedSelector(({ usersData }) => usersData);
  const { roles } = useTypedSelector(({ rolesData }) => rolesData);
  const { showLoading, closeLoading } = useContext(LoadingContext);

  const [addEmployee, setAddEmployee] = useState<boolean>();
  const [mainRoles, setMainRoles] = useState<Role[]>([]);
  const [totalServiceRoles, setTotalServicesRoles] = useState<any[]>();
  const [isUserUpdated, setIsUserUpdated] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<any[]>();
  const [userId, setUserId] = useState<string>();

  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    usersLoading ? showLoading(intl.formatMessage({ id: 'administration.usersLoading' })) : closeLoading();
  }, [usersLoading]);

  useEffect(() => {
    if (roles?.length === 0 && ROLES_STATES.UNKOWN) {
      dispatch(getRoles());
    }

    if (roles) {
      const findMainRoles = roles.filter(role => role.name.startsWith('Role_'));
      if (findMainRoles) {
        setMainRoles(findMainRoles);
      }
    }
  }, [roles]);

  useEffect(() => {
    if (users?.length === 0 && USERS_STATES.UNKOWN) {
      dispatch(getUsers());
    }

    if (users) {
      setUsersList(users);
      const servicesRolesCount: any[] = [];

      users.forEach(user => {
        const service = user.roles.find((role: any) => role.name.startsWith('Role_Service'));
        if (service) {
          servicesRolesCount.push(service);
        }
      });

      setTotalServicesRoles(servicesRolesCount);
    }
  }, [users]);

  useEffect(() => {
    if (user && isUserUpdated) {
      setIsUserUpdated(false);
      setTimeout(() => {
        const updatedUsers = _.cloneDeepWith(usersList, value => {
          if (value.id === user.id) {
            return user;
          }
          return undefined;
        });
        setUsersList(updatedUsers);
      }, 500);
    }
  }, [user, isUserUpdated, usersList]);

  const useStyle = makeStyles((theme: Theme) =>
    createStyles({
      tableWrapper: {
        width: '80%',
        margin: '0 auto',
        marginTop: '40px',
        marginBottom: '40px',
      },
      DefaultLine: {
        '& td': { backgroundColor: 'white !important', border: 'none', maxHeight: '15px', padding: '0px 10px' },
      },
      LightBlueLine: {
        '& td': { backgroundColor: '#E2F3FE !important', border: 'none', maxHeight: '15px', padding: '0px 10px' },
      },
      addEmployeeWrapper: {
        width: '60%',
        margin: '0 auto',
        marginTop: '40px',
        marginBottom: '40px',
        backgroundColor: '#E2F3FE',
        border: '1px solid #092A5E',
        borderRadius: '5px',
        minHeight: '500px',
        padding: '30px 150px',
      },
      backIconWrapper: {
        height: '36px',
        width: '36px',
        borderRadius: '18px',
        border: '2px solid #092A5E',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      formControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
      },
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
    }),
  );

  const classes = useStyle();
  const columnsData = [
    {
      name: 'id',
      label: `${intl.formatMessage({ id: 'administration.actions' })}`,
      options: {
        display: true,
        filter: false,
        filterList: [],
        // eslint-disable-next-line react/display-name
        customBodyRender: (value: string) => {
          return (
            <IconButton onClick={() => handleEditEmployee(value)} size="large">
              <EditIcon color="primary" fontSize="small" />
            </IconButton>
          );
        },
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: 'firstName',
      label: `${intl.formatMessage({ id: 'administration.firstName' })}`,
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: any, meta: any) => {
          return value;
        },
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: 'lastName',
      label: `${intl.formatMessage({ id: 'administration.lastName' })}`,
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: any, meta: any) => {
          return value;
        },
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: 'id',
      label: `${intl.formatMessage({ id: 'administration.user_activated' })}`,
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: any, meta: any) => {
          const user = usersList?.find(u => u.id === value);
          if (user && user.clientRoles && user.clientRoles.indexOf('tff-access') > -1) {
            return value ? <CheckCircleIcon style={{ color: '#00cc00' }} /> : <CancelIcon style={{ color: 'grey' }} />;
          }
          return '';
        },
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: 'email',
      label: `${intl.formatMessage({ id: 'administration.email' })}`,
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: any, meta: any) => {
          return value;
        },
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: 'roles',
      label: `${intl.formatMessage({ id: 'administration.roles' })}`,
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: any) => {
          const role = findRoles(value, 'Role_').map(role => role.description);
          return role;
        },
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: 'roles',
      label: `${intl.formatMessage({ id: 'administration.markets' })}`,
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: any) => {
          const markets = findRoles(value, 'M_').map(role => role.description.split(' ')[1]);
          return markets.join(', ');
        },
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: 'roles',
      label: `${intl.formatMessage({ id: 'administration.products' })}`,
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: any) => {
          const products = findRoles(value, 'P_').map(role => role.description);
          return products.join(', ');
        },
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: 'roles',
      label: `${intl.formatMessage({ id: 'administration.sources' })}`,
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: any) => {
          const products = findRoles(value, 'S_').map(role => role.description);
          return products.join(', ');
        },
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 101,
          },
        }),
      },
    },
  ];

  const tableOptions: MUIDataTableOptions = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    tableBodyHeight: '600px',
    enableNestedDataAccess: '.',
    selectableRows: 'none',
    rowsPerPage: 50,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        className: rowIndex % 2 === 0 ? [classes.LightBlueLine] : [classes.DefaultLine],
      };
    },
  };

  // here I set the them
  const getMuiTheme = () =>
    createTheme(
      adaptV4Theme({
        overrides: {
          MuiTable: {
            root: {
              backgroundColor: '#E2F3FE !important',
            },
          },
          MuiToolbar: {
            root: {
              backgroundColor: '#E2F3FE !important',
            },
          },
          MuiTableCell: {
            head: {
              backgroundColor: '#E2F3FE !important',
              border: 'none',
            },
            root: {
              color: '#092A5E !important',
              fontWeight: 'bold',
            },
          },
          MuiTableHead: {
            root: {
              color: '#092A5E !important',
              fontWeight: 'bold',
            },
          },

          MuiTypography: {
            root: {
              color: '#092A5E',
            },
          },
        },
      }),
    );

  const handleEditEmployee = (id: string) => {
    if (id) {
      setUserId(id);
      setAddEmployee(true);
    }
  };

  const findRoles = (roles: any[], substring: string) => {
    return roles.filter(role => role.name.startsWith(substring));
  };

  const renderUsersTable = () => {
    return (
      <div className={classes.tableWrapper}>
        {usersList && (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                data={usersList}
                columns={columnsData}
                title={`${intl.formatMessage({ id: 'administration.usersUnderAdmin' })}`}
                options={tableOptions}
              />
            </ThemeProvider>
          </StyledEngineProvider>
        )}
      </div>
    );
  };

  const handleUserFormChange = (userUpdated: boolean) => {
    setIsUserUpdated(userUpdated);
    setAddEmployee(false);
  };

  return (
    <div>
      <AdministrationHeaderCard
        onAddEmployee={() => setAddEmployee(!addEmployee)}
        servicesRoles={totalServiceRoles}
        usersCount={users?.length as number}
      />
      {addEmployee ? (
        <UserForm onUserFormChange={handleUserFormChange} mainRoles={mainRoles} userId={userId} />
      ) : (
        renderUsersTable()
      )}
    </div>
  );
};

export default UserAdministration;
