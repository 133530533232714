import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Typography } from '@mui/material';
import { IntlMessages } from '../util';
import { useKeycloak } from '@react-keycloak/web';
import { NavLink } from 'react-router-dom';
import { RenderReImportDialog } from './Re-Import-NDC/ReImportDialog';

export const ShowMoreNavigation = () => {
  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);
  const [viewDialog, setViewDialog] = useState<boolean>(false);
  const { keycloak } = useKeycloak();

  const isItAdmin = keycloak.hasRealmRole('Role_IT_Administrator');
  const isBusinessAdmin = keycloak.hasRealmRole('Role_Business_Administrator');
  const isServiceCenterAgentIntern = keycloak.hasRealmRole('Role_Service_Center_Agent_Intern');
  const isServiceCenterSupervisorIntern = keycloak.hasRealmRole('Role_Service_Center_Supervisor_Intern');

  const handleClick = (event: any) => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleDialog = () => {
    setViewDialog(true);
    setOpen(false);
  };

  return (
    <>
      <div>
        <IconButton onClick={handleClick} size="large">
          <MoreHorizIcon color="primary" fontSize="large" />
        </IconButton>
        <Menu className="user-info" id="simple-menu" anchorEl={anchorE1} open={open} onClose={handleRequestClose}>
          <MenuItem onClick={handleRequestClose} hidden={!isItAdmin}>
            <NavLink to="/tff/involuntary-changes">
              <Typography color="primary" variant="h5">
                Inv. Changes CR
              </Typography>
            </NavLink>
          </MenuItem>
          <MenuItem onClick={handleRequestClose} hidden={!isItAdmin}>
            <NavLink to="/tff/amend-failures">
              <Typography color="primary" variant="h5">
                Amend-Failures
              </Typography>
            </NavLink>
          </MenuItem>
          <MenuItem
            onClick={handleRequestClose}
            hidden={!isItAdmin && !isBusinessAdmin && !isServiceCenterAgentIntern && !isServiceCenterSupervisorIntern}
          >
            <NavLink to="/tff/defered-payment">
              <Typography color="primary" variant="h5">
                Defered Payment
              </Typography>
            </NavLink>
          </MenuItem>
          <MenuItem onClick={handleRequestClose} hidden={!isItAdmin && !isBusinessAdmin && isServiceCenterAgentIntern}>
            <NavLink to="/tff/involuntary-changes-atCom">
              <Typography color="primary" variant="h5">
                Inv. Changes atCom
              </Typography>
            </NavLink>
          </MenuItem>
          <MenuItem onClick={handleRequestClose} hidden={!isItAdmin}>
            <NavLink to="/tff/retrieve">
              <Typography color="primary" variant="h5">
                <IntlMessages id="pages.retrieveListTfm.drawer" />
              </Typography>
            </NavLink>
          </MenuItem>
          <MenuItem onClick={handleRequestClose} hidden={!isBusinessAdmin && !isItAdmin}>
            <NavLink to="/tff/settings/fees">
              <Typography color="primary" variant="h5">
                <IntlMessages id="pages.settings.fee-config.drawer" />
              </Typography>
            </NavLink>
          </MenuItem>
          <MenuItem onClick={handleRequestClose} hidden={!isItAdmin}>
            <NavLink to="/tff/administration">
              <Typography color="primary" variant="h5">
                Administration
              </Typography>
            </NavLink>
          </MenuItem>
          <MenuItem onClick={handleDialog} hidden={!isItAdmin}>
            <Typography color="primary" variant="h5">
              Re-Import
            </Typography>
          </MenuItem>
        </Menu>
      </div>
      <RenderReImportDialog viewDialog={viewDialog} setViewDialog={setViewDialog} />
    </>
  );
};
