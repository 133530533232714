import React from 'react';
import { PlaneIcon } from '../icons';
import { Box, Typography } from '@mui/material';
import PassengerRow from './PassengerRow';
import { TFF } from '@tff/types';

interface props {
  flightOnd: String;
  isOutbound: boolean;
  passengers: {
    [paxId: string]: TFF.Passenger;
  };
  ancillaries: TFF.Ancillaries;
}

const FlightColumn: React.FC<props> = ({ passengers, flightOnd, isOutbound, ancillaries }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '91px',
        minWidth: '320px',
        gap: '16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
        }}
      >
        <PlaneIcon
          style={{
            height: 22,
            width: 22,
            transform: isOutbound ? 'rotate(90deg)' : 'rotate(-90deg)',
          }}
        />
        <Typography
          sx={{
            gap: '8px',
            color: 'primary.main',
            fontSize: '18px',
            lineHeight: '20.59px',
            fontWeight: '600',
          }}
        >
          <strong>{flightOnd}</strong>
        </Typography>
      </Box>
      {Object.values(passengers).map((passenger, index) =>
        passenger.Type !== 'INF' && passenger.Id.indexOf('_') > -1 ? (
          <PassengerRow
            key={index}
            passengerNr={parseInt(passenger.Id.split('_')[1]) + 1}
            passenger={passenger}
            ancillaries={ancillaries}
            isOutbound={isOutbound}
          />
        ) : null,
      )}
    </Box>
  );
};

export default FlightColumn;
