import { Theme, Typography } from '@mui/material';
import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useIntl } from 'react-intl';
import { TypeOfSource } from '@tff/types/TFF/retrieve';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2px 4px',
      borderRadius: '4px',
    },
  }),
);

interface HeaderInfoDataProps {
  bookingStatus: string | undefined;
  bookingSource: TypeOfSource | undefined;
}

const BookingStatusBadge: React.FC<HeaderInfoDataProps> = ({ bookingStatus, bookingSource }) => {
  const classes = useStyles();
  const intl = useIntl();

  const statusColor = bookingStatus === 'Confirmed' ? '#30B575' : bookingStatus === 'Cancelled' ? '#D30D14' : '#F3CC49';

  const bookingStatusMessage = () => {
    if (bookingStatus === 'Confirmed') {
      if (bookingSource === 'NSK') {
        return intl.formatMessage({ id: 'bookingDetailCard.nskStatus.Confirmed' });
      } else if (bookingSource && ['AF', 'KL', 'EK', 'SQ'].indexOf(bookingSource) > -1) {
        return intl.formatMessage({ id: 'bookingDetailCard.ndcStatus.Confirmed' });
      }

      return intl.formatMessage({ id: 'bookingDetailCard.status.Confirmed' });
    }

    return intl.formatMessage({ id: `bookingDetailCard.status.${bookingStatus}` });
  };

  return (
    <div className={classes.statusWrapper} style={{ backgroundColor: statusColor }} data-testid="booking-status">
      <Typography
        variant="h6"
        style={{
          color: bookingStatus === 'Cancelled' ? 'white' : '#333333',
          fontSize: '18px',
        }}
      >
        {bookingStatusMessage()}
      </Typography>
    </div>
  );
};

export default BookingStatusBadge;
