import { Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';

interface props {}

const useStyles = makeStyles(() =>
  createStyles({
    headerRoot: {
      width: '100%',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: '#092A5E',
      padding: '20px 0px',
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '20px',
    },
    iconWrapper: {
      height: 40,
      width: 40,
      backgroundColor: '#70CBF4',
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

const RegistrationHeaderCard: React.FC<props> = () => {
  const classes = useStyles();

  const renderRoundIcon = (icon: React.ReactNode) => {
    return <div className={classes.iconWrapper}>{icon}</div>;
  };

  const renderHeader = () => {
    return (
      <div className="d-flex flex-row" style={{ padding: '20px 0px' }}>
        {renderRoundIcon(
          <svg
            viewBox="0 0 24 24"
            style={{
              fill: '#092A5E',
              width: '24px',
              height: '24px',
            }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="a"
              d="M16.493,27.747a2.9,2.9,0,0,1,.566,3.294l.8.8L31.84,17.857l-.8-.8a2.9,2.9,0,0,1-3.86-3.86l-.8-.8L12.4,26.383l.8.8A2.9,2.9,0,0,1,16.493,27.747Zm-4.173,1.216-2.038-2.038a.961.961,0,0,1,0-1.359L25.567,10.281a.961.961,0,0,1,1.359,0l2.038,2.038a.961.961,0,0,1,0,1.359.961.961,0,0,0,1.359,1.359.961.961,0,0,1,1.359,0l2.038,2.038a.961.961,0,0,1,0,1.359L18.433,33.719a.961.961,0,0,1-1.359,0l-2.038-2.038a.961.961,0,0,1,0-1.359.961.961,0,0,0-1.359-1.359A.961.961,0,0,1,12.319,28.963Z"
              transform="translate(-10 -10)"
            />
          </svg>,
        )}
        <Typography variant="h6" style={{ marginLeft: '10px', color: 'white', fontSize: '25px' }}>
          Registration
        </Typography>
      </div>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.headerRoot}>
        <div style={{ width: '60%', margin: '0 auto' }}>
          {renderHeader()}
          <Typography variant="h6" style={{ color: 'white' }}>
            Complete your Registration. Your request will be checked by the user administration.
          </Typography>
          <Typography variant="h6" style={{ color: 'white' }}>
            When your account has been granted, you'll be notified via mail.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default RegistrationHeaderCard;
