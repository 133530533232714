import { FC } from 'react';

const BlueCheckCheckBox: FC = (): JSX.Element => {
  return (
    <svg
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: '20.5px',
        height: '20.5px',
        marginTop: '-5px',
      }}
    >
      <path
        d="M1.33337 3C1.33337 1.89543 2.2288 1 3.33337 1H15.3334C16.4379 1 17.3334 1.89543 17.3334 3V15C17.3334 16.1046 16.4379 17 15.3334 17H3.33337C2.2288 17 1.33337 16.1046 1.33337 15V3Z"
        fill={'#1A7EAD'}
        stroke={'#1A7EAD'}
      />
      <path
        d="M13.0834 5.125L8.70837 12.625L5.58337 9.5"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BlueCheckCheckBox;
