import { TFF } from '@tff/types';
import { AncillaryType } from '../../../../types/ancillaryTypes';

//Gets the availability for all the sport baggage
export const getSportInitialSsrAvailability = (flightAncillaries: TFF.MetaValue[]): Record<string, number> => {
  const sportAncillaries: TFF.MetaValue[] = flightAncillaries.filter(
    ancillary => ancillary.type === AncillaryType.Sports,
  );

  return sportAncillaries.reduce((acc, ssr) => {
    acc[ssr.code] = ssr.restriction.actualCapacityLeft;
    return acc;
  }, {} as Record<string, number>);
};

//Checks if there is an unavailable ancillary already selected for all the passengers
export const isAncillaryIncludedForAllPassengers = (
  flightSsrAvailability: Record<string, number>,
  ssrsToAdd: { [paxId: string]: TFF.SsrDetails[] },
  flightOnd: string,
): boolean => {
  const unavailableSsrKeys = Object.keys(flightSsrAvailability).filter(key => flightSsrAvailability[key] === 0);

  if (unavailableSsrKeys.length === 0) {
    return false;
  }

  return unavailableSsrKeys.every(key =>
    Object.values(ssrsToAdd).every(ancillaries =>
      ancillaries.some(ancillary => ancillary.code === key && flightOnd === ancillary.segmentOnd),
    ),
  );
};
