import { IconButton, Theme } from '@mui/material';
import * as React from 'react';
import CustomTooltip from '../CustomTooltip';
import { ReactNode } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spaceAroundIcon: {
      [theme.breakpoints?.down('md')]: {
        padding: '12px 2px',
      },
      [theme.breakpoints?.up('md')]: {
        padding: '12px 8px',
      },
    },
  }),
);

interface HeaderInfoDataProps {
  title: string;
  label: string;
  handleOnClick: () => void;
  icon: ReactNode;
}

const HeaderIconButton: React.FC<HeaderInfoDataProps> = ({ title, label, handleOnClick, icon }) => {
  const classes = useStyles();

  return (
    <CustomTooltip title={title}>
      <IconButton
        color="secondary"
        aria-label={label}
        component="span"
        onClick={handleOnClick}
        size="large"
        className={classes.spaceAroundIcon}
      >
        {icon}
      </IconButton>
    </CustomTooltip>
  );
};

export default HeaderIconButton;
