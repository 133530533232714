import { TFF } from '@tff/types';
import * as React from 'react';
import DynamicTable from './DynamicTable';
import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ISourceConfig } from '../../models';
import { Ancillary } from '@tff/types/TFF';
import { Fare, FeeAndTax } from '@tff/types/TFF/tff-flight-details';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    centeredText: {
      textAlign: 'center',
      fontWeight: 'bold',
      paddingLeft: 'auto',
      paddingRight: 'auto',
    },
    headers: {
      padding: '5px',
      backgroundColor: '#E2F3FE',
    },
    journeyHeader: {
      padding: '5px',
      backgroundColor: '#70CBF4',
    },
  }),
);

interface IProps {
  orderDetails: TFF.OrderDetails;
  sourceConfig?: ISourceConfig;
  tuiServiceCharges?: { [paxId: string]: Fare };
}

const ChargesSummary: React.FC<IProps> = ({ orderDetails, sourceConfig, tuiServiceCharges }) => {
  const numberFormatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 });
  const classes = useStyles();

  if (!orderDetails || !sourceConfig) {
    return null;
  }

  type PaxFareDetails = {
    PaxID: string;
    PaxType: string;
    Passenger: TFF.Passenger;
    FeesAndTaxes: TFF.FeeAndTax[][];
    BaseAmount: number[];
    AncillaryIDs: string[][];
    Adjustments: TFF.FeeAndTax[][];
  };

  const createPaxFareDetails = (orderDetails: TFF.OrderDetails, bookingSource: string): PaxFareDetails[] => {
    const paxIds: string[] = Object.keys(orderDetails?.Fares);
    const result: PaxFareDetails[] = [];

    paxIds.forEach(PaxID => {
      let FeesAndTaxes: TFF.FeeAndTax[][] = [];
      let BaseAmount: number[] = [];
      let AncillaryIDs: string[][] = [];
      let Adjustments: TFF.FeeAndTax[][] = [];
      const fare: TFF.Fare = orderDetails.Fares[PaxID];

      if (bookingSource !== 'TUI_NSK') {
        FeesAndTaxes = fare.FeesAndTaxes ? [fare.FeesAndTaxes] : [];
        BaseAmount = fare.BaseAmount ? [fare.BaseAmount] : [];
        AncillaryIDs = fare.AncillaryIDs
          ? [fare.AncillaryIDs]
          : fare.FareProducts.map<string[]>(a => a.AncillaryIDs ?? []);
      } else {
        FeesAndTaxes = fare.FareProducts.map<TFF.FeeAndTax[]>(f => f.FeesAndTaxes ?? []);
        BaseAmount = fare.FareProducts.map(f => f.BaseAmount!);
        AncillaryIDs = fare.FareProducts.map<string[]>(a => a.AncillaryIDs ?? []);
      }

      Adjustments = fare.FareProducts.map<TFF.FeeAndTax[]>(f => f.Adjustments ?? []);

      result.push({
        PaxID,
        PaxType: fare.PaxType,
        Passenger: orderDetails.Passengers[PaxID],
        FeesAndTaxes,
        BaseAmount,
        AncillaryIDs,
        Adjustments,
      });
    });

    return result;
  };

  const journeyOnds: string[] = orderDetails.Journeys.map(j => j.Ond) as string[];
  const paxFares: PaxFareDetails[] = createPaxFareDetails(orderDetails, sourceConfig.SourceGroup);

  const createEmptySSrData = (title: string, passengerCount: number): dynTableItem => {
    const data: string[] = [];
    for (let i = 0; i < passengerCount; i++) {
      data.push('');
    }
    return { title, data, total: 0, count: 1 };
  };

  interface dynTableItem {
    count: number;
    title: string;
    data: (string | number)[];
    total: number;
  }

  /**
   * @param ssrsData
   * @param ancillaryOrFee
   * @param paxCounter
   * @param title
   */
  const createAncillaryOrFeeCosts = (
    ssrsData: { [key: string]: dynTableItem },
    ancillaryOrFee: Ancillary | FeeAndTax,
    paxCounter: number,
    title?: string,
  ): void => {
    let elementType: string = '';
    let elementAmount: number = 0;

    if ('Type' in ancillaryOrFee) {
      elementType = ancillaryOrFee.Type;
      elementAmount = (ancillaryOrFee as TFF.Ancillary).TotalAmount ?? 0;
    } else {
      elementType = (ancillaryOrFee as TFF.FeeAndTax).Code!;
      elementAmount = (ancillaryOrFee as TFF.FeeAndTax).Amount ?? 0;
    }

    const uniqueId = elementType + '-' + elementAmount;
    const amount = elementAmount;

    if (!ssrsData[uniqueId]) {
      ssrsData[uniqueId] = createEmptySSrData('', paxFares.length);
      ssrsData[uniqueId].data[paxCounter] = amount;
    } else {
      if (ssrsData[uniqueId].data[paxCounter]) {
        ssrsData[uniqueId].count += 1;
        ssrsData[uniqueId].data[paxCounter] = +ssrsData[uniqueId].data[paxCounter] + amount;
      } else {
        ssrsData[uniqueId].data[paxCounter] = amount;
      }
    }
    ssrsData[uniqueId].total = ssrsData[uniqueId].data
      ?.map(f => f ?? 0)
      .reduce((a: string | number, b: string | number) => +a + +b, 0) as number;

    const count = ssrsData[uniqueId].count;

    const titleElement =
      // @ts-ignore
      (ancillaryOrFee.Type ?? ancillaryOrFee.Value ?? ancillaryOrFee.Code) + ' ' + (ancillaryOrFee.Description ?? '');

    const _title =
      count > 1
        ? `${titleElement} (${ssrsData[uniqueId].total / elementAmount} x ${numberFormatter.format(elementAmount)})`
        : `${titleElement}`;
    ssrsData[uniqueId].title = `${_title}`;
  };

  const renderPaxHeader = () => {
    if (!paxFares) {
      return <></>;
    }
    const pax = paxFares.map(j => `${j.Passenger.LastName}, ${j.Passenger.FirstName}`);
    let columnsCount = pax.length;
    const rowsCount = 1;
    const data: (JSX.Element | string)[][] = [['Description', ...pax, 'Total']];
    data[data.length - 1] = data[data.length - 1].map<JSX.Element | string>((d, index) => (
      <span className={classes.centeredText} key={index}>
        {d}
      </span>
    ));
    columnsCount += 2; // Description + Total
    return (
      <DynamicTable columnsCount={columnsCount} rowsCount={rowsCount} data={data} paxNumber={paxFares.length} head />
    );
  };

  const renderPaxBaseAmount = (flightIndex: number = 0) => {
    if (!paxFares) {
      return <></>;
    }

    const paxBaseAmounts: string[] = [];
    let total = 0;
    paxFares.forEach(p => {
      const paxAmount = p.BaseAmount[flightIndex];
      total += paxAmount ? paxAmount : 0;
      paxAmount && paxBaseAmounts.push(paxAmount.toString());
    });

    const baseAmounts: (JSX.Element | string)[][] = [['Base Fares', ...paxBaseAmounts, String(total)]];
    baseAmounts[baseAmounts.length - 1] = baseAmounts[baseAmounts.length - 1].map<JSX.Element | string>((d, index) =>
      index > 0 ? <b>{numberFormatter.format(+d)}</b> : <b>{d}</b>,
    );
    const rowsCount = baseAmounts.length;
    const columnsCount = baseAmounts[0]?.length;

    return (
      <DynamicTable
        columnsCount={columnsCount}
        rowsCount={rowsCount}
        data={baseAmounts}
        paxNumber={paxFares.length}
        lastColumnStyle
      />
    );
  };

  const renderFeesAndTaxes = (flightIndex: number = 0) => {
    if (!paxFares) {
      return undefined;
    }

    let feeAndTaxesData: { [key: string]: dynTableItem } = {};
    paxFares.forEach((p, paxCounter) => {
      if (p.FeesAndTaxes?.length > flightIndex) {
        p.FeesAndTaxes[flightIndex].forEach(fee => createAncillaryOrFeeCosts(feeAndTaxesData, fee, paxCounter));
      }
    });

    const dataItemsCount = Object.values(feeAndTaxesData).length;

    if (feeAndTaxesData && dataItemsCount > 0) {
      const data: (JSX.Element | string | number)[][] = [];

      const summaryAmount: (JSX.Element | number)[] = [];
      let summaryTotal: number = 0;
      Object.values(feeAndTaxesData).forEach((ssrData, ssrIndex) => {
        data[ssrIndex] = [ssrData.title, ...ssrData.data, ssrData.total];
        summaryTotal += ssrData.total;

        ssrData.data.forEach((dataAmount, index) => {
          summaryAmount[index] = summaryAmount[index] ?? 0;
          summaryAmount[index] = +summaryAmount[index] + Number(dataAmount);
        });
      });
      data.push(['Total Fees & Taxes included', ...summaryAmount, summaryTotal]);
      data[data.length - 1] = data[data.length - 1].map<JSX.Element | number | string>((d, index) =>
        index > 0 ? <i>{numberFormatter.format(+d)}</i> : <i>{d}</i>,
      );

      const columnsCount = paxFares.length + 2;
      return (
        <DynamicTable
          columnsCount={columnsCount}
          rowsCount={dataItemsCount + 1}
          data={data}
          paxNumber={paxFares.length}
          lastColumnStyle
        />
      );
    }
    return undefined;
  };

  /**
   *
   * @param flightIndex
   */
  const renderSsrs = (flightIndex: number = 0) => {
    if (!paxFares) {
      return undefined;
    }

    let ssrsData: { [key: string]: dynTableItem } = {};
    paxFares.forEach((p, paxCounter) => {
      // 1. search seat this person and journey
      const ancillaryIDs = p.AncillaryIDs[flightIndex];

      if (ancillaryIDs) {
        const seatAncillaries = Object.values(orderDetails?.Ancillaries!).filter(a => {
          return a.Type === 'SEAT' && ancillaryIDs.indexOf(a.Id) > -1;
        });

        seatAncillaries.forEach(ancillary => createAncillaryOrFeeCosts(ssrsData, ancillary, paxCounter, 'Seat'));

        const infantAncillaries = Object.values(orderDetails?.Ancillaries!).filter(
          a => a.Type === 'INF' && ancillaryIDs.indexOf(a.Id) > -1,
        );

        // 2. search infant this person and journey
        infantAncillaries.forEach(ancillary => createAncillaryOrFeeCosts(ssrsData, ancillary, paxCounter, 'Infant'));

        const baggageAncillaries = Object.values(orderDetails?.Ancillaries!).filter(
          a => a.Type && (a.Type === 'BAGGAGE' || a.Type.startsWith('BA')) && ancillaryIDs.indexOf(a.Id) !== -1,
        );

        baggageAncillaries.forEach(ancillary => createAncillaryOrFeeCosts(ssrsData, ancillary, paxCounter, 'Baggage'));

        const otherAncillaries = Object.values(orderDetails?.Ancillaries!).filter(
          a =>
            a.Type &&
            ['SEAT', 'BAGGAGE', 'INF'].indexOf(a.Type) === -1 &&
            !a.Type.startsWith('BA') &&
            ancillaryIDs.indexOf(a.Id) !== -1,
        );

        otherAncillaries.forEach(ancillary => createAncillaryOrFeeCosts(ssrsData, ancillary, paxCounter, 'SSR'));
      }
    });

    const dataItemsCount = Object.values(ssrsData).length;
    if (ssrsData && dataItemsCount > 0) {
      const data: (JSX.Element | string | number)[][] = [];

      const summaryAmount: (JSX.Element | number)[] = [];
      let summaryTotal: number = 0;
      Object.values(ssrsData).forEach((ssrData, ssrIndex) => {
        data[ssrIndex] = [ssrData.title, ...ssrData.data, ssrData.total];
        summaryTotal += ssrData.total;

        ssrData.data.forEach((dataAmount, index) => {
          summaryAmount[index] = summaryAmount[index] ?? 0;
          summaryAmount[index] = +summaryAmount[index] + Number(dataAmount);
        });
      });
      data.push(['Total Extras', ...summaryAmount, summaryTotal]);
      data[data.length - 1] = data[data.length - 1].map<JSX.Element | number | string>((d, index) =>
        index > 0 ? <b>{numberFormatter.format(+d)}</b> : <b>{d}</b>,
      );
      const columnsCount = paxFares.length + 2;

      return (
        <DynamicTable
          columnsCount={columnsCount}
          rowsCount={dataItemsCount + 1}
          data={data}
          paxNumber={paxFares.length}
          lastColumnStyle
        />
      );
    }
    return undefined;
  };

  const renderAdjustments = (flightIndex: number = 0) => {
    if (!paxFares) {
      return undefined;
    }

    let adjustmentsData: { [key: string]: dynTableItem } = {};
    paxFares.forEach((p, paxCounter) => {
      if (p.Adjustments?.length > flightIndex) {
        p.Adjustments[flightIndex].forEach(fee => createAncillaryOrFeeCosts(adjustmentsData, fee, paxCounter));
      }
    });

    const dataItemsCount = Object.values(adjustmentsData).length;

    if (adjustmentsData && dataItemsCount > 0) {
      const data: (JSX.Element | string | number)[][] = [];

      const summaryAmount: (JSX.Element | number)[] = [];
      let summaryTotal: number = 0;
      Object.values(adjustmentsData).forEach((adjustmentData, adjustmentIndex) => {
        data[adjustmentIndex] = [adjustmentData.title, ...adjustmentData.data, adjustmentData.total];
        summaryTotal += adjustmentData.total;

        adjustmentData.data.forEach((dataAmount, index) => {
          summaryAmount[index] = summaryAmount[index] ?? 0;
          summaryAmount[index] = +summaryAmount[index] + Number(dataAmount);
        });
      });
      data.push(['Total Adjustment included', ...summaryAmount, summaryTotal]);
      data[data.length - 1] = data[data.length - 1].map<JSX.Element | number | string>((d, index) =>
        index > 0 ? <i>{numberFormatter.format(-Math.abs(+d))}</i> : <i>{d}</i>,
      );

      const columnsCount = paxFares.length + 2;
      return (
        <DynamicTable
          columnsCount={columnsCount}
          rowsCount={dataItemsCount + 1}
          data={data}
          paxNumber={paxFares.length}
          lastColumnStyle
        />
      );
    }
    return undefined;
  };

  const renderTotal = (flightIndex: number = 0, j: string) => {
    if (!paxFares) {
      return <></>;
    }

    const description = `Total ${j}`;
    const data: (number | JSX.Element | string)[][] = [];
    const paxTotal: string[] = [];
    paxFares.forEach(p => {
      const baseAmount = p.BaseAmount[flightIndex] ?? 0;
      const ancillaries =
        sourceConfig.SourceGroup !== 'TUI_AMADEUS' && p.AncillaryIDs.length && p.AncillaryIDs[flightIndex]?.length
          ? p.AncillaryIDs[flightIndex]
              .map(id => {
                if (orderDetails?.Ancillaries && orderDetails.Ancillaries[id]) {
                  const ancillary: TFF.Ancillary = orderDetails.Ancillaries[id];
                  return ancillary?.TotalAmount ?? 0;
                }
                return 0;
              })
              .reduce((a: number, b: number) => a + b, 0)
          : 0;
      const total = baseAmount + ancillaries;
      paxTotal.push(String(total));
    });

    const total = paxTotal.reduce((a, b) => +a + +b, 0);
    data.push([description, ...paxTotal, String(total)]);
    data[data.length - 1] = data[data.length - 1].map<JSX.Element | number | string>((d, index) =>
      index > 0 ? <strong>{numberFormatter.format(+d)}</strong> : <strong>{d}</strong>,
    );
    const rowsCount = data.length;
    const columnsCount = data[0].length;
    return (
      <div>
        <div style={{ height: '5px' }}>&nbsp;</div>
        <DynamicTable
          columnsCount={columnsCount}
          rowsCount={rowsCount}
          data={data}
          paxNumber={paxFares.length}
          lastColumnStyle
          lastRowStyle
          totalStyle
        />
      </div>
    );
  };

  const flightIndexes =
    sourceConfig.SourceGroup === 'TUI_NDC' ||
    sourceConfig.SourceGroup === 'TUI_AMADEUS' ||
    sourceConfig.SourceGroup === 'TUI_NDCX'
      ? ['Your Journey']
      : journeyOnds.map((ond: string, index: number) => `Flight ${index + 1} (${ond})`);

  const renderTuiFees = () => {
    if (!tuiServiceCharges) {
      return null;
    }
    let ssrsData: { [key: string]: dynTableItem } = {};

    paxFares.forEach((p, paxCounter) => {
      tuiServiceCharges[p.PaxID]?.FeesAndTaxes?.forEach(fee =>
        createAncillaryOrFeeCosts(ssrsData, fee, paxCounter, 'Service Charge'),
      );
    });

    if (tuiServiceCharges['ALL']) {
      tuiServiceCharges['ALL']?.FeesAndTaxes?.forEach(fee => {
        const mSsrData = createEmptySSrData(fee.Code!, paxFares.length);
        mSsrData.data[0] = 'pauschal';
        mSsrData.total = fee.Amount ?? 0;
        ssrsData[fee.Code!] = mSsrData;
      });
    }

    const dataItemsCount = Object.values(ssrsData).length;
    if (ssrsData && dataItemsCount > 0) {
      const data: (JSX.Element | string | number)[][] = [];

      const summaryAmount: (JSX.Element | number)[] = [];
      let summaryTotal: number = 0;
      Object.values(ssrsData).forEach((ssrData, ssrIndex) => {
        data[ssrIndex] = [ssrData.title, ...ssrData.data, ssrData.total];
        summaryTotal += ssrData.total;

        ssrData.data.forEach((dataAmount, index) => {
          summaryAmount[index] = summaryAmount[index] ?? 0;
          summaryAmount[index] = +summaryAmount[index] + Number(dataAmount);
        });
      });
      data.push(['Total TUI Charges', ...summaryAmount, summaryTotal]);
      data[data.length - 1] = data[data.length - 1].map<JSX.Element | number | string>((d, index) => {
        if (index > 0 && index <= data.length - 1 && isNaN(+d)) {
          d = <></>;
        }
        return !isNaN(+d) ? <b>{numberFormatter.format(+d)}</b> : <b>{d}</b>;
      });
      const columnsCount = paxFares.length + 2;
      return (
        <DynamicTable
          columnsCount={columnsCount}
          rowsCount={dataItemsCount + 1}
          data={data}
          paxNumber={paxFares.length}
          lastColumnStyle
        />
      );
    }
    return undefined;
  };

  return (
    <div className={classes.root}>
      {renderPaxHeader()}
      {flightIndexes &&
        flightIndexes.map((j, index) => {
          const ssrBlock = renderSsrs(index);
          return (
            <div style={{ marginBottom: '20px' }} key={index}>
              <div>
                <Typography
                  color="primary"
                  variant="subtitle1"
                  className={classes.journeyHeader}
                  style={{ maxWidth: 180 }}
                >
                  <strong>{j}</strong>
                </Typography>
                {renderPaxBaseAmount(index)}
                <Typography color="primary" variant="subtitle2" className={classes.headers} style={{ maxWidth: 180 }}>
                  Tax & Fees (included)
                </Typography>
              </div>
              {renderFeesAndTaxes(index)}

              {ssrBlock && (
                <>
                  <Typography color="primary" variant="subtitle2" className={classes.headers} style={{ maxWidth: 180 }}>
                    Extras
                  </Typography>
                  {ssrBlock}
                </>
              )}

              <Typography color="primary" variant="subtitle2" className={classes.headers} style={{ maxWidth: 180 }}>
                Adjustments
              </Typography>
              {renderAdjustments(index)}

              {renderTotal(index, j)}
            </div>
          );
        })}
      {
        <>
          <Typography color="primary" variant="subtitle1" className={classes.journeyHeader} style={{ maxWidth: 180 }}>
            <strong>TUI Service Charges</strong>
          </Typography>
          {renderTuiFees()}
        </>
      }
    </div>
  );
};

export default ChargesSummary;
