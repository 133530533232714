import UserRegistration from './UserRegistration';
import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../withRouter';

export const UserRegistrationRoutes = () => {
  return (
    <div className="app-wrapper h-100">
      <Routes>
        <Route path="/" element={<UserRegistration />} />
      </Routes>
    </div>
  );
};

export default withRouter(UserRegistrationRoutes);
