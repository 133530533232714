import { format } from 'date-fns';
import { MUIDataTableMeta } from 'mui-datatables';
import { Link } from 'react-router-dom';
import { ITableColumns } from '../../../models';
import { IntlMessages } from '../../../util';

export const columnDefinition: ITableColumns[] = [
  {
    name: 'no',
    label: <IntlMessages id="pages.retrieveList.list.no" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (_value: string | number, tableMeta: MUIDataTableMeta) => {
        return `${tableMeta.rowIndex + 1}`;
      },
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap',
          position: 'sticky',
          left: '0',
          background: 'white',
          zIndex: 100,
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          whiteSpace: 'nowrap',
          position: 'sticky',
          left: '0',
          background: 'white',
          zIndex: 101,
        },
      }),
    },
  },
  {
    name: 'product',
    label: <IntlMessages id="pages.retrieveList.list.product" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
      },
    },
  },
  {
    name: 'channel',
    label: <IntlMessages id="pages.retrieveList.list.channel" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
      },
    },
  },
  {
    name: 'tfmPnr',
    label: <IntlMessages id="pages.retrieveList.list.tfmPnr" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
        // return <Link to={`${props.match.url}/tfm/${value}`}>{value}</Link>;
      },
    },
  },
  {
    name: 'status',
    label: <IntlMessages id="pages.retrieveList.list.status" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
      },
    },
  },
  {
    name: 'bookingResponse.recordLocator',
    label: <IntlMessages id="pages.retrieveList.list.bookingid" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number, tableMeta: MUIDataTableMeta) => {
        return <Link to={`/tff/order/${value}@${tableMeta.rowData[6]}`}>{value}</Link>;
      },
    },
  },
  {
    name: 'bookingSource',
    label: <IntlMessages id="pages.retrieveList.list.bookingsrc" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
      },
    },
  },
  {
    name: 'midocoOrderId',
    label: <IntlMessages id="pages.retrieveList.list.midocoOrderId" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
      },
    },
  },
  {
    name: 'midocoOrderNo',
    label: <IntlMessages id="pages.retrieveList.list.midocoOrderNo" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
      },
    },
  },
  {
    name: 'clientPnr',
    label: <IntlMessages id="pages.retrieveList.list.clientPnr" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
      },
    },
  },
  {
    name: 'bookingResponse.bookingDate',
    label: <IntlMessages id="pages.retrieveList.list.bookingDate" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
          </div>
        );
      },
    },
  },
  {
    name: 'ticketed',
    label: <IntlMessages id="pages.retrieveList.list.ticketed" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number | boolean) => {
        if (value === undefined) {
          return '';
        }
        return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value ? '✅' : '❌'}</div>;
      },
    },
  },

  {
    name: 'ticketTimeLimit',
    label: <IntlMessages id="pages.retrieveList.list.ticketTimeLimit" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
          </div>
        );
      },
    },
  },

  {
    name: 'origin',
    label: <IntlMessages id="pages.retrieveList.list.origin" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
      },
    },
  },

  {
    name: 'destination',
    label: <IntlMessages id="pages.retrieveList.list.destination" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
      },
    },
  },

  {
    name: 'outDeparture',
    label: <IntlMessages id="pages.retrieveList.list.outDeparture" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
          </div>
        );
      },
    },
  },

  {
    name: 'outBookingClasses',
    label: <IntlMessages id="pages.retrieveList.list.outBookingClasses" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
      },
    },
  },
  {
    name: 'outCabinClasses',
    label: <IntlMessages id="pages.retrieveList.list.outCabinClasses" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
      },
    },
  },

  {
    name: 'inDeparture',
    label: <IntlMessages id="pages.retrieveList.list.inDeparture" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return (
          <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
            {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
          </div>
        );
      },
    },
  },

  {
    name: 'inBookingClasses',
    label: <IntlMessages id="pages.retrieveList.list.inBookingClasses" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
      },
    },
  },
  {
    name: 'inCabinClasses',
    label: <IntlMessages id="pages.retrieveList.list.inCabinClasses" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
      },
    },
  },

  {
    name: 'bookingSessionId',
    label: <IntlMessages id="pages.retrieveList.list.bookingSessId" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
      },
    },
  },

  {
    name: 'createdAt',
    label: <IntlMessages id="pages.retrieveList.list.createdAt" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
          </div>
        );
      },
    },
  },
  {
    name: 'updatedAt',
    label: <IntlMessages id="pages.retrieveList.list.updatedAt" />,
    options: {
      display: true,
      filter: true,
      filterList: [],
      customBodyRender: (value: string | number) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
          </div>
        );
      },
    },
  },
  {
    name: 'version',
    label: 'Version',
    options: {
      display: true,
      filter: true,
      filterList: [],
    },
  },
];
