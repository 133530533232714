import { useKeycloak } from '@react-keycloak/web';
import { PrivateRoute } from '../util/PrivateRoute';

import B2BRoutes from './b2b-routes';
import { Routes, Route } from 'react-router-dom';
import { withRouter } from './withRouter';

const B2bRoutes = () => {
  const { initialized } = useKeycloak();
  if (!initialized) {
    return <h3>Loading ... !!!</h3>;
  }

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/tff/*" element={<B2BRoutes />} />
      </Route>
    </Routes>
  );
};

export default withRouter(B2bRoutes);
