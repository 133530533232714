import * as React from 'react';
import { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

interface IProps {
  rowsCount: number;
  columnsCount: number;
  data: (JSX.Element | string | number)[][];
  lastColumnStyle?: boolean;
  lastRowStyle?: boolean;
  head?: boolean;
  paxNumber: number;
  totalStyle?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'transparent',
      width: '100%',
      marginLeft: '10px',
    },
    verticalTotal: {},
  }),
);

const StyledTableCell = withStyles(() =>
  createStyles({
    root: {
      height: 15,
      padding: 5,
    },
    head: {
      backgroundColor: '#70CBF4',
      color: '#092A5E',
    },
    body: {
      fontSize: 14,
      width: '120px',
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.action.hover,
    },
  }),
)(TableRow);

const DynamicTable: React.FC<IProps> = ({
  rowsCount,
  columnsCount,
  data,
  lastColumnStyle = false,
  head = false,
  paxNumber,
  lastRowStyle = false,
  totalStyle = false,
}) => {
  const numberFormatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 });
  const classes = useStyles();
  const rowsCountArray: number[] = useMemo(() => {
    const rows: number[] = [];
    for (let i = 1; i <= rowsCount; i++) {
      rows.push(i);
    }
    return rows;
  }, [rowsCount]);

  const columnsCountArray: number[] = useMemo(() => {
    const columns: number[] = [];
    for (let i = 1; i <= columnsCount; i++) {
      columns.push(i);
    }
    return columns;
  }, [columnsCount]);

  const columnWidth: number = useMemo(() => {
    switch (paxNumber) {
      case 1:
      case 2:
        return 130;
      case 3:
        return 180;
      case 4:
        return 160;
      case 5:
        return 140;
      default:
        return 100;
    }
  }, [paxNumber]);

  return (
    <Table className={classes.root}>
      <TableBody>
        {rowsCountArray.map((_row, rowsIndex) => (
          <StyledTableRow key={rowsIndex}>
            {columnsCountArray.map((_col, colIndex) => {
              const columnsToColor = colIndex % 2 !== 0;
              const backgroundColor = colIndex === 0 ? '#d8d8d8' : columnsToColor ? 'inherit' : '#d0cbcb';
              const value: JSX.Element | string | number = data[rowsIndex][colIndex];
              let formattedValue = React.isValidElement(value)
                ? value
                : colIndex === 0 || value === '' || isNaN(+value)
                ? value
                : numberFormatter.format(+value);
              return (
                <StyledTableCell
                  align={colIndex === 0 ? 'left' : head ? 'center' : 'right'}
                  key={colIndex}
                  style={{
                    backgroundColor: backgroundColor,
                    paddingLeft: colIndex === 0 ? '15px' : '5px',
                    height: '30px',
                    width: colIndex === 0 ? '180px' : colIndex === columnsCount - 1 ? '100px' : columnWidth,
                    borderTop: totalStyle ? '1px solid black' : 'none',
                  }}
                >
                  {formattedValue}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DynamicTable;
