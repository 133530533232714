import { LoadingContext } from '../contexts/LoadingContextProvider';
import { apigeeEndpoint } from '../apis';
import { useContext, useEffect } from 'react';

interface callApigeeProps {
  endpoint: string;
  onSuccess: () => void;
  call: boolean;
  onError?: (error: any) => void;
  loadingText?: string;
  doneText?: string;
}
export const useApigeeCall = async ({ endpoint, onSuccess, call, onError, doneText, loadingText }: callApigeeProps) => {
  const { showLoading, closeLoading } = useContext(LoadingContext);

  const backendCall = async (): Promise<void> => {
    try {
      showLoading(loadingText || 'get url: ' + endpoint);

      const response = await apigeeEndpoint.get(endpoint);
      //console.log(response);

      if (response.status === 200) {
        showLoading(doneText || 'Request successfull');
        onSuccess();
      } else {
        console.error(response);
        if (onError) onError(response);
        showLoading('Error, check console for detailed error message.');
      }
    } catch (error) {
      showLoading(error.message);
      if (onError) onError(error);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    if (call) {
      backendCall();
    }
  }, [call]);
};
