import * as React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { TypographyVariant } from '@mui/material';

interface props {
  children: React.ReactElement<any, any>;
  title: NonNullable<React.ReactNode>;
  variant?: TypographyVariant;
}

const CustomTooltip: React.FC<props> = props => {
  return <Tooltip title={<Typography variant={props.variant}>{props.title}</Typography>}>{props.children}</Tooltip>;
};
export default CustomTooltip;
