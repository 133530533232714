import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import CustomDialog from '../Dialogs';
import { ConfirmationDialog } from './ConfirmationDialog';
import AncillaryTable from './AncillaryTable';
import { useAddSsr } from '../../hooks/use-ssr-update';
import { useRebook } from '../RebookFlight/RebookContext';

export const RenderAncillaryTable = ({ setTableOpen, journeys, orderDetails, recordLocator }) => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showFailure, setShowFailure] = useState<boolean>(false);

  const { setSsrUpdateRequest, ssrStatus } = useAddSsr();
  const { ancillaryPaxData } = useRebook();

  const handleConfirm = () => {
    setSsrUpdateRequest({
      recordLocator: recordLocator,
      bookingSource: 'TUI-NSK',
      paxData: ancillaryPaxData,
    });
  };

  useEffect(() => {
    setShowConfirmation(false);
    setShowSuccess(false);
    setShowFailure(false);
    if (ssrStatus === 'CONFIRMED') {
      setShowConfirmation(true);
      setShowSuccess(true);
    } else if (ssrStatus === 'FAILURE') {
      setShowConfirmation(true);
      setShowFailure(true);
    }
  }, [ssrStatus]);

  return (
    <>
      <CustomDialog
        confirmDisabled={ancillaryPaxData.length < 1}
        onCancel={() => {
          setTableOpen(false);
        }}
        initialOpen={true}
        confirmButtonText="SAVE CHANGES"
        confirmButtonStyle={{
          backgroundColor: ancillaryPaxData.length < 1 ? 'grey' : '#04AA6D',
          color: 'black',
        }}
        onConfirm={() => {
          handleConfirm();
        }}
        width="lg"
        cancelButtonText="CANCEL"
        titleBg="#E2F3FE"
        title={
          <Typography variant="h5" align="left" fontWeight="bold">
            SSR Table
          </Typography>
        }
      >
        <AncillaryTable
          journeys={journeys}
          orderDetails={orderDetails}
          recordLocator={recordLocator}
          setTableOpen={setTableOpen}
        />
      </CustomDialog>
      {showConfirmation && (
        <ConfirmationDialog
          showConfirmation={showConfirmation}
          showFailure={showFailure}
          showSuccess={showSuccess}
          setShowConfirmation={setShowConfirmation}
          setTableOpen={setTableOpen}
        />
      )}
    </>
  );
};

export const RenderAmendError = ({ setErrorDialog, errorDialog, errorMessage }) => {
  return (
    <>
      <CustomDialog
        initialOpen={errorDialog}
        confirmButtonText="Close"
        confirmButtonStyle={{
          backgroundColor: '#F2E30F',
          color: 'black',
        }}
        onConfirm={() => {
          setErrorDialog(false);
        }}
        onCancel={() => {
          setErrorDialog(false);
        }}
        width="md"
        cancelButtonText=""
        titleBg="#E2F3FE"
        title={
          <Typography variant="h5" align="left" fontWeight="bold">
            FLIGHT NOT FOUND!
          </Typography>
        }
      >
        <>
          <Typography variant="h6">{errorMessage}</Typography>
        </>
      </CustomDialog>
    </>
  );
};
