import React, { useContext, useEffect, useState } from 'react';

import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
// @ts-ignore
import background from './airplanebg.png';
import { change } from 'redux-form';
import SearchOrderForm, { ISearchOrderFormData } from '../../components/forms/SearchOrderForm';
import { useMidoco } from '../../hooks/use-midoco';
import { IMidocoSearchOrderCriteria } from '@tff/types/Midoco/model';
import SearchOrderResultPage from '../../components/orders/SearchOrderResultPage';
import { RetrieveDbServiceProps, useRetrieveDbService } from '../../hooks/use-retrieve-db-service';
import { getSourceConfig, ISourceConfig, Message } from '../../models';
import { TypeOfTFFBookingSource } from '@tff/types/TFF';
import { RetrieveBookingProps, useRetrieveBooking } from '../../hooks/use-retrieve-booking';
import FlightDetails from '../../components/FlightDetails';
import { useDispatch } from 'react-redux';
import { keycloak } from '../../keycloak';
import { useMessages } from '../../hooks/use-messages';
import { IntlMessages } from '../../util';
import { toast } from 'react-toastify';
import { LoadingContext } from '../../contexts/LoadingContextProvider';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import BookingDetailsTable from '../../components/BookingDetailHeader/BookingDetailsTable';
import formatDateTime from '../../util/formatDateTime';
import TFFDialog from '../../styled-components/TFFDialog';
import TFFAlert from '../../styled-components/TFFAlert';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    searchForm: {
      backgroundColor: 'rgba(226, 243, 254, 0.75)',
      width: '600px',
      margin: 'auto',
      [theme.breakpoints.up('lg')]: {
        width: '836px',
      },
    },
    root: {
      padding: '5%',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
    },
  }),
);

export const OrderSearchPage: React.FC = () => {
  const classes = useStyle();
  const navigate = useNavigate();
  const params = useParams();

  const [configToUse, setConfigToUse] = useState<ISourceConfig | undefined>(undefined);
  const [recordLocator, setRecordLocator] = useState<string | undefined>(undefined);
  const [retrieveDbServiceProps, setRetrieveDbServiceProps] = useState<RetrieveDbServiceProps | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [midocoOrderNo, setMidocoOrderNo] = useState<number | undefined>(undefined);
  const [retrieveBookingProps, setRetrieveBookingProps] = useState<RetrieveBookingProps | undefined>(undefined);
  const [showList, setShowList] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<string | undefined>(undefined);
  const [hasPermission, setHasPermission] = useState<boolean>(true);
  const [midocoSearchOrderCriteria, setMidocoSearchOrderCriteria] = useState<IMidocoSearchOrderCriteria | undefined>(
    undefined,
  );
  const [openOrderDetailsDialog, setOpenOrderDetailsDialog] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { addMessage } = useMessages();
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const intl = useIntl();

  // Retrieved details der Buchung und in ist REQUIRED
  const { resetRetrieveDbState, fetchedRetrieveDbItem, fetchedRetrieveDbStatus, fetchedRetrieveDbError } =
    useRetrieveDbService(retrieveDbServiceProps);

  const {
    resetMidocoState,
    midocoFetchedOrder,
    midocoFetchedOrderList,
    midocoError,
    midocoWarning,
    midocoStatus,
    midocoLoading,
  } = useMidoco({
    midocoSearchOrderCriteria,
    orderNo: midocoOrderNo,
  });

  // Retrieve the booking from source
  const {
    status: retrieveBookingStatus,
    fetchedBooking: retrieveBookingResult,
    error: retrieveBookingError,
    resetRetrieveBookingState,
    retrieveloading,
  } = useRetrieveBooking(retrieveBookingProps);

  const paymentMethod = React.useMemo(() => {
    if (fetchedRetrieveDbItem?.flightDetailsBeforeCancellation?.OrderDetails?.Payments) {
      const payment = fetchedRetrieveDbItem?.flightDetailsBeforeCancellation.OrderDetails.Payments[0];
      return payment?.Method;
    }
  }, [fetchedRetrieveDbItem]);

  /**
   * "retrieve_not_found": "Fehler beim suchen der Buchung in der Retrieve DB.",
   *   "midoco_not_found": "Order in Midoco nicht gefunden",
   *   "booking_not_found": "Die gewünschte Buchung wurde nicht gefunden",
   */
  useEffect(() => {
    console.log('retrieveBookingError', retrieveBookingError);

    if (typeof retrieveBookingError === 'string') {
      if (retrieveBookingError.startsWith('PERMISSION_ERROR')) {
        setError(retrieveBookingError.split(':')[1]);
        setHasPermission(false);
      } else if (retrieveBookingError.includes(':')) {
        setError(retrieveBookingError.split(':')[1].replace(/\s/g, '_'));
      } else setError(retrieveBookingError);
    } else if (typeof retrieveBookingError === 'object') {
      if (Array.isArray(retrieveBookingError)) {
        setError(retrieveBookingError[0].StatusText ?? 'UNKNOWN ERROR');
      } else {
        setError(retrieveBookingError.StatusText);
      }
    }
    midocoError && setError('midoco_not_found');
    fetchedRetrieveDbError && setError('retrieve_not_found');
  }, [retrieveBookingError, midocoError, fetchedRetrieveDbError]);

  useEffect(() => {
    if (midocoFetchedOrderList && midocoFetchedOrderList.length > 0) {
      setShowList(true);
    } else {
      setShowList(false);
    }
  }, [midocoFetchedOrderList]);

  useEffect(() => {
    if (midocoWarning && !toast.isActive('midoco_warning')) {
      toast.warning(<IntlMessages id={`midoco.warning.multipleSalesOrders`} />, {
        toastId: 'midoco_warning',
        autoClose: false,
        draggable: true,
        progress: undefined,
      });
    }
  }, [midocoWarning]);

  useEffect(() => {
    if (fetchedRetrieveDbItem && fetchedRetrieveDbStatus === 'FETCHED' && !fetchedRetrieveDbError) {
      if (!configToUse) {
        let bookingSource = getSourceConfig(fetchedRetrieveDbItem?.bookingSource!);
        bookingSource && setConfigToUse(bookingSource);
      }

      if (fetchedRetrieveDbItem?.midocoOrderNo) {
        setMidocoOrderNo(+fetchedRetrieveDbItem.midocoOrderNo!);
      } else {
        setError('midoco_not_found');
      }
    }
  }, [fetchedRetrieveDbStatus, fetchedRetrieveDbStatus, fetchedRetrieveDbError]);

  useEffect(() => {
    midocoLoading || retrieveloading
      ? showLoading(intl.formatMessage({ id: 'pages.orderShowPage.loading.description' }))
      : closeLoading();
  }, [midocoLoading, retrieveloading]);

  /*
   * Retrieve a booking and if successful, show this
   */
  useEffect(() => {
    let recordLocatorToUse: string | undefined = recordLocator;
    if (midocoFetchedOrder && searchType === 'midocoID') {
      recordLocatorToUse = midocoFetchedOrder.OrderSummary?.RecordLocator;
      setRecordLocator(recordLocatorToUse);
      setRetrieveDbServiceProps({ recordLocator: recordLocatorToUse });
    }

    if (fetchedRetrieveDbItem && recordLocator) {
      recordLocatorToUse = fetchedRetrieveDbItem.recordLocator;
      if (fetchedRetrieveDbItem.recordLocator !== recordLocator) {
        setRecordLocator(recordLocatorToUse);
      }

      if (!hasPermission) {
        return;
      }
      const m = new Message(
        keycloak?.profile?.email as string,
        recordLocatorToUse,
        'CALLCENTER',

        'LOG_ENTRY',
        configToUse?.BookingSource!,
        'VIEW_DETAILS',
      );
      addMessage(m);
      setRetrieveBookingProps({ recordLocator: recordLocatorToUse, sourceConfig: configToUse });
    }
  }, [configToUse, recordLocator, fetchedRetrieveDbItem, midocoFetchedOrder]);

  useEffect(() => {
    setConfigToUse(undefined);
    setError(undefined);
    resetRetrieveDbState();
    resetMidocoState();
    resetRetrieveBookingState();
    setMidocoOrderNo(undefined);
    setRecordLocator(undefined);

    if (params?.recordLocator) {
      let [recordLocator, source] = params.recordLocator.split('@');
      if (recordLocator) setRecordLocator(recordLocator);
      if (source) {
        setSource(source);
      } else {
        dispatch(change('searchOrderForm', 'idType', 'bookingID'));
      }

      setRecordLocator(recordLocator);
      setRetrieveDbServiceProps({ recordLocator });
      dispatch(change('searchOrderForm', 'crsBookingId', recordLocator));
    }
  }, [params]);

  useEffect(() => {
    if (recordLocator && configToUse) {
      navigate(`/tff/order/${recordLocator}@${configToUse.SourceName}`);
    }
  }, [recordLocator, configToUse]);

  useEffect(() => {
    if (retrieveBookingStatus === 'FAILURE') handleDialog();
  }, [retrieveBookingStatus]);

  /**
   * resetting the previous values from list sets the given recordlocator and source
   * @param _recordLocator
   */
  const returnFromList = (_recordLocator?: string) => {
    setShowList(false);
    resetMidocoState();
    setMidocoSearchOrderCriteria(undefined);
    setRecordLocator(undefined);
    setConfigToUse(undefined);
    setError(undefined);
    resetRetrieveDbState();
    resetRetrieveBookingState();

    setTimeout(() => {
      if (_recordLocator) {
        let [recordLocator, source] = _recordLocator.split('@');
        navigate(`/tff/order/${recordLocator}@${source}`);
        dispatch(change('searchOrderForm', 'crsBookingId', recordLocator));
        setRecordLocator(recordLocator);
        if (source) {
          setSource(source);
        }
        setRetrieveDbServiceProps({ recordLocator });
      }
    }, 100);
  };

  const setSource = source => {
    switch (source) {
      case 'NSK':
      case 'X3':
        dispatch(change('searchOrderForm', 'idType', 'NSK'));
        setConfigToUse(getSourceConfig('TUI-NSK'));
        break;
      case 'AF':
      case 'IATA':
        dispatch(change('searchOrderForm', 'idType', 'AF'));
        setConfigToUse(getSourceConfig('TUI-NDC-AF')!);
        break;
      case 'KL':
        dispatch(change('searchOrderForm', 'idType', 'KL'));
        setConfigToUse(getSourceConfig('TUI-NDC-KL')!);
        break;
      case 'EK':
        dispatch(change('searchOrderForm', 'idType', 'EK'));
        setConfigToUse(getSourceConfig('TUI-NDC-EK')!);
        break;
      case 'SQ':
        dispatch(change('searchOrderForm', 'idType', 'SQ'));
        setConfigToUse(getSourceConfig('TUI-NDC-SQ')!);
        break;
      case 'NDCX':
        dispatch(change('searchOrderForm', 'idType', 'NDCX'));
        setConfigToUse(getSourceConfig('TUI-NDCX')!);
        break;
      case 'AMADEUS':
        dispatch(change('searchOrderForm', 'idType', 'AMADEUS'));
        setConfigToUse(getSourceConfig('TUI-AMADEUS')!);
        break;
    }
  };

  /**
   * Handles the search request based on entered details
   * @param formData
   */
  const handleSearch = (formData: ISearchOrderFormData) => {
    let bookingSource: TypeOfTFFBookingSource | undefined;
    setHasPermission(true);
    setConfigToUse(undefined);
    resetMidocoState();
    resetRetrieveDbState();
    resetRetrieveBookingState();
    setMidocoOrderNo(undefined);
    setError(undefined);
    setSearchType(formData.idType);

    let recordLocator = formData.crsBookingId?.toUpperCase().replace(/\s/g, '');
    if (recordLocator?.indexOf('/') === 3) {
      let sourceAlias;
      [sourceAlias, recordLocator] = recordLocator.split('/');
      switch (sourceAlias) {
        case '565': {
          bookingSource = 'TUI-NSK';
          break;
        }
        case '781': {
          bookingSource = 'TUI-NDC-AF';
          break;
        }
        case '783': {
          bookingSource = 'TUI-NDC-SQ';
          break;
        }
        case '784': {
          bookingSource = 'TUI-NDC-EK';
          break;
        }
        case '105': {
          bookingSource = 'TUI-NDCX';
          break;
        }
        case 'XXX': {
          bookingSource = 'TUI-AMADEUS';
          break;
        }
      }
    }

    setRecordLocator(recordLocator);

    if (formData.surname) {
      setMidocoSearchOrderCriteria(formData as IMidocoSearchOrderCriteria);
      return;
    }

    switch (formData.idType) {
      case 'bookingID':
        // we have only a recordLocator
        setRetrieveDbServiceProps({ recordLocator });
        break;
      case 'tfmPnr':
        // we have only a recordLocator
        setRetrieveDbServiceProps({ tfmPnr: recordLocator });
        break;
      case 'clientPnr':
        // we have only a recordLocator
        setRetrieveDbServiceProps({ clientPnr: recordLocator });
        break;
      case 'NSK':
        bookingSource = 'TUI-NSK';
        break;
      case 'AF':
        bookingSource = 'TUI-NDC-AF';
        break;
      case 'KL':
        bookingSource = 'TUI-NDC-KL';
        break;
      case 'EK':
        bookingSource = 'TUI-NDC-EK';
        break;
      case 'SQ':
        bookingSource = 'TUI-NDC-SQ';
        break;
      case 'NDCX':
        bookingSource = 'TUI-NDCX';
        break;
      case 'AMADEUS':
        bookingSource = 'TUI-AMADEUS';
        break;
      case 'midocoID':
        setMidocoOrderNo(+formData.crsBookingId!);
        break;
    }

    if (bookingSource) {
      const sourceConfig: ISourceConfig = getSourceConfig(bookingSource)!;
      setConfigToUse(sourceConfig);
      setRetrieveDbServiceProps({ recordLocator, sourceConfig });
    }
  };

  const handleDialog = (): void => {
    setOpenOrderDetailsDialog(prev => !prev);
  };

  const renderFlightDetails = () => {
    if (
      hasPermission &&
      retrieveBookingResult &&
      Object.keys(retrieveBookingResult).length > 0 &&
      configToUse &&
      retrieveBookingStatus === 'FETCHED'
    ) {
      return (
        <FlightDetails
          sourceConfig={configToUse}
          flightDetails={retrieveBookingResult}
          retrieveItem={fetchedRetrieveDbItem}
          midocoFetchedOrder={midocoStatus === 'MIDOCO_ORDER_FETCHED' ? midocoFetchedOrder : retrieveBookingResult}
          midocoOrderNo={midocoOrderNo!}
        />
      );
    } else {
      const bookingSource = fetchedRetrieveDbItem?.bookingSource === 'NSK' ? 'TUI-NSK' : 'TUI-NDC';
      if (!midocoFetchedOrderList) {
        const details = {
          'TFM-Record-Locator': {
            value: fetchedRetrieveDbItem?.tfmPnr,
          },
          Product: {
            value: fetchedRetrieveDbItem?.product,
            label: intl.formatMessage({ id: 'pages.flightDetails.bookingDetails.product' }),
          },
          'Midoco-ID': {
            value: midocoOrderNo,
          },
          'Channel-Details': {
            value: fetchedRetrieveDbItem?.channel, //channel-details (SkyScanner, L'Tur)
          },
          'Cancellation-Date': {
            label: intl.formatMessage({ id: 'pages.flightDetails.bookingDetails.cancelDate' }),
            value: formatDateTime(fetchedRetrieveDbItem?.cancellationDate),
            display:
              fetchedRetrieveDbItem &&
              fetchedRetrieveDbItem.status === 'CANCELLED' &&
              fetchedRetrieveDbItem.cancellationDate,
          },
          'Source-Info': {
            value: [
              ['AgencyId', fetchedRetrieveDbItem?.sourceInfo?.agent],
              ['IataNumber', fetchedRetrieveDbItem?.sourceInfo?.iataNumber],
              ['OfficeId', fetchedRetrieveDbItem?.sourceInfo?.pseudoCityCode],
              ['System', bookingSource],
            ],
          },
          'Ticket Time Limit': {
            label: intl.formatMessage({ id: 'pages.flightDetails.bookingDetails.ticketTimeLimit' }),
            value: [
              ['Retrieve Changed', formatDateTime(fetchedRetrieveDbItem?.ticketTimeLimit)],
              ['Retrieve Original', formatDateTime(fetchedRetrieveDbItem?.ticketTimeLimitAirline)],
              ['Airline', formatDateTime(fetchedRetrieveDbItem?.ticketTimeLimitAirline)],
            ],
            display: !configToUse?.SourceGroup.includes('NSK'),
          },
          'Ticketed Date': {
            value: formatDateTime(fetchedRetrieveDbItem?.ticketedAt),
            label: intl.formatMessage({ id: 'pages.flightDetails.bookingDetails.ticketedDate' }),
          },
          'Payment Status': {
            value: fetchedRetrieveDbItem?.flightDetailsBeforeCancellation?.OrderSummary?.PaymentStatus,
            label: intl.formatMessage({ id: 'pages.flightDetails.bookingDetails.PaymentStatus' }),
          },
          'Payment Method': {
            value: paymentMethod?.includes(':') ? paymentMethod?.split(':')[0] : paymentMethod,
            label: intl.formatMessage({
              id: 'bookingDetailCard.paymentMethod',
            }),
          },
        };
        return (
          <div className={classes.root}>
            {error && (
              <TFFAlert
                variant="error"
                text={`${intl.formatMessage({ id: `pages.orderSearchPage.error.${error}` })}`}
                link="Open Details"
                handleLink={handleDialog}
              />
            )}
            <div className={classes.searchForm}>
              <SearchOrderForm onSubmit={handleSearch} />
            </div>
            <TFFDialog
              dialogId="tff-search-order-dialog"
              header={
                <Typography variant="h5" style={{ fontWeight: 700 }} align="left">
                  {intl.formatMessage({ id: 'bookingDetailCard.bookingDetails' })}:{' '}
                  {retrieveBookingResult?.OrderSummary?.RecordLocator}
                </Typography>
              }
              onCancel={handleDialog}
              closeButton
              isOpen={openOrderDetailsDialog}
            >
              <BookingDetailsTable
                dbRetrieveItem={fetchedRetrieveDbItem}
                details={details}
                flightDetails={retrieveBookingResult}
                orderDetails={retrieveBookingResult?.OrderDetails}
                key={fetchedRetrieveDbItem?.orderId}
              />
            </TFFDialog>
          </div>
        );
      } else if (midocoFetchedOrderList && showList) {
        return (
          <div className={classes.root}>
            <SearchOrderResultPage midocoFetchedOrderList={midocoFetchedOrderList} returnFromList={returnFromList} />
          </div>
        );
      } else {
        return <div className={classes.root}>SOMETHING WENT WRONG</div>;
      }
    }
  };

  return renderFlightDetails();
};

export default OrderSearchPage;
