import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';

interface ButtonProps {
  color: 'primary' | 'secondary' | 'accent' | 'danger' | 'info' | 'warning' | 'success';
  variant: 'outlined' | 'contained';
  children?: React.ReactNode;
  size: 'small' | 'medium' | 'large';
  onClick?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  hidden?: boolean;
  type?: string;
}

const getHeight = (size: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return 37;
    case 'medium':
      return 60;
    case 'large':
      return 60;
    default:
      return 60;
  }
};

const getFontSize = (size: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return 14;
    case 'medium':
      return 16;
    case 'large':
      return 18;
    default:
      return 16;
  }
};

const getWidth = (size: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return 140;
    case 'medium':
      return 175;
    case 'large':
      return 286;
    default:
      return 140;
  }
};

const getColor = (color: 'primary' | 'secondary' | 'accent' | 'danger' | 'info' | 'warning' | 'success') => {
  switch (color) {
    case 'primary':
      return '#092A5E';
    case 'secondary':
      return '#70CBF4';
    case 'accent':
      return '#E2F3FE';
    case 'danger':
      return '#D40E14';
    case 'info':
      return '#DCDCDC';
    case 'warning':
      return '#F2E30F';
    case 'success':
      return '#40AF3C';
    default:
      return '#000000';
  }
};

function CustomButton(props: ButtonProps) {
  const { color, variant, size, children, disabled, ...other } = props;

  const useStyle = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        padding: '6px 12px',
        border: '2px solid',
        height: getHeight(size),
        [theme.breakpoints?.down('md')]: {
          width: 120,
          fontSize: 12,
        },
        [theme.breakpoints?.up('md')]: {
          width: 120,
          fontSize: 12,
        },
        [theme.breakpoints?.up('lg')]: {
          width: getWidth(size),
          fontSize: getFontSize(size),
        },
        lineHeight: 1.5,
        backgroundColor: disabled ? 'grey' : variant === 'contained' ? getColor(color) : 'transparent',
        color: disabled ? 'white' : variant === 'contained' ? '#FFFFFF' : getColor(color),
        borderColor: disabled ? 'none' : getColor(color),
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
          backgroundColor: disabled ? 'grey' : variant === 'contained' ? getColor(color) : 'transparent',
          borderColor: disabled ? 'white' : getColor(color),
        },
        '&:active': {
          backgroundColor: '#0062cc',
          borderColor: '#005cbf',
        },
        '&:focus': {
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
      },
    }),
  );

  const classes = useStyle();

  return (
    // @ts-ignore
    <Button className={classes.button} {...other} disabled={disabled}>
      {children}
    </Button>
  );
}

export default CustomButton;
