import { doLogin, keycloak } from '../keycloak';
import Error403 from '../routes/common-routes/403';
import { Outlet } from 'react-router-dom';

export const PrivateRoute = () => {
  const roles = ['tff-access'];
  if (!keycloak.authenticated) {
    console.warn('not authenticated');
    doLogin();
    return <></>;
  }

  const isAuthorized = roles => {
    if (keycloak && roles) {
      return roles.some(r => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };

  return isAuthorized(roles) ? <Outlet /> : <Error403 />;
};
