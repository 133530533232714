import { TFF } from '@tff/types';
import { useTypedSelector } from '../../../reducers';
import { weekday } from '../../FlightDetailCard';
import format from 'date-fns/format';

interface Rows {
  label: string;
  originalPrice: number;
  newPrice: number;
  surcharge: number;
  original: string;
  new: string;
  rebooking: boolean;
}

interface originalRows {
  originalOnd: string;
  segment: TFF.Segment;
}

interface offerRows {
  offerOnd: string;
  offerSegment: TFF.Segment;
}

const formatDate = (t: string): string | null => {
  if (t) {
    return format(new Date(t), 'dd.MM.yyyy');
  }

  return null;
};

const CreateRebookRows = (
  newPrice: string,
  surcharge: string,
  originalRow: originalRows,
  offerRows: offerRows,
): Rows => {
  const { locale } = useTypedSelector(({ settings }) => settings);
  return {
    label: 'FLIGHT',
    originalPrice: 0,
    newPrice: parseInt(newPrice),
    surcharge: parseInt(surcharge),
    original:
      `${originalRow.originalOnd}/` +
      `${weekday(originalRow.segment.DepartureTime, locale.locale).substring(0, 2)}. ${formatDate(
        originalRow.segment.DepartureTime,
      )} - ${new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(
        new Date(originalRow.segment.DepartureTime),
      )} - ${new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(
        new Date(originalRow.segment.ArrivalTime),
      )}`,

    new:
      offerRows.offerSegment &&
      `${offerRows.offerOnd}/` +
        `${weekday(offerRows.offerSegment.DepartureTime, locale.locale).substring(0, 2)}. ${formatDate(
          offerRows.offerSegment.DepartureTime,
        )} - ${new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(
          new Date(offerRows.offerSegment.DepartureTime),
        )} - ${new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(
          new Date(offerRows.offerSegment.ArrivalTime),
        )}`,

    rebooking: true,
  };
};

export default CreateRebookRows;
