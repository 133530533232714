import { Box, Typography } from '@mui/material';
import React from 'react';
import { TFF } from '@tff/types';
import { useIntl } from 'react-intl';

interface props {
  ancillary: TFF.Ancillary;
}

const AncillaryRow: React.FC<props> = ({ ancillary }) => {
  const intl = useIntl();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 0,
      }}
    >
      <Typography
        sx={{
          color: ancillary.Note === 'ATCOM' ? 'black' : 'gray',
        }}
      >
        {ancillary.TfaDetails !== undefined
          ? intl.locale === 'en-US'
            ? ancillary.TfaDetails?.DescriptionEN
            : ancillary.TfaDetails?.DescriptionDE
          : ancillary.Description}
      </Typography>
      <Typography>{ancillary.TotalAmount!.toFixed(2)}€</Typography>
    </Box>
  );
};

export default AncillaryRow;
