import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  AccordionActions,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import FlightColumn from './FlightColumn';
import { TFF } from '@tff/types';
import { useIntl } from 'react-intl';
import { BaggageIcon } from '../icons';

interface props {
  flightDetails: TFF.FlightDetails;
  handleEdit: () => void;
}

const AncillariesCard: React.FC<props> = ({ flightDetails, handleEdit }) => {
  const intl = useIntl();
  const outboundFlight = flightDetails.OrderDetails.Journeys[0]?.Ond! || '';
  const returnFlight = flightDetails.OrderDetails.Journeys[1]?.Ond! || '';
  const passengers = flightDetails.OrderDetails.Passengers;
  const ancillaries: TFF.Ancillaries = flightDetails.OrderDetails.Ancillaries!;
  const isRoundTrip = flightDetails.OrderDetails.Journeys.length > 1;
  return (
    <Accordion
      sx={{
        width: '100%',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: '5px',
        margin: '10px',
        position: 'relative',
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <BaggageIcon />
            <Typography
              variant="h6"
              color="primary"
              sx={{ marginLeft: '10px', display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}
            >
              <strong>{intl.formatMessage({ id: 'ancillaries.title' })}</strong>
            </Typography>
          </Box>

          <Typography
            variant="h6"
            color="primary"
            sx={{ marginRight: '24px', display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}
          >
            <strong>{flightDetails.OrderSummary?.TotalAncillaryPrice!.toFixed(2)} €</strong>
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: '#E2F3FE' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'white',
            borderRadius: '4px',
            minHeight: '91px',
            width: '100%',
            padding: '30px',
            gap: '120px',
          }}
        >
          {outboundFlight! && (
            <FlightColumn
              passengers={passengers}
              flightOnd={outboundFlight}
              isOutbound={true}
              ancillaries={ancillaries}
            />
          )}
          {isRoundTrip && (
            <FlightColumn
              passengers={passengers}
              flightOnd={returnFlight}
              isOutbound={false}
              ancillaries={ancillaries}
            />
          )}
        </Box>
      </AccordionDetails>
      <AccordionActions>
        <Button color="primary" variant="contained" sx={{ height: '40px' }} onClick={handleEdit}>
          {intl.formatMessage({ id: 'ancillaries.edit' })}
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

export default AncillariesCard;
