import { Button, MenuItem, Typography, Checkbox, FormControlLabel, Switch, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomButton from '../../components/Button';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { renderInput } from '../../components/forms/Fields';
import _difference from 'lodash/difference';
import { useTypedSelector } from '../../reducers';
import { getUser, resetUser, TFF_ACCESS_ROLE, updateUserRole } from '../../reducers/Users/usersSlice';

export type Role = {
  id: string;
  name: string;
  description: string;
};

interface IProduct {
  selected: boolean;
  disabled: boolean;
  name: string;
  value: string;
  belongsTo: string[];
}

interface IMarket {
  selected: boolean;
  name: string;
  value: string;
}

interface ISource {
  selected: boolean;
  name: string;
  value: string;
}

interface ISystem {
  selected: boolean;
  name: string;
  value: string;
}

interface IProps {
  onUserFormChange: (userUpdated: boolean) => void;
  mainRoles: Role[];
  userId?: string;
}

const defaultMarkets: IMarket[] = [
  {
    selected: false,
    name: 'Austria',
    value: 'M_AT',
  },
  {
    selected: false,
    name: 'Netherlands',
    value: 'M_NL',
  },
  {
    selected: false,
    name: 'Belgium',
    value: 'M_BE',
  },
  {
    selected: false,
    name: 'Poland',
    value: 'M_PL',
  },
  {
    selected: false,
    name: 'Germany',
    value: 'M_DE',
  },
  {
    selected: false,
    name: 'Switzerland',
    value: 'M_CH',
  },
];

const defaultProducts: IProduct[] = [
  {
    selected: false,
    disabled: true,
    name: 'tui.com/flug',
    value: 'P_tui.com/flug',
    belongsTo: ['M_AT', 'M_DE', 'M_CH'],
  },
  { selected: false, disabled: true, name: 'Fly and Mix', value: 'P_FlyandMix', belongsTo: ['M_AT', 'M_DE'] },
  { selected: false, disabled: true, name: 'X-TUI', value: 'P_X-TUI', belongsTo: ['M_AT', 'M_DE'] },
  {
    selected: false,
    disabled: true,
    name: 'TUI Ticket Shop',
    value: 'P_TuiTicketShop',
    belongsTo: ['M_AT', 'M_DE', 'M_CH', 'M_PL', 'M_NL', 'M_BE'],
  },
  {
    selected: false,
    disabled: true,
    name: 'TRIPS Flight Only',
    value: 'P_TripsFlightOnly',
    belongsTo: ['M_NL'],
  },
  {
    selected: false,
    disabled: true,
    name: 'TRIPS Package',
    value: 'P_TripsPackage',
    belongsTo: ['M_DE'],
  },
  { selected: false, disabled: true, name: 'Sparflug', value: 'P_Sparflug', belongsTo: ['M_DE'] },
];

const defaultSources: ISource[] = [
  {
    selected: false,
    name: 'NSK',
    value: 'S_NSK',
  },
  {
    selected: false,
    name: 'NDC',
    value: 'S_NDC',
  },
];

const defaultSystems: ISystem[] = [
  {
    selected: false,
    name: 'Dev',
    value: 'SY_DEV',
  },
  {
    selected: false,
    name: 'Qa',
    value: 'SY_QA',
  },
  {
    selected: false,
    name: 'Prod',
    value: 'SY_PROD',
  },
];

const UserForm: React.FC<IProps & InjectedFormProps<{}, IProps, string>> = ({
  change,
  handleSubmit,
  initialize,
  onUserFormChange,
  mainRoles,
  userId,
}) => {
  const { roles } = useTypedSelector(({ rolesData }) => rolesData);
  const { userForm } = useTypedSelector(({ form }) => form);
  const { user } = useTypedSelector(({ usersData }) => usersData);

  const formValues = userForm?.values;

  const [userRoles, setUserRoles] = useState<Role[]>();
  const [userMainRole, setUserMainRole] = useState<Role>();
  const [addedMarketRoles, setAddedMarketRoles] = useState<Role[]>([]);
  const [addedProductRoles, setAddedProductRoles] = useState<Role[]>([]);
  const [removedMarketRoles, setRemovedMarketRoles] = useState<Role[]>([]);
  const [removedProductRoles, setRemovedProductRoles] = useState<Role[]>([]);
  const [addedSourcesRoles, setAddedSourcesRoles] = useState<Role[]>([]);
  const [removedSourcesRoles, setRemovedSourcesRoles] = useState<Role[]>([]);
  const [addedSystemRoles, setAddedSystemRoles] = useState<Role[]>([]);
  const [removedSystemRoles, setRemovedSystemRoles] = useState<Role[]>([]);
  const [clientAccess, setClientAccess] = useState<boolean>(false);
  const [userSelectedMainRole, setUserSelectedMainRole] = useState<string>('');

  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(getUser(userId));
    }
  }, [userId]);

  useEffect(() => {
    if (user) {
      initialize(user);
    }
  }, [user]);

  const [productsList, setProductsList] = useState<IProduct[]>(defaultProducts);

  const [marketsState, setMarketsState] = useState<IMarket[]>(defaultMarkets);

  const [sourcesList, setSourcesList] = useState<ISource[]>(defaultSources);

  const [systemsList, setSystemLists] = useState<ISystem[]>(defaultSystems);

  useEffect(() => {
    //update products when market changes
    marketsState.forEach(market => {
      if (market.selected) {
        const newProductsState = productsList.map(item => {
          return item.belongsTo.includes(market.value) ? { ...item, disabled: false } : item;
        });
        setProductsList(newProductsState);
      }
    });

    const changedMarkets = marketsState.filter(market => market.selected).map(market => market.value);

    const marketRoles = roles.filter((role: Role) => role.name.startsWith('M_'));

    const userMarketRoles = formValues?.roles
      ?.filter((role: Role) => role.name.startsWith('M_'))
      ?.map((role: Role) => role.name);

    const rolesToAddDiff = _difference(changedMarkets, userMarketRoles);
    const rolesToRemoveDiff = _difference(userMarketRoles, changedMarkets);
    const rolesToAdd: Role[] = [];
    marketRoles.forEach(role => {
      rolesToAddDiff.forEach(diff => {
        if (role.name === diff) {
          rolesToAdd.push(role);
        }
      });
    });
    const rolesToRemove: Role[] = [];
    marketRoles.forEach(role => {
      rolesToRemoveDiff.forEach(diff => {
        if (role.name === diff) {
          rolesToRemove.push(role);
        }
      });
    });
    setAddedMarketRoles(rolesToAdd);
    setRemovedMarketRoles(rolesToRemove);
  }, [marketsState]);

  useEffect(() => {
    const changedProducts = productsList.filter(product => product.selected).map(product => product.value);
    const productRoles = roles.filter((role: Role) => role.name.startsWith('P_'));

    const userProductRoles = formValues?.roles
      ?.filter((role: Role) => role.name.startsWith('P_'))
      ?.map((role: Role) => role.name);

    const rolesToAddDiff = _difference(changedProducts, userProductRoles);
    const rolesToRemoveDiff = _difference(userProductRoles, changedProducts);
    const rolesToAdd: Role[] = [];
    productRoles.forEach(role => {
      rolesToAddDiff.forEach(diff => {
        if (role.name === diff) {
          rolesToAdd.push(role);
        }
      });
    });
    const rolesToRemove: Role[] = [];
    productRoles.forEach(role => {
      rolesToRemoveDiff.forEach(diff => {
        if (role.name === diff) {
          rolesToRemove.push(role);
        }
      });
    });
    setAddedProductRoles(rolesToAdd);
    setRemovedProductRoles(rolesToRemove);
  }, [productsList]);

  useEffect(() => {
    const changedSources = sourcesList.filter(source => source.selected).map(source => source.value);
    const sourcesRoles = roles.filter((role: Role) => role.name.startsWith('S_'));

    const userSourcesRoles = formValues?.roles
      ?.filter((role: Role) => role.name.startsWith('S_'))
      ?.map((role: Role) => role.name);

    const rolesToAddDiff = _difference(changedSources, userSourcesRoles);
    const rolesToRemoveDiff = _difference(userSourcesRoles, changedSources);
    const rolesToAdd: Role[] = [];
    sourcesRoles.forEach(role => {
      rolesToAddDiff.forEach(diff => {
        if (role.name === diff) {
          rolesToAdd.push(role);
        }
      });
    });
    const rolesToRemove: Role[] = [];
    sourcesRoles.forEach(role => {
      rolesToRemoveDiff.forEach(diff => {
        if (role.name === diff) {
          rolesToRemove.push(role);
        }
      });
    });
    setAddedSourcesRoles(rolesToAdd);
    setRemovedSourcesRoles(rolesToRemove);
  }, [sourcesList]);

  useEffect(() => {
    const changedSystems = systemsList.filter(source => source.selected).map(source => source.value);
    const systemRoles = roles.filter((role: Role) => role.name.startsWith('SY_'));

    const userSystemRoles = formValues?.roles
      ?.filter((role: Role) => role.name.startsWith('SY_'))
      ?.map((role: Role) => role.name);

    const rolesToAddDiff = _difference(changedSystems, userSystemRoles);
    const rolesToRemoveDiff = _difference(userSystemRoles, changedSystems);
    const rolesToAdd: Role[] = [];
    systemRoles.forEach(role => {
      rolesToAddDiff.forEach(diff => {
        if (role.name === diff) {
          rolesToAdd.push(role);
        }
      });
    });
    const rolesToRemove: Role[] = [];
    systemRoles.forEach(role => {
      rolesToRemoveDiff.forEach(diff => {
        if (role.name === diff) {
          rolesToRemove.push(role);
        }
      });
    });
    setAddedSystemRoles(rolesToAdd);
    setRemovedSystemRoles(rolesToRemove);
  }, [systemsList]);

  useEffect(() => {
    const rolesToAdd = addedMarketRoles.concat(...addedProductRoles);
    const rolesToAddWithSources = rolesToAdd.concat(...addedSourcesRoles);
    const rolesToAddWithSystems = rolesToAddWithSources.concat(...addedSystemRoles);

    if (userMainRole) {
      const newRoles = [...rolesToAddWithSystems, userMainRole];
      change('rolesToAdd', newRoles);
    } else {
      change('rolesToAdd', rolesToAddWithSystems);
    }
  }, [addedMarketRoles, addedProductRoles, addedSourcesRoles, addedSystemRoles, change, userMainRole]);

  useEffect(() => {
    const rolesToRemove = removedMarketRoles.concat(...removedProductRoles);
    const rolesToRemoveWithSources = rolesToRemove.concat(...removedSourcesRoles);
    const rolesToRemoveWithSystems = rolesToRemoveWithSources.concat(...rolesToRemoveWithSources);
    if (userMainRole) {
      const userMainRoleToRemove = userRoles?.find(role => role.name.startsWith('Role_'));
      if (userMainRoleToRemove) {
        if (userMainRoleToRemove.name !== userMainRole.name) {
          const newRoles = [...rolesToRemoveWithSystems, userMainRoleToRemove];
          change('rolesToRemove', newRoles);
        }
      }
    } else {
      change('rolesToRemove', rolesToRemoveWithSystems);
    }
  }, [
    removedMarketRoles,
    removedProductRoles,
    removedSourcesRoles,
    removedSystemRoles,
    change,
    userMainRole,
    userRoles,
  ]);

  /*   useEffect(() => {
    if (formValues?.roles) {
      console.log('formvalues', formValues?.roles);
      setUserRoles(formValues?.roles);
      let newProductsState: IProduct[] = [];
      const finalMarketsState: IMarket[] = marketsState;
      const getMarkets = findRoles(formValues?.roles, 'M_');
      if (getMarkets) {
        getMarkets.forEach(market => {
          marketsState.forEach(item => {
            if (item.value === market.name) {
              const findIndex = finalMarketsState.findIndex(value => value.value === item.value);
              finalMarketsState[findIndex].selected = true;
            }
          });
          newProductsState = productsList.map(item => {
            return item.belongsTo.includes(market.name) ? { ...item, disabled: false } : item;
          });
        });
        setMarketsState(finalMarketsState);
      }

      const getProducts = findRoles(formValues?.roles, 'P_');
      if (getProducts) {
        const products = newProductsState.length > 0 ? newProductsState : productsList;
        const finalProductsState: IProduct[] = products;
        getProducts.forEach(product => {
          products.forEach(item => {
            if (item.value === product.name) {
              const findIndex = finalProductsState.findIndex(value => value.value === item.value);
              finalProductsState[findIndex].selected = true;
            }
          });
        });

        setProductsList(finalProductsState);
      }

      const getSources = findRoles(formValues?.roles, 'S_');
      if (getSources) {
        const finalSourcesState: ISource[] = sourcesList;
        getSources.forEach(source => {
          sourcesList.forEach(item => {
            if (item.value === source.name) {
              const findIndex = finalSourcesState.findIndex(value => value.value === item.value);
              finalSourcesState[findIndex].selected = true;
            }
          });
        });
        setSourcesList(finalSourcesState);
      }

      const getSystems = findRoles(formValues?.roles, 'SY_');
      if (getSystems) {
        const finalSystemState: ISystem[] = systemsList;
        getSystems.forEach(system => {
          systemsList.forEach(item => {
            if (item.value === system.name) {
              const findIndex = finalSystemState.findIndex(value => value.value === item.value);
              finalSystemState[findIndex].selected = true;
            }
          });
        });
        setSystemLists(finalSystemState);
      }
    }
  }, [formValues?.roles, formValues?.accessRole]);
 */

  useEffect(() => {
    if (!formValues?.roles) return;

    setUserRoles(formValues.roles);

    const updateSelectedState = (list, prefix) => {
      const finalState = [...list];
      const matchedRoles = findRoles(formValues.roles, prefix);
      if (matchedRoles) {
        matchedRoles.forEach(role => {
          const index = finalState.findIndex(item => item.value === role.name);
          if (index !== -1) {
            finalState[index] = { ...finalState[index], selected: true };
          }
        });
      }
      return finalState;
    };

    // Update markets state
    const updatedMarketsState = updateSelectedState(marketsState, 'M_');
    setMarketsState(updatedMarketsState);

    // Update products state
    const filteredProductsList = productsList.map(item => ({
      ...item,
      disabled: !item.belongsTo.some(belongsToName => formValues.roles.includes(`M_${belongsToName}`)),
    }));
    const updatedProductsState = updateSelectedState(filteredProductsList, 'P_');
    setProductsList(updatedProductsState);

    // Update sources state
    const updatedSourcesState = updateSelectedState(sourcesList, 'S_');
    setSourcesList(updatedSourcesState);

    // Update systems state
    const updatedSystemsState = updateSelectedState(systemsList, 'SY_');
    setSystemLists(updatedSystemsState);
  }, [formValues?.roles, formValues?.accessRole]);

  useEffect(() => {
    if (clientAccess) {
      change('accessRole', [TFF_ACCESS_ROLE.name]);
    } else {
      change('accessRole', {});
    }
  }, [clientAccess]);

  useEffect(() => {
    if (userRoles) {
      const mainRole = mainRoles.find(
        role => role.name === userRoles?.find(userRole => userRole?.name.startsWith('Role_'))?.name,
      );
      setUserSelectedMainRole(mainRole?.id ?? '');
    }
  }, [userRoles, change, mainRoles]);

  const useStyle = makeStyles((theme: Theme) =>
    createStyles({
      tableWrapper: {
        width: '80%',
        margin: '0 auto',
        marginTop: '40px',
        marginBottom: '40px',
      },
      DefaultLine: {
        '& td': { backgroundColor: 'white !important', border: 'none', maxHeight: '15px', padding: '0px 10px' },
      },
      LightBlueLine: {
        '& td': { backgroundColor: '#E2F3FE !important', border: 'none', maxHeight: '15px', padding: '0px 10px' },
      },
      addEmployeeWrapper: {
        width: '60%',
        margin: '0 auto',
        marginTop: '40px',
        marginBottom: '40px',
        backgroundColor: '#E2F3FE',
        border: '1px solid #092A5E',
        borderRadius: '5px',
        minHeight: '500px',
        padding: '30px 150px',
      },
      backIconWrapper: {
        height: '36px',
        width: '36px',
        borderRadius: '18px',
        border: '2px solid #092A5E',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      formControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
      },
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
    }),
  );

  const classes = useStyle();

  const findRoles = (roles: Role[], substring: string) => {
    return roles.filter(role => role.name.startsWith(substring));
  };

  const onSave = (values: any) => {
    if (values) {
      dispatch(updateUserRole(values));
      onUserFormChange(true);
      dispatch(resetUser());
    }
  };

  const onCancel = () => {
    onUserFormChange(false);
    dispatch(resetUser());
  };

  const renderMarkets = () => {
    return (
      <div>
        <Typography variant="h6" color="primary">
          <strong>{intl.formatMessage({ id: 'administration.myMarkets' })}</strong>
        </Typography>
        {marketsState.map((market: IMarket, index: number) => {
          const handleMarketsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newState = marketsState.map(item =>
              item.value === market.value ? { ...item, selected: event.target.checked } : item,
            );
            setMarketsState(newState);
            const newProductsState = productsList.map(item => {
              return item.belongsTo.includes(market.value) ? { ...item, disabled: !event.target.checked } : item;
            });
            setProductsList(newProductsState);
          };
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={market.selected}
                  onChange={handleMarketsChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name={market.name}
                />
              }
              label={market.name}
              key={index}
              style={{ color: '#092A5E', width: '160px' }}
            />
          );
        })}
      </div>
    );
  };

  const renderProducts = () => {
    return (
      <div>
        <Typography variant="h6" color="primary">
          <strong>{intl.formatMessage({ id: 'administration.myProducts' })}</strong>
        </Typography>
        {productsList.map((product: IProduct, index: number) => {
          const handleProductsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newState = productsList.map(item =>
              item.value === product.value ? { ...item, selected: event.target.checked } : item,
            );
            setProductsList(newState);
          };
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={product.selected}
                  onChange={handleProductsChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name={product.name}
                  disabled={product.disabled}
                />
              }
              label={product.name}
              key={index}
              style={{ color: '#092A5E', width: '160px' }}
            />
          );
        })}
      </div>
    );
  };

  const renderSources = () => {
    return (
      <div>
        <Typography variant="h6" color="primary">
          <strong>{intl.formatMessage({ id: 'administration.sources' })}</strong>
        </Typography>
        {sourcesList.map((source: ISource, index: number) => {
          const handleSourceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newState = sourcesList.map(item =>
              item.value === source.value ? { ...item, selected: event.target.checked } : item,
            );
            setSourcesList(newState);
          };
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={source.selected}
                  onChange={handleSourceChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name={source.name}
                />
              }
              label={source.name}
              key={index}
              style={{ color: '#092A5E', width: '160px' }}
            />
          );
        })}
      </div>
    );
  };

  const renderSystems = () => {
    return (
      <div>
        <Typography variant="h6" color="primary">
          <strong>{intl.formatMessage({ id: 'administration.systems' })}</strong>
        </Typography>
        {systemsList.map((system: ISystem, index: number) => {
          const handleSystemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newState = systemsList.map(item =>
              item.value === system.value ? { ...item, selected: event.target.checked } : item,
            );
            setSystemLists(newState);
          };
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={system.selected}
                  onChange={handleSystemChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name={system.name}
                />
              }
              label={system.name}
              key={index}
              style={{ color: '#092A5E', width: '160px' }}
            />
          );
        })}
      </div>
    );
  };

  const handleMainRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.value;
    const role = mainRoles.find(role => role.id === id);
    setUserSelectedMainRole(id);
    setUserMainRole(role);
  };

  const handleClientAccess = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClientAccess(event.target.checked);
  };

  const activeInactiveTitle = () => {
    return clientAccess
      ? intl.formatMessage({ id: 'administration.user_activated' })
      : intl.formatMessage({ id: 'administration.user_deactivated' });
  };

  const renderAddEmployee = () => {
    return (
      <Form className={classes.addEmployeeWrapper} onSubmit={handleSubmit(onSave)}>
        <div className="row">
          <div className="col-md-8">
            <Button
              className={classes.flexRow}
              style={{ textTransform: 'none', marginBottom: '20px' }}
              onClick={onCancel}
            >
              <div className={classes.backIconWrapper}>
                <ArrowBackIcon color="primary" />
              </div>
              <Typography color="primary" variant="h6" style={{ marginLeft: '10px' }}>
                {intl.formatMessage({ id: 'administration.editUser' })}
              </Typography>
            </Button>
          </div>
          <div className="col-md-4 d-flex justify-content-end align-items-start">
            <FormControlLabel
              control={<Switch checked={clientAccess} onChange={handleClientAccess} name="Active" />}
              label={activeInactiveTitle()}
            />
          </div>
        </div>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-md-6">
            <TextField
              label={`${intl.formatMessage({ id: 'administration.role' })}`}
              placeholder="Select a role"
              variant="filled"
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '0px',
                },
              }}
              fullWidth
              value={userSelectedMainRole}
              select
              onChange={handleMainRoleChange}
            >
              {mainRoles?.map((role, index) => (
                <MenuItem key={index} value={role.id}>
                  {role.description}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="col-md-6">
            <Field
              name="email"
              label={`${intl.formatMessage({ id: 'administration.email' })}`}
              variant="filled"
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '0px',
                  color: 'black',
                },
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled
              component={renderInput}
            />
          </div>
        </div>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-md-6">
            <Field
              name="firstName"
              label={`${intl.formatMessage({ id: 'administration.firstName' })}`}
              variant="filled"
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '0px',
                  color: 'black',
                },
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled
              component={renderInput}
            />
          </div>
          <div className="col-md-6">
            <Field
              name="lastName"
              label={`${intl.formatMessage({ id: 'administration.lastName' })}`}
              variant="filled"
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '0px',
                  color: 'black',
                },
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled
              component={renderInput}
            />
          </div>
        </div>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-md-4">{renderMarkets()}</div>
          <div className="col-md-4">{renderProducts()}</div>
          <div className="col-md-4">
            {renderSources()} {renderSystems()}
          </div>
        </div>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-md-6 d-flex align-items-center"></div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div style={{ marginRight: '5px' }}>
              <CustomButton variant="contained" color="primary" size="medium" onClick={onCancel}>
                {`${intl.formatMessage({ id: 'administration.cancel' })}`}
              </CustomButton>
            </div>
            <CustomButton variant="contained" color="danger" size="medium" type="submit">
              {`${intl.formatMessage({ id: 'administration.saveSettings' })}`}
            </CustomButton>
          </div>
        </div>
      </Form>
    );
  };

  return <div>{renderAddEmployee()}</div>;
};

export default reduxForm<{}, IProps>({
  form: 'userForm',
})(UserForm);
