import * as React from 'react';
import { useEffect, useContext } from 'react';
import { TFF } from '@tff/types';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { apigeeEndpoint } from '../apis';

export type TypeOfStatus = 'IN_PROGRESS' | 'CHECKED' | 'FAILURE';

export type CheckRefundProps = {
  checkRefundRequest?: TFF.CancelFlightRequest;
};

export type CheckRefundResponse = {
  checkRefundResponse?: TFF.CancelFlightResponse;
  status?: TypeOfStatus;
  error?: any;
  hasPayment?: boolean;
  isVoidable?: boolean;
};

//
export const useCheckCancelRefund = (checkRefundProps: CheckRefundProps): CheckRefundResponse => {
  const [status, setStatus] = React.useState<TypeOfStatus>();
  const [checkRefundResponse, setCheckRefundResponse] = React.useState<TFF.CancelFlightResponse>();
  const [error, setError] = React.useState<unknown | Error | undefined>();
  const { showLoading, closeLoading, isLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (!isLoading && status === 'IN_PROGRESS') {
      showLoading('Checking Refund...');
    }
  }, [status, isLoading]);

  useEffect(() => {
    const checkRefundForCancellationAtBackend = async (checkRefundRequest: TFF.CancelFlightRequest): Promise<void> => {
      try {
        setStatus('IN_PROGRESS');
        const response = await apigeeEndpoint.post(`/cancel/${checkRefundRequest.bookingSource}`, {
          ...checkRefundRequest,
          action: 'check-refund-status',
        });
        if (response.data.status === 'FAILURE') {
          setError(response.data.error);
          setStatus('FAILURE');
        }
        setStatus('CHECKED');
        setCheckRefundResponse(response.data.data ?? response.data);
      } catch (error) {
        setError(error);
        setStatus('FAILURE');
      } finally {
        // checkRefundProps?.setLoaderState(false);
        closeLoading();
      }
    };

    if (checkRefundProps && checkRefundProps.checkRefundRequest && !status) {
      void checkRefundForCancellationAtBackend(checkRefundProps.checkRefundRequest);
    }
  }, [checkRefundProps]);

  return { error, checkRefundResponse, status };
};
