import axios, { AxiosRequestConfig } from 'axios';
import { keycloak } from '../keycloak';

/**
 * Create an apigee accessToken
 */
const getToken = async (): Promise<string> => {
  const config: AxiosRequestConfig = {
    method: 'post',
    url: window.ENV?.TFF_APIGEE_OUAUTH_ENDPOINT,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${window.ENV?.TFF_APIGEE_BASIC_AUTH}`,
    },
    data: 'grant_type=client_credentials',
  };

  return axios(config)
    .then((response: any) => {
      return response.data.access_token;
    })
    .catch((error: any) => {
      throw error;
    });
};

export const apigeeEndpoint = axios.create({
  baseURL: window.ENV?.TFF_APIGEE_ENDPOINT,
});

apigeeEndpoint.interceptors.request.use(async config => {
  const token = await getToken().catch(e => {
    console.log('Error getting Token: ' + e.message);
  });

  config.headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
  };
  if (keycloak?.token) {
    config.headers['kc-token'] = keycloak.token;
  }

  return config;
});
