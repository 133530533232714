import { Box, Typography } from '@mui/material';
import React from 'react';
import AncillaryRow from './AncillaryRow';
import { TFF } from '@tff/types';
import { useIntl } from 'react-intl';

interface props {
  passenger: TFF.Passenger;
  passengerNr: number;
  ancillaries: TFF.Ancillaries;
  isOutbound: boolean;
}

const PassengerRow: React.FC<props> = ({ isOutbound, passengerNr, passenger, ancillaries }) => {
  const intl = useIntl();
  return (
    <Box>
      <Typography>
        <strong>
          {intl.formatMessage({ id: 'ancillaries.passenger' })} {passengerNr} - {passenger.FirstName}{' '}
          {passenger.LastName}
        </strong>
      </Typography>
      {passenger.JourneyExtras![isOutbound ? 0 : 1].Ssrs?.map((ssr, index) =>
        ancillaries[ssr].Description ? <AncillaryRow key={index} ancillary={ancillaries[ssr]} /> : null,
      )}
    </Box>
  );
};

export default PassengerRow;
