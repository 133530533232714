import { Typography, Theme, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { TFF } from '@tff/types';
import * as React from 'react';
import { useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { format } from 'date-fns';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import { BaggageIcon, SeatIcon } from '../icons';

interface IProps {
  selectedOnd: string; // COMPLETE or Ond(origin and destination)
  flight: TFF.OrderDetails;
  setSelectedState: React.Dispatch<React.SetStateAction<{ [k: string]: boolean } | undefined>>;
  selected: { [k: string]: boolean } | undefined;
}

export const calculate_age = (dob: Date): string | undefined => {
  if (dob.getTime() > Date.now()) {
    return undefined;
  }
  const diff_ms = Date.now() - dob.getTime();
  const age_dt = new Date(diff_ms);

  return String(Math.abs(age_dt.getUTCFullYear() - 1970));
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50px',
      backgroundColor: '#092A5E',
    },
    headerText: {
      fontWeight: 'bold',
      color: 'white',
    },
    contentRoot: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      marginTop: '20px',
    },
    amount: {
      float: 'right',
      paddingRight: '10px',
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
    passenger: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.palette.text.primary,
      marginTop: '20px',
      marginLeft: '-10px',
    },
    ssr: {
      marginLeft: '0px',
    },
    other: {
      padding: '5px',
    },
    seat: {
      backgroundColor: '#75D4FF',
      padding: '5px',
    },
    infant: {
      backgroundColor: 'white',
      padding: '5px',

      marginTop: '0px',
      marginLeft: '0px',
    },
    stops_0: {
      maxWidth: '100%',
    },
    stops_1: {
      maxWidth: '50%',
    },
    stops_2: {
      maxWidth: '33%',
    },
    content: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#B5E3FB',
      borderRadius: 0,
    },
    contentHeader: {
      fontWeight: 'bold',
    },
    accordionRoot: {
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px',
      backgroundColor: '#dcdcdc',
      margin: '5px',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkboxPlaceholder: {
      width: 24,
      height: 24,
      padding: 9,
      margin: 0,
    },
  }),
);

const BookingChangeSelection: React.FC<IProps> = ({ selectedOnd, flight, setSelectedState, selected }) => {
  const classes = useStyles();
  const ancillaries: TFF.Ancillaries = useMemo(() => {
    return flight.Ancillaries ?? {};
  }, [flight]);

  const passengers: { [id: string]: TFF.Passenger } = useMemo(() => {
    return flight.Passengers ?? {};
  }, [flight]);

  const segmentOndList: string[] | undefined = useMemo(() => {
    if (!flight.Journeys) {
      return;
    }
    const selectedJourney = flight.Journeys.find(j => j.Ond === selectedOnd);
    if (!selectedJourney) {
      return;
    }

    return selectedJourney.Segments?.map<string>(s => s.Ond as string);
  }, [flight, selectedOnd]);

  const Header = () => {
    return (
      <Paper elevation={2} className={classes.headerRoot}>
        <Typography variant="h6" className={classes.headerText}>
          {selectedOnd}
        </Typography>
      </Paper>
    );
  };

  const Subheader = () => {
    if (!segmentOndList) {
      return <></>;
    }
    return (
      <div className={classes.contentRoot}>
        {segmentOndList.map((segmentOnD, index) => {
          return (
            <Paper elevation={1} key={index} className={`${classes.content}`}>
              <Typography variant="h6" className={classes.contentHeader} color="primary">
                {segmentOnD}
              </Typography>
            </Paper>
          );
        })}
      </div>
    );
  };

  const createIdForPaxSelection = (paxId: string | undefined): string => {
    if (!paxId) return '';
    return `P@${paxId}@${selectedOnd}`;
  };

  const createIdForAncillarySelection = (id: string | undefined): string => {
    return `A@${id}`;
  };

  const handleCancelSelectionChange = (selections: { name: string; checked: boolean }[]) => {
    if (!selections) return;

    const _selected = { ...selected };

    selections.forEach(ssr => {
      _selected[ssr.name] = ssr.checked;
    });
    setSelectedState(_selected);
  };

  const itemIsSelected = (name: string): boolean => {
    if (!selected || !Object.keys(selected).length) return false;
    return selected[name];
  };

  const handleSelection = (event: React.ChangeEvent<HTMLInputElement>, ancillary: TFF.Ancillary) => {
    event.stopPropagation();

    const { name, checked } = event.target;
    const selections: { name: string; checked: boolean }[] = [{ name, checked }];

    if (!checked) {
      // remove selection of pax for this segment
      selections.push({ name: createIdForPaxSelection(ancillary.PaxID), checked: false });
    }
    handleCancelSelectionChange(selections);
  };

  const handleSelectedPassenger = (event: React.ChangeEvent<HTMLInputElement>, passenger: TFF.Passenger) => {
    event.stopPropagation();

    const { name, checked } = event.target;
    const listOfAncillaries: { name: string; checked: boolean }[] = [];
    listOfAncillaries.push({ name, checked });

    if (passenger.JourneyExtras) {
      const journeyExtras = passenger.JourneyExtras.find(e => e.JourneyOnd === selectedOnd);

      if (journeyExtras) {
        if (journeyExtras.Infant) {
          listOfAncillaries.push({ name: createIdForAncillarySelection(journeyExtras.Infant), checked });
        }
        if (journeyExtras.Seat) {
          listOfAncillaries.push({ name: createIdForAncillarySelection(journeyExtras.Seat), checked });
        }
        if (journeyExtras.Ssrs) {
          journeyExtras.Ssrs.forEach(ssr => {
            listOfAncillaries.push({ name: createIdForAncillarySelection(ssr), checked });
          });
        }
        if (journeyExtras.SegmentExtras && journeyExtras.SegmentExtras.length > 0) {
          journeyExtras.SegmentExtras.forEach(sr => {
            sr.Seat && listOfAncillaries.push({ name: createIdForAncillarySelection(journeyExtras.Seat!), checked });

            sr.Ssrs?.forEach(ssr => {
              listOfAncillaries.push({ name: createIdForAncillarySelection(ssr), checked });
            });
          });
        }
      }
    }

    handleCancelSelectionChange(listOfAncillaries);
  };

  const createSsrContent = (
    seatReservation: TFF.Ancillary | undefined,
    infantAncillary: TFF.Ancillary | undefined,
    infantDetails: TFF.Passenger | undefined,
    otherSsrsIds: string[] | undefined,
    additionalClasses: string,
    leftMargin = 0,
  ) => {
    const seatElement: JSX.Element = seatReservation ? (
      <div key={seatReservation.Id} className={` ${classes.seat}`}>
        <Typography variant="h6" className={classes.contentHeader} color="primary">
          {(seatReservation.TotalAmount as number) > 0 ? (
            <Checkbox
              color="primary"
              name={createIdForAncillarySelection(seatReservation.Id)}
              checked={itemIsSelected(createIdForAncillarySelection(seatReservation.Id))}
              onChange={event => handleSelection(event, seatReservation)}
              disabled={itemIsSelected(createIdForPaxSelection(seatReservation.PaxID))}
            />
          ) : (
            <span className={classes.checkboxPlaceholder}></span>
          )}
          <SeatIcon />
          <span style={{ marginLeft: '5px' }}>
            {seatReservation.Description} {seatReservation.Value}
          </span>
          <span className={classes.amount}>{seatReservation.TotalAmount} €</span>
        </Typography>
      </div>
    ) : (
      <>ss</>
    );

    const infantElement: JSX.Element = infantAncillary ? (
      <div key={infantAncillary.Id} className={`${classes.infant}`} style={{ backgroundColor: '#70CBF4' }}>
        <Typography variant="h6" className={classes.contentHeader} color="primary">
          {(infantAncillary.TotalAmount as number) > 0 ? (
            <Checkbox
              color="primary"
              name={createIdForAncillarySelection(infantAncillary.Id)}
              checked={itemIsSelected(createIdForAncillarySelection(infantAncillary.Id))}
              onChange={event => handleSelection(event, infantAncillary)}
              disabled={itemIsSelected(createIdForPaxSelection(infantAncillary.PaxID))}
            />
          ) : (
            <span className={classes.checkboxPlaceholder}></span>
          )}
          <ChildFriendlyIcon color="primary" />
          <span style={{ marginLeft: '5px' }}>
            {infantAncillary.Description} {infantDetails?.FirstName} {infantDetails?.LastName}
          </span>
          <span className={classes.amount}>{infantAncillary.TotalAmount} €</span>
        </Typography>
      </div>
    ) : (
      <>ii</>
    );

    const otherJourneyExtras: JSX.Element = (
      <>
        {otherSsrsIds?.map(k => {
          const ancilliary: TFF.Ancillary = ancillaries[k];
          const backgroundColor = ancilliary.Type.startsWith('BA') ? '#B5E3FB' : '#CBECFC';

          if (!ancilliary) {
            return <></>;
          }

          return (
            <div key={ancilliary.Id} className={`${classes.other}`} style={{ backgroundColor }}>
              <Typography variant="h6" className={classes.contentHeader} color="primary">
                {(ancilliary.TotalAmount as number) > 0 ? (
                  <Checkbox
                    color="primary"
                    name={createIdForAncillarySelection(ancilliary.Id)}
                    checked={itemIsSelected(createIdForAncillarySelection(ancilliary.Id))}
                    onChange={event => handleSelection(event, ancilliary)}
                    disabled={itemIsSelected(createIdForPaxSelection(ancilliary.PaxID))}
                  />
                ) : (
                  <span className={classes.checkboxPlaceholder}></span>
                )}
                {ancilliary.Type.startsWith('BA') ? <BaggageIcon /> : <FastfoodIcon color="primary" />}
                <span style={{ marginLeft: '5px' }}>
                  {ancilliary.Description} {ancilliary.Value}
                </span>
                <span className={classes.amount}>{ancilliary.TotalAmount} €</span>
              </Typography>
            </div>
          );
        })}
      </>
    );

    return (
      <div style={{ marginLeft: `${leftMargin}%` }} className={`${classes.ssr} ${additionalClasses}`}>
        {infantAncillary && infantElement}
        {seatReservation && seatElement}
        {otherSsrsIds && otherJourneyExtras}
      </div>
    );
  };

  const RenderPassenerJourneyExtras = (passenger: TFF.Passenger) => {
    const journeyExtras = passenger.JourneyExtras?.find(je => je.JourneyOnd === selectedOnd);
    if (!passenger || !journeyExtras) {
      return undefined;
    }

    const seatReservation: TFF.Ancillary | undefined = journeyExtras.Seat ? ancillaries[journeyExtras.Seat] : undefined;
    const infantAncillary: TFF.Ancillary | undefined =
      passenger.InfantId && journeyExtras.Infant ? ancillaries[journeyExtras.Infant] : undefined;
    const infantDetails: TFF.Passenger | undefined =
      passenger.InfantId && infantAncillary ? passengers[passenger.InfantId] : undefined;
    return createSsrContent(seatReservation, infantAncillary, infantDetails, journeyExtras.Ssrs, `${classes.stops_0}`);
  };

  const RenderPassengerJourneySegmentsExtras = (passenger: TFF.Passenger) => {
    const journeyExtras = passenger.JourneyExtras?.find(je => je.JourneyOnd === selectedOnd);
    if (!journeyExtras) {
      return <></>;
    }
    const segmentOndCounts = segmentOndList?.length ?? 1;

    const segmentContent: JSX.Element[] = [];

    const segmentSize = 100 / segmentOndCounts;

    const stopsName: keyof typeof classes = `stops_${segmentOndCounts - 1}` as keyof typeof classes;

    journeyExtras.SegmentExtras?.forEach((se, index) => {
      const seatReservation: TFF.Ancillary | undefined = se.Seat ? ancillaries[se.Seat] : undefined;
      const infantAncillary: TFF.Ancillary | undefined =
        passenger.InfantId && journeyExtras.Infant ? ancillaries[journeyExtras.Infant] : undefined;
      const infantDetails: TFF.Passenger | undefined =
        passenger.InfantId && infantAncillary ? passengers[passenger.InfantId] : undefined;
      const leftMarginValue = segmentSize * index;

      segmentContent.push(
        createSsrContent(
          seatReservation,
          infantAncillary,
          infantDetails,
          se.Ssrs,
          `${classes[stopsName]}`,
          leftMarginValue,
        ),
      );
    });

    return <>{segmentContent}</>;
  };

  const createDob = (dob: string | undefined): string | undefined => {
    if (!dob || new Date(dob) > new Date()) {
      return '';
    }
    const dobAsDate: Date = new Date(dob);
    return format(dobAsDate, 'dd.MM.yyyy') + ' (' + calculate_age(dobAsDate) + ')';
  };

  const RenderPassengersAccordion = () => {
    if (!passengers) {
      return <></>;
    }
    const passengersList = Object.values<TFF.Passenger>(passengers);
    return (
      <div style={{ margin: '20px' }}>
        {passengersList
          .filter(p => p.Type !== 'INF')
          .map(pax => {
            return (
              <Accordion className={classes.accordionRoot} key={pax.Id} defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="passenger-content"
                  id="passenger-header"
                >
                  <FormControlLabel
                    aria-label="passenger"
                    onClick={event => event.stopPropagation()}
                    onFocus={event => event.stopPropagation()}
                    control={
                      <Checkbox
                        color="primary"
                        name={createIdForPaxSelection(pax.Id)}
                        checked={itemIsSelected(createIdForPaxSelection(pax.Id!))}
                        onChange={event => handleSelectedPassenger(event, pax)}
                      />
                    }
                    label={
                      <Typography
                        variant="h6"
                        className={classes.contentHeader}
                        color="primary"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <strong style={{ marginRight: '5px' }}>
                          {pax.FirstName} {pax.LastName}
                        </strong>{' '}
                        <span style={{ fontSize: '14px', marginRight: '5px' }}>
                          {pax.Type} {createDob(pax.DOB as string)}
                        </span>
                        {pax.InfantId && <ChildFriendlyIcon color="primary" />}
                      </Typography>
                    }
                  />
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  {RenderPassenerJourneyExtras(pax)}
                  {RenderPassengerJourneySegmentsExtras(pax)}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    );
  };

  return (
    <div>
      <Header />
      <Subheader />
      <RenderPassengersAccordion />
    </div>
  );
};
export default BookingChangeSelection;
