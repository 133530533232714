import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

export const AtcomCostOverviewDialog = ({ handleClose, open }) => {
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">{'Non-TFA Ancillaries Notification'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.formatMessage({ id: 'atcom.DialogCostText' })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button onClick={handleClose} autoFocus>
            {intl.formatMessage({ id: 'atcom.DialogCostClose' })}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
