import LanguageItem from './LanguageItem';
import languageData from './data';
import { CustomScrollbars } from '../../util';

export const LanguageSwitcher = ({
  switchLanguage,
  handleRequestClose,
}: {
  switchLanguage: any;
  handleRequestClose: any;
}) => {
  return (
    <CustomScrollbars className="messages-list language-list scrollbar" style={{ height: 80 }}>
      <ul className="list-unstyled">
        {languageData.map((language, index) => (
          <LanguageItem
            key={index}
            language={language}
            handleRequestClose={handleRequestClose}
            switchLanguage={switchLanguage}
          />
        ))}
      </ul>
    </CustomScrollbars>
  );
};
