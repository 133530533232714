import * as React from 'react';
import { FieldInputProps } from 'react-final-form';

interface TFFTextInputProps {
  inputId: string;
  labelText: string;
  disabled?: boolean;
  inputFromRedux: FieldInputProps<any>;
  isBookingId?: boolean;
}

export const TFFTextInput: React.FC<TFFTextInputProps> = ({
  inputId,
  labelText,
  disabled,
  inputFromRedux,
  isBookingId,
}) => {
  return (
    <label htmlFor={inputId} className={`input input-text${disabled ? ' disabled' : ''}`}>
      <span className="label">{labelText}</span>
      <span className="group" data-label="">
        <input
          id={inputId}
          type="text"
          disabled={disabled}
          {...inputFromRedux}
          className={`${isBookingId ? 'tff-id-input' : ''}`}
        />
      </span>
    </label>
  );
};

export default TFFTextInput;
