import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { reducer as reduxFormReducer } from 'redux-form';

import { ICampaignStoreState } from './Campaign/campaignSlice';
import { ISettingsStoreState } from './Settings/settingsSlice';
import campaignReducer from './Campaign/campaignSlice';
import { IFeeStoreState } from './Fee/feeSlice';
import feeReducer from './Fee/feeSlice';
import { IUsersStoreState } from './Users/usersSlice';
import rolesReducer from './Roles/rolesSlice';
import { IRolesStoreState } from './Roles/rolesSlice';
import { IFlightSettingsStoreState } from './FlightSettings/flightSlice';
import flightReducer from './FlightSettings/flightSlice';
import usersReducer from './Users/usersSlice';
import settingsSlice from './Settings/settingsSlice';
import checkboxesReducer from './Ancillary/ancillarySlice';

type RootState = {
  settings: ISettingsStoreState;
  feesData: IFeeStoreState;
  campaignData: ICampaignStoreState;
  usersData: IUsersStoreState;
  rolesData: IRolesStoreState;
  flightSettings: IFlightSettingsStoreState;
  form: any;
  checkboxes: any;
};

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export let reducers = {};

const reducer = () =>
  (reducers = {
    form: reduxFormReducer,
    settings: settingsSlice,
    feesData: feeReducer,
    campaignData: campaignReducer,
    usersData: usersReducer,
    rolesData: rolesReducer,
    flightSettings: flightReducer,
    checkboxes: checkboxesReducer,
  });

export default reducer;
