import * as React from 'react';

export const PricingIcon: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    style={{
      fill: '#ffffff',
      width: '24px',
      height: '24px',
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.959,12.959V18.9L26.615,32.551l5.936-5.936L18.9,12.959ZM11.98,11h7.347a.979.979,0,0,1,.693.287L34.713,25.981a.979.979,0,0,1,0,1.385l-7.347,7.347a.979.979,0,0,1-1.385,0L11.287,20.019A.979.979,0,0,1,11,19.327V11.98A.98.98,0,0,1,11.98,11Zm2.449,5.388a1.959,1.959,0,1,1,1.959,1.959A1.959,1.959,0,0,1,14.429,16.388Z"
      transform="translate(-11 -11)"
    />
  </svg>
);
