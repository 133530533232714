import { TFF } from '@tff/types';

interface AncillariesAccordion {
  type: string;
  ancillaries: TFF.MetaValue[];
}

/*
    assigns ssr objects corresponding type keys
  */

export const groupByType = (array: TFF.MetaValue[]): AncillariesAccordion[] => {
  return array.reduce((acc, obj) => {
    const key = obj.type;
    if (!acc[key]) {
      acc[key] = { type: key, ancillaries: [] };
    }
    acc[key].ancillaries.push(obj);
    return acc;
  }, []);
};
