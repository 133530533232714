import * as React from 'react';
import TFFButton from './TFFButton';
import { useIntl } from 'react-intl';
import TFFIconButton from './TFFIconButton';

interface TFFDialogProps {
  dialogId: string;
  header: React.ReactNode;
  children: React.ReactNode;
  isOpen: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButton?: boolean;
  cancelButton?: boolean;
  closeButton?: boolean;
  closeOnBackdropClick?: boolean;
  confirmDisabled?: boolean;
}

export const TFFDialog: React.FC<TFFDialogProps> = ({
  dialogId,
  header,
  children,
  isOpen,
  onConfirm,
  onCancel,
  confirmButtonText,
  cancelButtonText,
  confirmButton,
  cancelButton,
  closeButton,
  confirmDisabled,
}) => {
  const intl = useIntl();

  const handleClose = () => {
    onCancel && onCancel();
  };

  const handleConfirmation = () => {
    onConfirm && onConfirm();
  };

  return (
    <>
      <div
        aria-hidden="true"
        className="dialog-backdrop"
        id={`${dialogId}-backdrop`}
        onClick={handleClose}
        style={{ display: `${isOpen ? 'block' : ''}` }}
      ></div>
      <div className="dialog base js" role="dialog" id={dialogId} {...{ open: isOpen }}>
        <div className="modal tff-dialog">
          <header className="header">
            <span className="title">{header}</span>
            <span className="control">
              <TFFIconButton label={'Close button'} iconName={'cross'} handleClick={handleClose} />
            </span>
          </header>
          <section className="main">{children}</section>
          <menu className="footer tff-flex-space-between">
            {closeButton && <TFFButton buttonText="Close" variant="secondary" handleClick={handleClose} />}
            {cancelButton && (
              <TFFButton
                buttonText={cancelButtonText || intl.formatMessage({ id: 'global.cancel' })}
                handleClick={handleClose}
                outline
              />
            )}
            {confirmButton && (
              <TFFButton
                buttonText={confirmButtonText || intl.formatMessage({ id: 'global.confirm' })}
                handleClick={handleConfirmation}
                disabled={confirmDisabled}
              />
            )}
          </menu>
        </div>
      </div>
    </>
  );
};

export default TFFDialog;
