import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import * as logo from '../../../../assets/images/logo.png';
import { Dropdown } from 'react-bootstrap';

import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTypedSelector } from '../../../../reducers';
import { switchLanguage } from '../../../../reducers/Settings/settingsSlice';
import { authenticated } from '../../../../keycloak';
import { ShowMoreNavigation } from '../../../../components/ShowMoreNavigation';
import { LanguageSwitcher } from '../../../../components/LanguageSwitcher';
import { UserInfo } from '../../../../components/UserInfo';
import { useKeycloak } from '@react-keycloak/web';
import { IntlMessages } from '../../../../util';
import { NavLink } from 'react-router-dom';
import { withRouter } from '../../../../routes/withRouter';

const useStyle = makeStyles({
  navLink: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&.active': {
      borderBottom: '2px solid #092A5E',
    },
  },
});

const Header = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const classes = useStyle();
  const { locale } = useTypedSelector(({ settings }) => settings);
  const [langSwitcher, setLangSwitcher] = useState(false);

  const hasAccess =
    keycloak.hasRealmRole('Role_Business_Administrator') || keycloak.hasRealmRole('Role_IT_Administrator');

  const onLangSwitcherSelect = () => {
    setLangSwitcher(!langSwitcher);
  };

  const handleLanguageRequestClose = () => {
    setLangSwitcher(false);
  };

  const onSwitchLanguage = lang => {
    dispatch(switchLanguage(lang));
  };

  return (
    <AppBar className="app-main-header" position="fixed">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <Link className="app-logo mr-2 d-none d-sm-block" to="/">
          <img src={logo.default} alt="TFF" title="TUI Flight-Manager" />
        </Link>

        <ul
          className="header-notifications m-auto h-100 d-flex align-items-center justify-content-between"
          style={{ marginLeft: '200px', width: '60%' }}
        >
          <div className="d-flex align-items-center justify-content-between h-100">
            <li className="list-inline-item mr-4 h-100 d-flex align-items-center">
              <NavLink to="/tff/order/search" className={classes.navLink}>
                <Typography color="primary" variant="h5">
                  <IntlMessages id="header.manage_bookings" />
                </Typography>
              </NavLink>
            </li>
          </div>
          {hasAccess && (
            <li className="list-inline-item">
              <ShowMoreNavigation />
            </li>
          )}
        </ul>

        <Dropdown
          className="quick-menu"
          onToggle={onLangSwitcherSelect}
          show={langSwitcher}
          style={{ paddingRight: '10px' }}
        >
          <Dropdown.Toggle id="dropdown-language-switcher" className="d-inline-block" as="span" data-toggle="dropdown">
            <IconButton className="icon-btn" size="large">
              <i className={`flag flag-24 flag-${locale.icon}`} />
            </IconButton>
          </Dropdown.Toggle>

          <Dropdown.Menu align="start" className="w-50">
            <LanguageSwitcher switchLanguage={onSwitchLanguage} handleRequestClose={handleLanguageRequestClose} />
          </Dropdown.Menu>
        </Dropdown>
        {authenticated && <UserInfo />}
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Header);
