import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { keycloackApi } from '../../apis';
import { keycloak } from '../../keycloak';
import { toast } from 'react-toastify';

export enum ROLES_STATES {
  UNKOWN = 'UNKOWN',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

export interface IRolesStoreState {
  role?: any;
  roles: any[];
  state: ROLES_STATES;
  error?: any;
  rolesLoading?: boolean;
}

const initialState: IRolesStoreState = {
  role: undefined,
  roles: [],
  state: ROLES_STATES.UNKOWN,
  error: undefined,
  rolesLoading: false,
};

export const getRoles = createAsyncThunk('roles/getRoles', async (_, { rejectWithValue }) => {
  try {
    const response = await keycloackApi.get('/admin/realms/tff/roles', {
      headers: {
        Authorization: 'Bearer ' + keycloak.token,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      return rejectWithValue(response.data);
    }
  } catch (e) {
    toast.error(e.message);
    return rejectWithValue(e.response.data);
  }
});

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRole: (state, action: PayloadAction<{ state: ROLES_STATES }>) => {
      state.role = action.payload;
      state.state = ROLES_STATES.UNKOWN;
      state.rolesLoading = false;
    },
    resetRoleState: state => {
      state.state = ROLES_STATES.UNKOWN;
    },
  },
  extraReducers: builder => {
    builder.addCase(getRoles.pending, (state, action) => {
      state.state = ROLES_STATES.UNKOWN;
      state.rolesLoading = true;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
      state.state = ROLES_STATES.FINISHED;
      state.rolesLoading = false;
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      state.error = action.error.message;
      state.state = ROLES_STATES.ERROR;
      state.rolesLoading = false;
    });
  },
});

export const { setRole, resetRoleState } = rolesSlice.actions;

export default rolesSlice.reducer;
