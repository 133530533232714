const formatDateTime = (t?: string | Date): string | null | Date => {
  if (t) {
    try {
      if (t instanceof Date) {
        return t.toLocaleString();
      } else {
        return new Date(t).toLocaleString();
      }
    } catch (er) {
      return t;
    }
  }
  return null;
};
export default formatDateTime;
