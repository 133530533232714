import { ICampaign, IFee, TypeOfTFFBookingSource } from '@tff/types/TFF';

export interface IFeeFilter {
  dateOfSimulation?: Date;
  bookingSource: TypeOfTFFBookingSource | 'TUI-NDC-SQ';
  action: string;
  bookingDate: Date;
  channel: string;
  currency: string;
  travelDate: Date;
}

export const filterActiveFee = (campaigns: ICampaign[], feeFilter: IFeeFilter): IFee | undefined => {
  const filteredCampaigns = campaigns.map(item => Object.assign({}, item, { selected: false }));

  const activeCampaigns: ICampaign[] = filteredCampaigns.filter(campaign => {
    const dateOfValidation: Date = feeFilter.dateOfSimulation ? new Date(feeFilter.dateOfSimulation) : new Date();

    return (
      campaign.status === 'aktiv' && // status
      (!campaign.startDate || new Date(campaign.startDate) < dateOfValidation) && // > startDate
      (!campaign.endDate || new Date(campaign.endDate) >= dateOfValidation) && // < endDate
      (!campaign.bookingSources || campaign.bookingSources?.includes(feeFilter.bookingSource)) && // validate bookingSource
      (!campaign.travelStartDate || new Date(campaign.travelStartDate) < new Date(feeFilter.travelDate)) && // > travelStartDate
      (!campaign.travelEndDate || new Date(campaign.travelEndDate) >= new Date(feeFilter.travelDate))
    );
  });

  let activeFee: IFee | undefined;
  activeCampaigns?.forEach(campaign => {
    campaign.fees?.forEach((fee: IFee) => {
      if (
        !activeFee &&
        fee.actions?.includes(feeFilter.action as string) &&
        fee.channel?.includes(feeFilter.channel as string) &&
        fee.currency === feeFilter.currency
      ) {
        activeFee = fee;
      }
    });
  });

  return activeFee;
};
