import * as React from 'react';
import { CSSProperties } from 'react';

type Props = {
  style?: CSSProperties;
};

export const MinusIcon: React.FC<Props> = ({ style }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
    <path
      d="M16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27Z"
      stroke="#BABABA"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M10 16H22" stroke="#BABABA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
