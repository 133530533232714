import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import Table from '../Table';
import { columnDefinition } from './components/column-definitions';
import DeferedPaymentForm from './components/form';
import { DeferedPaymentProps } from './components/models';
import { RetrieveDbServiceProps, useRetrieveDbService } from '../../hooks/use-retrieve-db-service';
import { Retrieve } from '@tff/types/TFF';
import { MUIDataTableOptions } from 'mui-datatables';
import ChangeDialog, { ChangeForm } from './components/dialog';
import { UpdateRetrieveDbProps, useUpdateRetrieveDb } from '../../hooks/use-update-retrieve-db';
import { toast } from 'react-toastify';
import moment from 'moment';

const DeferedPayment = () => {
  const [retrieveItems, setRetrieveItems] = useState<Retrieve[]>([]);
  const [retrieveDbServiceProps, setRetrieveDbServiceProps] = useState<RetrieveDbServiceProps | undefined>(undefined);
  const [updateRetrieveDbProps, setUpdateRetrieveDbProps] = useState<UpdateRetrieveDbProps | undefined>(undefined);
  const [searchValues, setSearchValues] = useState<DeferedPaymentProps | undefined>(undefined);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const { resetRetrieveDbState, fetchedRetrieveDbItem, fetchedRetrieveDbItemList } =
    useRetrieveDbService(retrieveDbServiceProps);

  const { updateRetrieveList, updateRetrieveDbStatus, resetUpdateRetrieveDbState } =
    useUpdateRetrieveDb(updateRetrieveDbProps);

  type CustomToolbarProps = {
    selectedRows: number[];
  };

  const handleCustomToolbar = (selectedRows: number[]) => {
    setSelectedRows(selectedRows);
    resetUpdateRetrieveDbState();
    setOpenDialog(true);
  };

  const CustomToolbar: React.FC<CustomToolbarProps> = ({ selectedRows }) => {
    return (
      <div style={{ margin: 5 }}>
        <Button color="primary" variant="contained" onClick={() => handleCustomToolbar(selectedRows)}>
          Change Ticket Time Limit
        </Button>
      </div>
    );
  };

  const tableOptions: MUIDataTableOptions = {
    selectableRows: 'multiple',
    selectableRowsHeader: true,
    customToolbarSelect: selectedRows => {
      return <CustomToolbar selectedRows={selectedRows.data.map(d => d.dataIndex)} />;
    },
  };

  const onTicketTimeChange = (values: ChangeForm) => {
    let { ticketTimeLimit } = values;

    if (ticketTimeLimit) {
      const utcTime = moment(ticketTimeLimit).toISOString(true);

      const retrievesToChange: Retrieve[] = [];
      selectedRows.forEach(row => retrievesToChange.push(retrieveItems[row]));
      setUpdateRetrieveDbProps({
        itemsToUpdate: retrievesToChange,
        propsToUpdate: { ticketTimeLimit: utcTime },
      });
      setOpenDialog(false);
    }
  };

  const filterData = (matchObj: Partial<DeferedPaymentProps>, data: Retrieve[]) => {
    const matchEntries = Object.entries(matchObj);
    return data.filter(item => {
      let ret = true;
      return matchEntries.every(([key, val]) => {
        if (key === 'departureDateFrom') {
          ret = new Date(item.outDeparture!) >= new Date(val);
        } else if (key === 'lastTicketingDate') {
          ret = new Date(item.ticketTimeLimit!) >= new Date(val);
        } else if (key === 'departureDateTo') {
          ret = new Date(item.outDeparture!) < new Date(val);
        } else if (Array.isArray(val) && !val.includes(item[key])) {
          ret = false;
        } else if (!Array.isArray(val) && item[key] !== val) {
          ret = false;
        }

        return ret;
      });
    });
  };

  useEffect(() => {
    if (updateRetrieveDbStatus === 'FINISH') {
      if (updateRetrieveList!.length === updateRetrieveDbProps!.itemsToUpdate.length) {
        toast.success(`${updateRetrieveDbProps!.itemsToUpdate.length} items have been updated successfully!`);
      } else {
        toast.error('SOMETHING went wrong! Please try again');
      }
    }
  }, [updateRetrieveDbStatus]);

  useEffect(() => {
    if (fetchedRetrieveDbItem) {
      setRetrieveItems([fetchedRetrieveDbItem]);
    } else if (fetchedRetrieveDbItemList) {
      setRetrieveItems(fetchedRetrieveDbItemList);
      if (searchValues?.product) {
        if (searchValues.bookingSource?.length === 0) {
          delete searchValues.bookingSource;
        }
        if (searchValues.outCabinClasses?.length === 0) {
          delete searchValues.outCabinClasses;
        }
        const filteredList = filterData(searchValues, fetchedRetrieveDbItemList);
        setRetrieveItems(filteredList);
      }
    }
  }, [fetchedRetrieveDbItemList, fetchedRetrieveDbItem, searchValues]);

  const handleSearch = (searchValues: DeferedPaymentProps) => {
    const { tfmPnr, product } = searchValues;
    setSearchValues(searchValues);
    resetRetrieveDbState();
    if (tfmPnr) {
      setRetrieveDbServiceProps({ tfmPnr: tfmPnr });
    } else if (product) {
      setRetrieveDbServiceProps({ product: product });
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DeferedPaymentForm onSubmit={handleSearch} />
      </Grid>
      <Grid item xs={12}>
        <Table
          data={retrieveItems}
          columnsData={columnDefinition}
          tableId="deferedPaymentTable"
          tableName={'Defered Payment'}
          otherOptions={tableOptions}
        />
      </Grid>
      {openDialog && (
        <ChangeDialog open={openDialog} onCancel={() => setOpenDialog(false)} onConfirm={onTicketTimeChange} />
      )}
    </Grid>
  );
};

export default DeferedPayment;
