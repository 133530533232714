export interface ILanguageItem {
  languageId: string;
  locale: string;
  name: string;
  icon: string;
}

const languageData: ILanguageItem[] = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'gb',
  },
  {
    languageId: 'german',
    locale: 'de',
    name: 'German',
    icon: 'de',
  },
];
export default languageData;
