import * as React from 'react';
import { useEffect, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import Table from '../../components/Table';
import { ITableColumns } from '../../models';
import {
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import CustomDialog from '../../components/Dialogs';
import { createStyles, makeStyles } from '@mui/styles';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomButton from '../../components/Button';
import { useKeycloak } from '@react-keycloak/web';
import { apigeeEndpoint } from '../../apis';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { LoadingContext } from '../../contexts/LoadingContextProvider';
import { TaskDynamoItem, UpdateTaskRequest, TypeOfTaskStatus, TypeOfTaskDecision } from '@tff/types/TFF';

interface InvoluntaryChangeTableProps {
  data: TaskDynamoItem[];
}

const updateInvoluntaryChangeTask = async (data: UpdateTaskRequest) => {
  const response = await apigeeEndpoint.post('ndc/task', data);
  const sortedRetrieveList = response.data.sort(
    (a: UpdateTaskRequest, b: UpdateTaskRequest) =>
      new Date(b.ndcTaskToUpdate.receivedAt!).getTime() - new Date(a.ndcTaskToUpdate.receivedAt!).getTime(),
  );
  return sortedRetrieveList;
};

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      margin: '0 auto',
      width: '100%',
      padding: '5%',
    },
    formRoot: {
      borderRadius: 0,
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      minWidth: 300,
      margin: '15px',
    },
    formLabel: {
      marginLeft: 10,
    },
    helperText: {
      color: 'red',
      fontSize: '16px',
    },
    tableRoot: {
      marginTop: '50px',
    },
    header: {
      margin: '20px',
      padding: '10px',
      color: 'white',
      backgroundColor: '#092A5E',
    },
  }),
);

const ndcAction = yup.object().shape({
  remark: yup.string().required(),
  status: yup.string().required(),
  decision: yup.string().required(),
  autoConfirmDate: yup.date().required(),
  remindDate: yup.date().required(),
});

/**

 */
const InvoluntaryChangeTable: React.FC<InvoluntaryChangeTableProps> = ({ data }) => {
  const intl = useIntl();
  const classes = useStyle();
  const [ndcTaskToEdit, setNdcTaskToEdit] = useState<TaskDynamoItem | undefined>(undefined);
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();
  const { showLoading, closeLoading } = useContext(LoadingContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ChangeFormValues>({ resolver: yupResolver(ndcAction) });

  const openActionDialogWithTask = (sortKey: string) => {
    reset();
    const taskToEdit = data.find(d => d.sortKey === sortKey);
    setNdcTaskToEdit(taskToEdit);
  };

  const columnsData: ITableColumns[] = [
    {
      name: 'messageType',
      label: intl.formatMessage({ id: 'inv.table.messageType' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },

    {
      name: 'recordLocator',
      label: intl.formatMessage({ id: 'inv.table.recordLocator' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: value => <Link to={`/tff/order/${value}`}>{value}</Link>,
      },
    },

    {
      name: 'clientPnr',
      label: intl.formatMessage({ id: 'inv.table.clientPnr' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },

    {
      name: 'bookingSource',
      label: intl.formatMessage({ id: 'inv.table.bookingSource' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },

    {
      name: 'receivedAt',
      label: intl.formatMessage({ id: 'inv.table.received' }),
      options: {
        customBodyRender: value => <p style={{ margin: 0 }}>{moment(value).format('DD.MM.YYYY - HH:mm')}</p>,
        display: true,
        filter: false,
        filterList: [],
      },
    },

    {
      name: 'ttl',
      label: intl.formatMessage({ id: 'inv.table.ttl' }), // TimeToLive
      options: {
        customBodyRender: value => <p style={{ margin: 0 }}>{moment(value).format('DD.MM.YYYY - HH:mm')}</p>,
        display: true,
        filter: false,
        filterList: [],
      },
    },

    {
      name: 'remindDate',
      label: intl.formatMessage({ id: 'inv.table.remindDate' }),
      options: {
        customBodyRender: value => <p style={{ margin: 0 }}>{moment(value).format('DD.MM.YYYY - HH:mm')}</p>,
        display: true,
        filter: false,
        filterList: [],
      },
    },

    {
      name: 'autoConfirmDate',
      label: intl.formatMessage({ id: 'inv.table.autoConfirmDate' }),
      options: {
        customBodyRender: value => <p style={{ margin: 0 }}>{moment(value).format('DD.MM.YYYY - HH:mm')}</p>,
        display: true,
        filter: false,
        filterList: [],
      },
    },

    {
      name: 'status',
      label: intl.formatMessage({ id: 'inv.table.status' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },
    {
      name: 'classification',
      label: intl.formatMessage({ id: 'inv.table.classification' }),
      options: {
        display: true,
        filter: false,
        filterList: [],
      },
    },

    {
      name: 'decision',
      label: intl.formatMessage({ id: 'inv.table.decision' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },

    {
      name: 'sortKey',
      label: intl.formatMessage({ id: 'inv.table.actions' }),
      options: {
        display: true,
        filter: false,
        filterList: [],
        customBodyRender: (value: string) => {
          return (
            <IconButton aria-label="edit" onClick={() => openActionDialogWithTask(`${value}`)} size="large">
              <Edit fontSize="small" />
            </IconButton>
          );
        },
      },
    },
  ];

  const { mutate, isLoading } = useMutation(updateInvoluntaryChangeTask, {
    onSuccess: data => {
      if (data?.errorMessage) {
        toast.error(data.errorMessage);
      } else {
        toast.success('Data has been sent Successfully!');
      }
    },
    onError: (err: Error) => {
      toast.error(err.message.toString());
    },
    onSettled: () => {
      queryClient.invalidateQueries('involuntaryChanges');
    },
  });

  useEffect(() => {
    isLoading ? showLoading() : closeLoading();
  }, [isLoading]);

  type ChangeFormValues = {
    autoConfirmDate: Date;
    ndcTaskToEdit: Date;
    status: TypeOfTaskStatus;
    decision: TypeOfTaskDecision;
    remindDate: Date;
    remark: string;
  };

  /**
   * Message to send to backend
   * @param formValues
   */
  const onSubmit: SubmitHandler<ChangeFormValues> = formValues => {
    if (ndcTaskToEdit) {
      ndcTaskToEdit.autoConfirmDate = formValues.autoConfirmDate.toISOString();
      ndcTaskToEdit.remindDate = formValues.remindDate.toISOString();
      ndcTaskToEdit.status = formValues.status;
      ndcTaskToEdit.decision = formValues.decision;

      const updateTaskRequest: UpdateTaskRequest = {
        ndcTaskToUpdate: ndcTaskToEdit!,
        updateRemark: formValues.remark,
        updatedBy: keycloak?.profile?.username!,
      };

      mutate(updateTaskRequest);
      setNdcTaskToEdit(undefined);
    }
  };

  return (
    <>
      {ndcTaskToEdit && (
        <CustomDialog initialOpen={!!ndcTaskToEdit} onCancel={() => setNdcTaskToEdit(undefined)} width="md" form>
          <DialogTitle id="alert-dialog-title">Edit {ndcTaskToEdit.partitionKey}</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary" className={classes.header}>
                      Action
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container xs={12} justifyContent="space-between">
                  <Grid item xs={6}>
                    <FormControl variant="standard" className={classes.formControl}>
                      <TextField
                        {...register('autoConfirmDate')}
                        type="datetime-local"
                        name="autoConfirmDate"
                        variant="filled"
                        label="Last AutoConfirm Date"
                        defaultValue={moment(ndcTaskToEdit.autoConfirmDate).format('YYYY-MM-DDTHH:mm')}
                        inputProps={{ style: { textTransform: 'uppercase' } }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.autoConfirmDate && (
                        <FormHelperText className={classes.helperText}>
                          {errors.autoConfirmDate?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="standard" className={classes.formControl}>
                      <TextField
                        {...register('remindDate')}
                        type="datetime-local"
                        name="remindDate"
                        variant="filled"
                        label="Remind date"
                        defaultValue={moment(ndcTaskToEdit.remindDate).format('YYYY-MM-DDTHH:mm')}
                        inputProps={{ style: { textTransform: 'uppercase' } }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.autoConfirmDate && (
                        <FormHelperText className={classes.helperText}>
                          {errors.autoConfirmDate?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container xs={12} justifyContent="space-between">
                  <Grid item xs={6}>
                    <FormControl variant="standard" className={classes.formControl}>
                      <InputLabel className={classes.formLabel}>Status</InputLabel>
                      <Select
                        {...register('status')}
                        type="text"
                        name="status"
                        variant="filled"
                        defaultValue={ndcTaskToEdit.status}
                      >
                        <MenuItem key={'NEW'} value={'NEW'}>
                          NEW
                        </MenuItem>
                        <MenuItem key={'IN_PROGRESS'} value={'IN_PROGRESS'}>
                          IN_PROGRESS
                        </MenuItem>
                        <MenuItem key={'PENDING'} value={'PENDING'}>
                          PENDING
                        </MenuItem>
                        <MenuItem key={'CLOSE'} value={'CLOSE'}>
                          CLOSE
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl variant="standard" className={classes.formControl}>
                      <InputLabel className={classes.formLabel}>Decision</InputLabel>
                      <Select
                        {...register('decision')}
                        type="text"
                        name="decision"
                        variant="filled"
                        defaultValue={ndcTaskToEdit.decision}
                      >
                        <MenuItem key={'OPEN'} value={'OPEN'}>
                          OPEN
                        </MenuItem>
                        <MenuItem key={'CONFIRMED'} value={'CONFIRMED'}>
                          CONFIRMED
                        </MenuItem>
                        <MenuItem key={'CONFIRMED_WITH_CHANGES'} value={'CONFIRMED_WITH_CHANGES'}>
                          CONFIRMED_WITH_CHANGES
                        </MenuItem>
                        <MenuItem key={'REJECTED'} value={'REJECTED'}>
                          REJECTED
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <TextField
                      {...register('remark')}
                      type="text"
                      name="remark"
                      variant="filled"
                      label="Remark"
                      multiline
                      minRows={10}
                      style={{ margin: '20px', width: '95%' }}
                    />
                    {errors.remark && (
                      <FormHelperText className={classes.helperText}>{errors.remark?.message}</FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid container xs={12} justifyContent={'space-around'}>
                  <CustomButton
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    style={{ margin: '20px' }}
                    disabled={false}
                  >
                    Submit
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    size="large"
                    color="danger"
                    style={{ margin: '20px' }}
                    onClick={() => setNdcTaskToEdit(undefined)}
                  >
                    Cancel
                  </CustomButton>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </CustomDialog>
      )}

      <Table
        data={data && Array.isArray(data) && data.length > 0 ? data : []}
        columnsData={columnsData}
        tableId="ndcTable"
        tableName="Ndc Table"
        otherOptions={{ rowsPerPage: 10 }}
      />
    </>
  );
};

export default InvoluntaryChangeTable;
