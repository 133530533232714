import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import TextField from '@mui/material/TextField';
import {
  FormControl,
  FormHelperText,
  RadioGroup,
  InputBase,
  Select,
  FormControlLabel,
  InputLabel,
  Checkbox,
  InputBaseComponentProps,
  AutocompleteRenderInputParams,
} from '@mui/material';
import { FormLabel } from 'react-bootstrap';
import { FieldInputProps } from 'react-final-form';
import { Autocomplete } from '@mui/material';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material/useAutocomplete';

import moment from 'moment';

interface props {
  input: any;
  label: React.ReactNode;
  hintText: string;
  multiple?: boolean;
  showError: boolean;
  mask: string;
  children: JSX.Element;
  formatter: unknown;
  options: any;
  required: boolean;
  disabled: boolean;
  valueObject: any;
  onChangeFn: (event: any, values: any) => any;
  meta: {
    touched: boolean;
    error?: any;
  };
}

export const renderInput = ({
  input,
  label,
  hintText,
  showError,
  mask,
  meta: { touched, error },
  ...custom
}: props) => {
  return (
    <TextField
      variant="standard"
      floatinglabeltext={label}
      label={label}
      error={error && (touched || showError)}
      helperText={error && (touched || showError) ? (error.text ? error.text : error) : hintText}
      fullWidth
      {...input}
      {...custom}
    />
  );
};

export const renderInputBase = ({ input, hintText, showError, mask, meta: { touched, error }, ...custom }: props) => {
  return <InputBase error={error && (touched || showError)} {...input} {...custom} />;
};

export const renderSelectFieldBase = ({
  input,
  label,
  hintText,
  showError,
  meta: { touched, error },
  children,
  formatter,
  ...custom
}: props) => {
  return (
    <Select variant="standard" error={error && (touched || showError)} {...input} fullWidth {...custom}>
      {children}
    </Select>
  );
};

export const renderSelectField = ({
  input,
  label,
  hintText,
  showError,
  meta: { touched, error },
  children,
  formatter,
  ...custom
}: props) => {
  return (
    <TextField
      variant="standard"
      select
      floatinglabeltext={label}
      label={label}
      multiple
      error={error && (touched || showError)}
      helperText={error && (touched || showError) ? (error.text ? error.text : error) : hintText}
      fullWidth
      {...input}
      {...custom}
    >
      {children}
    </TextField>
  );
};

interface IAutoCompleteProps<T> {
  id: string;
  input: FieldInputProps<T>;
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  options: T[];
  getOptionLabel: (option: T) => string;
  getOptionSelected?: (option: T, value: T) => boolean;
  loading?: boolean;
  loadingText?: string;
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: T | T[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T> | undefined,
  ) => void | undefined;
  error?: boolean;
  disableClearable?: boolean;
  label?: string;
  fullWidth?: boolean;
  required?: boolean;
  inputProps?: InputBaseComponentProps;
  InputLabelProps?: AutocompleteRenderInputParams['InputLabelProps'];
}

export const AutoCompleteField = <T,>(props: IAutoCompleteProps<T>): JSX.Element => {
  const {
    id,
    inputValue,
    setInputValue,
    options,
    getOptionLabel,
    getOptionSelected,
    onChange,
    input,
    disableClearable = false,
    error,
    ...others
  } = props;

  const { InputLabelProps, ...rest } = others;
  return (
    <Autocomplete<T, boolean, boolean>
      id={id}
      autoComplete={true}
      autoHighlight={true}
      autoSelect={true}
      inputValue={inputValue}
      {...input}
      {...rest}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={getOptionSelected}
      renderInput={params => (
        <TextField
          variant="standard"
          {...params}
          {...input}
          {...rest}
          InputLabelProps={{
            shrink: true,
            ...InputLabelProps,
          }}
        />
      )}
      blurOnSelect={true}
      disableClearable={disableClearable}
      onChange={(event, value, reason) => {
        input.onChange(value);
        if (onChange) onChange(event, value, reason);
      }}
      onInputChange={(event, value, reason) => {
        if (event) {
          if (reason === 'input') {
            setInputValue(value);
          }
          if (reason === 'clear') {
            setInputValue('');
          }
        } else {
          if (value && reason === 'reset') {
            setInputValue(value);
          }
        }
      }}
    />
  );
};

export const renderAutocompleteField = ({
  input: { ...inputProps },
  hintText,
  label,
  required,
  showError,
  meta: { touched, error },
  valueObject,
  options,
  onChangeFn,
  ...custom
}: props) => {
  const onChange = (event: any, values: any) => {
    // wird eine onChangeFn übergeben hat diese vorrang
    // todo... setzen einer ID ist kritisch... was wenn keine ID existiert?
    onChangeFn ? onChangeFn(event, values) : inputProps.onChange(values.id);
  };
  return (
    <Autocomplete
      value={valueObject}
      onChange={onChange}
      options={options}
      renderInput={params => (
        <TextField
          variant="standard"
          {...params}
          error={error && (touched || showError)}
          label={label}
          required={required}
          helperText={error && (touched || showError) ? (error.text ? error.text : error) : hintText}
        />
      )}
      {...custom}
    />
  );
};

export const renderKeyboardDatePicker = ({
  meta: { error, touched },
  input: { onBlur, value, ...inputProps },
  showError,
  disabled,
  ...others
}: props) => {
  const onChange = (date: string) => {
    Date.parse(date) ? inputProps.onChange(moment(date).format('YYYY-MM-DD')) : inputProps.onChange(null);
  };

  return (
    <>
      <DatePicker
        disableToolbar
        {...inputProps}
        {...others}
        format="dd.MM.yyyy"
        variant="inline"
        autoOk
        value={value ? moment(value, 'YYYY-MM-DD').toDate() : null}
        error={error && (touched || showError)} // todo: normalerweise error & touched... aber touched schheint hier nicht zu funktionieren
        onChange={onChange}
        disabled={disabled}
        renderInput={props => <TextField {...props} />}
      />
      {error && (touched || showError) && (
        <FormHelperText style={{ color: 'red' }}>{error.text ? error.text : error}</FormHelperText>
      )}
    </>
  );
};

export const renderKeyboardTimePicker = ({
  meta: { error, touched },
  input: { onBlur, value, ...inputProps },
  showError,
  disabled,
  ...others
}: props) => {
  const onChange = (date: string) => {
    Date.parse(date) ? inputProps.onChange(moment(date).format('HH:mm')) : inputProps.onChange(null);
  };

  return (
    <>
      <DateTimePicker
        disableToolbar
        ampm={false}
        {...inputProps}
        {...others}
        format="HH:mm"
        variant="inline"
        autoOk
        value={value ? moment(value, 'YYYY-MM-DDTHH:mm').toDate() : null}
        error={error && (touched || showError)}
        onChange={onChange}
        disabled={disabled}
        renderInput={props => <TextField {...props} />}
      />
      {error && (touched || showError) && (
        <FormHelperText style={{ color: 'red' }}>{error.text ? error.text : error}</FormHelperText>
      )}
    </>
  );
};

export const renderRadioGroupButton = ({ label, hintText, meta: { error }, input, children, ...rest }: props) => {
  return (
    <FormControl variant="standard" component="fieldset">
      <FormLabel>{label}</FormLabel>
      <RadioGroup {...input} {...rest}>
        {children}
      </RadioGroup>
      <FormHelperText>{error ? error : hintText}</FormHelperText>
    </FormControl>
  );
};

export const renderCheckbox = ({ label, meta: { error, touched }, input, showError }: props) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={(_e, checked: boolean) => input.onChange(checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        }
        label={label}
      />
      {error && (touched || showError) && (
        <FormHelperText style={{ color: 'red' }}>{error.text ? error.text : error}</FormHelperText>
      )}
    </>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const renderMultiselectField = ({ input: { value, name, onChange, ...restInput }, meta, ...rest }) => (
  <FormControl variant="standard" className={rest.styling} fullWidth>
    <InputLabel htmlFor="select-multiple-chip">{rest.labelname}</InputLabel>
    <Select
      variant="standard"
      MenuProps={MenuProps}
      multiple
      displayEmpty
      {...rest}
      name={name}
      inputProps={restInput}
      error={meta.error && meta.touched}
      onChange={onChange}
      value={[...value]}
      fullWidth
    />
  </FormControl>
);
