export const CreatePassengerAlias = (paxId: string): string => {
  let alias = paxId;

  if (alias.startsWith('PAX_')) {
    if (alias.indexOf('INF') === -1) {
      const extractedNumber = parseInt(alias.substring(4));
      alias = 'PAX ' + (extractedNumber + 1);
    }
  }

  return alias;
};
