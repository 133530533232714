import { Box, Button, DialogContent, DialogTitle, TextField, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useState } from 'react';
import { useEffect, useMemo } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { TFF } from '@tff/types';
import { CancelFlightRequest, IFee, TypeOfRefund } from '@tff/types/TFF';
import CustomButton from '../Button';

import BookingChangeSelection from './BookingChangeSelection';

import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Message } from '../../models';
import { keycloak } from '../../keycloak';
import { useChangeBooking } from '../../hooks/use-change-booking';
import { SeatIcon, UserFamilyIcon } from '../icons';
import { useMessages } from '../../hooks/use-messages';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export type SeatsReservation = {
  date: string;
  origin: string;
  destination: string;
  seats: string[];
  totalAmount: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '5px',
      borderColor: theme.palette.text.primary,
      margin: '20px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: '#E2F3FE',
      padding: '10px',
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '20px',
    },
    iconWrapper: {
      height: 40,
      width: 40,
      backgroundColor: theme.palette.text.primary,
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    infoIcon: {
      position: 'absolute',
      right: -10,
      top: -10,
      height: 20,
      width: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#D40E14',
      color: 'white',
      borderRadius: 10,
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      minHeight: '100px',
      width: '100%',
      padding: '20px',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 400,
    },
    accountsRoot: {
      padding: '0px 40px 20px 40px',
    },
    accountTagRoot: {
      height: '71px',
      width: '247px',
      backgroundColor: '#70CBF4',
      borderRadius: '5px',
      padding: '5px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    flightsRoot: {
      borderTopWidth: '2px',
      borderTopColor: theme.palette.text.primary,
      backgroundColor: '#70CBF4',
      borderTopStyle: 'double',
      padding: '10px',
      margin: '0px 20px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    flightDetails: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 10px 0px 30px',
    },
    paxDetails: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 10px 0px 30px',
    },
    seatsRoot: {
      borderTopWidth: '2px',
      borderTopColor: theme.palette.text.primary,
      backgroundColor: '#F0F9FE',
      borderTopStyle: 'double',
      padding: '10px',
      margin: '0px 20px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    upgradesRoot: {
      borderBottomWidth: '2px',
      borderBottomColor: theme.palette.text.primary,
      backgroundColor: '#70CBF4',
      borderBottomStyle: 'double',
      borderTopWidth: '2px',
      borderTopColor: theme.palette.text.primary,
      borderTopStyle: 'double',
      padding: '10px',
      margin: '5px 20px',
    },
    sumRoot: {
      borderBottomWidth: '2px',
      borderBottomColor: 'rgba(9, 42, 94, 0.2)',
      borderBottomStyle: 'double',
      padding: '10px',
      margin: '0px 20px',
    },
    balanceRoot: {
      backgroundColor: '#092A5E',
      borderBottomRightRadius: '5px',
      borderBottomLeftRadius: '5px',
      padding: '10px',
      margin: '0px 20px 20px 20px',
    },

    divider: {
      borderBottomWidth: '2px',
      borderBottomColor: '#E2F3FE',
      borderBottomStyle: 'double',
    },

    totalRoot: {
      margin: '10px 20px',
      borderTopWidth: '2px',
      borderTopColor: theme.palette.text.primary,
      borderTopStyle: 'double',
    },
    tab: {
      '& .MuiTab-wrapper': {
        alignItems: 'flex-start',
      },
    },
    tabLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontWeight: 'bold',
      color: 'white',
    },
    tabNumber: {
      height: '40px',
      width: '40px',
      borderRadius: '20px',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#E2F3FE',
      marginRight: '5px',
    },
    buttonGroup: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '20px',
    },
    button: {
      minWidth: 150,
    },
    selectedButton: {
      '&&': {
        color: 'white',
        backgroundColor: '#092A5E',
      },
    },
    passengersWrapper: {},
    globalSelectionRoot: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    selectFieldRoot: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: 500,
      alignItems: 'center',
      marginRight: '20px',
    },
    fieldName: {
      fontSize: '16px',
      fontWeight: 'bold',
      alignSelf: 'center',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    },
    rootSelect: {
      padding: '0px',
      color: theme.palette.text.primary,
    },
    select: {
      color: theme.palette.text.primary,

      '&:before': {
        borderColor: theme.palette.text.primary,
      },
      '&:after': {
        borderColor: theme.palette.text.primary,
      },
    },
    selectInput: {
      color: '#092A5E',
    },
    queueRoot: {
      padding: '20px',
    },
    queuesWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#F0F9FE',
      padding: '20px',
      margin: '20px 0px',
    },
    textField: {
      fontWeight: 500,
      padding: '10px',
      border: '1px solid #092A5E',
      backgroundColor: 'white',
    },
    multilineColor: {
      color: theme.palette.text.primary,
      fontSize: '18px',
    },
    reasonRoot: {
      backgroundColor: '#F0F9FE',
      padding: '20px',
      margin: '0px 20px',
      display: 'flex',
      flexDirection: 'column',
    },
    textFieldFees: {
      fontWeight: 500,
      width: 100,
      padding: '0px 5px',
      border: '1px solid #092A5E',
      backgroundColor: 'white',
    },
  }),
);

interface props {
  flightDetails: TFF.FlightDetails;
  serviceFee?: IFee;
  bookingSource: TFF.TypeOfTFFBookingSource | 'TUI-NDC-SQ';
  onCancel: () => void;
  onConfirm: () => void;
}

const ChangeBooking: React.FC<props> = ({ flightDetails, serviceFee, onCancel, bookingSource }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState<number>(0);
  const [selectedCancelOnd, setSelectedCancelOnd] = useState<string>('COMPLETE');
  const [changeBookingRequest, setChangeBookingRequest] = useState<TFF.CancelFlightRequest>();
  const [changeBookingResponse, setChangeBookingResponse] = useState<TFF.CancelFlightResponse>();
  const [serviceCharge, setServiceCharge] = useState<number>(serviceFee?.fee ?? 0);
  const [calculatedServiceCharge, setCalculatedServiceCharge] = useState<number>(0);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<any | undefined>(undefined);
  let paxFaresTotal = 0;
  let paxExtrasTotal = 0;
  let paxSeatsTotal = 0;

  const intl = useIntl();

  const { changedBooking, status, error } = useChangeBooking({
    changeBookingRequest: changeBookingRequest,
  });
  const { addMessage } = useMessages({
    recordLocator: flightDetails?.OrderSummary?.RecordLocator,
    bookingSource: bookingSource,
  });

  const refundType: TypeOfRefund = 'TAX_REFUND';
  const [selected, setSelected] = useState<{ [k: string]: boolean } | undefined>({});

  const orderDetails = flightDetails.OrderDetails;
  const orderSummary = flightDetails.OrderSummary;

  useEffect(() => {
    if ((status === 'CHANGED' || status === 'FAILURE') && changeBookingResponse) return;
    if (status) {
      if (changedBooking && !changeBookingResponse) {
        setChangeBookingResponse(changedBooking);
        handleAddNotice();
      }
    }
  }, [status, changeBookingResponse]);

  const ancillaries: TFF.Ancillaries = useMemo(() => {
    return orderDetails.Ancillaries ?? {};
  }, [orderDetails]);

  useEffect(() => {
    if (error) {
      let errorMsg = error.message;

      if (Array.isArray(error)) {
        // in case of NDC sometimes an array of errormessages are received:
        errorMsg = error
          .map<string>(e => {
            return e.DescText;
          })
          .join('\n');
      }
      toast.error(errorMsg, {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [error]);

  useEffect(() => {
    if (selected) {
      const selectedPax = Object.entries(selected).filter(([value, selected]) => value.startsWith('P') && selected);
      const feesMaxValue = selectedPax.length > 1 ? selectedPax.length * serviceCharge : serviceCharge;
      setCalculatedServiceCharge(feesMaxValue);
    }
  }, [selected]);

  const handleCancelBookingSelection = (event: React.MouseEvent<HTMLElement>, selection: string) => {
    if (selection !== null) {
      setSelectedCancelOnd(selection);
    }
  };

  const handleAddNotice = () => {
    const msg = new Message(
      keycloak?.profile?.email ?? 'UNKNOWN',
      orderSummary?.RecordLocator!,
      'CALLCENTER',
      'LOG_ENTRY',
      bookingSource,
      'CANCEL_BOOKING',
    );
    msg.content = 'Cancel Booking';
    addMessage(msg);
  };

  const confirmCancellation = () => {
    const cancelRequest: CancelFlightRequest = {
      action: 'cancel',
      typeOfRefund: refundType,
      bookingSource: bookingSource,
      recordLocator: orderSummary?.RecordLocator!,
      serviceChargeTui: serviceCharge,
      serviceChargeSource: 0,
    };

    if (bookingSource.startsWith('TUI-NDC')) {
      cancelRequest.orderId = orderSummary?.BookingId;
      cancelRequest.ndcAgencyDetails = {
        agencyId: '', // orderDetails?.NdcAgencyDetails?.AgencyId ?? '',
        iataNumber: '', //orderDetails?.NdcAgencyDetails?.IataNumber ?? '',
        officeId: '', //orderDetails?.NdcAgencyDetails?.OfficeId ?? '',
      };
    } else {
      cancelRequest.agent = 'TFFX3';
    }

    setOpenConfirmDialog(cancelRequest);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const renderCancelPax = () => {
    if (!selected) {
      return <div></div>;
    }
    const pax = Object.entries(selected)
      .filter(([value, selected]) => value.startsWith('P') && selected)
      .map(p => p[0]);
    const selectedPax = pax.map(p => p.split('@')).map(p => ({ Pax: orderDetails.Passengers[p[1]], Ond: p[2] }));
    return (
      <div className={classes.flightsRoot} style={{ backgroundColor: '#F0F9FE' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <UserFamilyIcon />
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>PAX</strong>
          </Typography>
        </div>
        <div className={classes.paxDetails}>
          {orderDetails.Journeys.map((j, i) => {
            const pax = selectedPax.filter(p => p.Ond === j.Ond).map(p => p.Pax);
            let paxFaresSum = 0;
            pax.forEach(p => {
              const paxFares: TFF.Fare | undefined = orderDetails?.Fares && orderDetails.Fares[p.Id];
              const journeyFares = paxFares?.FareProducts && paxFares?.FareProducts.find(f => f.JourneyOnd === j.Ond);
              if (journeyFares?.BaseAmount) {
                paxFaresSum += journeyFares.BaseAmount;
              }
            });
            paxFaresTotal += paxFaresSum;
            const paxNames = selectedPax.filter(p => p.Ond === j.Ond).map(p => `${p.Pax.FirstName} ${p.Pax.LastName}`);
            const allPax = pax.length === Object.keys(orderDetails.Passengers).length;
            return (
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                key={i}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  style={{ marginBottom: '10px', display: 'flex', flexDirection: 'row' }}
                >
                  <span style={{ marginRight: '40px' }}>
                    {j?.Segments && moment(j?.Segments[0]?.DepartureTime).format('DD.MM.YYYY')}{' '}
                  </span>
                  <span style={{ marginRight: '20px' }}> {j.Ond} </span>
                  <span style={{ display: 'flex', flexDirection: 'row' }}>
                    {paxNames.length > 0 ? (allPax ? 'ALL PAX' : paxNames.join(', ')) : 'NO CHANGES'}
                  </span>
                </Typography>
                <Typography variant="h6" color="primary">
                  <strong>{-paxFaresSum}</strong>
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderSeats = () => {
    if (!selected) {
      return <div></div>;
    }
    const selectedAncillaries: string[] = Object.entries(selected)
      .filter(([value, selected]) => value.startsWith('A') && selected)
      .map(a => a[0])
      .map(a => a.split('@'))
      .map(a => a[1]);
    const seats: TFF.Ancillary[] = [];
    selectedAncillaries.forEach(a => {
      if (ancillaries[a].Type === 'SEAT') seats.push(ancillaries[a]);
    });
    return (
      <div className={classes.seatsRoot}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SeatIcon />
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px', textTransform: 'uppercase' }}>
            <strong>seats</strong>
          </Typography>
        </div>
        <div className={classes.paxDetails}>
          <div>
            {orderDetails.Journeys.map((j, i) => {
              const segmentsOnds = j?.Segments?.map(s => s.Ond) as string[];
              const Ond = j.Ond;
              const journeyOnds = [...segmentsOnds, Ond];
              const journeySeats = seats.filter(s => journeyOnds.includes(s.JourneyOnd));
              const totalAmount: number[] = journeySeats.map(s => s.TotalAmount) as number[];
              const sum = totalAmount.reduce((a, b) => a + b, 0);
              paxSeatsTotal += sum;
              return (
                <div
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  key={i}
                >
                  <Typography variant="h6" color="primary" style={{ display: 'flex', flexDirection: 'row' }}>
                    <span style={{ marginRight: '40px' }}>
                      {j?.Segments && moment(j?.Segments[0]?.DepartureTime).format('DD.MM.YYYY')}{' '}
                    </span>
                    <span style={{ marginRight: '20px' }}> {j.Ond} </span>
                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                      {journeySeats.length > 0 ? journeySeats.map(s => s.Value).join(', ') : 'NO CHANGES'}
                    </span>
                  </Typography>
                  <Typography variant="h6" color="primary">
                    <strong>{sum > 0 && -sum}</strong>
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderExtras = () => {
    if (!selected) {
      return <div></div>;
    }
    const selectedAncillaries: string[] = Object.entries(selected)
      .filter(([value, selected]) => value.startsWith('A') && selected)
      .map(a => a[0])
      .map(a => a.split('@'))
      .map(a => a[1]);
    const extras: TFF.Ancillary[] = [];
    selectedAncillaries.forEach(a => {
      if (ancillaries[a].Type !== 'SEAT' && ancillaries[a].Type !== 'INF') extras.push(ancillaries[a]);
    });
    return (
      <div className={classes.seatsRoot}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SeatIcon />
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px', textTransform: 'uppercase' }}>
            <strong>extras</strong>
          </Typography>
        </div>
        <div className={classes.paxDetails}>
          <div>
            {orderDetails.Journeys.map((j, i) => {
              const segmentsOnds = j?.Segments?.map(s => s.Ond) as string[];
              const Ond = j.Ond;
              const journeyOnds = [...segmentsOnds, Ond];
              const journeyExtras = extras.filter(e => journeyOnds.includes(e.JourneyOnd));
              const totalAmount: number[] = journeyExtras.map(s => s.TotalAmount) as number[];
              const sum = totalAmount.reduce((a, b) => a + b, 0);
              paxExtrasTotal += sum;
              return (
                <div
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  key={i}
                >
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ marginBottom: '10px', display: 'flex', flexDirection: 'row' }}
                  >
                    <span style={{ marginRight: '40px' }}>
                      {j?.Segments && moment(j?.Segments[0]?.DepartureTime).format('DD.MM.YYYY')}{' '}
                    </span>
                    <span style={{ marginRight: '20px' }}> {j.Ond} </span>
                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                      {journeyExtras.length > 0
                        ? journeyExtras
                            .map(e => (e.Type.startsWith('BA') ? `${e.Description} ${e.Type}` : e.Description))
                            .join(', ')
                        : 'NO CHANGES'}
                    </span>
                  </Typography>
                  <Typography variant="h6" color="primary">
                    <strong>{sum > 0 && -sum}</strong>
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderSumProducts = () => {
    return (
      <div className={classes.upgradesRoot}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>SUM PRODUCTS (inc. TAX)</strong>
          </Typography>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>{paxFaresTotal + paxSeatsTotal + paxExtrasTotal}</strong>
          </Typography>
        </div>
      </div>
    );
  };

  const renderAirlineFees = () => {
    return (
      <div className={classes.sumRoot}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px', textTransform: 'uppercase' }}>
            <strong>Airline Fees (cost & cancellation)</strong>
          </Typography>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>XXX</strong>
          </Typography>
        </div>
      </div>
    );
  };

  const renderNonRefundableTax = () => {
    return (
      <div className={classes.sumRoot}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px', textTransform: 'uppercase' }}>
            <strong>Non refundable Tax</strong>
          </Typography>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>XXX</strong>
          </Typography>
        </div>
      </div>
    );
  };

  const renderNonRefundableExtras = () => {
    return (
      <div className={classes.sumRoot}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px', textTransform: 'uppercase' }}>
            <strong>Non refundable extras</strong>
          </Typography>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>XXX</strong>
          </Typography>
        </div>
      </div>
    );
  };

  const renderServiceFee = () => {
    return (
      <div className={classes.sumRoot} style={{ borderStyle: 'none' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px', textTransform: 'uppercase' }}>
            <strong>Service Fee Cancellation</strong>
          </Typography>
          <TextField
            variant="standard"
            onChange={handleCancellationFees}
            className={classes.textFieldFees}
            InputProps={{
              disableUnderline: true,
              className: classes.multilineColor,
              defaultValue: calculatedServiceCharge,
            }}
            type="number"
          />
        </div>
      </div>
    );
  };

  const renderTotal = () => {
    return (
      <div className={classes.upgradesRoot} style={{ borderBottomStyle: 'none', margin: '0px 20px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>{intl.formatMessage({ id: 'flights.total' })}</strong>
          </Typography>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>{paxFaresTotal + paxSeatsTotal + paxExtrasTotal + calculatedServiceCharge}</strong>
          </Typography>
        </div>
      </div>
    );
  };

  const renderRefunds = () => {
    return (
      <div className={classes.balanceRoot}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" style={{ marginLeft: '10px', color: 'white', textTransform: 'uppercase' }}>
            <strong>{intl.formatMessage({ id: 'flights.refunds' })}</strong>
          </Typography>
          <Typography variant="h6" style={{ marginLeft: '10px', color: 'white' }}>
            <strong>$MISSING</strong>
          </Typography>
        </div>
      </div>
    );
  };

  const handleCancellationFees = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    setServiceCharge(+event.currentTarget.value);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConformConfirmationDialog = () => {
    setOpenConfirmDialog(false);
    setChangeBookingRequest(openConfirmDialog);
  };

  const renderConfirmationDialog = () => {
    return (
      <Dialog
        open={!!openConfirmDialog}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'DEBUG Message: (Cancel Details) '}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <pre>{JSON.stringify(openConfirmDialog, null, 4)}</pre>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog}>Close</Button>
          <Button onClick={handleConformConfirmationDialog} autoFocus>
            Confirm and Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const flightCancellationOverview = () => (
    <div>
      <Typography
        variant="h6"
        color="primary"
        style={{ padding: '20px', textTransform: 'uppercase', display: 'flex', justifyContent: 'space-between' }}
      >
        <strong>{intl.formatMessage({ id: 'flights.cancellationOverview' })}</strong>
        <strong>IN EUR</strong>
      </Typography>
      {renderCancelPax()}
      {renderSeats()}
      {renderExtras()}
      {renderSumProducts()}
      {renderAirlineFees()}
      {renderNonRefundableTax()}
      {renderNonRefundableExtras()}
      {renderServiceFee()}
      {renderTotal()}
      {renderRefunds()}
      <div className={classes.buttonsWrapper}>
        <CustomButton
          variant="contained"
          color="primary"
          size="large"
          onClick={onCancel}
          disabled={status !== undefined}
          style={{ marginRight: '20px' }}
        >
          {intl.formatMessage({ id: 'flights.cancel' })}
        </CustomButton>
        <CustomButton
          variant="contained"
          color="danger"
          size="large"
          onClick={confirmCancellation}
          disabled={status !== undefined}
        >
          {intl.formatMessage({ id: 'flights.confirmCancellation' })}
        </CustomButton>
      </div>
    </div>
  );

  const renderSelection = () => {
    if (!orderDetails?.Journeys?.length) {
      return <></>;
    }
    const journeys: string[] = orderDetails?.Journeys?.map(j => j.Ond) as string[];

    return (
      <div>
        <Typography color="primary" variant="h6">
          <strong>What do you want to cancel?</strong>
        </Typography>
        <ToggleButtonGroup
          className={classes.buttonGroup}
          value={selectedCancelOnd}
          exclusive
          onChange={handleCancelBookingSelection}
          style={{ justifyContent: 'left', marginLeft: '0px' }}
        >
          <ToggleButton value={'COMPLETE'} className={classes.button} classes={{ selected: classes.selectedButton }}>
            Complete Booking
          </ToggleButton>
          {journeys.map((journey, index) => (
            <ToggleButton
              key={index}
              value={journey}
              className={classes.button}
              classes={{ selected: classes.selectedButton }}
            >
              {journey}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <div className={classes.passengersWrapper}>
          <BookingChangeSelection
            // handleCancelSelectionChange={handleCancelSelectionChange}
            setSelectedState={setSelected}
            selected={selected}
            selectedOnd={selectedCancelOnd}
            flight={orderDetails}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={tabValue}
        indicatorColor="primary"
        variant="fullWidth"
        textColor="primary"
        onChange={handleTabChange}
        aria-label="cancellation tabs"
      >
        <Tab
          className={classes.tab}
          label={
            <Typography
              variant="h6"
              className={classes.tabLabel}
              style={{ color: tabValue === 0 ? '#092A5E' : 'white' }}
            >
              <span className={classes.tabNumber} style={{ backgroundColor: tabValue === 0 ? '#092A5E' : 'white' }}>
                1
              </span>
              Change Selection
            </Typography>
          }
        />
        <Tab
          className={classes.tab}
          label={
            <Typography
              variant="h6"
              className={classes.tabLabel}
              style={{ color: tabValue === 1 ? '#092A5E' : 'white' }}
            >
              <span className={classes.tabNumber} style={{ backgroundColor: tabValue === 1 ? '#092A5E' : 'white' }}>
                2
              </span>
              Confirm Changes
            </Typography>
          }
        />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        {renderSelection()}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {flightCancellationOverview()}
      </TabPanel>
      {renderConfirmationDialog()}
      <pre>{JSON.stringify(selected, null, 4)}</pre>
    </div>
  );
};

export default ChangeBooking;
