import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: '0 auto',
      width: '80%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    paper: {
      width: '836px',
      height: '596px',
      margin: 'auto',
      padding: '40px',
      marginBottom: 10,
      display: 'flex',
      backgroundColor: 'rgba(226, 243, 254, 0.75)',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: 100,
      justifyContent: 'space-between',
    },
  }),
);

const ManuallyBooking = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <BuildIcon color="primary" style={{ fontSize: 100 }} />
        <Typography variant="h3" color="primary">
          Under Construction
        </Typography>
      </div>
    </div>
  );
};

export default ManuallyBooking;
