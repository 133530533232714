import * as React from 'react';
import OverviewItem from './OverviewItem';
import { Grid } from '@mui/material';

interface props {
  originalRecords: {}[];
  newRecords: {}[];
  labels: { [key: string]: string };
}
const ChangesOverview: React.FC<props> = ({ originalRecords, newRecords, labels }) => {
  return (
    <Grid item xs={12} style={{ overflowX: 'auto' }}>
      <table style={{ margin: '0 -20px', width: '100%' }}>
        <tbody>
          {newRecords.map((changedRecord, index) => (
            <tr key={'row' + index}>
              <OverviewItem labels={labels} originalRecord={originalRecords[index]} newRecord={changedRecord} />
            </tr>
          ))}
        </tbody>
      </table>
    </Grid>
  );
};
export default ChangesOverview;
