import { Navigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import InvoluntaryChangesAtCom from '../../components/InvoluntaryChangesAtCom';
import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../withRouter';

export const InvoluntaryChangesAtComRoutes = () => {
  const { keycloak } = useKeycloak();

  const isServiceCenterAgentIntern = keycloak.hasRealmRole('Role_Service_Center_Agent_Intern');
  const isItAdmin = keycloak.hasRealmRole('Role_IT_Administrator');

  return (
    <div className="app-wrapper h-100" style={{ padding: '100px' }}>
      <Routes>
        <Route
          path="/"
          element={
            isServiceCenterAgentIntern || isItAdmin ? (
              <InvoluntaryChangesAtCom />
            ) : (
              <Navigate to={'/tff/common/error-403'} />
            )
          }
        />
      </Routes>
    </div>
  );
};

export default withRouter(InvoluntaryChangesAtComRoutes);
