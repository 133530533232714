import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { useState } from 'react';
import { UserFamilyIcon } from '../icons';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIntl } from 'react-intl';
import AccordionActions from '@mui/material/AccordionActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { TFF } from '@tff/types';
import { TypeOfSourceGroup } from '../../models';
import ContactsEditForm from './ContactsEditForm';
import AlertMessage from '../AlertMessage';
import { mapTitlesFromSource } from '../../util/mapTitles';
import AddressTile from './AddressTile';

interface props {
  sourceGroup: TypeOfSourceGroup;
  midocoDetails: TFF.FlightDetails;
  addresses: TFF.Address[];
  handleEditBtn?: () => void;
  allowEdit?: boolean;
  midocoOrderNo: number | undefined;
  flightDetails: TFF.FlightDetails;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '5px',
      borderColor: theme.palette.text.primary,
      margin: '10px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '10px 0px',
    },
    iconWrapper: {
      height: 40,
      width: 40,
      backgroundColor: '#E2F3FE',
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    infoIcon: {
      position: 'absolute',
      right: -10,
      top: -10,
      height: 20,
      width: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#D40E14',
      color: 'white',
      borderRadius: 10,
    },
    menuWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100px',
      width: '100%',
      backgroundColor: '#E2F3FE',
    },
    menuButtonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 400,
    },
    cardsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      margin: 10,
      flexWrap: 'wrap',
      width: '50%',
      alignItems: 'flex-start',
    },
    cardSeparator: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }),
);

const MuiAccordionSummary = withStyles({
  root: {},
  content: {
    margin: '0 !important',
  },
  expanded: {},
  expandIcon: {},
})(AccordionSummary);

const MuiAccordionDetails = withStyles({
  root: {
    backgroundColor: '#E2F3FE',
  },
})(AccordionDetails);

const ContactDetailCard: React.FC<props> = ({
  addresses,
  sourceGroup,
  midocoDetails,
  allowEdit,
  midocoOrderNo,
  flightDetails,
}) => {
  const midocoAddresses =
    midocoDetails?.OrderDetails?.Addresses?.map(mAdr => {
      mAdr.Salutation = mapTitlesFromSource(mAdr.Salutation);
      return mAdr;
    }) ?? [];

  const passengerEditEnabled =
    allowEdit !== false && midocoAddresses.filter(adr => adr.Type === 'INVOICE_ADDRESS').length > 0;
  const classes = useStyles();

  const [showEditForms, setShowEditForms] = useState<boolean>(false);
  const [untouchedAddresses, setUntouchedAddresses] = useState<TFF.Address[]>([
    ...addresses.map(adr => {
      adr.Salutation = mapTitlesFromSource(adr.Salutation);
      return adr;
    }),
  ]);
  const [showSuccess, setShowSuccess] = useState<boolean | string>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const intl = useIntl();

  const resetMessages = (): void => {
    setShowSuccess(false);
    setShowError(false);
  };

  const updateAddresses = (responseData, l) => {
    //console.log(responseData);
    try {
      //NDC no update required
      if (responseData.airlineDetails === 'No update at airline required') {
        setShowSuccess(intl.formatMessage({ id: 'edit.address.NDCSuccess' }));
      } else {
        //NDC returns an object, nsk an array?
        const addressesFromResponse =
          responseData.addresses ?? Object.values(responseData.airlineDetails?.OrderDetails?.Addresses);
        setUntouchedAddresses(addressesFromResponse);
        setShowSuccess(true);
      }

      //todo: midoco address should be updated from response not from local copy...
      midocoDetails.OrderDetails.Addresses = [
        ...(midocoDetails.OrderDetails.Addresses
          ? midocoDetails.OrderDetails.Addresses.filter(adr => adr.Type !== 'INVOICE_ADDRESS')
          : []),
        l,
      ];
    } catch (er) {
      console.error(er);
      setShowError(true);
    }

    setShowEditForms(false);
  };

  const renderHeader = () => {
    return (
      <div className={classes.flexCenter}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div className={classes.iconWrapper}>
            <UserFamilyIcon />
          </div>
          <Typography component={'span'} variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>{intl.formatMessage({ id: 'contactDetails.title' }).toUpperCase()}</strong>
          </Typography>
        </div>
      </div>
    );
  };

  return untouchedAddresses ? (
    <Accordion className={classes.root}>
      <MuiAccordionSummary expandIcon={<ExpandMoreIcon />}>{renderHeader()}</MuiAccordionSummary>
      {!showEditForms && (
        <MuiAccordionDetails className={classes.cardSeparator}>
          <div className={classes.cardsWrapper}>
            {untouchedAddresses.map((a, index) => (
              <AddressTile key={index} address={a} headline={intl.formatMessage({ id: 'contacts.contact' })} />
            ))}
          </div>
          <div className={classes.cardsWrapper}>
            {midocoAddresses
              ?.filter(a => a.Type === 'INVOICE_ADDRESS')
              .map((a, index) => (
                <AddressTile
                  key={index}
                  address={a}
                  headline={'Midoco ' + intl.formatMessage({ id: 'contacts.contact' })}
                />
              ))}
          </div>
        </MuiAccordionDetails>
      )}
      {showEditForms && (
        <MuiAccordionDetails>
          <ContactsEditForm
            flightDetails={flightDetails}
            midocoOrderNo={midocoOrderNo}
            sourceGroup={sourceGroup}
            midocoDetails={midocoDetails}
            onSuccess={(r, l) => updateAddresses(r, l)}
            onBack={() => {
              setShowEditForms(false);
              resetMessages();
            }}
          />
        </MuiAccordionDetails>
      )}
      <Divider></Divider>
      {showSuccess && (
        <AccordionActions>
          <AlertMessage
            message={{
              type: 'success',
              msg: showSuccess === true ? intl.formatMessage({ id: 'edit.passenger.success' }) : showSuccess,
            }}
          />
        </AccordionActions>
      )}
      {showError && (
        <AccordionActions>
          <AlertMessage
            message={{ type: 'error', msg: intl.formatMessage({ id: 'edit.passenger.unexpectedResponse' }) }}
          />
        </AccordionActions>
      )}

      {passengerEditEnabled && !showEditForms && (
        <AccordionActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowEditForms(true);
              resetMessages();
            }}
          >
            {intl.formatMessage({ id: 'edit.address.editAddresses' })}
          </Button>
          {/*<Button variant="contained" color="primary" onClick={handleEdit}>
            {intl.formatMessage({ id: 'edit.address.editAddresses' })}
          </Button>*/}
        </AccordionActions>
      )}
    </Accordion>
  ) : (
    <></>
  );
};
export default ContactDetailCard;
