export type DeferedPaymentProps = {
  tfmPnr?: string;
  product?: ProductsEnum;
  bookingSource?: CarrierEnum;
  origin?: string;
  destination?: string;
  outCabinClasses?: CabinClassEnum;
  departureDateFrom?: string;
  departureDateTo?: string;
  lastTicketingDate?: string;
  midocoOrderId?: string;
};

export enum ProductsEnum {
  ATCOMRES = 'atcomres',
  TFM = 'TFM',
  AIRCRUISER = 'aircruiser',
}

export enum CabinClassEnum {
  Y = 'Y',
  M = 'M',
  W = 'W',
  C = 'C',
  F = 'F',
}

export enum CarrierEnum {
  KL = 'KL',
  EK = 'EK',
  AF = 'AF',
}
