import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

export const AtcomDialog = ({ handleRequest, open, rebookCloser }) => {
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={handleRequest}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">{'Non-TFA Ancillaries Notification'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.formatMessage({ id: 'atcom.DialogText' })}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Button onClick={() => rebookCloser(false)} autoFocus>
            {intl.formatMessage({ id: 'atcom.DialogCancel' })}
          </Button>
        </Box>
        <Box>
          <Button onClick={() => handleRequest(true)} autoFocus>
            {intl.formatMessage({ id: 'atcom.DialogProceed' })}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
