import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
  param: string;
}

const SwitchComponent: React.FC<Props> = ({ param }) => {
  const intl = useIntl();

  switch (param) {
    case 'SPORTS':
      return <span>{intl.formatMessage({ id: 'ancillaries.edit.sportsLuggage' })}</span>;

    case 'HAND_LUGGAGE':
      return <span>{intl.formatMessage({ id: 'ancillaries.edit.handLuggage' })}</span>;

    case 'BAGGAGE':
      return <span>{intl.formatMessage({ id: 'ancillaries.edit.checkedInLuggage' })}</span>;

    case 'ANIMALS':
      return <span>{intl.formatMessage({ id: 'ancillaries.edit.pets' })}</span>;

    case 'MEALS':
      return <span>{intl.formatMessage({ id: 'ancillaries.edit.meals' })}</span>;

    default:
      return null;
  }
};

export default SwitchComponent;
