import React from 'react';
import { Typography } from '@mui/material';

import CustomDialog from '../Dialogs';

interface RefundWarningDialogProps {
  setShowWarning: (value: boolean) => void;
  onConfirm: () => void;
}

export const RefundWarningDialog: React.FC<RefundWarningDialogProps> = ({ setShowWarning, onConfirm }) => {
  return (
    <CustomDialog
      initialOpen={true}
      confirmButtonText="Yes, Accept without Refund"
      onConfirm={() => {
        setShowWarning(true);
        onConfirm();
      }}
      onCancel={() => {
        setShowWarning(false);
      }}
      cancelButtonText="No, back"
      cancelButtonStyle={{
        color: '#09295D',
        border: 'solid 1px #09295D',
      }}
    >
      <Typography variant="h6">
        Refund is not possible.
        <br /> Would you like to proceed without refund?
      </Typography>
    </CustomDialog>
  );
};
