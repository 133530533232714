import { Theme, Typography } from '@mui/material';
import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bookingHeadingContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    bookingHeadingContent: { color: 'white', fontSize: '18px' },
    bookingHeading: {
      fontSize: '16px',
      color: '#DBDBDB',
      textTransform: 'uppercase',
    },
  }),
);

interface HeaderInfoDataProps {
  title: string;
  value: any;
  isHighlighted?: boolean;
}

const HeaderInfoData: React.FC<HeaderInfoDataProps> = ({ title, value, isHighlighted }) => {
  const classes = useStyles();

  return (
    <div className={classes.bookingHeadingContainer}>
      <Typography variant="h6" color="primary" className={classes.bookingHeading}>
        {title}
      </Typography>
      <Typography
        variant="h6"
        className={classes.bookingHeadingContent}
        style={isHighlighted ? { fontSize: '24px' } : {}}
      >
        {isHighlighted ? <strong>{value}</strong> : <>{value}</>}
      </Typography>
    </div>
  );
};

export default HeaderInfoData;
