import * as React from 'react';

export const SeatIcon: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    style={{
      fill: '#092A5E',
      width: '24px',
      height: '30.154px',
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="a"
      d="M29.615,31.615A3.385,3.385,0,1,0,33,28.231,3.385,3.385,0,0,0,29.615,31.615ZM28.077,16.807q0,.02,0,.039t0,.039v9.191a1.231,1.231,0,1,1-2.462,0V23.615a1.09,1.09,0,0,0-2.154,0v7.077a1.846,1.846,0,0,0,1.846,1.846h1.919a5.846,5.846,0,1,1,11.547,0h1.919a1.846,1.846,0,0,0,1.846-1.846V25.5q0-.02,0-.039V23.58a1.093,1.093,0,0,0-2.153.035v2.462a1.231,1.231,0,1,1-2.462,0V15.308a1.846,1.846,0,0,0-3.692,0v4.615H31.769V15.308a1.846,1.846,0,1,0-3.692,0ZM21,37.615V19.154a3.538,3.538,0,0,1,3.538-3.538h1.077v-.308A4.307,4.307,0,0,1,33,12.293a4.307,4.307,0,0,1,7.385,3.014v.308h1.076A3.538,3.538,0,0,1,45,19.154v4.395q0,.033,0,.067v14a3.538,3.538,0,0,1-3.538,3.538H24.385a1.249,1.249,0,0,1-.219-.019A3.539,3.539,0,0,1,21,37.615Zm2.462-17.385a4.172,4.172,0,0,1,2.154,0V18.077H24.538a1.043,1.043,0,0,0-1.077,1.006Zm19.077,0V19.083a1.043,1.043,0,0,0-1.077-1.006H40.385v2.154a4.174,4.174,0,0,1,2.154,0ZM28.233,35.1H25.308a4.386,4.386,0,0,1-1.846-.4v2.951a1.063,1.063,0,0,0,1.077,1.049H41.462a1.063,1.063,0,0,0,1.077-1.049V34.692a4.386,4.386,0,0,1-1.846.4H37.767a5.938,5.938,0,0,1-9.534,0Z"
      transform="translate(-21 -11)"
    />
  </svg>
);
