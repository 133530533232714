import * as React from 'react';
import { IconProps, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

export type messageType = {
  msg?: JSX.Element | string | JSX.Element[];
  type?: 'error' | 'warning' | 'info' | 'success';
  title?: string;
  icon?: IconProps;
};

interface props {
  message?: messageType | string;
}
const AlertMessage: React.FC<props> = ({ message }) => {
  if (!message) {
    return <></>;
  } else {
    const undefinedOrVal = (msgStringOrObject, key) => {
      return typeof msgStringOrObject === 'string' && key === 'msg' ? msgStringOrObject : msgStringOrObject[key];
    };
    return (
      <Alert
        icon={undefinedOrVal(message, 'icon')}
        variant="outlined"
        severity={undefinedOrVal(message, 'type')}
        style={{ alignItems: 'center' }}
      >
        {undefinedOrVal(message, 'title') && (
          <AlertTitle>
            <Typography variant="h5" color={undefinedOrVal(message, 'type') === 'error' ? 'error' : 'textPrimary'}>
              {undefinedOrVal(message, 'title')}
            </Typography>
          </AlertTitle>
        )}
        {typeof message === 'string' || typeof message.msg === 'string' ? (
          <Typography variant="h6" color={undefinedOrVal(message, 'type') === 'error' ? 'error' : 'textPrimary'}>
            {typeof message === 'string' ? message : message.msg}
          </Typography>
        ) : (
          undefinedOrVal(message, 'msg')
        )}
      </Alert>
    );
  }
};
export default AlertMessage;
