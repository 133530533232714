import { TFF } from '@tff/types';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const BookingDetailsTable: FC<{
  details: { [k: string]: any };
  dbRetrieveItem: TFF.Retrieve | undefined;
  orderDetails: TFF.OrderDetails | undefined;
  flightDetails: TFF.FlightDetails | undefined;
}> = ({ details, dbRetrieveItem, flightDetails, orderDetails }): JSX.Element => {
  return (
    <table>
      <tbody>
        {Object.keys(details)
          .filter(infoKey => details[infoKey].display === undefined || details[infoKey].display)
          .sort()
          .map(infoKey => {
            return (
              <tr key={infoKey}>
                <td style={{ paddingRight: '30px', verticalAlign: 'top', paddingTop: '10px', fontWeight: 'bold' }}>
                  {details[infoKey].label ?? infoKey}
                </td>
                {Array.isArray(details[infoKey].value) && (
                  <td style={{ whiteSpace: 'pre', paddingTop: '10px' }}>
                    <table>
                      <tbody>
                        {details[infoKey].value.map(subDetails => {
                          return (
                            <tr key={subDetails[0]}>
                              <td style={{ paddingRight: '30px', verticalAlign: 'top' }}>{subDetails[0]}</td>
                              <td style={{ whiteSpace: 'pre' }}>{subDetails[1] || '-'}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </td>
                )}
                {!Array.isArray(details[infoKey].value) && (
                  <td style={{ whiteSpace: 'pre', paddingTop: '10px' }}>{details[infoKey].value ?? '-'}</td>
                )}
              </tr>
            );
          })}
        {dbRetrieveItem?.product === 'atcomres' && (
          <tr>
            <td style={{ paddingRight: '30px', verticalAlign: 'top', paddingTop: '10px', fontWeight: 'bold' }}>
              ATCOM Details
            </td>
            <td style={{ whiteSpace: 'pre', paddingTop: '10px' }}>
              {`Promotion Code:\t\t${dbRetrieveItem.additionalParams?.promotion.split('$')[0] ?? '-'}
Promotion Name:\t\t${dbRetrieveItem.additionalParams?.promotion.split('$')[1] ?? '-'}
ATCOM-Record-Locator:\t${dbRetrieveItem.additionalParams?.atComResBookingId ?? '-'}`}
            </td>
          </tr>
        )}
        {flightDetails?.OrderSummary?.ChildRecordLocators && (
          <>
            <td
              style={{
                paddingRight: '30px',
                verticalAlign: 'top',
                paddingTop: '10px',
                fontWeight: 'bold',
              }}
            >
              Child PNR
            </td>
            <table>
              <tbody>
                {flightDetails.OrderSummary?.ChildRecordLocators.map((childRecord, i) => {
                  return (
                    <tr key={childRecord}>
                      <td
                        style={{
                          paddingTop: `${i === 0 ? '10px' : ''}`,
                          verticalAlign: 'top',
                        }}
                      >
                        <Link to={`../${childRecord.trim()}@${dbRetrieveItem?.bookingSource}`}>{childRecord}</Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
        {flightDetails?.OrderSummary?.ParentRecordLocator && (
          <>
            <td
              style={{
                paddingRight: '30px',
                verticalAlign: 'top',
                paddingTop: '10px',
                fontWeight: 'bold',
              }}
            >
              Parent PNR
            </td>

            <td style={{ whiteSpace: 'pre', paddingTop: '10px' }} key={flightDetails.OrderSummary.ParentRecordLocator}>
              <Link to={`../${flightDetails.OrderSummary.ParentRecordLocator}@${dbRetrieveItem?.bookingSource}`}>
                {flightDetails.OrderSummary.ParentRecordLocator}
              </Link>
            </td>
          </>
        )}
      </tbody>
    </table>
  );
};

export default BookingDetailsTable;
