import * as React from 'react';
import { useEffect, useContext, useState } from 'react';
import ContainerHeader from '../../../components/ContainerHeader';
import { Button } from '@mui/material';
import CampaignForm from '../../../components/forms/CampaignForm';
import CampaignConfig from '../../../components/CampaignConfig';
import CustomDialog from '../../../components/Dialogs';

import { useDispatch } from 'react-redux';
import FeeFilter from './FeeFilter';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ICampaign } from '@tff/types/TFF';
import { filterActiveFee, IFeeFilter } from './filter-active-fee';
import { useTypedSelector } from '../../../reducers';
import { CAMPAIGN_STATES, saveCampaign, loadCampaigns, resetCampaign } from '../../../reducers/Campaign/campaignSlice';
import { IntlMessages } from '../../../util';
import { LoadingContext } from '../../../contexts/LoadingContextProvider';
import { useLocation } from 'react-router-dom';

const Feeconfigs: React.FC = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { campaigns, campaign, state, campaignLoading } = useTypedSelector(({ campaignData }) => campaignData);
  const [displayedCampaigns, setDisplayedCampaigns] = useState<ICampaign[]>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const location = useLocation();

  useEffect(() => {
    campaignLoading ? showLoading() : closeLoading();
  }, [campaignLoading]);

  useEffect(() => {
    if (location.hash.indexOf('#search') > -1) {
      setShowFilter(true);
    }

    if (campaigns?.length === 0 && state === CAMPAIGN_STATES.UNKOWN) {
      dispatch(loadCampaigns());
    }
    if (campaigns?.length > 0) {
      //dispatch(ResetCampaign());
      setDisplayedCampaigns(campaigns);
    }
  }, [dispatch, campaigns, state]);

  useEffect(() => {
    dispatch(loadCampaigns());
  }, [campaign, dispatch]);

  const handleAddCampaign = (values: ICampaign) => {
    setOpenDialog(false);
    dispatch(saveCampaign(values));
  };

  const handleOpenNewCampaign = () => {
    dispatch(resetCampaign());
    setOpenDialog(true);
  };

  /**
   * @description a little function to help us with reordering the result
   * @param list
   * @param startIndex
   * @param endIndex
   */
  const reorder = (list: ICampaign[] | undefined, startIndex: number, endIndex: number) => {
    const result = Array.from(list as ICampaign[]);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   *
   * @param result
   */
  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(displayedCampaigns, result.source.index, result.destination.index);
    setDisplayedCampaigns(items);
  };

  /**
   *
   * @param values
   */
  const handleFilter = (values: IFeeFilter) => {
    setTimeout(() => {
      setDisplayedCampaigns([]);
    }, 100);

    const activeFee = filterActiveFee(campaigns, values);
    activeFee && (activeFee.selected = true);

    setTimeout(() => {
      setDisplayedCampaigns(campaigns);
    }, 100);
  };

  return (
    <div className="app-wrapper">
      <ContainerHeader
        title={<IntlMessages id="pages.settings.fee-config" />}
        subtitle={<IntlMessages id="pages.settings.fee-config.description" />}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 40 }}>
        <Button variant="contained" color="primary" onClick={handleOpenNewCampaign}>
          <IntlMessages id="pages.fees.new-campaign" />
        </Button>
        <CustomDialog
          initialOpen={openDialog}
          form
          onCancel={() => setOpenDialog(false)}
          onConfirm={() => setOpenDialog(false)}
          width="lg"
        >
          <CampaignForm
            isNew
            onSubmit={(values: ICampaign) => handleAddCampaign(values)}
            onCancel={() => setOpenDialog(false)}
          />
        </CustomDialog>
      </div>
      {/* FeeFilter */}
      {showFilter && <FeeFilter onFilter={handleFilter} />}
      {/* List of Campaigns */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'droppable'}>
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              {displayedCampaigns?.map((campaign, index) => (
                <CampaignConfig campaign={campaign} key={index} index={index} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {/* <ConfigList /> */}
    </div>
  );
};

export default Feeconfigs;
