import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { TFF } from '@tff/types';
import HistoryIcon from '@mui/icons-material/History';
import InfoIcon from '@mui/icons-material/Info';
import CachedIcon from '@mui/icons-material/Cached';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomDialog from '../Dialogs';
import CollapsibleTable from '../CollapsibleTable';
import CommentIcon from '@mui/icons-material/Comment';
import { TicketIcon } from '../icons';
import { Passenger } from '@tff/types/TFF';
import { ISourceConfig } from '../../models';
import FlightMenu from './FlightMenu';
import CustomTooltip from '../CustomTooltip';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';
import BookingDetailsTable from './BookingDetailsTable';
import HeaderInfoData from './HeaderInfoData';
import BookingStatusBadge from './BookingStatusBadge';
import HeaderIconButton from './HeaderIconButton';

export type HistoryTableRow = {
  date: string;
  historyCode: string;
  createdBy: string;
  details: string[];
};

interface props {
  dbRetrieveItem?: TFF.Retrieve;
  flightDetails: TFF.FlightDetails;
  sourceConfig?: ISourceConfig;
  midocoFlightDetails?: TFF.FlightDetails;
  onMenuClicked: (buttonId: string) => void;
  hasFlightChanges: boolean;
  disableButtons: boolean;
  isShrunk: boolean;
  midocoOrderNo?: number;
  serviceFeeTotalAmount: number;
  activeButton: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerRoot: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: '#092A5E',
    },
    menuRoot: {
      width: '100%',
      backgroundColor: '#E2F3FE',
      border: '1px solid #BABABA',
    },
    flexStretch: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '10px 20px',
      alignItems: 'stretch',
      gap: '20px',
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    iconWrapper: {
      height: 40,
      width: 40,
      backgroundColor: '#70CBF4',
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    totalHeader: {
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '40px',
      width: '120px',
      borderWidth: '1px',
      borderColor: 'white',
      borderStyle: 'double',
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    totalSum: {
      backgroundColor: '#70CBF4',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '40px',
      width: '144px',
      borderWidth: '1px 1px 1px 0px',
      borderColor: 'white',
      borderStyle: 'double',
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    table: {
      minWidth: 650,
    },
    headerTitle: {
      marginLeft: '10px',
      marginRight: '10px',
      color: 'white',
      fontSize: '25px',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      [theme.breakpoints?.down('md')]: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '120px',
      },
    },
    commentsTable: {
      minWidth: 700,
    },
    shrunkenHeaderMenu: {
      backgroundColor: '#092A5E',
      width: '100%',
      alignItems: 'center',
    },
    shrunkenHeaderInfo: {
      alignItems: 'flex-start',
      minWidth: '500px',
      padding: '0 20px',
    },
    headerMenu: {
      opacity: 1,
      visibility: 'visible',
      marginRight: 0,
      transitionProperty: 'visibility opacity',
      transitionDuration: '0.1s',
    },
    responsiveWidth: {
      [theme.breakpoints?.down('md')]: {
        width: '95%',
      },
      [theme.breakpoints?.up('md')]: {
        width: '85%',
      },
      [theme.breakpoints?.up('lg')]: {
        width: '60%',
      },
    },
  }),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 15,
      padding: 2,
    },
    head: {
      backgroundColor: '#092A5E',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const BookingDetailHeader: React.FC<props> = ({
  flightDetails,
  sourceConfig,
  midocoFlightDetails,
  onMenuClicked,
  hasFlightChanges,
  disableButtons,
  isShrunk,
  midocoOrderNo,
  dbRetrieveItem,
  serviceFeeTotalAmount,
  activeButton,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const [groupedOrderHistory, setGroupedOrderHistory] = useState<Record<string, TFF.OrderHistory[]>>({});
  const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(false);
  const [openOrderDetailsDialog, setOpenOrderDetailsDialog] = useState<boolean>(false);
  const [openCommentsDialog, setOpenCommentsDialog] = useState<boolean>(false);

  const numberFormatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 });

  const orderDetails = flightDetails.OrderDetails;
  const orderHistory = flightDetails.OrderHistory;
  const orderSummary = flightDetails.OrderSummary;

  const paymentMethod = useMemo(() => {
    if (!midocoFlightDetails?.OrderDetails.Payments) {
      return null;
    }
    const payment = midocoFlightDetails?.OrderDetails.Payments[0];
    return payment?.Method;
  }, [midocoFlightDetails]);

  useEffect(() => {
    if (orderHistory) {
      const result = groupBy(orderHistory, (item: TFF.OrderHistory) => {
        return [item.Date, item.HistoryCode, item.CreatedBy];
      });
      setGroupedOrderHistory(result);
    }
  }, [orderHistory]);

  const groupBy = (array: TFF.OrderHistory[], f: (item: TFF.OrderHistory) => (string | Date)[]) => {
    const groups: Record<string, TFF.OrderHistory[]> = {};
    array.forEach(o => {
      const group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });

    return groups;
  };

  const formatBookingDate = (): React.JSX.Element => {
    let bDate = orderSummary?.BookingDate;
    let needsPostfix = false;
    if (bDate?.endsWith('*')) {
      bDate = bDate.substring(0, bDate.length - 1);
      needsPostfix = true;
    }
    if (bDate) {
      return needsPostfix ? (
        <CustomTooltip title={'Date of booking from retrieve'}>
          <div>
            {format(new Date(bDate), 'dd.MM.yyyy HH:mm')}
            <small>*</small>
          </div>
        </CustomTooltip>
      ) : (
        <>{format(new Date(bDate), 'dd.MM.yyyy HH:mm')}</>
      );
    }
    return <></>;
  };

  const renderTicketIcon = () => {
    return (
      <div className={classes.iconWrapper}>
        <TicketIcon />
      </div>
    );
  };

  const renderHistoryDialog = () => {
    const columns = ['Date', 'Historycode', 'Created by'];
    const data = groupedOrderHistory
      ? Object.entries(groupedOrderHistory)?.map(item => {
          const [date, historyCode, createdBy] = JSON.parse(item[0]);
          const details = item[1].map(item => item.Details);
          return { date: format(new Date(date), 'dd.MM.yyyy HH:mm'), historyCode, details, createdBy };
        })
      : [];
    return (
      <CustomDialog
        onCancel={() => setOpenHistoryDialog(false)}
        confirmButton={false}
        initialOpen={openHistoryDialog}
        width="lg"
        confirmButtonText={intl.formatMessage({ id: 'pages.flightCombination.addNote' })}
        cancelButtonText={intl.formatMessage({ id: 'pages.flightCombination.close' })}
        title={
          <Typography variant="h5" align="left">
            {intl.formatMessage({ id: 'pages.flightCombination.detailsRecordlocator' })} : {orderSummary?.RecordLocator}
          </Typography>
        }
      >
        <div style={{ width: '60%' }}>
          <CollapsibleTable columns={columns} data={data} />
        </div>
      </CustomDialog>
    );
  };

  const renderCommentsDialog = () => {
    return (
      <CustomDialog
        onCancel={() => setOpenCommentsDialog(false)}
        confirmButton={false}
        initialOpen={openCommentsDialog}
        width="lg"
        title={
          <Typography variant="h5" align="left">
            {'Comments for Flight'} : {orderSummary?.RecordLocator}
          </Typography>
        }
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Comment</StyledTableCell>
                <StyledTableCell>Created by</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderDetails?.Comments?.map((comment, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {format(new Date(comment.date), 'dd.MM.yyyy HH:mm')}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {comment.text}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {comment.createdBy}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomDialog>
    );
  };

  const formatDateTime = (t?: string | Date): string | null | Date => {
    if (t) {
      try {
        if (t instanceof Date) {
          return t.toLocaleString();
        } else {
          return new Date(t).toLocaleString();
        }
      } catch (er) {
        return t;
      }
    }
    return null;
  };

  const renderBookingDetailsDialog = () => {
    const details = {
      'TFM-Record-Locator': {
        value: dbRetrieveItem?.tfmPnr,
      },
      Product: {
        value: dbRetrieveItem?.product,
        label: intl.formatMessage({ id: 'pages.flightDetails.bookingDetails.product' }),
      },
      'Midoco-ID': {
        value: midocoOrderNo,
      },
      'Channel-Details': {
        value: dbRetrieveItem?.channel, //channel-details (SkyScanner, L'Tur)
      },
      'Cancellation-Date': {
        label: intl.formatMessage({ id: 'pages.flightDetails.bookingDetails.cancelDate' }),
        value: formatDateTime(dbRetrieveItem?.cancellationDate),
        display: dbRetrieveItem && dbRetrieveItem.status === 'CANCELLED' && dbRetrieveItem.cancellationDate,
      },
      'Source-Info': {
        value: Object.entries(flightDetails?.OrderSummary?.SourceDetails ?? { '-': '-' }),
      },
      'Ticket Time Limit': {
        label: intl.formatMessage({ id: 'pages.flightDetails.bookingDetails.ticketTimeLimit' }),
        value: [
          ['Retrieve Changed', formatDateTime(dbRetrieveItem?.ticketTimeLimit)],
          ['Retrieve Original', formatDateTime(dbRetrieveItem?.ticketTimeLimitAirline)],
          ['Airline', formatDateTime(orderSummary?.PaymentTimeLimit)],
        ],
        display: !sourceConfig?.SourceGroup.includes('NSK'),
      },
      'Ticketed Date': {
        value: formatDateTime(dbRetrieveItem?.ticketedAt),
        label: intl.formatMessage({ id: 'pages.flightDetails.bookingDetails.ticketedDate' }),
      },
      'Payment Status': {
        value: orderSummary?.PaymentStatus,
        label: intl.formatMessage({ id: 'pages.flightDetails.bookingDetails.PaymentStatus' }),
      },
      'Payment Method': {
        value: paymentMethod?.includes(':') ? paymentMethod?.split(':')[0] : paymentMethod,
        label: intl.formatMessage({
          id: 'bookingDetailCard.paymentMethod',
        }),
      },
    };

    if (dbRetrieveItem?.bookingSource) {
      if (dbRetrieveItem.bookingSource === 'NDCX') {
        details['Amadeus-Record-Locator'] = {
          value: dbRetrieveItem?.sourceRecordLocator,
          label: 'Amadeus Record Locator',
        };
      } else if (['AF', 'KL', 'EK', 'SQ'].indexOf(dbRetrieveItem.bookingSource) > -1) {
        details['Order-Id'] = {
          value: dbRetrieveItem?.orderId,
          label: 'Order Id',
        };
      } else {
        if (dbRetrieveItem?.sourceRecordLocator) {
          details['Source-Record-Locator'] = {
            value: dbRetrieveItem?.sourceRecordLocator,
            label: 'Source Record Locator',
          };
        }
      }
    }
    if (dbRetrieveItem?.airlineRecordLocator) {
      details['Airline-Record-Locator'] = {
        value: dbRetrieveItem?.airlineRecordLocator,
        label: 'Airline Record Locator',
      };
    }

    return (
      <CustomDialog
        onCancel={() => setOpenOrderDetailsDialog(false)}
        confirmButton={false}
        initialOpen={openOrderDetailsDialog}
        closeOnBackdropClick
        cancelButtonText={intl.formatMessage({ id: 'bookingDetailCard.bookingDetails.close' })}
      >
        <Typography variant="h5" style={{ fontWeight: 700 }} align="left">
          {intl.formatMessage({ id: 'bookingDetailCard.bookingDetails' })} :{' '}
          {dbRetrieveItem?.sourceRecordLocator ?? orderSummary?.RecordLocator}
        </Typography>
        <BookingDetailsTable
          flightDetails={flightDetails}
          orderDetails={orderDetails}
          dbRetrieveItem={dbRetrieveItem}
          details={details}
          key={dbRetrieveItem?.orderId}
        />
      </CustomDialog>
    );
  };

  const renderHeader = () => {
    const total =
      (orderSummary?.TotalFarePrice ?? 0) + serviceFeeTotalAmount + (orderSummary?.TotalAncillaryPrice ?? 0);

    return (
      <div className={`${classes.flexStretch} ${classes.responsiveWidth}`}>
        <div className={classes.flexCenter}>
          {renderTicketIcon()}
          <Typography variant="h6" className={classes.headerTitle}>
            {intl.formatMessage({ id: 'bookingDetailCard.bookingDetails' })}
          </Typography>
          <HeaderIconButton
            title={intl.formatMessage({ id: 'bookingDetailCard.history.hint' })}
            label="history"
            handleOnClick={() => setOpenHistoryDialog(true)}
            icon={<HistoryIcon />}
          />
          {orderDetails.Comments && (
            <HeaderIconButton
              title={intl.formatMessage({ id: 'bookingDetailCard.comments.hint' })}
              label="comments"
              handleOnClick={() => setOpenCommentsDialog(true)}
              icon={<CommentIcon />}
            />
          )}
          <HeaderIconButton
            title={intl.formatMessage({ id: 'bookingDetailCard.orderDetails.hint' })}
            label="details"
            handleOnClick={() => setOpenOrderDetailsDialog(true)}
            icon={<InfoIcon />}
          />
          <HeaderIconButton
            title={intl.formatMessage({ id: 'bookingDetailCard.history.reload' })}
            label="history"
            handleOnClick={() => navigate(0)}
            icon={<CachedIcon />}
          />
          {dbRetrieveItem?.targetEnvironment &&
            window.ENV?.STAGE &&
            dbRetrieveItem?.targetEnvironment !== window.ENV?.STAGE && (
              <Typography variant="h4" color="error" align={'right'}>
                ‼️{dbRetrieveItem?.targetEnvironment}
              </Typography>
            )}
        </div>
        <div className={classes.flexCenter} style={{ gap: '16px' }}>
          <Typography variant="h6" color="white" style={{ fontSize: '16px', textTransform: 'uppercase' }}>
            {intl.formatMessage({ id: 'bookingDetailCard.status' })}
          </Typography>
          <BookingStatusBadge
            bookingStatus={orderSummary?.BookingStatus}
            bookingSource={dbRetrieveItem?.bookingSource}
          />
        </div>
        <div className={classes.flexCenter}>
          <div className={classes.totalHeader}>
            <Typography
              variant="h6"
              color="primary"
              style={{ color: 'white', fontSize: '15px', textTransform: 'uppercase' }}
            >
              <span>{intl.formatMessage({ id: 'bookingDetailCard.total' })}</span>{' '}
              {intl.formatMessage({ id: 'bookingDetailCard.in' })} {orderSummary?.Currency}
            </Typography>
          </div>
          <div className={classes.totalSum}>
            <Typography variant="h6" color="primary" style={{ color: 'white', fontSize: '24px' }}>
              <strong>{numberFormatter.format(total)}</strong>
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const renderBookingDetail = () => {
    const firstPassenger: Passenger = Object.values(orderDetails.Passengers).find(
      passenger => !/INF/.test(passenger.Id),
    ) as Passenger;
    // @ts-ignore
    const ddogUrl: string = `https://tui-observability.datadoghq.eu/logs?query=index%3Aflightsourcing-tff-index-15%20account_id%3A${
      window.ENV?.ACC_NO
    }%20%28%40booking_no%3A${orderSummary?.BookingId}%20OR%20%40pnr%3A${
      dbRetrieveItem?.sourceRecordLocator || orderSummary?.RecordLocator
    }%20OR%20%40tfmPnr%3A${
      orderSummary?.TfmPnr
    }%29&additional_filters=%5B%7B%7D%5D&cols=service%2C%40pnr%2C%40tfmPnr%2C%40booking_no%2C%40action&index=flightsourcing-tff-index-15&messageDisplay=inline&saved-view-id=27663&stream_sort=time%2Cdesc&viz=stream&from_ts=${
      Date.now() - 900000 /* 15 min */
    }&to_ts=${Date.now()}&live=false`;

    const airlineRecordLocator =
      dbRetrieveItem?.orderId || dbRetrieveItem?.airlineRecordLocator || orderSummary?.RecordLocator;
    const sourceRecordLocator = dbRetrieveItem?.sourceRecordLocator || orderSummary?.RecordLocator;

    return (
      <div className={`${classes.flexStretch} ${classes.responsiveWidth}`} style={{ padding: '20px 20px 10px' }}>
        <HeaderInfoData
          title="Record Locator"
          value={
            window.ENV && keycloak.hasRealmRole('Role_IT_Administrator') ? (
              <a href={ddogUrl} target="blank">
                {sourceRecordLocator}
              </a>
            ) : (
              sourceRecordLocator
            )
          }
          isHighlighted
        />
        <HeaderInfoData
          title={intl.formatMessage({ id: 'bookingDetailCard.bookingId' })}
          value={
            window.ENV && keycloak.hasRealmRole('Role_IT_Administrator') ? (
              <a href={ddogUrl} target="blank">
                {airlineRecordLocator}
              </a>
            ) : (
              airlineRecordLocator
            )
          }
        />
        <HeaderInfoData
          title={intl.formatMessage({ id: 'bookingDetailCard.airlineCode' })}
          value={orderSummary?.DesignatedAirlineCode}
        />
        <HeaderInfoData
          title={intl.formatMessage({ id: 'bookingDetailCard.name1passenger' })}
          value={firstPassenger.FirstName + ' ' + firstPassenger.LastName}
        />
        <HeaderInfoData
          title={intl.formatMessage({ id: 'bookingDetailCard.bookingChannel' })}
          value={sourceConfig?.BookingSource}
        />
        <HeaderInfoData
          title={intl.formatMessage({ id: 'bookingDetailCard.bookingDate' })}
          value={formatBookingDate()}
          isHighlighted
        />
      </div>
    );
  };

  const renderMenu = () => {
    return (
      <div className={classes.headerMenu}>
        <FlightMenu
          handleMenuClicked={onMenuClicked}
          product={dbRetrieveItem?.product}
          orderSummary={orderSummary as TFF.OrderSummary}
          hasFlightChanges={hasFlightChanges}
          disableButtons={disableButtons}
          isShrunk={isShrunk}
          activeButton={activeButton}
          flightRetrieve={dbRetrieveItem}
        />
      </div>
    );
  };

  const renderShrunkenHeaderMenu = () => {
    const total =
      (orderSummary?.TotalFarePrice ?? 0) + serviceFeeTotalAmount + (orderSummary?.TotalAncillaryPrice ?? 0);

    return (
      <div className={`${classes.shrunkenHeaderMenu} ${classes.flexStretch}`}>
        <div className={`${classes.flexStretch} ${classes.shrunkenHeaderInfo}`}>
          <HeaderInfoData title="Record Locator" value={orderSummary?.RecordLocator} isHighlighted />
          <HeaderInfoData
            title={intl.formatMessage({ id: 'bookingDetailCard.bookingDate' })}
            value={formatBookingDate()}
            isHighlighted
          />
          <HeaderInfoData
            title={intl.formatMessage({ id: 'bookingDetailCard.status' })}
            value={
              <BookingStatusBadge
                bookingStatus={orderSummary?.BookingStatus}
                bookingSource={dbRetrieveItem?.bookingSource}
              />
            }
          />
          <HeaderInfoData
            title={`${intl.formatMessage({ id: 'bookingDetailCard.total' })} ${intl.formatMessage({
              id: 'bookingDetailCard.in',
            })} ${orderSummary?.Currency}`}
            value={numberFormatter.format(total)}
            isHighlighted
          />
        </div>
        <FlightMenu
          handleMenuClicked={onMenuClicked}
          orderSummary={orderSummary as TFF.OrderSummary}
          hasFlightChanges={hasFlightChanges}
          disableButtons={disableButtons}
          isShrunk={isShrunk}
          activeButton={activeButton}
        />
      </div>
    );
  };

  const renderMainMenu = () => {
    return (
      <div className={classes.headerRoot}>
        {renderHeader()}
        {isShrunk ? renderShrunkenHeaderMenu() : renderBookingDetail()}
        {renderHistoryDialog()}
        {renderCommentsDialog()}
        {openOrderDetailsDialog && renderBookingDetailsDialog()}
        {!isShrunk && <div className={classes.menuRoot}>{renderMenu()}</div>}
      </div>
    );
  };

  return <div style={{ width: '100%' }}>{renderMainMenu()}</div>;
};

export default BookingDetailHeader;
