interface RebookSearchState {
  Outbound: string[][];
  Return: string[][];
}

interface RefinedObject {
  Outbound: string;
  Inbound: string;
}

export const findNewOnds = (rebookSearchState: RebookSearchState): RefinedObject => ({
  Outbound: rebookSearchState.Outbound[0]?.slice(1, 3).join('') || '',
  Inbound: rebookSearchState.Return[0]?.slice(1, 3).join('') || '',
});
