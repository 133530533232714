import { ThemeOptions } from '@mui/material';

const customTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#092A5E',
    },
    secondary: {
      main: '#70CBF4',
    },
    info: {
      main: '#E2F3FE',
    },
    error: {
      main: '#D40E14',
      contrastText: '#FFF',
    },
  },
  typography: {
    fontFamily: 'tuitype',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1600,
      xl: 1920,
    },
  },
};
export default customTheme;
