import { isEqual } from 'lodash';

/**
 * returns a new Object with only changed key-values compared to another object
 * @param {object} newO - The first object with approximately changed values
 * @param {object} oldO - The second object to compare the first objects entries to
 * @returns {object} - Returns an object with changed entries only
 */
const changedOnly = (newO, oldO) => {
  const newKeys = Object.keys(newO);
  const newCopy = { ...newO };

  newKeys.forEach(key => {
    if (isEqual(oldO[key], newO[key])) {
      delete newCopy[key];
    }
  });

  return newCopy;
};

export default changedOnly;
