import * as React from 'react';

export const TicketIcon: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    style={{
      fill: '#092A5E',
      width: '24px',
      height: '24px',
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="a"
      d="M16.493,27.747a2.9,2.9,0,0,1,.566,3.294l.8.8L31.84,17.857l-.8-.8a2.9,2.9,0,0,1-3.86-3.86l-.8-.8L12.4,26.383l.8.8A2.9,2.9,0,0,1,16.493,27.747Zm-4.173,1.216-2.038-2.038a.961.961,0,0,1,0-1.359L25.567,10.281a.961.961,0,0,1,1.359,0l2.038,2.038a.961.961,0,0,1,0,1.359.961.961,0,0,0,1.359,1.359.961.961,0,0,1,1.359,0l2.038,2.038a.961.961,0,0,1,0,1.359L18.433,33.719a.961.961,0,0,1-1.359,0l-2.038-2.038a.961.961,0,0,1,0-1.359.961.961,0,0,0-1.359-1.359A.961.961,0,0,1,12.319,28.963Z"
      transform="translate(-10 -10)"
    />
  </svg>
);
