import * as React from 'react';
import { useEffect, useContext, useState } from 'react';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { ConfirmBookingRequest, ConfirmBookingResponse } from '@tff/types/TFF/confirm-booking-handler';
import { apigeeEndpoint } from '../apis';

export type TypeOfStatus = 'IN_CONFIRMATION' | 'CONFIRMED' | 'FAILURE';

export type UseConfirmResponse = {
  confirmResponse?: ConfirmBookingResponse;
  confirmStatus?: TypeOfStatus;
  confirmError?: Error | undefined;
  setConfirmBookingRequest: (confirmBookingRequest: ConfirmBookingRequest) => void;
};

//
export const useConfirmBooking = (): UseConfirmResponse => {
  const [confirmStatus, setConfirmStatus] = React.useState<TypeOfStatus>();
  const [confirmResponse, setConfirmResponse] = React.useState<ConfirmBookingResponse>();
  const [confirmError, setConfirmError] = React.useState<Error | undefined>();
  const [confirmBookingRequest, setConfirmBookingRequest] = useState<ConfirmBookingRequest | undefined>(undefined);
  const { showLoading, closeLoading } = useContext(LoadingContext);

  useEffect(() => {
    const confirmBookingAtBackend = async (confirmRequest: ConfirmBookingRequest): Promise<void> => {
      try {
        setConfirmStatus('IN_CONFIRMATION');
        showLoading(`Confirm ${confirmRequest.bookingSource} Booking`);

        const response = await apigeeEndpoint.post(`/confirm`, confirmRequest).catch(err => {
          setConfirmError(err);
          return err;
        });

        if (!response || response.data.status === 'FAILURE') {
          setConfirmStatus('FAILURE');
          setConfirmError(response.data.error);
        } else if (response.data.status === 'SUCCESS') {
          setConfirmStatus('CONFIRMED');
          setConfirmResponse(response.data.data ?? response.data);
        }
      } catch (error) {
        setConfirmStatus('FAILURE');
      } finally {
        closeLoading();
      }
    };

    if (
      confirmBookingRequest &&
      confirmBookingRequest.recordLocator &&
      (!confirmStatus || confirmStatus === 'FAILURE')
    ) {
      void confirmBookingAtBackend(confirmBookingRequest);
    }
  }, [confirmBookingRequest]);

  return { setConfirmBookingRequest, confirmError, confirmResponse, confirmStatus };
};
