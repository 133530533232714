import * as React from 'react';
import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface overviewProps {
  originalRecord: any;
  newRecord: any;
  labels: { [key: string]: string };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accent: {
      color: theme.palette.error.main,
    },
    centered: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tdTop: {
      verticalAlign: 'top',
      padding: '20px',
    },
  }),
);

const OverviewItem = (props: overviewProps) => {
  const classes = useStyles();

  const { originalRecord, newRecord, labels } = props;

  const wrapRedIfChanged = (newData, oldData) => {
    if (newData !== oldData && newData) {
      return (
        <Typography variant="body1">
          {oldData} <ArrowForwardIcon fontSize="small" /> <span className={classes.accent}>{newData}</span>
        </Typography>
      );
    } else {
      return <Typography variant="body1">{newData}</Typography>;
    }
  };
  const cellContent = fieldName => {
    return (
      <td className={classes.tdTop} key={fieldName}>
        <div style={{ whiteSpace: 'nowrap' }}>
          <Typography variant="caption" display="block" gutterBottom>
            {labels[fieldName]}
          </Typography>
          {wrapRedIfChanged(
            newRecord[fieldName] === undefined && newRecord[fieldName.split('.')[0]]
              ? newRecord[fieldName.split('.')[0]][fieldName.split('.')[1]]
              : newRecord[fieldName],
            originalRecord[fieldName] === undefined && originalRecord[fieldName.split('.')[0]]
              ? originalRecord[fieldName.split('.')[0]][fieldName.split('.')[1]]
              : originalRecord[fieldName],
          )}
        </div>
      </td>
    );
  };

  return <>{Object.keys(labels).map(fieldName => cellContent(fieldName))}</>;
};

export default React.memo(OverviewItem);
