import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Paper,
  Container,
  Box,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  RadioProps,
  Alert,
  Stack,
  Snackbar,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { TFF } from '@tff/types';
import RenderFlightTrack from './RenderFlightTrack';
import { ErrorOutline } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IntlMessages } from '../../util';
import { TypeOfStatus } from '../../hooks/use-amend-booking';
import {
  MuiAccordion,
  MuiAccordionDetails,
  MuiAccordionSummary,
  SelectRadioIcon,
  SelectRadioCheckedIcon,
  useStyles,
} from './AccordionStyles';

const SelectRadio = (props: RadioProps) => {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<SelectRadioCheckedIcon />}
      icon={<SelectRadioIcon />}
      {...props}
    />
  );
};

type RenderFlightsProps = {
  originalFlight: TFF.OrderDetails;
  flights: TFF.Offer[];
  airports: TFF.IAirport[];
  handleSelectedAmendBookings: (selectedFlexableId: string) => void;
  amendStatus?: TypeOfStatus;
  renderError: TFF.Offer[];
};

const RenderFlights: React.FC<RenderFlightsProps> = ({
  originalFlight,
  flights,
  airports,
  handleSelectedAmendBookings,
  amendStatus,
  renderError,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const passengers = Object.values(originalFlight?.Fares || {})
    .map((fare: { FareProducts: TFF.FareProduct[] }) => fare.FareProducts)
    .filter(Boolean);

  const sums: { BaseAmount: number }[] = [];

  passengers.forEach((passengerArray, index) => {
    passengerArray.forEach((passenger, objIndex) => {
      if (!sums[objIndex]) {
        sums[objIndex] = { BaseAmount: 0 };
      }
      if (sums[objIndex] && passenger && typeof passenger.BaseAmount === 'number') {
        sums[objIndex].BaseAmount += passenger.BaseAmount;
      }
    });
  });

  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});
  const [fareFamily, setFareFamily] = useState<boolean>(false);

  const flightGroups: { [key: string]: TFF.Offer[] } = {};

  // Iterate through the flights and group them
  for (const flight of flights) {
    const segment = flight.Journey[0].Segments[0];
    const key = `${segment.DepartureTime}_${segment.Ond}`;

    if (!flightGroups[key]) {
      flightGroups[key] = [];
    }

    flightGroups[key].push(flight);
  }

  const groupedFlights: TFF.Offer[][] = Object.values(flightGroups);

  const handleChange = (setId: string, value: string) => {
    setSelectedValues({ [setId]: value });
  };

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      handleSelectedAmendBookings(Object.values(selectedValues)[0]);
    }
  }, [selectedValues]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {groupedFlights.length > 0
        ? groupedFlights.map((flight, index) => {
            const firstFlight = flight[0];
            return (
              <Paper
                key={index}
                elevation={2}
                className={classes.root}
                sx={{
                  borderColor: selectedValues[firstFlight.Id] ? '#1A7EAD' : '#D6D1C2',
                  marginY: '24px',
                }}
              >
                <Container sx={{ padding: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {firstFlight.Journey &&
                      airports &&
                      firstFlight.Journey.map((singleJourney, index) => (
                        <RenderFlightTrack key={index} journey={singleJourney} airports={airports} />
                      ))}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      {Object.keys(firstFlight.Ancillaries ?? {})
                        .slice(0, 3)
                        .map((key, index) => (
                          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <div style={{ width: '40px', textAlign: 'center' }}>
                              <ErrorOutline sx={{ color: '#00308F', fontSize: '24px' }} />
                            </div>
                            <div style={{ fontSize: '16px', fontWeight: 400 }}>
                              {firstFlight.Ancillaries?.[key].Type}
                            </div>
                          </div>
                        ))}
                    </div>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid #DBDBDB',
                        borderRadius: '4px',
                        padding: '16px',
                        height: '100px',
                      }}
                    >
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={selectedValues[firstFlight.Id] || ''}
                          onChange={e => handleChange(firstFlight.Id, e.target.value)}
                        >
                          {flight.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              value={item.Id}
                              control={<SelectRadio />}
                              onChange={e => {
                                e.preventDefault();
                                item.FareFamily === 'Perfect' && setFareFamily(true);
                              }}
                              label={
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '198px',
                                  }}
                                >
                                  <span style={{ fontSize: '18px', fontWeight: 400 }}>
                                    {item.FareFamily === 'Pure' ? 'Pure fare' : 'Perfect tariff'}
                                  </span>
                                  <strong
                                    style={{
                                      marginLeft: '10px',
                                      fontSize: '18px',
                                    }}
                                  >
                                    {item.TotalPriceDiff === 0 ? '0.00 €' : `${item.TotalPriceDiff?.toFixed(2)} €`}
                                  </strong>
                                </div>
                              }
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                  <div className={classes.flightDetailRoot}>
                    <MuiAccordion>
                      <MuiAccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: '#0076B5' }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          padding: '0px 0px 0px 5px',
                        }}
                      >
                        <Typography component={'span'} className={classes.heading} style={{ fontSize: '18px' }}>
                          <IntlMessages id="pages.flightDetails.flightDetails" />
                        </Typography>
                      </MuiAccordionSummary>
                      <MuiAccordionDetails>
                        <Container sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                          {firstFlight.Journey &&
                            airports &&
                            firstFlight.Journey.map((singleJourney, index) => (
                              <>
                                <RenderFlightTrack key={index} journey={singleJourney} airports={airports} />
                                <Box sx={{ marginLeft: 20 }}>
                                  Flight Number:{' '}
                                  {singleJourney.Segments.map(segment => {
                                    return segment.FlightNumber;
                                  })}
                                  <span style={{ marginLeft: '5px', marginRight: '5px' }}>-</span>
                                  Type:{' '}
                                  {singleJourney.Segments.map(segment => {
                                    return segment.MarketingCarrier;
                                  })}
                                  <br />
                                  <span style={{ fontSize: '24px' }} className="tui-icon tui-icon--seat">
                                    {' '}
                                  </span>
                                  {singleJourney.Segments.map(segment => {
                                    return intl.formatMessage({ id: `cabinClass.${segment.CabinClass}` });
                                  })}
                                </Box>
                              </>
                            ))}
                        </Container>
                      </MuiAccordionDetails>
                    </MuiAccordion>
                  </div>
                </Container>
              </Paper>
            );
          })
        : renderError.length > 0 && (
            <Stack sx={{ width: '100%', margin: 1 }} spacing={2}>
              <Alert variant="outlined" severity="error">
                {' '}
                <IntlMessages id="rebook.flightNotFound" />
              </Alert>
            </Stack>
          )}
      <Box sx={{ width: 500 }}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={fareFamily} autoHideDuration={1200}>
          {/* later when provided, change this message to Intl */}
          <Alert severity="warning" variant="filled" sx={{ width: '100%' }} onClose={() => setFareFamily(false)}>
            If there was a luggage booked for Pure tariff, it will be lost if you proceed with Perfect tariff. <br />{' '}
            For SSR refund please go to NSK/Midoco
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
};

export default RenderFlights;
