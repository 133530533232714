import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { TFF } from '@tff/types';
import { Journey } from '@tff/types/TFF';
import { Box, Button, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

import AncillaryAccordion from './AncillaryAccordion';
import { useAncillaryBooking } from '../../hooks/use-ancillary-booking';
import useStyles from './AccordionStyles';
import AncillaryCostTable from './AncillaryCostTable';
import { ConfirmationDialog } from '../AncillaryTable/ConfirmationDialog';
import { useTypedSelector } from '../../reducers';
import localeFormat from '../../util/localeFormat';
import { mergeBXObjects } from './Utils/AncillariesPage/mergeBXObjects';
import { formSsrs } from './Utils/AncillariesPage/formSsrs';
import { updateSsrCode } from './Utils/AncillariesPage/updateSsrCode';
import { groupByType } from './Utils/AncillariesPage/groupByType';
import { updateGroupedRows } from './Utils/AncillariesPage/updateGroupedRows';
import { mergeByType } from './Utils/AncillariesPage/mergeByType';
import CostOverview from '../CostOverview';
import { parsePaxData } from './Utils/AncillariesPage/parsePaxData';
import { useAmendBooking } from '../../hooks/use-amend-booking';
import { ValidationDialog } from './ValidationDialog';
import { resetSsrs } from './Utils/AncillariesPage/resetSsrs';
import { addSsrsToAdd } from './Utils/AncillariesPage/addSsrsToAdd';
import { removeSsrsToAdd } from './Utils/AncillariesPage/removeSsrsToAdd';
import { AmendDialog } from './AmendDialog';
import {
  addMissingAncillariesToMetaValues,
  getJourneysOnd,
  getMissingAncillaries,
  MissingAncillaries,
} from './Utils/AncillariesPage/missingAncillaries';
import { checkDirection } from './Utils/AncillariesPage/checkDirection';

interface props {
  flightDetails: TFF.FlightDetails;
  midocoOrderNo?: number;
  rebooking?: boolean;
  showCostTable?: boolean;
  setShowCostTable?: (value: boolean) => void;
  rebookOffers?: any;
  ancillarySeats?: any;
  payload?: any;
  ancillaryRequest?: boolean;
  handleAmendBookingConfirm?: (value: TFF.PaxData[], serviceFee: number) => void;
  sessionId?: string;
  displayRebookConfirmation?: boolean;
  rebookingResponse?: any;
  setAncillaryData?: (value) => void;
  newOnds?: {
    Outbound: string;
    Inbound: string;
  };
  tfmPnr?: string;
  setAncillaryId?: (value: string) => void;
  fareFamily?: string[] | undefined;
  amendBalanceDue?: number;
}

interface AncillariesAccordion {
  type: string;
  ancillaries: TFF.MetaValue[];
}

interface GroupedAncillaries {
  type: string;
  ancillaries: { outbound: TFF.MetaValue[]; inbound: TFF.MetaValue[] };
  matchedPassengers: {};
}

const AncillariesPage: React.FC<props> = ({
  flightDetails,
  midocoOrderNo,
  rebooking,
  showCostTable,
  setShowCostTable,
  rebookOffers,
  payload,
  ancillaryRequest,
  handleAmendBookingConfirm,
  sessionId,
  displayRebookConfirmation,
  setAncillaryData,
  newOnds,
  tfmPnr,
  setAncillaryId,
  fareFamily,
  ancillarySeats,
  amendBalanceDue,
}) => {
  const { locale } = useTypedSelector(({ settings }) => settings.locale);
  const intl = useIntl();

  const journeys: TFF.Journey[] = flightDetails.OrderDetails.Journeys;

  const { setAmendBookingRequest, amendResponse, amendStatus } = useAmendBooking();
  const [groupedRows, setGroupedRows] = useState<GroupedAncillaries[]>([]);
  const [outboundRows, setOutboundRows] = useState<AncillariesAccordion[]>([]);
  const [inboundRows, setInboundRows] = useState<AncillariesAccordion[]>([]);
  const [outboundRaw, setOutboundRaw] = useState<TFF.MetaValue[]>([]);
  const [inboundRaw, setInboundRaw] = useState<TFF.MetaValue[]>([]);
  const [displayTable, setDisplayTable] = useState<boolean>(false);
  const [ssrErrors, setSsrErrors] = useState<string>();
  const [validationDialog, setValidationDialog] = useState<boolean>(false);
  const [adjustableSurcharge, setAdjustableSurcharge] = useState<{ [key: string]: string }>({});
  const [partialSsrs, setPartialSsrs] = useState<string[]>([]);

  const [ssrsToAdd, setSsrsToAdd] = useState<{ [paxId: string]: TFF.SsrDetails[] }>(() => {
    return Object.values(flightDetails.OrderDetails.Passengers).reduce((acc, pax) => {
      if (pax.Type === 'INF') {
        return acc;
      }
      const ssrs: TFF.SsrDetails[] = [];
      formSsrs(ssrs, pax, flightDetails);

      acc[pax.Id] = mergeBXObjects(ssrs);
      return acc;
    }, {} as { [paxId: string]: TFF.SsrDetails[] });
  });
  const [includedSsrs] = useState<{ [paxId: string]: TFF.SsrDetails[] }>(ssrsToAdd);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showFailure, setShowFailure] = useState<boolean>(false);
  const [, setTableOpen] = useState<boolean>(false);
  const [missingAncillaries, setMissingAncillaries] = useState<MissingAncillaries>({ outbound: [], inbound: [] });
  const [bookedPartially, setBookedPartially] = useState<boolean>(false);

  const isOutboundRebooked = rebooking && rebookOffers.outbound.new !== rebookOffers.outbound.original;
  const isInboundRebooked = rebooking && rebookOffers.inbound.new !== rebookOffers.inbound.original;

  const handleValidationDialog = () => {
    setValidationDialog(false);
  };

  const handleAmendDialog = () => {
    setBookedPartially(false);
  };

  useEffect(() => {
    Object.values(ssrsToAdd).forEach(group =>
      group.forEach(item => {
        item.code = updateSsrCode(item.code, outboundRaw ?? inboundRaw);
      }),
    );
  }, [outboundRaw, inboundRaw]);

  const classes = useStyles();

  const { setSearchAncillariesLambdaRequest, ancillaryResponse, ancillaryStatus } = useAncillaryBooking();
  const Ancillaries: TFF.Ancillaries = flightDetails.OrderDetails.Ancillaries!;

  useEffect(() => {
    if (ancillaryStatus === 'FAILURE') {
      return;
    }

    if (ancillaryStatus === 'IN_ANCILLARY') {
      // Checks whether the request comes from rebooking section or ancillary section
      const sourceData = rebooking ? payload : journeys;

      const localeParam: string = localeFormat(locale);

      setSearchAncillariesLambdaRequest({
        journeyIdentifiers: Object.values(sourceData)
          .map(journey => {
            const typedJourney = journey as Journey;
            return {
              journeyIdentifier: typedJourney.JourneyIdentifier!,
              productClass: typedJourney.AdditionalParams?.ProductClass,
              promotionCode: '2019',
            };
          })
          .filter(Boolean),
        currency: 'EUR',
        locale: localeParam,
      });
    }

    if (ancillaryStatus === 'CONFIRMED') {
      if (ancillaryResponse?.body) {
        if (flightDetails.OrderDetails.Journeys.length < 2 && ancillaryResponse.body[0].errors) {
          if (ancillaryResponse.body[0].errors.length < 1) {
            /* oneway trip
              directly assigns ancillaries without checking values whether outbound or inbound
            */
            const metaValues = ancillaryResponse!.body[0].metaValues;
            const ancillaryDirections = ancillaryResponse!.body.map(checkDirection);
            const journeysOnd = getJourneysOnd(ancillaryDirections);
            const missingAncillaries = getMissingAncillaries(Ancillaries, journeysOnd, metaValues);
            addMissingAncillariesToMetaValues(missingAncillaries.outbound, metaValues, locale);
            setMissingAncillaries(missingAncillaries);

            setOutboundRows(
              Object.values(groupByType(ancillaryResponse!.body[0].metaValues)).map((i: AncillariesAccordion) => i),
            );
            setOutboundRaw(ancillaryResponse!.body[0].metaValues);

            setOutboundRows(prevRows => updateGroupedRows(prevRows, intl));
          } else {
            setSsrErrors(ancillaryResponse.body[0].errors[0].message);
          }
        } else {
          /* roundtrip
            compares directions whether values belong to outbound or inbound, assigns accordingly
          */
          const metaValues0 = ancillaryResponse!.body[0].metaValues;
          const metaValues1 = ancillaryResponse!.body[1].metaValues;
          const errors0 = ancillaryResponse.body[0]?.errors;
          const errors1 = ancillaryResponse.body[1]?.errors;
          const ancillaryDirections = ancillaryResponse!.body.map(checkDirection);

          const journeysOnd = getJourneysOnd(ancillaryDirections);
          const missingAncillaries = getMissingAncillaries(
            Ancillaries,
            journeysOnd,
            metaValues0,
            metaValues1,
            errors0,
            errors1,
          );
          //Outbound missing ancillaries
          addMissingAncillariesToMetaValues(missingAncillaries.outbound, metaValues0, locale);
          //Inbound missing ancillaries
          addMissingAncillariesToMetaValues(missingAncillaries.inbound, metaValues1, locale);
          setMissingAncillaries(missingAncillaries);

          const metaValues = [metaValues0, metaValues1];
          const firstSegment = ancillaryResponse!.body[0].segment.split('-').at(-1);

          journeys.forEach((journey, index) => {
            if (ancillaryResponse?.body?.length && ancillaryResponse.body[index].errors?.length) {
              setSsrErrors(ancillaryResponse.body[index].errors![0].message);
            } else {
              const formattedOnd = flightDetails.OrderDetails.Journeys[0].Ond!.replaceAll('-', '');
              const isFirstSegmentMatch = rebooking
                ? firstSegment === newOnds?.Outbound
                : formattedOnd === firstSegment;

              const rowSetterMap: { [key: string]: [(rows: any) => void, (rows: any) => void] } = {
                true: [setOutboundRows, setInboundRows],
                false: [setInboundRows, setOutboundRows],
              };

              const setRows = rowSetterMap[String(isFirstSegmentMatch)][index];
              setRows(Object.values(groupByType(metaValues[index])).map(i => i));
            }
          });

          //keep raw response for later to compare values
          setOutboundRaw(metaValues0);
          setInboundRaw(metaValues1);
          //set modified values to render ancillary accordions
          setOutboundRows(prevRows => updateGroupedRows(prevRows, intl));
          setInboundRows(prevRows => updateGroupedRows(prevRows, intl));
        }
      }
    }
  }, [ancillaryStatus]);

  useEffect(() => {
    setGroupedRows(mergeByType(outboundRows, inboundRows));
  }, [inboundRows, outboundRows]);

  const findSsr = (paxId: string, ssrDetails: TFF.SsrDetails) => {
    return ssrsToAdd[paxId]?.find(ssr => {
      return ssr.code === ssrDetails.code && ssr.segmentOnd === ssrDetails.segmentOnd;
    });
  };

  const handleConfirm = () => {
    setAmendBookingRequest({
      action: displayTable ? 'confirm' : 'validate',
      bookingSource: 'TUI-NSK',
      recordLocator: flightDetails.OrderSummary?.RecordLocator!,
      tfmPnr: tfmPnr!,
      paxData: parsePaxData(ssrsToAdd, outboundRaw, inboundRaw, Ancillaries),
      midocoOrderNo: midocoOrderNo,
      sessionId: amendResponse?.sessionId,
    });
  };

  useEffect(() => {
    if (displayTable && amendStatus === 'SUCCESS') {
      setShowConfirmation(true);
      setShowSuccess(true);
    } else if (displayTable && amendStatus === 'FAILURE') {
      setShowConfirmation(true);
      setShowFailure(true);
    }

    if (amendStatus === 'SUCCESS') {
      setDisplayTable(true);
      if (amendResponse?.tfaAmendResponseDetails?.validatedSsrsAmendResult?.status === 'FAILED') {
        setDisplayTable(false);

        const ssrCodes = amendResponse.tfaAmendResponseDetails.validatedSsrsAmendResult?.ancillaries
          .filter(a => a.status !== 'AVAILABLE')
          .map(a => `${a.segmentOnd}~${a.paxId}~${a.code}`);

        setPartialSsrs(ssrCodes);
        setBookedPartially(true);
      }
      if (amendResponse && setAncillaryId) {
        setAncillaryId(amendResponse.query.sessionId!);
      }
    } else if (amendStatus === 'FAILURE') {
      setValidationDialog(true);
      if (setShowCostTable) {
        setShowCostTable(false);
      }
    }
  }, [amendStatus]);

  useEffect(() => {
    if (rebooking && showCostTable) {
      setAmendBookingRequest({
        action: 'validate',
        bookingSource: 'TUI-NSK',
        recordLocator: flightDetails.OrderSummary?.RecordLocator!,
        tfmPnr: tfmPnr!,
        paxData: parsePaxData(ssrsToAdd, outboundRaw, inboundRaw, Ancillaries, ancillarySeats),
        midocoOrderNo: midocoOrderNo,
        sessionId: sessionId,
      });
    }
  }, [showCostTable]);

  useEffect(() => {
    if (rebooking && ancillaryRequest) {
      if (handleAmendBookingConfirm) {
        handleAmendBookingConfirm(
          parsePaxData(ssrsToAdd, outboundRaw, inboundRaw, Ancillaries, ancillarySeats, adjustableSurcharge),
          parseInt(adjustableSurcharge['Service Fee'] ?? 0),
        );
      }
    }
  }, [ancillaryRequest]);

  const isTypeValid = type => {
    return ['HAND_LUGGAGE', 'BAGGAGE', 'SPORTS', 'ANIMALS'].includes(type);
  };

  const renderError = () => {
    if (outboundRows && outboundRows.length > 0) {
      return;
    } else if (inboundRows && inboundRows.length > 0) {
      return;
    } else {
      return <Alert severity="error">{ssrErrors}</Alert>;
    }
  };

  return (
    <>
      {!rebooking && (
        <Box width="100%" className={classes.ancillaryHeader}>
          <Typography fontSize="27px" fontWeight={700} color="primary">
            {intl.formatMessage({ id: 'ancillaries.title' })}
          </Typography>
        </Box>
      )}

      {!displayTable &&
        [...groupedRows]
          .sort((a, b) => {
            const order = ['HAND_LUGGAGE', 'BAGGAGE', 'SPORTS', 'ANIMALS'];
            return order.indexOf(a.type) - order.indexOf(b.type);
          })
          .map(
            (ancillary, index) =>
              isTypeValid(ancillary.type) && (
                <AncillaryAccordion
                  key={ancillary.type}
                  groupedRows={ancillary}
                  flightDetails={flightDetails}
                  addSsrsToAdd={addSsrsToAdd}
                  removeSsrsToAdd={removeSsrsToAdd}
                  resetSsrs={resetSsrs}
                  findSsr={findSsr}
                  ssrsToAdd={ssrsToAdd}
                  rebooking={rebooking}
                  fareFamily={fareFamily}
                  newOnds={newOnds}
                  setSsrsToAdd={setSsrsToAdd}
                  groupedAncillary={groupedRows}
                  includedSsrs={includedSsrs}
                  validatedAncillaries={amendResponse?.tfaAmendResponseDetails?.validatedSsrsAmendResult?.ancillaries}
                  missingAncillaries={missingAncillaries}
                  isOutboundRebooked={isOutboundRebooked}
                  isInboundRebooked={isInboundRebooked}
                />
              ),
          )}

      <Box>{renderError()}</Box>

      {displayTable && (
        <>
          <AncillaryCostTable
            flightDetails={flightDetails}
            ssrsToAdd={ssrsToAdd}
            includedSsrs={includedSsrs}
            metaValues={[...new Set([...inboundRows, ...outboundRows])]}
            rebooking={rebooking}
            rebookOffers={rebookOffers}
            setDisplayTable={setDisplayTable}
            submitChanges={handleConfirm}
            setAdjustableSurcharge={setAdjustableSurcharge}
            amendBalanceDue={amendBalanceDue}
          />
          <CostOverview
            ssrDisplayData={{
              ssrsToAdd: ssrsToAdd,
              includedSsrs: includedSsrs,
              metaValues: [...new Set([...inboundRows, ...outboundRows])],
            }}
          />
        </>
      )}

      {showConfirmation && (displayTable || displayRebookConfirmation) && (
        <ConfirmationDialog
          showConfirmation={showConfirmation}
          showFailure={showFailure}
          showSuccess={showSuccess}
          setShowConfirmation={setShowConfirmation}
          setTableOpen={setTableOpen}
          rebooking={rebooking}
          flightDetails={flightDetails}
          rebookingResponse={amendResponse}
        />
      )}

      {bookedPartially && <AmendDialog handleClose={handleAmendDialog} open={bookedPartially} ssrs={partialSsrs} />}

      {validationDialog && <ValidationDialog handleClose={handleValidationDialog} open={validationDialog} />}

      {!rebooking && !displayTable && (
        <Box className={classes.ancillaryFooter}>
          <Button color="info" className={classes.ancillaryCancel} onClick={() => setDisplayTable(false)}>
            {intl.formatMessage({ id: 'ancillaries.action.cancel' })}
          </Button>
          <Button
            className={classes.ancillaryAccept}
            onClick={() => {
              handleConfirm();
            }}
          >
            {intl.formatMessage({ id: 'ancillaries.action.accept' })}
          </Button>
        </Box>
      )}
    </>
  );
};

export default AncillariesPage;
