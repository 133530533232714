import { TFF } from '@tff/types';

/* 
  Checks whether "airlineSsrCode" is the same value as "code"
  If so returns it, if not it returns the "code" value.
  This is needed because TFA only accepts "code" value for adding ssrs.
*/

export const updateSsrCode = (code: string, rows: TFF.MetaValue[]): string => {
  if (rows) {
    const foundItem = rows.find(i => i.airlineSsrCode === code);
    return foundItem ? foundItem.code : code;
  } else {
    return code;
  }
};
