import * as React from 'react';
import { useEffect, useContext } from 'react';
import { TFF } from '@tff/types';

import { LoadingContext } from '../contexts/LoadingContextProvider';
import { apigeeEndpoint } from '../apis';

export type TypeOfStatus = 'PROCESSING' | 'CHANGED' | 'FAILURE';

export type ChangeBookingProps = {
  recordLocator?: string;
  changeBookingRequest?: TFF.CancelFlightRequest;
};

export type ChangeBookingResponse = {
  changedBooking?: TFF.CancelFlightResponse;
  status?: TypeOfStatus;
  error?: any;
};

//
export const useChangeBooking = (changeBookingProps: ChangeBookingProps): ChangeBookingResponse => {
  const [status, setStatus] = React.useState<TypeOfStatus>();
  const [changedBooking, setChangedBooking] = React.useState<TFF.CancelFlightResponse>();
  const [error, setError] = React.useState<unknown | Error | undefined>();
  const { showLoading, closeLoading } = useContext(LoadingContext);

  useEffect(() => {
    const cancelBookingAtBackend = async (changeRequest: TFF.CancelFlightRequest): Promise<void> => {
      try {
        setStatus('PROCESSING');
        showLoading(`Cancel ${changeRequest.bookingSource} Booking`);

        const response = await apigeeEndpoint.post(`/change/${changeRequest.bookingSource}`, changeRequest);

        if (response.data.status === 'FAILURE') {
          setError(response.data.error);
          setStatus('FAILURE');
        }

        setChangedBooking(response.data.data ?? response.data);
      } catch (error) {
        setError(error);
        setStatus('FAILURE');
      } finally {
        closeLoading();
      }
    };

    if (changeBookingProps && changeBookingProps.changeBookingRequest && !status) {
      void cancelBookingAtBackend(changeBookingProps.changeBookingRequest);
    }
  }, [changeBookingProps]);

  return { error, changedBooking, status };
};
