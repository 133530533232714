import { TFF } from '@tff/types';

export const removeSsrsToAdd = (paxId: string, addSsrs: TFF.SsrDetails, setSsrsToAdd) => {
  setSsrsToAdd(prevState => {
    if (addSsrs.code === 'BA0') {
      return {
        ...prevState,
        [paxId]: prevState[paxId].filter(ssr => {
          return !ssr.code.startsWith('BX') || ssr.segmentOnd !== addSsrs.segmentOnd;
        }),
      };
    }
    if (prevState[paxId].find(ssr => ssr.code === addSsrs.code && ssr.segmentOnd === addSsrs.segmentOnd)?.amount! > 1) {
      return {
        ...prevState,
        [paxId]: prevState[paxId].map(ssr => {
          if (ssr.code === addSsrs.code && ssr.segmentOnd === addSsrs.segmentOnd) {
            return { ...ssr, amount: ssr.amount - 1 };
          }
          return ssr;
        }),
      };
    }
    return {
      ...prevState,
      [paxId]: prevState[paxId].filter(ssr => {
        return ssr.code !== addSsrs.code || ssr.segmentOnd !== addSsrs.segmentOnd;
      }),
    };
  });
};
