import { Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { TFF } from '@tff/types';
import { keycloak } from '../../keycloak';
import { useState } from 'react';

interface props {
  orderSummary: TFF.OrderSummary;
  product?: TFF.TypeOfProduct;
  hasFlightChanges: boolean;
  handleMenuClicked: (value: string) => void;
  disableButtons: boolean;
  isShrunk: boolean;
  activeButton: string;
  flightRetrieve?: TFF.Retrieve;
}

const useStyles = makeStyles(() =>
  createStyles({
    menuWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    menuButtonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '5px',
      padding: '16px 0',
    },
    iconBg: {
      color: '#E2F3FE',
    },
    flightMenuButtons: {
      borderRadius: '3px',
      padding: '5px 16px',
    },
  }),
);

const FlightMenu: React.FC<props> = ({
  handleMenuClicked,
  orderSummary,
  hasFlightChanges,
  disableButtons,
  isShrunk = false,
  product,
  activeButton,
  flightRetrieve,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const isIntern = () => {
    const internalRoles = [
      'Role_IT_Administrator',
      'Role_Business_Administrator',
      'Role_Service_Center_Agent_Intern',
      'Role_Service_Center_Supervisor_Intern',
    ];
    let isIn = false;
    internalRoles.forEach(internRole => {
      if (keycloak.hasRealmRole(internRole)) {
        isIn = true;
      }
      return false;
    });
    return isIn;
  };

  const isItAdmin = keycloak.hasRealmRole('Role_IT_Administrator');
  const isBusinessAdmin = keycloak.hasRealmRole('Role_Business_Administrator');
  const hasNLRole = keycloak.hasRealmRole('M_NL');
  const hasDERole = keycloak.hasRealmRole('M_DE');

  const [isOcnClicked, setIsOcnCliked] = useState<boolean>(false);

  const menuButtons = [
    {
      id: 'notice',
      name: `${intl.formatMessage({ id: 'menu.notice' })}`,
      disable: false,
    },
    {
      id: 'rebook',
      name: `${intl.formatMessage({ id: 'menu.changeBooking' })}`,
      disable: false,
      condition: () => {
        const bookingSources = window.ENV?.ENABLE_NDC_REBOOK ? ['NSK', 'AF', 'KL'] : ['NSK'];
        return window.ENV?.ENABLE_REBOOK === true && bookingSources.includes(flightRetrieve?.bookingSource ?? '');
      },
    },
    {
      id: 'cancelBooking',
      name: `${intl.formatMessage({ id: 'menu.cancelBooking' })}`,
      disable:
        (orderSummary?.BookingStatus === 'Closed' ?? true) ||
        orderSummary.BookingStatus.toLowerCase() === 'cancelled' ||
        disableButtons,
    },
    {
      id: 'history',
      name: `${intl.formatMessage({ id: 'menu.history' })}`,
      disable: false,
    },
    {
      id: 'changes',
      name: `${intl.formatMessage({ id: 'menu.changes' })}`,
      disable: !hasFlightChanges,
    },
    {
      id: 'triggerPNRchangeToAcr',
      name: `${intl.formatMessage({ id: 'menu.pnrChangeTriggerToAcr' })}`,
      disable: orderSummary.BookingStatus.toLowerCase() === 'cancelled',
      condition: () => {
        return (
          product &&
          ((product === 'aircruiser' && (hasNLRole || isIntern())) ||
            (product === 'atcomres' && (hasDERole || isIntern())))
        );
      },
    },
    {
      id: 'ocnman',
      name: `${intl.formatMessage({ id: 'menu.ocnman' })}`,
      disable:
        (orderSummary?.BookingStatus === 'Closed' ?? true) ||
        orderSummary.BookingStatus.toLowerCase() === 'cancelled' ||
        (!isItAdmin && !isBusinessAdmin) ||
        isOcnClicked,
      condition: () => {
        return orderSummary?.SourceDetails && orderSummary.SourceDetails.System === 'TUI-NDC';
      },
    },
  ];

  const buttonBackgroundColor = buttonId =>
    // TODO - Uncomment this when you want the activeButton to have different color - this was meant to
    //  be done in TFF-1901 however there were some edge cases that made us postpone this implementation
    //  isShrunk ? (activeButton === buttonId ? '#71CBF4' : '#E2F3FE') : '#09295D';
    isShrunk ? '#E2F3FE' : '#09295D';

  return (
    <div className={classes.menuWrapper}>
      <div className={classes.menuButtonsWrapper}>
        {menuButtons
          .filter(btn => {
            //skip false condition buttons
            return btn.condition === undefined || btn.condition();
          })
          .map((button, index) => {
            return (
              <Button
                variant="contained"
                key={index}
                onClick={() => {
                  handleMenuClicked(button.id);
                  if (!isShrunk) {
                    setIsOcnCliked(true);
                  }
                }}
                disabled={button.disable}
                className={classes.flightMenuButtons}
                sx={{
                  backgroundColor: buttonBackgroundColor(button.id),
                  color: isShrunk ? '#09295D' : 'white',
                  '&.Mui-disabled': {
                    backgroundColor: '#BABABA',
                    color: 'white',
                  },
                  '&:hover': {
                    backgroundColor: buttonBackgroundColor(button.id),
                  },
                }}
                disableElevation
              >
                {button.name}
              </Button>
            );
          })}
      </div>
    </div>
  );
};

export default FlightMenu;
