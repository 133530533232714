import CustomDialog from '../Dialogs';
import { Typography } from '@mui/material';
import ReImportMarket from './ReImportMarket';

export const RenderReImportDialog = ({ viewDialog, setViewDialog }) => {
  return (
    <>
      <CustomDialog
        initialOpen={viewDialog}
        confirmButtonText="Close"
        confirmButtonStyle={{
          backgroundColor: '#F2E30F',
          color: 'black',
        }}
        onConfirm={() => {
          setViewDialog(false);
        }}
        onCancel={() => {
          setViewDialog(false);
        }}
        width="md"
        cancelButtonText=""
        titleBg="#E2F3FE"
        title={
          <Typography variant="h5" align="left" fontWeight="bold">
            NDC-REIMPORT
          </Typography>
        }
      >
        <>
          <ReImportMarket setViewDialog={setViewDialog} />
        </>
      </CustomDialog>
    </>
  );
};
