import { TFF } from '@tff/types';

interface AncillariesAccordion {
  type: string;
  ancillaries: TFF.MetaValue[];
}

/* 
    adds mock ssr object "BA0" to accordion data 
    and 
    create additional object with type "HAND_LUGGAGE
  */

export const updateGroupedRows = (prevRows: AncillariesAccordion[], intl): AncillariesAccordion[] => {
  if (prevRows.length < 2) {
    return prevRows;
  }

  const newRows = [...prevRows];

  const lastObject = { ...newRows[newRows.length - 1] };

  const secondLastObject = { ...newRows[newRows.length - 2] };

  const filteredAncillaries = secondLastObject.ancillaries.filter(ancillary => ancillary.code !== 'HBAG');

  lastObject.ancillaries = [
    ...lastObject.ancillaries,
    ...filteredAncillaries,
    {
      code: 'BA0',
      type: 'BAGGAGE',
      shortDescription: intl.formatMessage({ id: 'ancillaries.edit.handLuggageOnly' }),
      price: {
        amount: 0,
        currency: '',
        regularAmount: 0,
      },
      airlineSsrCode: 'BA0',
      locale: '',
      restriction: { actualCapacityLeft: 99 },
    },
  ];

  newRows[newRows.length - 1] = lastObject;

  newRows.push({
    type: 'HAND_LUGGAGE',
    ancillaries: newRows.filter(i => i.type === 'ADDITIONAL_BAGGAGE')[0].ancillaries.filter(i => i.code === 'HBAG'),
  });

  return newRows;
};
