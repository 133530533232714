import { TFF } from '@tff/types';
import { Price, Restriction } from '@tff/types/TFF/tfa-amend-ancillary-service';

export interface MissingAncillaries {
  outbound: TFF.Ancillary[];
  inbound: TFF.Ancillary[];
}

export interface JourneysOnd {
  outbound: string;
  inbound?: string;
}

export const addMissingAncillariesToMetaValues = (
  missingAncillaries: TFF.Ancillary[],
  metaValues: TFF.MetaValue[],
  locale: string = 'de',
) => {
  if (Array.isArray(metaValues)) {
    missingAncillaries.forEach(ancillary => {
      const newMetaValue: TFF.MetaValue = {
        airlineSsrCode: ancillary.Type,
        code: ancillary.TfaDetails?.Code ?? '',
        locale: `${locale}-${locale.toUpperCase()}`,
        price: {
          amount: ancillary.TotalAmount,
          currency: 'EUR',
          regularAmount: ancillary.TotalAmount,
        } as Price,
        restriction: { actualCapacityLeft: 0 } as Restriction,
        shortDescription:
          (locale === 'de' ? ancillary.TfaDetails?.DescriptionDE : ancillary.TfaDetails?.DescriptionEN) ??
          ancillary.Description ??
          '',
        type: 'SPORTS',
      };
      metaValues.push(newMetaValue);
    });
  }
};

export const getMissingAncillaries = (
  bookedAncillaries: TFF.Ancillaries,
  journeysOnd: JourneysOnd,
  tfaRetrievedOutboundMetaValues: TFF.MetaValue[],
  tfaRetrievedInboundMetaValues?: TFF.MetaValue[],
  outboundErrors: any[] = [],
  inboundErrors: any[] = [],
): MissingAncillaries => {
  let missingAncillaries: MissingAncillaries = { outbound: [], inbound: [] };
  let existingSportsInboundMetaValue: TFF.MetaValue | undefined;
  const hasInboundFlight = tfaRetrievedInboundMetaValues !== undefined;

  const filteredBookedAncillaries = removeInvalidAncillaries(bookedAncillaries);
  Object.keys(filteredBookedAncillaries).forEach(entry => {
    const parts: string[] = entry.split('~');
    const ancillaryType: string = parts[parts.length - 2];
    const journeyOnd: string = parts[0];

    const existingSportsOutboundMetaValue = findAncillaryByCode(tfaRetrievedOutboundMetaValues, ancillaryType);
    if (hasInboundFlight) {
      existingSportsInboundMetaValue = findAncillaryByCode(tfaRetrievedInboundMetaValues, ancillaryType);
    }

    if (!existingSportsOutboundMetaValue) {
      if (
        outboundErrors.length < 1 &&
        journeyOnd === journeysOnd.outbound &&
        !isSameAncillaryType(missingAncillaries.outbound, ancillaryType)
      ) {
        missingAncillaries.outbound.push(bookedAncillaries[entry]);
      }
    }

    if (hasInboundFlight && !existingSportsInboundMetaValue) {
      if (
        inboundErrors.length < 1 &&
        journeyOnd === journeysOnd.inbound &&
        !isSameAncillaryType(missingAncillaries.inbound, ancillaryType)
      ) {
        missingAncillaries.inbound.push(bookedAncillaries[entry]);
      }
    }
  });

  return missingAncillaries;
};

export const getJourneysOnd = (ancillaryDirections: (string | undefined)[]): JourneysOnd => {
  const journeysOnd: string[] = ancillaryDirections.map(
    journeyOnd => `${journeyOnd!.slice(0, 3)}-${journeyOnd!.slice(3)}`,
  );

  return {
    outbound: journeysOnd[0],
    inbound: journeysOnd[1],
  } as JourneysOnd;
};

const findAncillaryByCode = (
  metaValues: TFF.MetaValue[] | undefined,
  ancillaryType: string,
): TFF.MetaValue | undefined => metaValues?.find(value => value.airlineSsrCode === ancillaryType);

const isSameAncillaryType = (ancillaries: TFF.Ancillary[], ancillaryType: string): boolean => {
  return ancillaries.some(ancillary => ancillary.Id.includes(ancillaryType));
};

const removeInvalidAncillaries = (bookedAncillaries: TFF.Ancillaries): TFF.Ancillaries => {
  const filteredBookedAncillaries: TFF.Ancillaries = {};

  for (const key in bookedAncillaries) {
    const isValidAncillary = !['SEAT', 'INF'].includes(bookedAncillaries[key].Type);
    if (isValidAncillary) {
      filteredBookedAncillaries[key] = bookedAncillaries[key];
    }
  }

  return filteredBookedAncillaries;
};
