import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../withRouter';

export const WelcomePageRoutes = () => {
  return (
    <div className="app-wrapper h-100">
      <Routes>
        <Route path={'/tff/order/search'} />
      </Routes>
    </div>
  );
};

export default withRouter(WelcomePageRoutes);
