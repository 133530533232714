import * as React from 'react';
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars';

export const CustomScrollbars = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<Scrollbars> &
    Readonly<ScrollbarProps> &
    Readonly<{ children?: React.ReactNode }>,
): JSX.Element => (
  <Scrollbars
    {...props}
    autoHide
    renderTrackHorizontal={(props: any) => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
  />
);
