import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { useIntl } from 'react-intl';
import { TicketIcon } from '../icons';
import { ServicePackageConnectorResponse } from '../../hooks/use-service-packages';

interface props {
  serviceResponse?: ServicePackageConnectorResponse;
}

const ServicesCard: React.FC<props> = ({ serviceResponse }) => {
  const intl = useIntl();

  return (
    <Accordion
      sx={{
        width: '100%',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: '5px',
        margin: '10px',
        position: 'relative',
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: 0,
            }}
          >
            <Box
              sx={{
                height: 40,
                width: 40,
                backgroundColor: '#E2F3FE',
                borderRadius: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TicketIcon />
            </Box>
            <Typography
              variant="h6"
              color="primary"
              sx={{ marginLeft: '10px', display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}
            >
              <strong>{intl.formatMessage({ id: 'servicePackages.title' })}</strong>
            </Typography>

            <Box padding={'0px 45px'}>
              <Typography>{serviceResponse?.servicePackages.passengers![0].selectedServicePackage.name}</Typography>
            </Box>
          </Box>

          <Typography
            variant="h6"
            color="primary"
            sx={{ marginRight: '24px', display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}
          >
            {serviceResponse?.servicePackages.passengers === undefined ? (
              <strong>0.00 €</strong>
            ) : (
              <strong>
                {serviceResponse?.servicePackages.passengers.length! *
                  serviceResponse?.servicePackages.passengers[0].selectedServicePackage.price.value!}{' '}
                €
              </strong>
            )}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: '#E2F3FE' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            borderRadius: '4px',
            minHeight: '250px',
            width: '100%',
          }}
        >
          {serviceResponse?.servicePackages.passengers === undefined ? null : (
            <>
              <Box sx={{ color: '#09295D', marginLeft: '50px', marginTop: '17px' }}>
                <Typography fontWeight={700}>
                  {`${serviceResponse?.servicePackages.passengers[0].selectedServicePackage.name} - ${serviceResponse?.servicePackages.passengers.length} passenger(s) x €${serviceResponse?.servicePackages.passengers[0].selectedServicePackage.price.value}`}
                </Typography>
              </Box>

              <Box sx={{ size: '18px', marginLeft: '60px', marginTop: '17px' }}>
                {Array.from(
                  new Set(
                    serviceResponse?.servicePackages.passengers[0].selectedServicePackage.serviceConfigs
                      .filter(services => services.value === 'true')
                      .map(services => services.service.name),
                  ),
                ).map((serviceName, index) => (
                  <li key={index} style={{ fontSize: '18px' }}>
                    {serviceName}
                  </li>
                ))}
              </Box>
            </>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ServicesCard;
