import React from 'react';
import { useEffect, useContext, useState } from 'react';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { apigeeEndpoint } from '../apis';
import { AmendBookingRequest, AmendBookingResponse } from '@tff/types/TFF/amend-booking-service';

export type TypeOfStatus = 'IN_VALIDATING' | 'SUCCESS' | 'FAILURE';

export type UseAmendResponse = {
  amendResponse?: AmendBookingResponse;
  amendStatus?: TypeOfStatus;
  amendError?: Error | undefined;
  setAmendBookingRequest: (amendBookingRequest: AmendBookingRequest) => void;
  amendBookingRequest: AmendBookingRequest;
};

export const useAmendBooking = (): UseAmendResponse => {
  const [amendStatus, setAmendStatus] = React.useState<TypeOfStatus>();
  const [amendResponse, setAmendResponse] = React.useState<AmendBookingResponse>();
  const [amendError, setAmendError] = React.useState<Error | undefined>();
  const [amendBookingRequest, setAmendBookingRequest] = useState<AmendBookingRequest>({} as AmendBookingRequest);
  const { showLoading, closeLoading } = useContext(LoadingContext);

  useEffect(() => {
    const amendBookingFromBackend = async (amendBookingRequest: AmendBookingRequest): Promise<void> => {
      try {
        setAmendStatus('IN_VALIDATING');
        if (amendBookingRequest.action === 'validate')
          showLoading(`Validating ${amendBookingRequest.bookingSource} Booking`);
        else showLoading(`Confirming ${amendBookingRequest.bookingSource} Booking`);

        const response = await apigeeEndpoint.post('/amend-booking', amendBookingRequest);

        if (response.data.status === 'SUCCESS') {
          setAmendStatus('SUCCESS');
          setAmendResponse(response.data.data ?? response.data);
        } else {
          setAmendStatus('FAILURE');
          setAmendError(response.data.error.message);
        }
      } catch (error) {
        setAmendStatus('FAILURE');
      } finally {
        closeLoading();
      }
    };

    if (
      (amendBookingRequest && amendBookingRequest.recordLocator) ||
      (amendBookingRequest && amendBookingRequest.sessionId)
    ) {
      void amendBookingFromBackend(amendBookingRequest);
    }
  }, [amendBookingRequest]);

  return { setAmendBookingRequest, amendError, amendResponse, amendStatus, amendBookingRequest };
};
