import { TFF } from '@tff/types';
import * as _ from 'lodash';

/**
 * This function is used to add Passengers Journey-Extras to a NDC-Booking
 * @param flightDetails
 * @constructor
 */
export const CreateJourneyExtras = (flightDetails: TFF.FlightDetails): TFF.FlightDetails => {
  const { Journeys, Ancillaries, Passengers, Fares } = flightDetails.OrderDetails;

  const defaultJourneyExtras = Journeys.map<TFF.JourneyExtras>(j => {
    return {
      JourneyOnd: j.Ond,
      Seat: undefined,
      Ssrs: [],
      SegmentExtras: j.Segments.map<TFF.SegmentExtras>(s => {
        return {
          SegmentOnd: s.Ond,
          Seat: undefined,
          Ssrs: [],
        } as TFF.SegmentExtras;
      }),
      Infant: undefined,
    } as TFF.JourneyExtras;
  });
  const ancillariesArr = (Ancillaries && Object.values(Ancillaries)) ?? [];

  const paxIdsFromFares: string[] = Object.keys(Fares);
  paxIdsFromFares.forEach(paxId => {
    const jExtrasForPax = _.cloneDeep<TFF.JourneyExtras[]>(defaultJourneyExtras);

    jExtrasForPax.forEach(je => {
      je.JourneyOnd;
      const jSeat = ancillariesArr.find(
        a =>
          (!a.PaxID || a.PaxID === paxId) &&
          a.JourneyOnd === je.JourneyOnd &&
          a.Type === 'SEAT' &&
          (!a.SegmentOnd || a.SegmentOnd === je.JourneyOnd),
      )?.Id;

      je.Ssrs = ancillariesArr
        .filter(
          a =>
            a.Type !== 'SEAT' &&
            a.Type !== 'INF' &&
            (!a.PaxID || a.PaxID === paxId) &&
            a.JourneyOnd === je.JourneyOnd &&
            (!a.SegmentOnd || a.SegmentOnd === je.JourneyOnd),
        )
        .map(a => a.Id);
      jSeat && (je.Seat = jSeat);

      je.SegmentExtras?.forEach(seg => {
        const sSeat = ancillariesArr.find(
          a =>
            (!a.PaxID || a.PaxID === paxId) &&
            a.JourneyOnd === je.JourneyOnd &&
            a.Type === 'SEAT' &&
            (!a.SegmentOnd || a.SegmentOnd === seg.SegmentOnd),
        )?.Id;
        sSeat && (seg.Seat = sSeat);

        seg.Ssrs = ancillariesArr
          .filter(
            a =>
              a.Type !== 'SEAT' &&
              a.Type !== 'INF' &&
              (!a.PaxID || a.PaxID === paxId) &&
              a.JourneyOnd === je.JourneyOnd &&
              a.SegmentOnd &&
              a.SegmentOnd === seg.SegmentOnd,
          )
          .map(a => a.Id);
      });
    });
    Passengers[paxId].JourneyExtras = jExtrasForPax;
  });

  return flightDetails;
};
