import * as React from 'react';
import { CSSProperties } from 'react';

type Props = {
  style?: CSSProperties;
};

export const FlightIcon: React.FC<Props> = ({ style }) => (
  <svg
    viewBox="0 0 24 24"
    style={{
      fill: '#ffffff',
      width: '23.02px',
      height: '24px',
      ...style,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.959,27.854v3.266l2.5,3.325A.98.98,0,0,1,24.214,35.9L21.2,34.3a1.967,1.967,0,0,1-.4,0l-3.016,1.6a.98.98,0,0,1-1.243-1.453l2.5-3.325V27.909l-8.849,1.966A.98.98,0,0,1,9,28.919v-.735a.98.98,0,0,1,.462-.832l9.579-5.96V14.959a1.959,1.959,0,1,1,3.918,0v6.425l9.576,5.905a.98.98,0,0,1,.465.834v.8a.98.98,0,0,1-1.2.955Z"
      transform="translate(36.02 -9) rotate(90)"
    />
  </svg>
);
