import * as yup from 'yup';
import validateFormValues from '../DeferedPayment/utils/form-values-validator';

const schema = yup.object().shape({
  queueSelections: yup
    .array()
    .of(yup.object().shape({ queue: yup.string().required(), queueNote: yup.string().required() }))
    .min(1)
    .required(),
});

export const validate = validateFormValues(schema);
