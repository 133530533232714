import * as React from 'react';
import { useEffect, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import Table from '../../components/Table';
import { ITableColumns, Message } from '../../models';
import { TypeOfTaskDecision, TypeOfTaskStatus } from '@tff/types/TFF';
import { IconButton, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import CustomDialog from '../../components/Dialogs';
import moment from 'moment';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { apigeeEndpoint } from '../../apis';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { LoadingContext } from '../../contexts/LoadingContextProvider';
import { CreateOrUpdateAmendFailureRequest } from '@tff/types/TFF';
import { keycloak } from '../../keycloak';
import { MUIDataTableMeta } from 'mui-datatables';
import { EnhancedAmendFailureDynamoItem } from './amend-failures';
import { useMessages } from '../../hooks/use-messages';
import { Link } from 'react-router-dom';

interface IAmendFailureTableProps {
  data: EnhancedAmendFailureDynamoItem[];
}

const amendFailureAction = yup.object().shape({
  status: yup.string().required(),
});

const updateAmendFailure = async (data: CreateOrUpdateAmendFailureRequest) => {
  const response = await apigeeEndpoint.post('amend-failure', data);
  return response.data;
};

/**

 */
const AmendFailuresTable: React.FC<IAmendFailureTableProps> = ({ data }) => {
  const intl = useIntl();

  const [amendFailureToEdit, setAmendFailureToEdit] = useState<EnhancedAmendFailureDynamoItem | undefined>(undefined);
  const queryClient = useQueryClient();
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const { addMessage } = useMessages();

  const { reset } = useForm<ChangeFormValues>({ resolver: yupResolver(amendFailureAction) });

  const openActionDialogWithTask = (sortKey: string) => {
    reset();
    const amendFailureToEdit = data.find(d => d.sortKey === sortKey);
    setAmendFailureToEdit(amendFailureToEdit);
  };

  const columnsData: ITableColumns<EnhancedAmendFailureDynamoItem>[] = [
    {
      name: 'failureType',
      label: intl.formatMessage({ id: 'global.failureType' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },

    {
      name: 'recordLocator',
      label: intl.formatMessage({ id: 'global.recordLocator' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number, tableMeta: MUIDataTableMeta) => {
          return <Link to={`/tff/order/${value}@${tableMeta.rowData[9]}`}>{value}</Link>;
        },
      },
    },

    {
      name: 'clientPnr',
      label: intl.formatMessage({ id: 'global.clientPnr' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },
    {
      name: 'bookingSource',
      label: intl.formatMessage({ id: 'global.bookingSource' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },
    {
      name: 'product',
      label: intl.formatMessage({ id: 'global.product' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },
    {
      name: 'origin',
      label: intl.formatMessage({ id: 'global.origin' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },
    {
      name: 'destination',
      label: intl.formatMessage({ id: 'global.destination' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },

    // {
    //   name: 'receivedAt',
    //   label: intl.formatMessage({ id: 'global.received' }),
    //   options: {
    //     customBodyRender: value => <p style={{ margin: 0 }}>{moment(value).format('DD.MM.YYYY - HH:mm')}</p>,
    //     display: true,
    //     filter: false,
    //     filterList: [],
    //   },
    // },

    {
      name: 'departureTime',
      label: intl.formatMessage({ id: 'global.departureTime' }), // TimeToLive
      options: {
        customBodyRender: value => <p style={{ margin: 0 }}>{moment(value).format('DD.MM.YYYY - HH:mm')}</p>,
        display: true,
        filter: false,
        filterList: [],
      },
    },

    {
      name: 'returnTime',
      label: intl.formatMessage({ id: 'global.returnTime' }),
      options: {
        customBodyRender: value => <p style={{ margin: 0 }}>{moment(value).format('DD.MM.YYYY - HH:mm')}</p>,
        display: true,
        filter: false,
        filterList: [],
      },
    },

    {
      name: 'carrierCode',
      label: intl.formatMessage({ id: 'global.carrierCode' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },

    {
      name: 'status',
      label: intl.formatMessage({ id: 'global.status' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        filterOptions: {
          names: ['CLOSE', 'NEW'],
        },
      },
    },
    {
      name: 'description',
      label: intl.formatMessage({ id: 'global.description' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },
    {
      name: 'updatedAt',
      label: intl.formatMessage({ id: 'global.updatedAt' }),
      options: {
        customBodyRender: value => <p style={{ margin: 0 }}>{moment(value).format('DD.MM.YYYY - HH:mm')}</p>,
        display: true,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'createdAt',
      label: intl.formatMessage({ id: 'global.createdAt' }),
      options: {
        customBodyRender: value => <p style={{ margin: 0 }}>{moment(value).format('DD.MM.YYYY - HH:mm')}</p>,
        display: true,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'action',
      label: intl.formatMessage({ id: 'global.actions' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          if (!value) {
            return <></>;
          }
          return (
            <IconButton aria-label="edit" onClick={() => openActionDialogWithTask(`${value}`)} size="large">
              <Edit fontSize="small" />
            </IconButton>
          );
        },
      },
    },
  ];

  const { mutate, isLoading } = useMutation(updateAmendFailure, {
    onSuccess: data => {
      if (data?.errorMessage) {
        toast.error(data.errorMessage);
      } else {
        toast.success(intl.formatMessage({ id: 'global.dataSuccessfullySend' }));
      }
    },
    onError: (err: Error) => {
      toast.error(err.message.toString());
    },
    onSettled: () => {
      queryClient.invalidateQueries('amendFailures');
    },
  });

  useEffect(() => {
    isLoading ? showLoading('Loading Amend-Failures') : closeLoading();
  }, [isLoading]);

  type ChangeFormValues = {
    status: TypeOfTaskStatus;
    failureType: TypeOfTaskDecision;
    remindDate: Date;
    remark: string;
  };

  const handleConformAmendFailureDialog = () => {
    const msg = new Message(
      keycloak?.profile?.email ?? 'UNKNOWN',
      amendFailureToEdit?.recordLocator!,
      'CALLCENTER',
      'NOTICE',
      amendFailureToEdit?.bookingSource as string,
      'REMOVE_AMEND_ERROR_FROM_LIST',
    );
    addMessage(msg);

    if (amendFailureToEdit) {
      amendFailureToEdit.status = 'CLOSE';
      amendFailureToEdit.action = undefined;
      const updateAmendFailureRequest: CreateOrUpdateAmendFailureRequest = {
        amendFailureToCreateOrUpdate: amendFailureToEdit!,
        updateRemark: 'Manually removed from list',
        updatedBy: keycloak?.profile?.username!,
      };

      mutate(updateAmendFailureRequest);
      setAmendFailureToEdit(undefined);
    }
  };
  return (
    <>
      {amendFailureToEdit && (
        <CustomDialog
          initialOpen={!!amendFailureToEdit}
          onCancel={() => setAmendFailureToEdit(undefined)}
          onConfirm={handleConformAmendFailureDialog}
          width="md"
          cancelButtonText={intl.formatMessage({ id: 'cancel.flight.no' })}
          confirmButtonText={intl.formatMessage({ id: 'cancel.flight.yes' })}
          title="Close Amend-Failure"
          warning
        >
          <Typography variant="h6" color="primary">
            Do you want to close and remove the amend-failure for booking "{amendFailureToEdit.recordLocator}" from
            list?
          </Typography>
        </CustomDialog>
      )}

      <Table
        data={data && Array.isArray(data) && data.length > 0 ? data : []}
        columnsData={columnsData}
        tableId="amendFailureTable"
        tableName="Amend-Failure Table"
        otherOptions={{ rowsPerPage: 10 }}
      />
    </>
  );
};

export default AmendFailuresTable;
