import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { Typography, Box, useMediaQuery } from '@mui/material';
import { TFF } from '@tff/types';
import { FlightLand, FlightTakeoff } from '@mui/icons-material';
import StepConnector from '@mui/material/StepConnector';
import { useTypedSelector } from '../../reducers';
import { weekday } from '../FlightDetailCard';
import { format } from 'date-fns';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '35%',
      marginRight: 0,
      padding: 0,
      '& .MuiStepper-root': {
        backgroundColor: 'transparent',
      },
    },
    icons: {},
  }),
);

const useConnectorStyles = makeStyles({
  stepConnector: (labels: { label: string; currentColor: string }[]) => {
    const styles = {};

    labels.forEach(({ currentColor }, index) => {
      if (index < labels.length - 1) {
        styles[`&:nth-child(${2 * index + 2})`] = { color: currentColor };
      }
    });

    return styles;
  },
  stepConnectorLine: {
    borderColor: '#737373',
    marginBottom: '70px',
    marginRight: '-20px',
    marginLeft: '-60px',
  },
});

interface IFlightTrackProps {
  journey: TFF.Journey;
  airports: TFF.IAirport[];
}

const RebookHorizontalTrack: React.FC<IFlightTrackProps> = ({ journey, airports }) => {
  const classes = useStyles();
  const theme = useTheme();
  const segments: TFF.Segment[] = journey.Segments;
  const lastSegment = segments[segments.length - 1];
  const firstSegment: TFF.Segment | undefined = segments[0];
  const { locale } = useTypedSelector(({ settings }) => settings);

  const changeWidth = useMediaQuery('(max-width: 1240px)');

  const formatDate = (t: string): string | null => {
    if (t) {
      return format(new Date(t), 'dd.MM.yyyy');
    }

    return null;
  };

  const getLabels = () => {
    const labels = segments.map((s, index) => ({
      label: s.Origin,
      currentColor:
        (s.Replacements && s.Replacements?.length > 0) || s.State === 'UN' ? 'red' : theme.palette.primary.main,
      icon: index === 0 ? <FlightTakeoff style={{ marginBottom: 10 }} color="primary" /> : null,
      airports: airports?.find(item => item.id === firstSegment.Origin)?.nameDE,
      city: airports?.find(item => item.id === firstSegment.Origin)?.nameDE,
      hour: new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(new Date(firstSegment.DepartureTime)),
      date: `${weekday(lastSegment?.ArrivalTime, locale.locale).substring(0, 2)}. ${formatDate(
        lastSegment?.DepartureTime,
      )}`,
    }));

    labels.push({
      label: lastSegment.Destination,
      currentColor:
        (lastSegment.Replacements && lastSegment.Replacements?.length > 0) || lastSegment.State === 'UN'
          ? 'red'
          : theme.palette.primary.main,
      icon: <FlightLand style={{ marginBottom: 10 }} color="primary" />,
      airports: airports?.find(item => item.id === lastSegment.Destination)?.nameDE,
      city: airports?.find(item => item.id === lastSegment.Destination)?.nameDE,
      hour: new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(new Date(lastSegment.ArrivalTime)),
      date: `${weekday(lastSegment?.ArrivalTime, locale.locale).substring(0, 2)}. ${formatDate(
        lastSegment?.ArrivalTime,
      )}`,
    });

    return labels;
  };

  const labels = getLabels();

  const connectorClasses = useConnectorStyles(labels);

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={-1}
        connector={
          <StepConnector
            classes={{
              root: connectorClasses.stepConnector,
              line: connectorClasses.stepConnectorLine,
            }}
          />
        }
        sx={{
          width: {
            sm: '120%',
            md: changeWidth ? '120%' : '110%',
            lg: '120%',
          },
          marginLeft: {
            md: changeWidth ? '0px' : '-100px',
            lg: '-50px',
          },
        }}
      >
        {labels.map(({ label, icon, city, hour, date }, index) => (
          <Step key={index}>
            <span
              style={{
                backgroundColor: 'white',
                position: 'relative',
              }}
            >
              {icon && icon}
            </span>
            <Typography component={'span'} variant="h6" color="primary">
              {index == 0 || index == labels.length - 1 ? (
                <strong
                  style={{
                    padding: 10,
                    backgroundColor: 'white',
                    position: 'relative',
                  }}
                >
                  {label}
                </strong>
              ) : (
                <>
                  <CircleOutlinedIcon
                    sx={{
                      width: '8px',
                      height: '8px',
                      marginLeft: '10px',
                      marginTop: '25px',
                      backgroundColor: 'white',
                      position: 'relative',
                    }}
                  />
                  <Typography sx={{ fontSize: '18px', marginTop: '0px', padding: 0 }}>{label}</Typography>
                </>
              )}
            </Typography>
            <Box
              sx={{
                marginLeft: '35px',
                marginRight: '-58px',
                visibility: index == 0 || index == labels.length - 1 ? 'visible' : 'hidden',
              }}
            >
              <Typography>{city}</Typography>
              <Typography>
                <strong>{hour}</strong>
              </Typography>
              <Typography>{date}</Typography>
            </Box>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default RebookHorizontalTrack;
