import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import Table from '../Table';
import { ITableColumns } from '../../models';
import { MUIDataTableMeta } from 'mui-datatables';
import { useRetrieveDbService } from '../../hooks/use-retrieve-db-service';

interface IProps {
  match: {
    url: string;
  };
}

const RetrieveList = (props: IProps) => {
  const intl = useIntl();

  const columnsData: ITableColumns[] = [
    {
      name: 'no',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.no' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (_value: string | number, tableMeta: MUIDataTableMeta) => {
          return `${tableMeta.rowIndex + 1}`;
        },
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: 'product',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.product' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
        },
      },
    },
    {
      name: 'channel',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.channel' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
        },
      },
    },
    {
      name: 'targetEnvironment',
      label: 'Environment',
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
        },
      },
    },
    {
      name: 'tfmPnr',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.tfmPnr' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
          // return <Link to={`${props.match.url}/tfm/${value}`}>{value}</Link>;
        },
      },
    },
    {
      name: 'status',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.status' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
        },
      },
    },
    {
      name: 'bookingResponse.recordLocator',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.bookingid' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number, tableMeta: MUIDataTableMeta) => {
          return <Link to={`/tff/order/${value}@${tableMeta.rowData[9]}`}>{value}</Link>;
        },
      },
    },
    {
      name: 'orderId',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.orderId' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
        },
      },
    },
    {
      name: 'journeyType',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.journeyType' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
        },
      },
    },
    {
      name: 'bookingSource',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.bookingsrc' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
        },
      },
    },
    {
      name: 'midocoOrderId',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.midocoOrderId' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
        },
      },
    },
    {
      name: 'midocoOrderNo',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.midocoOrderNo' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
        },
      },
    },
    {
      name: 'clientPnr',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.clientPnr' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
        },
      },
    },
    {
      name: 'bookingResponse.bookingDate',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.bookingDate' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return (
            <div style={{ whiteSpace: 'nowrap' }}>
              {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
            </div>
          );
        },
      },
    },
    {
      name: 'ticketed',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.ticketed' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number | boolean) => {
          if (value === undefined) {
            return '';
          }
          return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value ? '✅' : '❌'}</div>;
        },
      },
    },

    {
      name: 'ticketTimeLimit',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.ticketTimeLimit' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return (
            <div style={{ whiteSpace: 'nowrap' }}>
              {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
            </div>
          );
        },
      },
    },

    {
      name: 'origin',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.origin' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
        },
      },
    },

    {
      name: 'destination',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.destination' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
        },
      },
    },

    {
      name: 'outDeparture',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.outDeparture' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return (
            <div style={{ whiteSpace: 'nowrap' }}>
              {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
            </div>
          );
        },
      },
    },

    {
      name: 'outBookingClasses',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.outBookingClasses' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
        },
      },
    },
    {
      name: 'outCabinClasses',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.outCabinClasses' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
        },
      },
    },

    {
      name: 'inDeparture',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.inDeparture' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return (
            <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
              {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
            </div>
          );
        },
      },
    },

    {
      name: 'inBookingClasses',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.inBookingClasses' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
        },
      },
    },
    {
      name: 'inCabinClasses',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.inCabinClasses' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value}</div>;
        },
      },
    },

    {
      name: 'bookingSessionId',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.bookingSessId' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
        },
      },
    },

    {
      name: 'createdAt',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.createdAt' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return (
            <div style={{ whiteSpace: 'nowrap' }}>
              {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
            </div>
          );
        },
      },
    },
    {
      name: 'updatedAt',
      label: intl.formatMessage({ id: 'pages.retrieveList.list.updatedAt' }),
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string | number) => {
          return (
            <div style={{ whiteSpace: 'nowrap' }}>
              {value && value !== '' && format(new Date(value), 'dd.MM.yyyy HH:mm')}
            </div>
          );
        },
      },
    },
    {
      name: 'version',
      label: 'Version',
      options: {
        display: true,
        filter: true,
        filterList: [],
      },
    },
  ];

  const { fetchedRetrieveDbItemList } = useRetrieveDbService({ recordLocator: 'ALL' });

  return (
    <>
      <Table
        data={fetchedRetrieveDbItemList ?? []}
        columnsData={columnsData}
        tableId="retrieveDetailsTable"
        otherOptions={{ sortOrder: { name: 'bookingResponse.bookingDate', direction: 'desc' } }}
        tableName={`${intl.formatMessage({ id: 'pages.retrieveList.list.headerStart' })} ${
          fetchedRetrieveDbItemList?.length ?? 0
        } ${intl.formatMessage({ id: 'pages.retrieveList.list.headerEnd' })}`}
      />
    </>
  );
};

export default RetrieveList;
