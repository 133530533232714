import { TFF } from '@tff/types';

/*
  this functionality is to merge 'additional luggages" into an one object
  and sum their amounts. Since luggages can be chosen multiple times for each
  passenger, this approach is required. 
*/

export const mergeBXObjects = (arr: TFF.SsrDetails[]) => {
  const result: TFF.SsrDetails[] = [];
  const bxMap = {} as TFF.SsrDetails;

  arr.forEach(item => {
    const { code, amount, segmentOnd } = item;
    if (code.includes('BX')) {
      const key = `${segmentOnd}`;
      if (!bxMap[key]) {
        bxMap[key] = { ...item };
      } else {
        bxMap[key].amount += amount;
      }
    } else {
      result.push(item);
    }
  });

  Object.values(bxMap).forEach(bxItem => result.push(bxItem));

  return result;
};
