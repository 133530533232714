import { useState, useEffect } from 'react';
import { Box, Button, TextField, Container } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useIntegrateMarket } from '../../hooks/use-integrate-market';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../Dialogs';

const ReImportMarket = ({ setViewDialog }) => {
  const [market, setMarket] = useState<string>('');
  const [orderId, setOrderId] = useState<string>('');
  const [showError, setShowError] = useState(false);
  const isImportDisabled: boolean = (market && orderId) === '';

  const navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent) => {
    setMarket(event.target.value as string);
  };

  const { setIntegrateMarketRequest, integrateError, integrateResponse, integrateStatus } = useIntegrateMarket();

  const handleImport = () => {
    setIntegrateMarketRequest({
      market: market,
      orderId: orderId,
    });
  };

  useEffect(() => {
    if (integrateStatus === 'CONFIRMED') {
      navigate(`/tff/order/${integrateResponse.Retrieve.recordLocator}`);
      setViewDialog(false);
    } else if (integrateStatus === 'FAILURE') {
      setShowError(true);
    }
  }, [integrateStatus, integrateError]);

  const renderErrorItems = () => {
    if (integrateError?.length >= 3) {
      const keysArray = Object.keys(integrateError[2]);

      return keysArray.map((key, index) => {
        const errorObject = Object.values(integrateError[2])[index]?.[0]?.Error;
        return (
          <div key={index}>
            <span>{key}: </span>
            <span>{errorObject?.StatusText ?? errorObject?.DescText ?? 'N/A'}</span>
          </div>
        );
      });
    }
  };

  const handleClose = () => {
    setShowError(false);
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 3,
        }}
      >
        <TextField
          variant="outlined"
          placeholder="OrderId"
          sx={{
            backgroundColor: '#F3F0EC',
            borderRadius: 1,
            width: '300px',
          }}
          onChange={e => setOrderId(e.target.value)}
        ></TextField>
      </Box>
      <Box sx={{ minWidth: 300, marginBottom: 3 }}>
        <FormControl fullWidth>
          <InputLabel id="market-select-label">Market</InputLabel>
          <Select
            labelId="market-select-label"
            id="market-select"
            value={market}
            label="Market"
            onChange={handleChange}
            sx={{
              backgroundColor: '#FFFFFF',
              borderRadius: 1,
            }}
          >
            <MenuItem value={'market_nl'} onSelect={() => setMarket('market_nl')}>
              Market NL
            </MenuItem>
            <MenuItem value={'market_de'} onSelect={() => setMarket('market_de')}>
              Market DE
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Button
          color="primary"
          variant="contained"
          sx={{
            height: '40px',
            paddingLeft: 16,
            paddingRight: 16,
            fontWeight: 'bold',
            fontSize: '0.9rem',
          }}
          disabled={isImportDisabled}
          onClick={() => handleImport()}
        >
          Re-Import
        </Button>
      </Box>
      <CustomDialog initialOpen={showError} cancelButtonText="" confirmButtonText="Close" onConfirm={handleClose}>
        Errors:
        <br />
        {renderErrorItems()}
      </CustomDialog>
    </Container>
  );
};

export default ReImportMarket;
