import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';

//Keycloack Configuration
const kcConfig: KeycloakConfig = {
  realm: 'tff',
  // @ts-ignore
  url: window.ENV?.KEYCLOAK_ENDPOINT,
  clientId: 'tff',
};

// Creating a Keycloak Instance with the given configuration
const keycloak: Keycloak = new Keycloak(kcConfig);
let authenticated;
/**
 * Initialize Keycloak instance and calls the provided callback function if successfully authenticated
 * @param onAuthenticatedCallback
 */
const initKeycloak = async (onAuthenticatedCallback: () => void): Promise<void> => {
  const initOptions: KeycloakInitOptions = {
    onLoad: 'check-sso',
    refreshToken: '5',
    enableLogging: true,
    checkLoginIframe: false,
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  };

  authenticated = await keycloak.init(initOptions);

  onAuthenticatedCallback();
};

keycloak.onAuthSuccess = () => {
  keycloak.loadUserProfile().then(() => {
    console.log('tokenParsed', JSON.stringify(keycloak.tokenParsed, null, 4));
  });
};

keycloak.onTokenExpired = () => {
  console.log('[keyloack.tsx] token expired');
};

keycloak.onActionUpdate = (res: unknown) => {
  console.log('[keycloack.tsx] onActionUpdate', res);
};

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const getToken = (): string | undefined => keycloak.token;

const getProfile = (): Keycloak.KeycloakProfile | undefined => keycloak.profile;

const isTokenExpired = (): ((minValidity?: number | undefined) => boolean) => keycloak.isTokenExpired;

export { initKeycloak, doLogin, doLogout, getToken, getProfile, keycloak, isTokenExpired, authenticated };
