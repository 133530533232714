import React, { createContext, useContext, useState, ReactNode } from 'react';
import { TFF } from '@tff/types';
import { Journey } from '@tff/types/TFF';

interface JourneyDetails {
  Outbound: string[][];
  Return: string[][];
}

interface RebookContextProps {
  dataChanged: boolean;
  searching: boolean;
  rebookSearchState: JourneyDetails;
  defaultValues: string[];
  displayFlights: boolean;
  displayConfirmation: boolean;
  outboundFlights: TFF.Offer[];
  returnFlights: TFF.Offer[];
  flexibleOutboundFlights: TFF.Offer[];
  flexibleReturnFlights: TFF.Offer[];
  outboundNewJourney: TFF.Journey;
  returnNewJourney: TFF.Journey;
  ancillaryPaxData: TFF.PaxData[];
  newRows: any;
  seatAncillary: any;
  searchedDirectionCount: number;
  enableSearchAlternatives: boolean;
  toggleSearchButton: (newState: boolean) => void;
  toggleSearching: (newState: boolean) => void;
  toggleSearchState: (type: 'Outbound' | 'Return', newDetails: string[]) => void;
  toggleDefaultValues: (newState: string[]) => void;
  toggleDisplay: (newState: boolean) => void;
  toggleConfirmation: (newState: boolean) => void;
  toggleFlexibleOutboundFlights: (newState: TFF.Offer[]) => void;
  toggleFlexibleReturnFlights: (newState: TFF.Offer[]) => void;
  toggleOutboundFlights: (newState: TFF.Offer[]) => void;
  toggleReturnFlights: (newState: TFF.Offer[]) => void;
  toggleOutboundNewJourney: (newState: any) => void;
  toggleReturnNewJourney: (newState: any) => void;
  toggleAncillaryPaxData: (newState: TFF.PaxData[]) => void;
  toggleNewRows: (newState: any) => void;
  toggleSeatAncillary: (newState: any) => void;
  incrementSearchedDirectionCount: () => number;
  toggleSearchAlternatives: (value: boolean) => void;
}

const RebookContext = createContext<RebookContextProps>({
  dataChanged: false,
  searching: false,
  rebookSearchState: { Outbound: [], Return: [] },
  defaultValues: [],
  displayFlights: false,
  displayConfirmation: false,
  outboundFlights: [],
  returnFlights: [],
  flexibleOutboundFlights: [],
  flexibleReturnFlights: [],
  outboundNewJourney: {} as TFF.Journey,
  returnNewJourney: {} as TFF.Journey,
  ancillaryPaxData: [],
  newRows: [],
  seatAncillary: [],
  searchedDirectionCount: 0,
  enableSearchAlternatives: false,
  toggleSearchButton: () => {},
  toggleSearching: () => {},
  toggleSearchState: () => {},
  toggleDefaultValues: () => {},
  toggleDisplay: () => {},
  toggleConfirmation: () => {},
  toggleFlexibleOutboundFlights: () => {},
  toggleFlexibleReturnFlights: () => {},
  toggleOutboundFlights: () => {},
  toggleReturnFlights: () => {},
  toggleOutboundNewJourney: () => {},
  toggleReturnNewJourney: () => {},
  toggleAncillaryPaxData: () => {},
  toggleNewRows: () => {},
  toggleSeatAncillary: () => {},
  incrementSearchedDirectionCount: () => 0,
  toggleSearchAlternatives: () => {},
});

export const useRebook = () => {
  return useContext(RebookContext);
};

interface RebookProviderProps {
  children: ReactNode;
}

export const RebookProvider: React.FC<RebookProviderProps> = ({ children }) => {
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [rebookSearchState, setRebookSearchState] = useState<JourneyDetails>({ Outbound: [], Return: [] });
  const [displayFlights, setDisplayFlights] = useState<boolean>(false);
  const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<string[]>([]);
  const [flexibleOutboundFlights, setFlexibleOutboundFlights] = useState<TFF.Offer[]>([]);
  const [flexibleReturnFlights, setFlexibleReturnFlights] = useState<TFF.Offer[]>([]);
  const [outboundFlights, setOutboundFlights] = useState<TFF.Offer[]>([]);
  const [returnFlights, setReturnFlights] = useState<TFF.Offer[]>([]);
  const [outboundNewJourney, setOutboundNewJourney] = useState<TFF.Journey>({} as Journey);
  const [returnNewJourney, setReturnNewJourney] = useState<TFF.Journey>({} as Journey);
  const [ancillaryPaxData, setAncillaryPaxData] = useState<TFF.PaxData[]>([]);
  const [newRows, setNewRows] = useState<any>([]);
  const [seatAncillary, setSeatAncillary] = useState<any>([]);
  const [searchedDirectionCount, setSearchedDirectionCount] = useState<number>(0);
  const [enableSearchAlternatives, setEnableSearchAlternatives] = useState<boolean>(false);

  const toggleSearchAlternatives = (value: boolean) => {
    setEnableSearchAlternatives(true);
  };

  const incrementSearchedDirectionCount = () => {
    setSearchedDirectionCount(prevCount => prevCount + 1);
    return searchedDirectionCount;
  };

  const toggleNewRows = (newState: any) => {
    setNewRows(newState);
  };

  const toggleSeatAncillary = (newState: any) => {
    setSeatAncillary(newState);
  };

  const toggleSearchButton = () => {
    setDataChanged(true);
  };

  const toggleSearching = () => {
    setSearching(true);
  };

  const toggleSearchState = (type: 'Outbound' | 'Return', newDetails: string[]) => {
    setRebookSearchState(prevState => ({
      ...prevState,
      [type]: [newDetails],
    }));
  };

  const toggleDisplay = (newState: boolean) => {
    setDisplayFlights(newState);
  };

  const toggleConfirmation = (newState: boolean) => {
    setDisplayConfirmation(newState);
  };

  const toggleDefaultValues = (newState: string[]) => {
    setDefaultValues(newState);
  };

  const toggleFlexibleOutboundFlights = (newState: TFF.Offer[]) => {
    setFlexibleOutboundFlights(newState);
  };

  const toggleFlexibleReturnFlights = (newState: TFF.Offer[]) => {
    setFlexibleReturnFlights(newState);
  };

  const toggleOutboundFlights = (newState: TFF.Offer[]) => {
    setOutboundFlights(newState);
  };

  const toggleReturnFlights = (newState: TFF.Offer[]) => {
    setReturnFlights(newState);
  };

  const toggleOutboundNewJourney = (newState: TFF.Journey) => {
    setOutboundNewJourney(newState);
  };

  const toggleReturnNewJourney = (newState: TFF.Journey) => {
    setReturnNewJourney(newState);
  };

  const toggleAncillaryPaxData = (newState: TFF.PaxData[]) => {
    setAncillaryPaxData(newState);
  };

  const states = {
    dataChanged,
    toggleSearchButton,
    searching,
    toggleSearching,
    rebookSearchState,
    toggleSearchState,
    displayFlights,
    displayConfirmation,
    toggleDisplay,
    toggleConfirmation,
    defaultValues,
    toggleDefaultValues,
    flexibleOutboundFlights,
    toggleFlexibleOutboundFlights,
    flexibleReturnFlights,
    toggleFlexibleReturnFlights,
    outboundFlights,
    toggleOutboundFlights,
    returnFlights,
    toggleReturnFlights,
    outboundNewJourney,
    toggleOutboundNewJourney,
    returnNewJourney,
    toggleReturnNewJourney,
    ancillaryPaxData,
    toggleAncillaryPaxData,
    newRows,
    toggleNewRows,
    seatAncillary,
    toggleSeatAncillary,
    searchedDirectionCount,
    incrementSearchedDirectionCount,
    enableSearchAlternatives,
    toggleSearchAlternatives,
  };

  return <RebookContext.Provider value={states}>{children}</RebookContext.Provider>;
};
