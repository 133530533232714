import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { apigeeEndpoint } from '../apis';
import { AmendTfaAncillaryLambdaRequest } from '@tff/types/TFF';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { AmendTfaAncillaryLambdaResponse } from '@tff/types/TFF/tfa-amend-ancillary-service';

export type TypeOfStatus = 'IN_ANCILLARY' | 'CONFIRMED' | 'FAILURE';

export type UseSsrResponse = {
  ssrResponse?: AmendTfaAncillaryLambdaResponse;
  ssrStatus: TypeOfStatus;
  ssrError?: Error | undefined;
  setSsrUpdateRequest: (ssrUpdateRequest: AmendTfaAncillaryLambdaRequest) => void;
};

export const useAddSsr = (): UseSsrResponse => {
  const { showLoading, closeLoading } = useContext(LoadingContext);

  const [ssrStatus, setSsrStatus] = React.useState<TypeOfStatus>('IN_ANCILLARY');
  const [ssrResponse, setSsrResponse] = React.useState<AmendTfaAncillaryLambdaResponse>();
  const [ssrError, setSsrError] = React.useState<Error>();
  const [ssrUpdateRequest, setSsrUpdateRequest] = useState<AmendTfaAncillaryLambdaRequest>();
  useEffect(() => {
    const ssrFromBackend = async (ssrUpdateRequest: AmendTfaAncillaryLambdaRequest): Promise<void> => {
      try {
        setSsrStatus('IN_ANCILLARY');
        showLoading('Please Wait');

        const response = await apigeeEndpoint.post('ancillary-amend', ssrUpdateRequest);

        if (response.data.status === 'SUCCESS') {
          if (response.data.body != undefined) {
            setSsrStatus('CONFIRMED');
            setSsrResponse(response.data.data ?? response.data);
          }
          closeLoading();
        } else {
          setSsrStatus('FAILURE');
          setSsrError(response.data.error);
          closeLoading();
        }
      } catch (error) {
        setSsrStatus('FAILURE');
        closeLoading();
      }
    };

    if (ssrUpdateRequest) {
      void ssrFromBackend(ssrUpdateRequest);
    }
  }, [ssrUpdateRequest]);

  return {
    setSsrUpdateRequest,
    ssrError,
    ssrResponse,
    ssrStatus,
  };
};
