import * as React from 'react';
import { useMemo } from 'react';
import { CabinClassEnum, CarrierEnum, DeferedPaymentProps, ProductsEnum } from './models';
import { Field, Form as FinalForm } from 'react-final-form';
import { Button, Grid, MenuItem, Paper, Chip, TextField } from '@mui/material';
import { Alert } from '@mui/material';
import { renderKeyboardDatePicker, renderMultiselectField, renderSelectField } from '../../forms/Fields';
import { validate } from '../utils/validation-schema';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

export type DeferedPaymentFormProps = {
  onSubmit: (formValues: DeferedPaymentProps) => void;
};

const DeferedPaymentForm: React.FC<DeferedPaymentFormProps> = ({ onSubmit }) => {
  const initialDeferedPayment = useMemo(() => {
    return {} as Partial<DeferedPaymentProps>;
  }, []);

  return (
    <FinalForm<DeferedPaymentProps>
      onSubmit={onSubmit}
      initialValues={initialDeferedPayment}
      validate={validate}
      validateOnBlur={false}
      render={({ values }) => {
        return (
          <Paper elevation={2} style={{ padding: 20 }}>
            <div style={{ width: '30%', marginBottom: 10 }}>
              <Alert variant="outlined" severity="warning">
                Only <strong>TFM-Nr</strong> or <strong>Product</strong> are enough to search for{' '}
                <strong>payments</strong>
              </Alert>
            </div>

            <Grid container spacing={3}>
              <Grid item xs={1}>
                <Field name="tfmPnr">
                  {({ input, meta }) => (
                    <TextField
                      variant="standard"
                      {...input}
                      label="TFM-PNR"
                      helperText={(meta.dirty || meta.submitFailed) && meta.touched && meta.error}
                      inputProps={{ maxLength: 6, style: { textTransform: 'uppercase' } }}
                      fullWidth
                    />
                  )}
                </Field>
              </Grid>
              <Grid container item xs={1} alignItems="center" justifyContent="center" style={{ marginTop: '-15px' }}>
                <div
                  style={{
                    width: '2px',
                    background: 'lightgrey',
                    height: '80%',
                    marginTop: '20%',
                  }}
                ></div>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={2} direction="row">
                  <Grid item xs={2}>
                    <Field name="product">
                      {({ _input, meta }) => (
                        <Field
                          name="product"
                          label="Product"
                          component={renderSelectField}
                          helperText={(meta.dirty || meta.submitFailed) && meta.touched && meta.error}
                        >
                          {ProductsEnum &&
                            Object.keys(ProductsEnum).map(key => (
                              <MenuItem key={key} value={ProductsEnum[key]}>
                                {ProductsEnum[key]}
                              </MenuItem>
                            ))}
                        </Field>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      name="bookingSource"
                      labelname="Carrier"
                      component={renderMultiselectField}
                      renderValue={selected => (
                        <div className="multi-select-chips">
                          {selected.map(value => (
                            <Chip key={value} label={CarrierEnum[value]} className="multi-select-chip" />
                          ))}
                        </div>
                      )}
                    >
                      {Object.entries(CarrierEnum).map(([key, value], index) => (
                        <MenuItem key={index} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={2}>
                    <Field name="origin">
                      {({ input, meta }) => (
                        <TextField
                          variant="standard"
                          {...input}
                          label="Origin"
                          helperText={(meta.dirty || meta.submitFailed) && meta.touched && meta.error}
                          inputProps={{ maxLength: 3, style: { textTransform: 'uppercase' } }}
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={2}>
                    <Field name="destination">
                      {({ input, meta }) => (
                        <TextField
                          variant="standard"
                          {...input}
                          label="Destination"
                          helperText={(meta.dirty || meta.submitFailed) && meta.touched && meta.error}
                          inputProps={{ maxLength: 3, style: { textTransform: 'uppercase' } }}
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      name="outCabinClasses"
                      labelname="Cabin Class"
                      component={renderMultiselectField}
                      renderValue={selected => (
                        <div className="multi-select-chips">
                          {selected.map(value => (
                            <Chip key={value} label={CabinClassEnum[value]} className="multi-select-chip" />
                          ))}
                        </div>
                      )}
                    >
                      {Object.entries(CabinClassEnum).map(([key, value], index) => (
                        <MenuItem key={index} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                  <Grid item xs={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Field
                        name="departureDateFrom"
                        label="Departure Date From"
                        component={renderKeyboardDatePicker}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Field name="departureDateTo" label="Departure Date To" component={renderKeyboardDatePicker} />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Field
                        name="lastTicketingDate"
                        label="Last Ticketing Date (After)"
                        component={renderKeyboardDatePicker}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {false && (
                    <Grid item xs={2}>
                      <Field name="midocoOrderId">
                        {({ input, meta }) => (
                          <TextField
                            variant="standard"
                            {...input}
                            label="Midoco Order Id"
                            helperText={(meta.dirty || meta.submitFailed) && meta.touched && meta.error}
                            fullWidth
                          />
                        )}
                      </Field>
                    </Grid>
                  )}
                  <Grid container item xs={2} alignItems="flex-end" justifyContent="center">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() => onSubmit(values)}
                      fullWidth
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        );
      }}
    />
  );
};

export default DeferedPaymentForm;
