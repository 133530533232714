import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import { useEffect, useState, memo } from 'react';
import { TypeOfCancellationReasonNSK, TypeOfRefund } from '@tff/types/TFF';
import { useIntl } from 'react-intl';

export type CancelQueue = {
  id: string;
  queueNote: string;
  refundType: TypeOfRefund;
  noteEditable?: boolean;
  cancellationReasons: TypeOfCancellationReasonNSK[];
};

export type FieldType = {
  key: string;
  value: string;
  hint?: string;
  restrictedTo?: string[];
  queue?: CancelQueue;
};

interface IProps {
  fieldName: string;
  fieldValues: FieldType[];
  onChange: (selectedField: FieldType) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectFieldRoot: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: 500,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    fieldName: {
      fontSize: '16px',
      fontWeight: 'bold',
      alignSelf: 'center',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    },
    rootSelect: {
      padding: '0px',
      color: theme.palette.text.primary,
    },
    select: {
      color: theme.palette.text.primary,

      '&:before': {
        borderColor: theme.palette.text.primary,
      },
      '&:after': {
        borderColor: theme.palette.text.primary,
      },
    },
    textField: {
      fontWeight: 500,
      padding: '10px',
      border: '1px solid #092A5E',
      backgroundColor: 'white',
    },
    multilineColor: {
      color: theme.palette.text.primary,
      fontSize: '18px',
    },
    selectInput: {
      color: '#092A5E',
    },
  }),
);

const SelectField: React.FC<IProps> = ({ fieldName, fieldValues, onChange }) => {
  const [selectedFieldItem, setSelectedFieldItem] = useState<FieldType>();
  const [selectedField, setSelectedField] = useState<string | undefined>('');
  const [helperText, setHelperText] = useState<string | undefined>();
  const intl = useIntl();

  useEffect(() => {
    if (selectedFieldItem) {
      onChange(selectedFieldItem!);
    }
  }, [selectedFieldItem, onChange]);

  const classes = useStyles();

  const handleChange = (event: SelectChangeEvent<string>) => {
    const key = event.target.value as string;
    const _selectedField = fieldValues.find(field => field.key === key);
    setSelectedField(key);
    setSelectedFieldItem(_selectedField);
    const helperText = _selectedField?.hint;
    setHelperText(helperText as string);
  };

  return (
    <div className={classes.selectFieldRoot}>
      <Typography color="primary" variant="h6" className={classes.fieldName}>
        {fieldName}
      </Typography>
      <FormControl variant="standard" className={classes.formControl}>
        <Select
          variant="standard"
          labelId="open-select-label"
          id="open-select"
          value={selectedField}
          onChange={handleChange}
          classes={{ select: classes.rootSelect }}
          className={classes.select}
          inputProps={{ className: classes.selectInput }}
        >
          {fieldValues.map(item => (
            <MenuItem key={item.key} value={item.key}>
              {intl.formatMessage({ id: `${item.value}` })}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default memo(SelectField);
