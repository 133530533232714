import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISourceConfig } from '../../models';

export interface IFlightSettings {
  midocoNo: string | undefined;
  bookingId: string | undefined;
  sourceConfig: ISourceConfig | undefined;
}

export interface IFlightSettingsStoreState {
  flightSettings: IFlightSettings | undefined;
  error: string | undefined;
  loading: boolean;
  state: FLIGHT_SETTINGS_STATES;
}

enum FLIGHT_SETTINGS_STATES {
  UNKOWN = 'UNKOWN',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

const initialState: IFlightSettingsStoreState = {
  flightSettings: undefined,
  error: undefined,
  loading: false,
  state: FLIGHT_SETTINGS_STATES.UNKOWN,
};

type FlightPayload = {
  flightSettings: IFlightSettings | undefined;
  error: string | undefined;
  loading: boolean;
  state: FLIGHT_SETTINGS_STATES;
};

const flightSettingsSlice = createSlice({
  name: 'flightSettings',
  initialState,
  reducers: {
    setFlightSettings: (state, action: PayloadAction<FlightPayload>) => {
      state.flightSettings = action.payload.flightSettings;
      state.error = action.payload.error;
      state.loading = action.payload.loading;
      state.state = action.payload.state;
    },
    resetFlightSettings: state => {
      state.flightSettings = undefined;
      state.error = undefined;
      state.loading = false;
      state.state = FLIGHT_SETTINGS_STATES.UNKOWN;
    },
  },
});

export const { setFlightSettings, resetFlightSettings } = flightSettingsSlice.actions;

export default flightSettingsSlice.reducer;
