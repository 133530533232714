import { TFF } from '@tff/types';
import { AmendTfaAncillaryLambdaRequest } from '@tff/types/TFF';

export const map6MResults = (
  flightAfter,
  recordLocator: string,
  seatAncillaries: TFF.Ancillary[],
): AmendTfaAncillaryLambdaRequest => {
  const passengerSeats = {};

  flightAfter.forEach(flight => {
    flight.passengers.forEach(passenger => {
      let seatCode: string | undefined;
      if (passenger.seat?.row && passenger.seat.column) {
        seatCode = passenger.seat.row + passenger.seat.column;
      }
      const segmentOnd = `${flight.legs[0].origin}-${flight.legs[0].destination}`;

      if (!passengerSeats[passenger.id]) {
        passengerSeats[passenger.id] = {
          paxId: passenger.id,
          removeSsrIds: [],
          removeSeatIds: [],
          addSeats: [],
          addSsrs: [],
        };
      }

      if (!seatCode) {
        const seatAncillary = seatAncillaries.find(s => s.PaxID === passenger.id && s.SegmentOnd === segmentOnd);
        seatAncillary && passengerSeats[passenger.id].removeSeatIds.push(seatAncillary.Id);
      } else {
        passengerSeats[passenger.id].addSeats.push({
          code: seatCode,
          segmentOnd: segmentOnd,
        });
      }
    });
  });

  return {
    recordLocator,
    bookingSource: 'TUI-NSK',
    paxData: Object.values(passengerSeats),
  };
};
