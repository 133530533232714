import { TypeOfTFFBookingSource } from '@tff/types/TFF';
import { TypeOfSource } from '@tff/types/TFF/retrieve';

export type TypeOfSourceGroup = 'TUI_NSK' | 'TUI_NDC' | 'TUI_AMADEUS' | 'TUI_NDCX' | 'HV_NSK';

export interface ISourceConfig {
  // ID for this source
  Id: string;
  // BookingSource un zu identifizieren wo der Flug zu retrieven ist
  BookingSource?: TypeOfTFFBookingSource;

  // Sourcename ist die bezeichnung der Source
  SourceName: TypeOfSource;
  // Gruppe zu welcher die Source gehört. Bsp TUI_NSK| TUI_NDC | 'HV_NSK'
  SourceGroup: TypeOfSourceGroup;
  //  ist ein kürzel, welches für die endpoint-URL benötigt wird
  ApiPath: string;
  // Entspricht der BookingSource. Da es mal der sourceIdent,
  // mal der Provider und mal die airline sein kann machen wir ein array draus
  SourceAliase: string[];
  // die Version der Source. Im spezielen bei NDC notwendig
  SourceVersion?: string;

  _responseModel?: { new (...args: any): any };
}

// @ts-ignore
const sourceConfigData: ISourceConfig[] = [
  {
    Id: 'TUI-NSK',
    SourceAliase: ['NSK', 'X3', '565'],
    BookingSource: 'TUI-NSK',
    SourceName: 'NSK',
    SourceGroup: 'TUI_NSK',
    SourceVersion: '18.2',
    ApiPath: 'TUI-NSK',
  },
  {
    Id: 'TUI-NDC-AF',
    SourceAliase: ['AF', '781', 'NDC_AF'],
    BookingSource: 'TUI-NDC-AF',
    ApiPath: 'TUI-NDC-AF',
    SourceName: 'AF',
    SourceGroup: 'TUI_NDC',
    SourceVersion: '18.2',
  },
  {
    Id: 'TUI-NDC-KL',
    SourceAliase: ['KL', '781', 'NDC_KL'],
    BookingSource: 'TUI-NDC-KL',
    ApiPath: 'TUI-NDC-KL',
    SourceName: 'KL',
    SourceGroup: 'TUI_NDC',
    SourceVersion: '18.2',
  },
  {
    Id: 'TUI-NDC-EK',
    SourceAliase: ['EK', '784', 'NDC_EK'],
    BookingSource: 'TUI-NDC-EK',
    ApiPath: 'TUI-NDC-EK',
    SourceName: 'EK',
    SourceGroup: 'TUI_NDC',
    SourceVersion: '17.2',
  },
  {
    Id: 'TUI-NDC-SQ',
    SourceAliase: ['SQ', '783', 'NDC_SQ'],
    BookingSource: 'TUI-NDC-SQ',
    ApiPath: 'TUI-NDC-SQ',
    SourceName: 'SQ',
    SourceGroup: 'TUI_NDC',
    SourceVersion: '18.1',
  },
  {
    Id: 'TUI-NDCX',
    SourceAliase: ['BA', '323', 'NDCX'],
    BookingSource: 'TUI-NDCX',
    ApiPath: 'NDCX',
    SourceName: 'NDCX',
    SourceGroup: 'TUI_NDCX',
    SourceVersion: 'AMA_18.1',
  },
  {
    Id: 'TUI-AMADEUS',
    SourceAliase: ['AMA', 'AMADEUS'],
    BookingSource: 'TUI-AMADEUS',
    ApiPath: 'AMADEUS',
    SourceName: 'AMADEUS',
    SourceGroup: 'TUI_AMADEUS',
    SourceVersion: 'AMA_18.1',
  },
];

export const getSourceConfig = (_bookingSource: TypeOfTFFBookingSource | TypeOfSource): ISourceConfig | undefined => {
  let bookingSource: TypeOfTFFBookingSource = _bookingSource as TypeOfTFFBookingSource;
  switch (_bookingSource) {
    case 'NSK':
      bookingSource = 'TUI-NSK';
      break;
    case 'AF':
      bookingSource = 'TUI-NDC-AF';
      break;
    case 'KL':
      bookingSource = 'TUI-NDC-KL';
      break;
    case 'EK':
      bookingSource = 'TUI-NDC-EK';
      break;
    case 'SQ':
      bookingSource = 'TUI-NDC-SQ';
      break;
    case 'NDCX':
      bookingSource = 'TUI-NDCX';
      break;
    case 'AMADEUS':
      bookingSource = 'TUI-AMADEUS';
      break;
  }

  return (
    sourceConfigData.find(sc => sc.BookingSource === bookingSource) ??
    sourceConfigData.find(sc => sc.SourceAliase.includes(bookingSource))
  );
};
