import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import {
  Button,
  FormHelperText,
  Grid,
  TextField,
  Alert,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { apigeeEndpoint } from '../../apis';
import { useQueryClient } from 'react-query';
import AmendFailuresTable from './AmendFailuresTable';
import { LoadingContext } from '../../contexts/LoadingContextProvider';
import { AmendFailureDynamoItem } from '@tff/types/TFF';
import { useIntl } from 'react-intl';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export type EnhancedAmendFailureDynamoItem = AmendFailureDynamoItem & { action?: string };

const searchSchema = yup.object().shape({
  recordLocatorToSearch: yup
    .string()
    .test('len', 'Must be exactly 6 characters', val => !val || val.length === 0 || val.length === 6),
  productToSearch: yup.string(),
  archive: yup.boolean().optional(),
});

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      margin: '0 auto',
      width: '100%',
      padding: '5%',
    },
    formRoot: {
      borderRadius: 0,
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      minWidth: 200,
      margin: '15px',
    },
    formLabel: {
      marginLeft: 10,
    },
    helperText: {
      color: 'red',
      fontSize: '16px',
    },
    tableRoot: {
      marginTop: '50px',
    },
    header: {
      margin: '20px',
      padding: '10px',
      color: 'white',
      backgroundColor: '#092A5E',
    },
  }),
);

const AmendFailure: React.FC<{ pnr: string }> = ({ pnr }) => {
  const intl = useIntl();
  const classes = useStyle();
  const queryClient = useQueryClient();
  const [amendFailure, setAmendFailure] = useState<EnhancedAmendFailureDynamoItem[]>([]);
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchDone, setSearchDone] = useState<boolean | undefined>(undefined);
  const {
    register: registerSearch,
    handleSubmit: handleSearchSubmit,
    formState: { errors: searchErrors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(searchSchema),
    defaultValues: {
      archive: false,
      recordLocatorToSearch: pnr || '',
      productToSearch: '',
    },
  });

  const archiveChecked = watch('archive');

  const loadSearchedData = async (archive: boolean, product?: string, recordLocator?: string) => {
    showLoading(`Suche AmendFailures in Datenbank`);
    await queryClient.fetchQuery(
      'amendFailures',
      async () => {
        const response = await apigeeEndpoint.get<EnhancedAmendFailureDynamoItem[] | { errorMessage: string }>(
          archive
            ? `amend-failure/archive/${product === '' ? '*' : product}/${recordLocator}`
            : `amend-failure/${product === '' ? '*' : product}/${recordLocator}`,
        );
        setSearchDone(true);
        if ('errorMessage' in response.data) {
          setAmendFailure([]);
          return [];
        }
        const enhancedAmendFailureDynamoItems: EnhancedAmendFailureDynamoItem[] = response.data.map(item => {
          const action = item.status === 'NEW' ? item.sortKey : undefined;
          return { ...item, action };
        });

        setErrorMessage('');
        setAmendFailure(enhancedAmendFailureDynamoItems);
        return response.data;
      },
      {
        retry: 2,
        staleTime: 1000,
        cacheTime: 50000,

        initialData: () => {
          return queryClient.getQueryData('amendFailures');
        },
      },
    );
    closeLoading();
  };

  useEffect(() => {
    if (pnr !== '' && pnr.length === 6) {
      loadSearchedData(false, '', pnr);
    } else if (pnr !== '' && pnr.length !== 6) {
      setErrorMessage('PNR IS NOT VALID');
    }
  }, []);

  const submitSearchForm = (data: { [x: string]: any }) => {
    const product = data.productToSearch;
    const recordLocator = data.recordLocatorToSearch;
    const archive = data.archive;
    loadSearchedData(archive, product, recordLocator);
  };

  const SearchForm: React.FC = () => (
    <Grid container direction="row" justifyContent="space-between">
      <form onSubmit={handleSearchSubmit(submitSearchForm)} style={{ width: '100%' }}>
        <Grid container justifyContent="center">
          <Grid item xs={2} style={{ marginRight: '20px' }}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="product-select-label">{intl.formatMessage({ id: 'global.product' })}</InputLabel>
              <Select {...registerSearch('productToSearch')} name="productToSearch" defaultValue="">
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="atcomres">Atcom</MenuItem>
                <MenuItem value="aircruiser">Aircruiser</MenuItem>
                {/* Value of 'aircruiser' might change according to Backend */}
              </Select>
            </FormControl>
            {searchErrors.productToSearch && (
              <FormHelperText className={classes.helperText}>
                {searchErrors.productToSearch?.message?.toString()}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={2}>
            <TextField
              {...registerSearch('recordLocatorToSearch')}
              type="text"
              name="recordLocatorToSearch"
              variant="filled"
              label={intl.formatMessage({ id: 'global.recordLocator' })}
              fullWidth
              inputProps={{ maxLength: 6, style: { textTransform: 'uppercase' } }}
              defaultValue={pnr}
            />
            {searchErrors.recordLocatorToSearch && (
              <FormHelperText className={classes.helperText}>
                {searchErrors.recordLocatorToSearch?.message?.toString()}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={2}>
            <FormGroup row style={{ display: 'flex', justifyContent: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...registerSearch('archive')}
                    checked={archiveChecked}
                    onChange={e => setValue('archive', e.target.checked)}
                  />
                }
                label={intl.formatMessage({ id: 'global.displayArchive' })}
                labelPlacement="end"
              />
            </FormGroup>
            {searchErrors.archive && (
              <FormHelperText className={classes.helperText}>
                {searchErrors.archive?.message?.toString()}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              id="submit"
              style={{ marginRight: '20px', marginLeft: '20px', height: '56px' }}
            >
              {intl.formatMessage({ id: 'global.search' })}
            </Button>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </Box>
      </form>
    </Grid>
  );

  const renderTable = () => {
    if (!searchDone) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography variant="h6">Please select a product and start search</Typography>
        </Box>
      );
    } else {
      if (amendFailure.length === 0) {
        return (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography variant="h6">Sorry, no matching results found</Typography>
          </Box>
        );
      }
      return <AmendFailuresTable data={amendFailure} />;
    }
  };

  return (
    <div className={classes.root}>
      <SearchForm />
      <div className={classes.tableRoot}>{renderTable()}</div>
    </div>
  );
};

export default AmendFailure;
