import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { apigeeEndpoint } from '../apis';

import { LoadingContext } from '../contexts/LoadingContextProvider';
import { Booking, ServicePackageConnectorRequest, ServicePackages } from '@tff/types/TFF/service-package-connector';

type TypeOfStatus = 'FETCHING' | 'CONFIRMED' | 'FAILURE';

export interface ServicePackageConnectorResponse {
  request: ServicePackageConnectorRequest;
  sessionId: string;
  booking: Booking;
  servicePackages: ServicePackages;
  status: 'failure' | 'success';
}

export type UseServiceResponse = {
  serviceResponse?: ServicePackageConnectorResponse;
  serviceStatus: TypeOfStatus;
  serviceError?: Error | undefined;
  setServiceRequest: (serviceRequest: string | undefined) => void;
};

export const useFetchServices = (): UseServiceResponse => {
  const { showLoading, closeLoading } = useContext(LoadingContext);

  const [serviceStatus, setServiceStatus] = React.useState<TypeOfStatus>('FETCHING');
  const [serviceResponse, setServiceResponse] = React.useState<ServicePackageConnectorResponse>();
  const [serviceError, setServiceError] = React.useState<Error | undefined>();
  const [serviceRequest, setServiceRequest] = useState<string | undefined>();

  const queryClient = useQueryClient();

  useEffect(() => {
    const ssrFromBackend = async (tfmPnrOrServicePackageId: string): Promise<void> => {
      try {
        const queryKey = `get:service-package:${tfmPnrOrServicePackageId}`;

        setServiceStatus('FETCHING');
        showLoading('Please Wait');

        const response = await queryClient.fetchQuery(
          queryKey,
          async () => {
            return await apigeeEndpoint.get(`service-package/booking/${tfmPnrOrServicePackageId}`);
          },
          {
            retry: 2,
            cacheTime: 10 * 60 * 1000,
            // staleTime: 10 * 60 * 1000,
            initialData: () => {
              return queryClient.getQueryData<any>(queryKey);
            },
          },
        );

        if (response.data.status === 'successful') {
          setServiceStatus('CONFIRMED');
          setServiceResponse(response.data.response);
          closeLoading();
        } else {
          setServiceStatus('FAILURE');
          setServiceError(response.data.error);
          closeLoading();
        }
      } catch (error) {
        setServiceStatus('FAILURE');
        closeLoading();
      }
    };

    if (serviceRequest) {
      void ssrFromBackend(serviceRequest);
    }
  }, [serviceRequest]);

  return {
    setServiceRequest,
    serviceError,
    serviceResponse,
    serviceStatus,
  };
};
