import { Paper, Typography, Theme, Box, Container } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { IntlMessages } from '../../util';
import { useIntl } from 'react-intl';
import RenderFlightTrack from './RenderFlightTrack';
import { ErrorOutline } from '@mui/icons-material';
import { TFF } from '@tff/types';
import FlightSegment from '../FlightDetailCard/FlightSegment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 400,
      color: '#0076B5',
      textDecoration: 'underline',
    },
    flightDetailRoot: {
      margin: '5px 0',
      witdh: '100%',
      '& .MuiCollapse-root': {
        width: '100%',
      },
      '& .MuiCollapse-entered': {
        width: '100%',
        marginBottom: '-20px',
      },
    },
    offer: {
      width: '100%',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '10px',
      borderColor: '#D6D1C2',
      margin: '10px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: '#E2F5FD',
    },
  }),
);

const MuiAccordion = withStyles({
  root: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',

    alignItems: 'flex-end',
    '&::before': {
      display: 'none',
    },
  },
})(Accordion);

const MuiAccordionSummary = withStyles({
  root: {
    width: 'fit-content',
    borderRadius: '5px',
    marginRight: '3px',
    minHeight: '28px',
    maxHeight: '28px',
  },
  content: {
    marginRight: '5px',
  },
  expanded: {
    borderRadius: '0px',
    borderTopRightRadius: '5px ',
    borderTopLeftRadius: '5px',
  },
  expandIcon: {},
})(AccordionSummary);

const MuiAccordionDetails = withStyles({
  root: {
    backgroundColor: '#E2F5FD',
    display: 'block',
    width: '100%',
    marginBottom: '15px',
  },
})(AccordionDetails);
interface props {
  offer: TFF.Journey;
  airports: TFF.IAirport[];
  orderDetails: TFF.OrderDetails;
  price: string[];
}

export const RebookNewOffer: React.FC<props> = ({ offer, airports, orderDetails, price }) => {
  const classes = useStyles();
  const intl = useIntl();
  const segments: TFF.Segment[] = offer.Segments;

  const flightPrice = offer.Ond?.split('-')[1] === price[0] ? price[1] : price[3];

  return flightPrice ? (
    <>
      <Paper elevation={0} className={classes.offer}>
        <Container sx={{ padding: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            <Typography sx={{ color: '#00308F', fontWeight: 'bold', padding: 1, marginRight: '7ch' }}>
              {intl.formatMessage({ id: 'rebook.newOffer' }).toUpperCase()}
            </Typography>
            {offer && airports && <RenderFlightTrack journey={offer} airports={airports} isNewOffer={true} />}
            <Typography sx={{ marginBottom: 6, marginLeft: 12 }}>
              <ErrorOutline sx={{ color: '#00308F', fontSize: '24px', marginBottom: 1 }} /> {offer?.FareFamily}
            </Typography>
            <Typography
              sx={{
                fontSize: '24px',
                lineHeight: '27.46px',
                color: '#00308F',
                fontWeight: '700',
                padding: 1,
                marginLeft: 'auto',
              }}
            >
              {`+${flightPrice}€`}
            </Typography>
          </Box>
          <div className={classes.flightDetailRoot}>
            <MuiAccordion>
              <MuiAccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#0076B5' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  padding: '0px 0px 0px 5px',
                }}
              >
                <Typography component={'span'} className={classes.heading} style={{ fontSize: '18px' }}>
                  <IntlMessages id="pages.flightDetails.flightDetails" />
                </Typography>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                {segments.map((segment: TFF.Segment, index: number) => {
                  return (
                    <FlightSegment
                      key={index}
                      tffSegment={segment as TFF.Segment}
                      orderDetails={orderDetails}
                      isNewOffer={true}
                    />
                  );
                })}
              </MuiAccordionDetails>
            </MuiAccordion>
          </div>
        </Container>
      </Paper>
    </>
  ) : null;
};
