import * as React from 'react';
import { ReactNode, useState, useMemo } from 'react';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import { ITableColumns, IOptionsJoinMUIDataTableOptions } from '../models';

enum LocalStorageSuffix {
  columnOrder = '_COLUMN_ORDER',
  columnDisplayOverride = '_COLUMN_DISPLAY_OVERRIDE',
  columnFilter = '_COLUMN_FILTER',
  rowsPerPage = '_ROWS_PER_PAGE',
}

const generateLocalStorageKeys = (id: string) => {
  const prefix = 'TUI_TABLE';
  return {
    columnOrder: `${prefix}${id}${LocalStorageSuffix.columnOrder}`,
    columnDisplayOverride: `${prefix}${id}${LocalStorageSuffix.columnDisplayOverride}`,
    columnFilter: `${prefix}${id}${LocalStorageSuffix.columnFilter}`,
    rowsPerPage: `${prefix}${id}${LocalStorageSuffix.rowsPerPage}`,
  };
};

const setLocalStorage = (key: string, value: any): boolean => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (e: any) {
    return false;
  }
};

const getLocalStorage = <Type,>(key: string): Type | undefined => {
  try {
    const unparsed = localStorage.getItem(key);
    if (!unparsed) {
      return undefined;
    }
    return JSON.parse(unparsed);
  } catch (e: any) {
    removeLocalStorage(key);
    return undefined;
  }
};

const removeLocalStorage = (key: string): boolean => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (e: any) {
    return false;
  }
};

interface Props {
  tableId: string;
  tableName: string | ReactNode;
  data: any[];
  columnsData: ITableColumns[];
  otherOptions?: MUIDataTableOptions;
}

const Table: React.FC<Props> = ({ data, columnsData, tableId, tableName, otherOptions }) => {
  const localStorageKeys = useMemo(() => {
    return generateLocalStorageKeys(tableId);
  }, [tableId]);

  const rowsPerPageOptions = [10, 30, 50, 100];
  const [rowsPerPage, _setRowsPerPage] = useState<number>(
    (() => {
      const stored = getLocalStorage<number>(localStorageKeys.rowsPerPage);
      // Not stored, use default
      if (!stored) {
        return rowsPerPageOptions[0];
      }
      // Faulty value, use default, reset local Storage item
      if (!rowsPerPageOptions.includes(stored)) {
        localStorage.removeItem(localStorageKeys.rowsPerPage);
        return rowsPerPageOptions[0];
      }
      return stored;
    })(),
  );

  const setRowsPerPage = (value: number): void => {
    _setRowsPerPage(value);
    if (value === rowsPerPageOptions[0]) {
      removeLocalStorage(localStorageKeys.rowsPerPage);
      return;
    }
    setLocalStorage(localStorageKeys.rowsPerPage, value);
  };

  const [columnDisplayOverride, _setColumnDisplayOverride] = useState<{ [key: string]: boolean }>(
    (() => {
      const stored = getLocalStorage<number>(localStorageKeys.columnDisplayOverride);
      // Not stored, use default
      if (!stored) {
        return {};
      }
      return stored;
    })(),
  );

  const setColumnDisplayOverride = (value: { [key: string]: boolean }): void => {
    _setColumnDisplayOverride(value);
    setLocalStorage(localStorageKeys.columnDisplayOverride, value);
  };

  const setColumnDisplayEnabled = (name: string): void => {
    setColumnDisplayOverride({ ...columnDisplayOverride, [name]: true });
  };
  const setColumnDisplayDisabled = (name: string): void => {
    setColumnDisplayOverride({ ...columnDisplayOverride, [name]: false });
  };

  const [columnFilter, _setColumnFilter] = useState<{ [key: string]: string | number }>(
    (() => {
      const stored = getLocalStorage<{ [key: string]: string | number }>(localStorageKeys.columnFilter);
      // Not stored, use default
      if (!stored) {
        return {};
      }
      return stored;
    })(),
  );

  const setColumnFilter = (value: { [key: string]: string | number }): void => {
    _setColumnFilter(value);
    if (Object.keys(value).length === 0) {
      localStorage.removeItem(localStorageKeys.columnFilter);
      return;
    }
    setLocalStorage(localStorageKeys.columnFilter, value);
  };

  const columns: ITableColumns<any>[] = useMemo(() => {
    const data = columnsData.map(item => {
      const name: string = String(item.name);
      const displayOverride = (() => {
        if (columnDisplayOverride[name] !== undefined) {
          return { display: columnDisplayOverride[name] };
        }
        return undefined;
      })();

      const filterOverride = (() => {
        if (columnFilter[name] !== undefined) {
          return { filterList: [columnFilter[name]] } as { filterList: [string] };
        }
        return undefined;
      })();

      return {
        ...item,
        options: {
          ...item.options,
          ...displayOverride,
          ...filterOverride,
        },
      };
    });

    return data;
  }, [columnsData, columnDisplayOverride, columnFilter]);

  const [columnOrder, _setColumnOrder] = useState<string[]>(
    (() => {
      const stored = getLocalStorage<string[]>(localStorageKeys.columnOrder);
      // Not stored, generate default
      if (!stored) {
        return columnsData.map(item => {
          return String(item.name);
        });
      }

      // Remove items that are saved in localstorage but not in columnsData
      const out: string[] = stored
        .map(item => {
          const find = columnsData.find(search => {
            return search.name === item;
          });
          if (!find) {
            return undefined;
          }
          return find.name;
        })
        .filter(value => {
          return value !== undefined;
        }) as string[];

      // Add missing items
      columnsData.forEach((item, index) => {
        if (out.includes(String(item.name))) {
          return undefined;
        }
        out.splice(index, 0, String(item.name));
      });

      setLocalStorage(localStorageKeys.columnOrder, out);
      return out;
    })(),
  );

  const setColumnOrder = (value: string[]) => {
    _setColumnOrder(value);
    setLocalStorage(localStorageKeys.columnOrder, value);
  };

  const muiColumnOrder = useMemo(() => {
    return columnOrder.map(item => {
      return columnsData.findIndex(value => {
        return value.name === item;
      });
    });
  }, [columnOrder, columnsData]);

  const options: IOptionsJoinMUIDataTableOptions = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    enableNestedDataAccess: '.',
    selectableRows: 'none',
    rowsPerPage,
    rowsPerPageOptions,
    onChangeRowsPerPage: (numberOfRows: number) => {
      setRowsPerPage(numberOfRows);
    },
    onColumnOrderChange: (newColumnOrder: number[]) => {
      const out = newColumnOrder.map(item => {
        return String(columnsData[item].name);
      });
      setColumnOrder(out);
    },
    onViewColumnsChange: (changedColumn: string, action: string) => {
      if (action === 'remove') {
        setColumnDisplayDisabled(changedColumn);
        return;
      }
      if (action === 'add') {
        setColumnDisplayEnabled(changedColumn);
        return;
      }
    },
    onFilterChange: (
      changedColumn: string | MUIDataTableColumn | null,
      filterList: string[][],
      type: string,
      changedColumnIndex: number,
    ) => {
      const out: { [key: string]: string | number } = {};
      filterList.forEach((item: (string | number)[], index) => {
        if (item.length === 0) {
          return;
        }
        const key = String(columnsData[index].name);
        out[key] = item[0];
      });
      setColumnFilter(out);
    },
    draggableColumns: {
      enabled: true,
    },
    viewColumns: true,
    columnOrder: muiColumnOrder,
    print: false,
    download: false,
    ...otherOptions,
  };

  return (
    <React.Fragment>
      <MUIDataTable
        title={tableName}
        data={data}
        columns={columns as MUIDataTableColumnDef[]}
        options={options as MUIDataTableOptions}
      />
    </React.Fragment>
  );
};

export default Table;
