import * as React from 'react';
import {
  Button,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import { useState, useEffect } from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { useIntl } from 'react-intl';
import { TFF } from '@tff/types';
import { useTypedSelector } from '../../../reducers';
import { IntlMessages } from '../../../util';
import { renderInput, renderKeyboardDatePicker, renderSelectField } from '../Fields';
import { TypeOfTFFBookingSource } from '@tff/types/TFF';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

interface IProps {
  isNew: boolean;
  onCancel: () => void;
  onSubmit: (values: TFF.ICampaign) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 'auto',
      maxWidth: '80%',
      padding: '20px 40px 40px 40px',
    },
    headerText: {
      textAlign: 'center',
      color: '#1769aa',
      height: '40px',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    formControl: {
      margin: 0,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      height: '15px',
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 30,
    },
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name: string, bookingSource: string[], theme: Theme) => {
  return {
    fontWeight: bookingSource.indexOf(name) === -1 ? 400 : theme.typography.fontWeightMedium,
  };
};

//Bokkingsources to pick from
const bookingSources = [
  'TUI-NSK',
  'TUI-NDC-AF',
  'TUI-NDC-KL',
  'TUI-NDC-EK',
  'TUI-NDC-SQ',
  'TUI-AMADEUS',
  'TUI-NDCX',
] as TypeOfTFFBookingSource[];

const CampaignForm: React.FC<IProps & InjectedFormProps<TFF.ICampaign, IProps, string>> = ({
  isNew,
  onCancel,
  onSubmit,
  change,
  handleSubmit,
  initialize,
}) => {
  const classes = useStyle();
  const theme = useTheme();

  const { campaign } = useTypedSelector(({ campaignData }) => campaignData);

  const [bookingSource, setBookingSource] = useState<string[] | undefined>([]);

  const headerTitle = isNew ? (
    <IntlMessages id="campaign.form.add-campaign" />
  ) : (
    <IntlMessages id="campaign.form.edit-campaign" />
  );
  const buttonText = isNew ? <IntlMessages id="campaign.form.add" /> : <IntlMessages id="campaign.form.save" />;

  const handleBookingSourceChange = (event: SelectChangeEvent<string[]>) => {
    setBookingSource(event.target.value as string[]);
  };

  // Load form
  useEffect(() => {
    if (campaign) {
      initialize(campaign);
      setBookingSource(campaign.bookingSources);
    }
  }, [campaign, initialize]);

  // Update BookingSource upon changes
  useEffect(() => {
    if (bookingSource) {
      change('bookingSources', bookingSource);
    }
  }, [bookingSource, change]);

  const intl = useIntl();

  return (
    <>
      <Typography className={classes.headerText}>{headerTitle}</Typography>
      <Form
        onSubmit={handleSubmit(values => {
          if (values) {
            const newCampaign: TFF.ICampaign = { ...values, priority: 1 };
            onSubmit(newCampaign);
          }
        })}
      >
        <div className="row">
          <div className="col-md-3">
            <Field
              name="name"
              label={<IntlMessages id="campaign.form.name" />}
              required
              placeholder={intl.formatMessage({ id: 'campaign.form.enter-name' })}
              component={renderInput}
            />
          </div>
          <div className="col-md-2">
            <FormControl variant="standard" className={classes.formControl} fullWidth required>
              <InputLabel id="mutiple-chip-label">
                <IntlMessages id="campaign.form.booking-source" />
              </InputLabel>
              <Select
                variant="standard"
                labelId="mutiple-chip-label"
                id="mutiple-chip"
                multiple
                value={bookingSource}
                onChange={handleBookingSourceChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {(selected as string[]).map(value => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {bookingSources.map(name => (
                  <MenuItem key={name} value={name} style={getStyles(name, bookingSource as string[], theme)}>
                    {name === 'TUI-NDCX' ? 'TUI-NDCX-BA' : name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-2">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Field
                name="startDate"
                label={<IntlMessages id="campaign.form.start-date" />}
                placeholder={intl.formatMessage({ id: 'campaign.form.pick-date' })}
                component={renderKeyboardDatePicker}
                fullWidth={false}
              />
            </LocalizationProvider>
          </div>
          <div className="col-md-2">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Field
                name="endDate"
                label={<IntlMessages id="campaign.form.end-date" />}
                placeholder={intl.formatMessage({ id: 'campaign.form.pick-date' })}
                component={renderKeyboardDatePicker}
                fullWidth={false}
              />
            </LocalizationProvider>
          </div>
          <div className="col-md-2">
            <Field
              name="status"
              label={<IntlMessages id="campaign.form.status" />}
              required
              placeholder={intl.formatMessage({ id: 'campaign.form.pick-status' })}
              component={renderSelectField}
              fullWidth
            >
              <MenuItem value="aktiv">
                <IntlMessages id="campaign.form.aktiv" />
              </MenuItem>
              <MenuItem value="inaktiv">
                <IntlMessages id="campaign.form.inaktiv" />
              </MenuItem>
            </Field>
          </div>
        </div>
        <div className="row" style={{ marginTop: 10 }}>
          <div className="col-md-9">
            <Field
              name="description"
              label={<IntlMessages id="campaign.form.description" />}
              required
              placeholder={intl.formatMessage({ id: 'campaign.form.enter-desc' })}
              component={renderInput}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: 10 }}>
          <div className="col-md-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Field
                name="bookingStartDate"
                label={<IntlMessages id="campaign.form.booking-start-date" />}
                placeholder={intl.formatMessage({ id: 'campaign.form.pick-date' })}
                component={renderKeyboardDatePicker}
                fullWidth
              />
            </LocalizationProvider>
          </div>
          <div className="col-md-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Field
                name="bookingEndDate"
                label={<IntlMessages id="campaign.form.booking-end-date" />}
                placeholder={intl.formatMessage({ id: 'campaign.form.pick-date' })}
                component={renderKeyboardDatePicker}
                fullWidth
              />
            </LocalizationProvider>
          </div>
          <div className="col-md-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Field
                name="travelStartDate"
                label={<IntlMessages id="campaign.form.travel-start-date" />}
                placeholder={intl.formatMessage({ id: 'campaign.form.pick-date' })}
                component={renderKeyboardDatePicker}
                fullWidth
              />
            </LocalizationProvider>
          </div>
          <div className="col-md-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Field
                name="travelEndDate"
                label={<IntlMessages id="campaign.form.travel-end-date" />}
                placeholder={intl.formatMessage({ id: 'campaign.form.pick-date' })}
                component={renderKeyboardDatePicker}
                fullWidth
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className={classes.buttonsWrapper}>
          <Button color="secondary" style={{ marginRight: 10 }} onClick={onCancel}>
            <IntlMessages id="campaign.form.cancel" />
          </Button>
          <Button variant="contained" color="primary" type="submit" style={{ color: 'white' }}>
            {buttonText}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default reduxForm<TFF.ICampaign, IProps>({
  form: 'campaignForm',
})(CampaignForm);
