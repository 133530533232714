import * as React from 'react';
import {
  Button,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import { useState, useEffect } from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { renderCheckbox, renderInput, renderSelectField } from '../Fields';
import { useTypedSelector } from '../../../reducers';
import { IntlMessages } from '../../../util';

import { useIntl } from 'react-intl';
import { IFee } from '@tff/types/TFF';

interface IProps {
  isNew: boolean;
  campaignKey?: string;
  onCancel: () => void;
  onSubmit: (values: IFee) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 'auto',
      maxWidth: '80%',
      padding: '20px 40px 40px 40px',
    },
    headerText: {
      textAlign: 'center',
      color: '#1769aa',
      height: '40px',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    formControl: {
      margin: 0,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      height: '15px',
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 30,
    },
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name: string, feeActions: string[], theme: Theme) => {
  return {
    fontWeight: feeActions.indexOf(name) === -1 ? 400 : theme.typography.fontWeightMedium,
  };
};

//Dummy Booking-Source
const feeActionsData = ['CANCEL', 'CHANGE'];

const FeesForm: React.FC<IProps & InjectedFormProps<IFee, IProps, string>> = ({
  isNew,
  onCancel,
  onSubmit,
  change,
  handleSubmit,
  initialize,
  campaignKey,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const intl = useIntl();

  //const { feesForm } = useTypedSelector(({ form }) => form);
  const { fee } = useTypedSelector(({ feesData }) => feesData);

  const [feeActions, setFeeActions] = useState<string[]>([]);

  const headerTitle = isNew ? (
    <IntlMessages id="campaign.form.add-fee" />
  ) : (
    <IntlMessages id="campaign.form.edit-fee" />
  );
  const buttonText = isNew ? <IntlMessages id="campaign.form.add" /> : <IntlMessages id="campaign.form.save" />;

  const handleFeeActionChange = (event: SelectChangeEvent<string[]>) => {
    setFeeActions(event.target.value as string[]);
  };

  // Update BookingSource upon changes
  useEffect(() => {
    if (feeActions) {
      change('actions', feeActions);
    }
  }, [feeActions, change]);

  // Initiliaze the form if there is a fee
  useEffect(() => {
    if (fee && !isNew) {
      initialize(fee);
      setFeeActions(fee.actions as string[]);
    }
  }, [fee, isNew, initialize]);

  return (
    <>
      <Typography className={classes.headerText}>{headerTitle}</Typography>
      <Form
        onSubmit={handleSubmit(values => {
          if (values) {
            if (isNew) {
              const fee: IFee = { ...values, partitionKey: campaignKey };
              onSubmit(fee);
            } else {
              onSubmit(values);
            }
          }
        })}
      >
        <div className="row">
          <div className="col-md-2">
            <Field
              name={'channel'}
              label={<IntlMessages id="pages.fees.campaign-config.channel" />}
              required
              placeholder={intl.formatMessage({ id: 'fee.form.select-channel' })}
              component={renderSelectField}
              fullWidth
            >
              <MenuItem value="SELF_SERVICE_B2C">SELF_SERVICE_B2C</MenuItem>
              <MenuItem value="CALLCENTER">CALLCENTER</MenuItem>
            </Field>
          </div>
          <div className="col-md-4">
            <FormControl variant="standard" className={classes.formControl} fullWidth required>
              <InputLabel id="mutiple-chip-label">
                <IntlMessages id="fee.form.actions" />
              </InputLabel>
              <Select
                variant="standard"
                labelId="mutiple-chip-label"
                id="mutiple-chip"
                multiple
                value={feeActions}
                onChange={handleFeeActionChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {(selected as string[]).map(value => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {feeActionsData.map(name => (
                  <MenuItem key={name} value={name} style={getStyles(name, feeActions, theme)}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-2">
            <Field
              name="fee"
              label={<IntlMessages id="fee.form.fee" />}
              required
              placeholder={intl.formatMessage({ id: 'fee.form.enter-fee' })}
              component={renderInput}
            />
          </div>
          <div className="col-md-2">
            <Field
              name="currency"
              label={<IntlMessages id="fee.form.currency" />}
              required
              placeholder={intl.formatMessage({ id: 'fee.form.select-currency' })}
              component={renderSelectField}
              fullWidth
            >
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="EUR">EURO</MenuItem>
            </Field>
          </div>
          <div className="col-md-2" style={{ top: 18 }}>
            <Field
              name="flexibel"
              label={<IntlMessages id="fee.form.just_a_default" />}
              required
              component={renderCheckbox}
              fullWidth
            />
          </div>
        </div>
        <div className={classes.buttonsWrapper}>
          <Button color="secondary" style={{ marginRight: 10 }} onClick={onCancel}>
            <IntlMessages id="campaign.form.cancel" />
          </Button>
          <Button variant="contained" color="primary" type="submit" style={{ color: 'white' }}>
            {buttonText}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default reduxForm<IFee, IProps>({
  form: 'feesForm',
})(FeesForm);
