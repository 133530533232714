import _ from 'lodash';
import * as TFF from '@tff/types/TFF';

const keysToIgnore = ['Id', 'DepartureTerminal', 'ArrivalTerminal'];

const minLengthString = (value: string, minLength: number, defaultChar = ' '): string => {
  while (value.length < minLength) {
    value = defaultChar + value;
  }
  return value;
};

const prepareJourney = (journey: TFF.Journey): TFF.Journey => {
  if (!journey || !journey.Segments) {
    return journey;
  }
  journey.Segments!.forEach(segment => {
    segment.FlightNumber = minLengthString(segment.FlightNumber.replace(/ /g, '0'), 4, '0');
    segment.DepartureTime = segment.DepartureTime.substring(0, 16);
    segment.ArrivalTime = segment.ArrivalTime.substring(0, 16);
    segment.OperatingCarrier = segment.OperatingCarrier ?? segment.MarketingCarrier;
  });

  return journey;
};

export type DiffedResult = { bookedJourney: TFF.Journey; modifiedJourney: TFF.Journey; diff: TFF.Journey };

export const CheckDifferences = (bookedJourney: TFF.Journey, modifiedJourney: TFF.Journey): DiffedResult => {
  const changes = (newObj: Record<string, any>, origObj: Record<string, any>) => {
    let arrayIndexCounter = 0;
    return _.transform(newObj, (result: any, value, key) => {
      if (keysToIgnore.indexOf(key) > -1) {
        return;
      }

      let origValue = origObj[key];

      if (!_.isEqual(value, origValue)) {
        const resultKey = _.isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey] = _.isObject(value) && _.isObject(origValue) ? changes(value, origValue) : value;
      }
    });
  };
  const diff = changes(prepareJourney(modifiedJourney), prepareJourney(bookedJourney));
  return { bookedJourney, modifiedJourney, diff };
};
