import * as React from 'react';

interface TFFButtonProps {
  buttonText: string;
  outline?: boolean;
  disabled?: boolean;
  variant?: 'primary' | 'promotion' | 'secondary' | 'tertiary' | 'error' | 'success';
  handleClick?: () => void;
  type?: 'button' | 'submit';
}

export const TFFButton: React.FC<TFFButtonProps> = ({
  buttonText,
  disabled,
  handleClick,
  outline,
  variant = 'primary',
  type = 'button',
}) => {
  return (
    <button
      className={`button ${variant} medium${outline ? ' outline' : ''}`}
      type={type}
      disabled={disabled}
      onClick={handleClick || undefined}
    >
      {buttonText}
    </button>
  );
};

export default TFFButton;
