import { Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import CustomButton from './Button';
import { useIntl } from 'react-intl';

interface props {
  onAddEmployee?: () => void;
  servicesRoles?: any[];
  usersCount: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    headerRoot: {
      width: '100%',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: '#092A5E',
    },
    menuRoot: {
      width: '100%',
      backgroundColor: '#E2F3FE',
      borderBottomWidth: '2px',
      borderBottomColor: '#092A5E',
      borderBottomStyle: 'double',
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '20px',
    },
    iconWrapper: {
      height: 40,
      width: 40,
      backgroundColor: '#70CBF4',
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    infoIcon: {
      position: 'absolute',
      right: -10,
      top: -10,
      height: 20,
      width: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#D40E14',
      color: 'white',
      borderRadius: 10,
    },
    menuWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100px',
      width: '60%',
      padding: '20px',
      margin: '0 auto',
    },
    menuButtonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    statusWrapper: {
      backgroundColor: '#F2E30F',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '37px',
      width: '140px',
      borderRadius: '4px',
    },
    totalHeader: {
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '37px',
      width: '140px',
      borderWidth: '1px',
      borderColor: 'white',
      borderStyle: 'double',
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    totalSum: {
      backgroundColor: '#70CBF4',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '37px',
      width: '140px',
      borderWidth: '1px 1px 1px 0px',
      borderColor: 'white',
      borderStyle: 'double',
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    bookingHeadingContent: { color: 'white', textAlign: 'center', fontSize: '34px' },
    bookingHeading: {
      fontSize: '16px',
      color: 'white',
      textAlign: 'center',
    },
  }),
);

const AdministrationHeaderCard: React.FC<props> = ({ onAddEmployee, servicesRoles, usersCount }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [supervisorsCount, setSupervisorsCount] = useState<number>(0);
  const [agentsCount, setAgentsCount] = useState<number>(0);

  useEffect(() => {
    if (servicesRoles) {
      let serviceCenterAgentsCount = 0;
      let serviceCenterSupervisorCount = 0;
      servicesRoles.forEach(role => {
        switch (role.name) {
          case 'Role_Service_Center_Agent_Extern':
            serviceCenterAgentsCount += 1;
            break;
          case 'Role_Service_Center_Agent_Intern':
            serviceCenterAgentsCount += 1;
            break;
          case 'Role_Service_Center_Supervisor_Extern':
            serviceCenterSupervisorCount += 1;
            break;
          case 'Role_Service_Center_Supervisor_Intern':
            serviceCenterSupervisorCount += 1;
            break;
        }
      });
      setSupervisorsCount(serviceCenterSupervisorCount);
      setAgentsCount(serviceCenterAgentsCount);
    }
  }, [servicesRoles]);

  const renderRoundIcon = (icon: React.ReactNode, backgroundColor?: string) => {
    return (
      <div className={classes.iconWrapper} style={{ backgroundColor }}>
        {icon}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className={classes.flexCenter}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {renderRoundIcon(
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                width: '24px',
                height: '24px',
              }}
              viewBox="0 0 120 120"
            >
              <path
                fill="#082A5E"
                d="M96.9759262,78.2958661 C106.883272,84.9153731 109.110109,87.8451288 108.995895,95.0632156 C108.948189,98.0781632 108.948189,101.006376 108.976817,103.584746 C108.980979,103.959663 108.985432,104.292459 108.989874,104.579052 C108.992512,104.749276 108.994578,104.867471 108.995649,104.922694 C109.038915,107.161671 107.235837,109 104.996527,109 L15.0034731,109 C12.7641632,109 10.9610846,107.161671 11.0043507,104.922694 C11.005422,104.867471 11.0074876,104.749276 11.0101258,104.579052 C11.0145677,104.292459 11.0190205,103.959663 11.0231832,103.584746 C11.0518112,101.006376 11.0518112,98.0781632 11.0041047,95.0632156 C10.8898875,87.8449444 13.1169345,84.9151649 23.0174634,78.3009635 L42.850349,64.9840009 C40.0518154,62.4096234 38.1931588,59.204982 37.1461569,55.4905041 C36.2741344,52.3968053 36.0743096,50.0049254 36.0092857,45.4648043 C36.006339,45.2602124 36.006339,45.2602124 36.0027856,44.9996684 L36.0032936,35.0633653 C35.8943053,28.2253301 38.0126132,21.7846189 42.2105604,17.4534078 C46.3313086,13.1993842 52.7789608,11.1140951 59.9342306,11.0000006 C67.2199243,11.1140791 73.6684687,13.1991543 77.7890247,17.4529795 C81.9868948,21.7853269 84.1056758,28.2265133 83.9972144,34.9996165 L83.9967064,45.0634172 C83.9936609,45.2602124 83.9936609,45.2602124 83.9907142,45.4648043 C83.9256903,50.0049254 83.7258655,52.3968053 82.853843,55.4905041 C81.8068514,59.2049455 79.948221,62.4095605 77.1497333,64.9839252 L96.9759262,78.2958661 Z"
              />
            </svg>,
          )}
          <Typography
            variant="h6"
            style={{ marginLeft: '10px', color: 'white', fontSize: '25px', textTransform: 'uppercase' }}
          >
            {intl.formatMessage({ id: 'administration.userAdministration' })}
          </Typography>
        </div>
        <CustomButton variant="contained" color="danger" size="medium" onClick={onAddEmployee!} hidden>
          {intl.formatMessage({ id: 'administration.addEmployee' })}
        </CustomButton>
      </div>
    );
  };

  const renderServicesDetail = () => {
    return (
      <div className={classes.flexCenter}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {renderRoundIcon(
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                fill: '#092A5E',
                width: '24px',
                height: '24px',
                fillRule: 'evenodd',
              }}
              viewBox="0 0 120 120"
            >
              <path d="M64,109 L64,86.6520757 L83.713012,70.0000018 C83.7163809,69.9973626 88.0945797,72.8682164 96.8476085,78.6125633 C106.861273,85.1635337 109.112023,88.0634193 108.995796,95.2062435 C108.947284,98.1912453 108.947284,101.089151 108.976594,103.640655 C108.981647,104.011801 108.98569,104.341378 108.989732,104.624439 C108.992764,104.793681 108.994786,104.910468 108.995796,104.964903 C109.039255,107.180891 107.217027,109 104.954149,109 L64,109 Z M56,109 L15.0470705,109 C12.7830258,109 10.960672,107.181537 11.0051443,104.966336 C11.006155,104.911921 11.0081765,104.795175 11.0112087,104.625993 C11.0152516,104.343033 11.0192946,104.013572 11.0233375,103.642558 C11.0526488,101.091961 11.0526488,98.1950836 11.0041336,95.2111418 C10.8889099,88.0708542 13.139815,85.1719983 23.1470946,78.6283011 L36.2755215,70 L56,86.6600117 L56,109 Z M36.0154289,45.9047989 L52.5985517,58.3943857 C53.2908797,58.9158128 54.1329451,59.1976803 54.998355,59.1976803 L64.9975356,59.1976803 C67.2064936,59.1976803 68.9972078,57.399444 68.9972078,55.181207 C68.9972078,52.96297 67.2064936,51.1647336 64.9975356,51.1647336 L56.3315791,51.1647336 L37.3997973,36.9062532 C36.9691929,36.5819433 36.4946612,36.3580118 36.0040013,36.2304707 L36.0040013,35.1620996 C35.8950102,28.2969425 38.0138366,21.8284122 42.2114926,17.4795757 C46.3321549,13.2080562 52.7796265,11.1144695 59.9340402,11 C67.2194432,11.1144695 73.6679147,13.2080562 77.7875771,17.4795757 C81.986233,21.8294163 84.1040595,28.2979467 83.9960683,35.0978361 L83.9950684,45.203283 C83.9920687,45.4010943 83.9920687,45.4010943 83.9890689,45.6069386 C83.9250741,50.1656359 83.7240906,52.5664828 82.853162,55.673225 C81.8862412,59.1163468 80.2283771,62.1226771 77.7785779,64.5978288 L59.998035,80 L42.2084928,64.5847752 C39.765693,62.1116318 38.1118286,59.110322 37.1469076,55.673225 C36.2941673,52.6348518 36.0841228,50.2716469 36.0154289,45.9047989 Z" />
            </svg>,
            'white',
          )}
          <Typography variant="h6" color="primary" style={{ color: 'white', marginLeft: '10px' }}>
            {agentsCount} {intl.formatMessage({ id: 'administration.serviceAgents' })}
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {renderRoundIcon(
            <svg
              viewBox="0 0 120 120"
              style={{
                fill: '#092A5E',
                width: '24px',
                height: '24px',
                fillRule: 'evenodd',
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M55.2032487,98 L56.3867369,96.841447 C57.3913375,95.8580126 57.7945926,94.4323296 57.4509667,93.0828281 L53,75.6600117 L72.713012,59.0138512 C72.7180653,59.0098937 72.7231187,59.0049469 72.7291826,59 L85.8476085,67.6233542 C95.8612728,74.1719983 98.1120228,77.0708542 97.9957964,84.2111418 C97.9472845,87.1950836 97.9472845,90.0919607 97.9765937,92.6425582 C97.981647,93.0135721 97.9856897,93.3430325 97.9897324,93.6259925 C97.9927644,93.7951749 97.9947857,93.9119206 97.9957964,93.966336 C98.0392549,96.1815368 96.2170267,98 93.9541488,98 L55.2032487,98 Z M42.7986208,98 L4.04707048,98 C1.7830258,98 -0.0393280141,96.1815368 0.00514429203,93.966336 C0.00615502626,93.9119206 0.00817649472,93.7951749 0.0112086974,93.6259925 C0.0152516343,93.3430325 0.0192945712,93.0135721 0.0233375082,92.6425582 C0.0526488008,90.0919607 0.0526488008,87.1950836 0.0041335578,84.2111418 C-0.111090144,77.0708542 2.13981499,74.1719983 12.1470946,67.6283011 L25.2755215,59 L45,75.6600117 L40.5507479,93.0828281 C40.2070983,94.4323296 40.6103812,95.8580126 41.6150511,96.841447 L42.7986208,98 Z M31.2084928,53.5847752 C28.765693,51.1116318 27.1118286,48.110322 26.1469076,44.673225 C25.2749791,41.5664828 25.0749955,39.1656359 25.0110007,34.6069386 C25.008001,34.4010943 25.008001,34.4010943 25.0040013,34.1390195 L25.0040013,24.1620996 C24.8950102,17.2969425 27.0138366,10.8284122 31.2114926,6.47957565 C35.3321549,2.20805623 41.7796265,0.114469491 48.9340402,0 C56.2194432,0.114469491 62.6679147,2.20805623 66.7875771,6.47957565 C70.986233,10.8294163 73.1040595,17.2979467 72.9960683,24.0978361 L72.9950684,34.203283 C72.9920687,34.4010943 72.9920687,34.4010943 72.9890689,34.6069386 C72.9250741,39.1656359 72.7240906,41.5664828 71.853162,44.673225 C70.8862412,48.1163468 69.2283771,51.1226771 66.7785779,53.5978288 L48.998035,69 L31.2084928,53.5847752 Z"
                transform="translate(11 11)"
              />
            </svg>,
            'white',
          )}
          <Typography variant="h6" color="primary" style={{ color: 'white', marginLeft: '10px' }}>
            {supervisorsCount} {intl.formatMessage({ id: 'administration.serviceSupervisor' })}
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {renderRoundIcon(
            <svg
              viewBox="0 0 120 120"
              style={{
                fill: '#092A5E',
                width: '24px',
                height: '24px',
                fillRule: 'evenodd',
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M55.2032487,98 L56.3867369,96.841447 C57.3913375,95.8580126 57.7945926,94.4323296 57.4509667,93.0828281 L53,75.6600117 L72.713012,59.0138512 C72.7180653,59.0098937 72.7231187,59.0049469 72.7291826,59 L85.8476085,67.6233542 C95.8612728,74.1719983 98.1120228,77.0708542 97.9957964,84.2111418 C97.9472845,87.1950836 97.9472845,90.0919607 97.9765937,92.6425582 C97.981647,93.0135721 97.9856897,93.3430325 97.9897324,93.6259925 C97.9927644,93.7951749 97.9947857,93.9119206 97.9957964,93.966336 C98.0392549,96.1815368 96.2170267,98 93.9541488,98 L55.2032487,98 Z M42.7986208,98 L4.04707048,98 C1.7830258,98 -0.0393280141,96.1815368 0.00514429203,93.966336 C0.00615502626,93.9119206 0.00817649472,93.7951749 0.0112086974,93.6259925 C0.0152516343,93.3430325 0.0192945712,93.0135721 0.0233375082,92.6425582 C0.0526488008,90.0919607 0.0526488008,87.1950836 0.0041335578,84.2111418 C-0.111090144,77.0708542 2.13981499,74.1719983 12.1470946,67.6283011 L25.2755215,59 L45,75.6600117 L40.5507479,93.0828281 C40.2070983,94.4323296 40.6103812,95.8580126 41.6150511,96.841447 L42.7986208,98 Z M31.2084928,53.5847752 C28.765693,51.1116318 27.1118286,48.110322 26.1469076,44.673225 C25.2749791,41.5664828 25.0749955,39.1656359 25.0110007,34.6069386 C25.008001,34.4010943 25.008001,34.4010943 25.0040013,34.1390195 L25.0040013,24.1620996 C24.8950102,17.2969425 27.0138366,10.8284122 31.2114926,6.47957565 C35.3321549,2.20805623 41.7796265,0.114469491 48.9340402,0 C56.2194432,0.114469491 62.6679147,2.20805623 66.7875771,6.47957565 C70.986233,10.8294163 73.1040595,17.2979467 72.9960683,24.0978361 L72.9950684,34.203283 C72.9920687,34.4010943 72.9920687,34.4010943 72.9890689,34.6069386 C72.9250741,39.1656359 72.7240906,41.5664828 71.853162,44.673225 C70.8862412,48.1163468 69.2283771,51.1226771 66.7785779,53.5978288 L48.998035,69 L31.2084928,53.5847752 Z"
                transform="translate(11 11)"
              />
            </svg>,
          )}
          <Typography variant="h6" color="primary" style={{ color: 'white', marginLeft: '10px' }}>
            0 {intl.formatMessage({ id: 'administration.serviceTeamLeads' })}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.headerRoot}>
        <div style={{ width: '60%', margin: '0 auto' }}>
          {renderHeader()}
          <Typography variant="h6" style={{ color: 'white', paddingLeft: '20px' }}>
            {usersCount} {intl.formatMessage({ id: 'administration.colleaguesAdministration' })}
          </Typography>
          {renderServicesDetail()}
        </div>
      </div>
    </div>
  );
};

export default AdministrationHeaderCard;
