import { Navigate, useLocation } from 'react-router-dom';

import AmendFailure from './amend-failures';
import { keycloak } from '../../keycloak';
import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../withRouter';

export const AmendFailuresRoutes = () => {
  const isItAdmin = keycloak.hasRealmRole('Role_IT_Administrator');
  const isBusinessAdmin = keycloak.hasRealmRole('Role_Business_Administrator');
  const isServiceCenterAgentIntern = keycloak.hasRealmRole('Role_Service_Center_Agent_Intern');

  const pnrUrl = useLocation();
  const pnrMatch = pnrUrl.pathname.match(/\/pnr\/([^/]+)/);
  const pnr = pnrMatch ? pnrMatch[1] : '';

  return (
    <div className="app-wrapper h-100">
      <Routes>
        <Route
          path="/*"
          element={
            isServiceCenterAgentIntern || isItAdmin || isBusinessAdmin ? (
              <AmendFailure pnr={pnr} />
            ) : (
              <Navigate to={'/tff/common/error-403'} />
            )
          }
        />
      </Routes>
    </div>
  );
};

export default withRouter(AmendFailuresRoutes);
