import * as React from 'react';
import { CSSProperties } from 'react';

type Props = {
  style?: CSSProperties;
};

export const ClickableMinusIcon: React.FC<Props> = ({ style }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 18.25C14.5563 18.25 18.25 14.5563 18.25 10C18.25 5.44365 14.5563 1.75 10 1.75C5.44365 1.75 1.75 5.44365 1.75 10C1.75 14.5563 5.44365 18.25 10 18.25Z"
      stroke="#1A7EAD"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M5.5 10H14.5" stroke="#1A7EAD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
