import React from 'react';

import { TFF } from '@tff/types';
import { Typography } from '@mui/material';

import CustomDialog from '../Dialogs';

enum ConfirmationMessages {
  AmendSsr = 'Ancillaries were amended successfully',
  AmendSsrAndFlights = 'Flights and ancillaries were amended successfully',
  AmendFlights = 'Flights were amended successfully',
  Default = 'Details were amended',
}

interface props {
  setShowConfirmation: (value: boolean) => void;
  showConfirmation: boolean;
  showSuccess: boolean;
  showFailure: boolean;
  setTableOpen: (value: boolean) => void;
  rebooking?: boolean;
  flightDetails?: TFF.FlightDetails;
  rebookingResponse?: TFF.AmendBookingResponse;
}

export const ConfirmationDialog: React.FC<props> = ({
  setShowConfirmation,
  showFailure,
  showSuccess,
  showConfirmation,
  setTableOpen,
  rebooking,
  flightDetails,
  rebookingResponse,
}) => {
  const hasAmendedSsrs = () => {
    const originalSsrs = flightDetails?.OrderDetails.Ancillaries
      ? Object.keys(flightDetails.OrderDetails.Ancillaries).length
      : 0;
    const amendedSsrs = rebookingResponse?.amendedBooking?.OrderDetails.Ancillaries
      ? Object.keys(rebookingResponse?.amendedBooking?.OrderDetails.Ancillaries).length
      : 0;

    return originalSsrs !== amendedSsrs;
  };

  const getConfirmationMessage = (): string => {
    switch (true) {
      case rebooking === undefined || !rebooking:
        return ConfirmationMessages.AmendSsr;
      case rebooking !== undefined && rebooking:
        return hasAmendedSsrs() ? ConfirmationMessages.AmendSsrAndFlights : ConfirmationMessages.AmendFlights;
      default:
        return ConfirmationMessages.Default;
    }
  };

  return (
    <>
      <CustomDialog
        initialOpen={showConfirmation}
        confirmButtonText="Reload PNR"
        confirmButtonStyle={{
          backgroundColor: '#F2E30F',
          color: 'black',
        }}
        onConfirm={() => {
          setShowConfirmation(false);
          setTableOpen(false);
          window.location.reload();
        }}
        onCancel={() => {
          setShowConfirmation(false);
          setTableOpen(false);
          window.location.reload();
        }}
        width="md"
        cancelButtonText=""
        titleBg="#E2F3FE"
        title={
          <Typography variant="h5" align="left" fontWeight="bold">
            Amend Notification
          </Typography>
        }
      >
        <>
          <Typography variant="h6">
            {showSuccess && getConfirmationMessage()}
            {showFailure && 'SOMETHING WENT WRONG... PLEASE TRY AGAIN LATER, EDITING FLIGHT FAILED'}
          </Typography>
        </>
      </CustomDialog>
    </>
  );
};
