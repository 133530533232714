import { IntlMessages } from '../../util';
import { Link } from 'react-router-dom';

const Error403 = () => (
  <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
    <div className="page-error-content">
      <div className="error-code mb-4 animated zoomInDown">403</div>
      <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
        <IntlMessages id="extraPages.403Msg" />
      </h2>
      <p className="text-center zoomIn animation-delay-20 animated">
        <Link className="btn btn-primary" to="/tff/order/search">
          <IntlMessages id="extraPages.goHome" defaultMessage="Go to Homepage" />
        </Link>
      </p>
    </div>
  </div>
);

export default Error403;
