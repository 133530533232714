import * as React from 'react';
import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';

const numberFormatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 });
export type CancelBookingSummaryProps = {
  farePrice: number;
  taxes: number;
  ancillaries: number;
  serviceFee: number;
  servicePackagesFee: number;

  farePrice_refundable: number;
  taxes_refundable: number;
  ancillaries_refundable: number;
  serviceFee_refundable: number;
  servicePackagesFee_refundable: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sumRoot: {
      borderTopWidth: '2px',
      borderTopColor: theme.palette.text.primary,
      backgroundColor: '#70CBF4',
      borderTopStyle: 'double',
      padding: '10px',
      margin: '0px 20px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  }),
);

const CancelBookingSummary: React.FC<CancelBookingSummaryProps> = ({
  farePrice,
  taxes,
  ancillaries,
  serviceFee,
  servicePackagesFee,
  farePrice_refundable,
  taxes_refundable,
  ancillaries_refundable,
  serviceFee_refundable,
  servicePackagesFee_refundable,
}: CancelBookingSummaryProps) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div style={{ marginTop: 20 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '0px 20px',
        }}
      >
        <Typography variant="h6" color="primary" style={{ marginLeft: '20px' }}>
          <strong>{intl.formatMessage({ id: 'cancel.cancellation' }).toUpperCase()}</strong>
        </Typography>
        <div style={{ display: 'flex' }}>
          <Typography variant="h6" color="primary" style={{ textAlign: 'right' }}>
            <strong>{intl.formatMessage({ id: 'cancel.payed' })}</strong>
          </Typography>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px', textAlign: 'left' }}>
            <strong>{intl.formatMessage({ id: 'cancel.refundable' })}</strong>
          </Typography>
        </div>
      </div>
      <div className={classes.sumRoot} style={{ borderRadius: 0, backgroundColor: '#F0F9FE' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>{intl.formatMessage({ id: 'pricingDetails.flightPrice' }).toUpperCase()}</strong>
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6" color="primary" style={{ marginLeft: '10px', width: '80px', textAlign: 'right' }}>
              {numberFormatter.format(farePrice)}
            </Typography>
            <Typography variant="h6" color="primary" style={{ marginLeft: '10px', width: '80px', textAlign: 'right' }}>
              <strong>{numberFormatter.format(farePrice_refundable)}</strong>
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>{intl.formatMessage({ id: 'pricingDetails.taxAndFees' }).toUpperCase()}</strong>
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6" color="primary" style={{ marginLeft: '10px', width: '80px', textAlign: 'right' }}>
              {numberFormatter.format(taxes)}
            </Typography>
            <Typography variant="h6" color="primary" style={{ marginLeft: '10px', width: '80px', textAlign: 'right' }}>
              <strong>{numberFormatter.format(Number(taxes_refundable))}</strong>
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>{intl.formatMessage({ id: 'pricingDetails.ancillaries' }).toUpperCase()}</strong>
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6" color="primary" style={{ marginLeft: '10px', width: '80px', textAlign: 'right' }}>
              {numberFormatter.format(ancillaries)}
            </Typography>
            <Typography variant="h6" color="primary" style={{ marginLeft: '10px', width: '80px', textAlign: 'right' }}>
              <strong>{numberFormatter.format(ancillaries_refundable)}</strong>
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>{intl.formatMessage({ id: 'pricingDetails.serviceFee' }).toUpperCase()}</strong>
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6" color="primary" style={{ marginLeft: '10px', width: '80px', textAlign: 'right' }}>
              {numberFormatter.format(serviceFee)}
            </Typography>
            <Typography variant="h6" color="primary" style={{ marginLeft: '10px', width: '80px', textAlign: 'right' }}>
              <strong>{numberFormatter.format(serviceFee_refundable)}</strong>
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>SERVICE PACKAGES</strong>
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6" color="primary" style={{ marginLeft: '10px', width: '80px', textAlign: 'right' }}>
              {numberFormatter.format(servicePackagesFee)}
            </Typography>
            <Typography variant="h6" color="primary" style={{ marginLeft: '10px', width: '80px', textAlign: 'right' }}>
              <strong>{numberFormatter.format(servicePackagesFee_refundable)}</strong>
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTopStyle: 'double',
            marginTop: '5px',
            paddingTop: '5px',
          }}
        >
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>{intl.formatMessage({ id: 'pricingDetails.sum' }).toUpperCase()}</strong>
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6" color="primary" style={{ marginLeft: '10px', width: '80px', textAlign: 'right' }}>
              {numberFormatter.format(farePrice + taxes + ancillaries + serviceFee + servicePackagesFee)}
            </Typography>
            <Typography variant="h6" color="primary" style={{ marginLeft: '10px', width: '80px', textAlign: 'right' }}>
              <strong>
                {numberFormatter.format(
                  farePrice_refundable +
                    taxes_refundable +
                    ancillaries_refundable +
                    serviceFee_refundable +
                    servicePackagesFee_refundable,
                )}
              </strong>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelBookingSummary;
