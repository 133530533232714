import withStyles from '@mui/styles/withStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export const MuiAccordion = withStyles({
  root: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',

    alignItems: 'flex-end',
    '&::before': {
      display: 'none',
    },
  },
})(Accordion);
export const MuiAccordionSummary = withStyles({
  root: {
    width: 'fit-content',
    // backgroundColor: '#F3F0EC',
    borderRadius: '5px',
    marginRight: '3px',
    minHeight: '28px',
    maxHeight: '28px',
  },
  content: {
    marginRight: '5px',
  },
  expanded: {
    borderRadius: '0px',
    borderTopRightRadius: '5px ',
    borderTopLeftRadius: '5px',
  },
  expandIcon: {},
})(AccordionSummary);
export const MuiAccordionDetails = withStyles({
  root: {
    backgroundColor: '#F3F0EC',
    display: 'block',
    width: '100%',
    marginBottom: '15px',
  },
})(AccordionDetails);
