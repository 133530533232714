import * as React from 'react';
import { ReactElement, useMemo, useState } from 'react';
import { TFF } from '@tff/types';
import { Box, Paper, createStyles } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { weekday } from '../FlightDetailCard';
import { useTypedSelector } from '../../reducers';
import * as dateFns from 'date-fns';
import { format } from 'date-fns';

interface props {
  onOfferSelected: (offer: TFF.Offer[]) => void;
  flexibleOffers: TFF.Offer[];
  searchDate: string;
}

const useStyles = makeStyles(
  createStyles({
    flexible: {
      width: '100%',
      borderColor: '#1A7EAD',
      margin: '20px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'row',
      backgroundColor: '#FFFFFF',
    },
  }),
);

const FlexibleTrack: React.FC<props> = ({ flexibleOffers, onOfferSelected, searchDate }) => {
  const [selectedBox, setSelectedBox] = useState<number>(3);
  const classes = useStyles();
  const { locale } = useTypedSelector(({ settings }) => settings);

  const onOfferClick = id => {
    const selectedOffer = offersToDisplay.find(offer => offer.Id === id);
    const matchingFlights = flexibleOffers.filter(flight => flight.StartDate === selectedOffer?.StartDate);
    onOfferSelected(matchingFlights);
  };

  const calculateLowestPrice: { [date: string]: TFF.Offer } = useMemo(() => {
    const lowestPriceFlights = {};
    flexibleOffers.forEach(offer => {
      const startDate = offer.StartDate.toString().split('T')[0];
      const totalPriceDiff = offer.TotalPriceDiff;
      if (totalPriceDiff !== undefined) {
        if (!lowestPriceFlights[startDate] || totalPriceDiff < lowestPriceFlights[startDate]?.TotalPriceDiff) {
          lowestPriceFlights[startDate] = offer;
        }
      }
    });

    return lowestPriceFlights;
  }, [flexibleOffers]);

  const offersToDisplay: TFF.Offer[] = Object.values(calculateLowestPrice);

  const createdBoxes = useMemo(() => {
    if (!searchDate) {
      return [];
    }
    let startDate = dateFns.addDays(new Date(searchDate), 0);
    const elements: ReactElement[] = [];
    for (let i = 0; i < 7; i++) {
      const countedDate = dateFns.addDays(new Date(startDate), i).toISOString().split('T')[0];
      const offerForTheDay: TFF.Offer = calculateLowestPrice[countedDate.split('T')[0]];
      const ids = offerForTheDay ? offerForTheDay.Id : i - 7;
      const costs: string | number | undefined = offerForTheDay ? offerForTheDay.TotalPriceDiff : '-';

      elements.push(
        <Box
          key={ids}
          sx={{
            border: '1px solid #1A7EAD',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: costs != '-' ? 'pointer' : 'default',
            backgroundColor: costs === '-' ? '#DBDBDB' : selectedBox === i ? '#1A7EAD' : 'white',
            ':hover': {
              backgroundColor: costs === '-' ? '#DBDBDB' : selectedBox === i ? '#1A7EAD' : '#F0F0F0',
            },
          }}
          onClick={() => {
            if (costs !== '-') {
              setSelectedBox(i);
              onOfferClick(ids);
            }
          }}
        >
          <div style={{ color: selectedBox === i ? '#FFFFFF' : '#333333' }}>
            {`${weekday(countedDate, locale.locale).substring(0, 3)}`} {format(new Date(countedDate), 'dd MMM')}
          </div>
          <div style={{ color: selectedBox === i ? '#FFFFFF' : '#1A7EAD' }}>
            {costs === '-'
              ? costs
              : typeof costs === 'number'
              ? `+${costs.toFixed(2)}€`
              : `+${costs === '0' ? '0.00' : costs}€`}
          </div>
        </Box>,
      );
    }
    return elements;
  }, [flexibleOffers, selectedBox]);

  return (
    <Paper elevation={0} className={classes.flexible}>
      {createdBoxes}
    </Paper>
  );
};

export default FlexibleTrack;
