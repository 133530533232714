import * as React from 'react';

export const GasStationIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
    style={{
      height: 20,
      width: 20,
    }}
    viewBox="0 0 120 120"
  >
    <g fill="none" fill-rule="evenodd" stroke="#092A5E" stroke-linecap="round" stroke-linejoin="round" stroke-width="8">
      <path d="M33.42 52.781h26.478V32H33.42z" />
      <path d="M72.3 103.25h5.575H16h5.017V34.416C21.017 24.797 27.97 17 36.55 17h20.218C65.347 17 72.3 24.797 72.3 34.416v68.834Z" />
      <path d="M98.5 52.625V80.75a7.5 7.5 0 0 1-15 0V69.5c0-6.213-5.037-11.25-11.25-11.25m21.136-5.625h10.228v-18.75H93.386zm5.114-18.75v-11.25L92.875 17" />
    </g>
  </svg>
);
