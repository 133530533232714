import * as React from 'react';
import { useEffect, useContext } from 'react';
import { TFF } from '@tff/types';
import { ISourceConfig } from '../models';
import { RetrieveFlightLambdaResponse, TypeOfTFFBookingSource } from '@tff/types/TFF';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';
import { keycloak } from '../keycloak';
import { CreateJourneyExtras } from '../util/create-journey-extras';
import { apigeeEndpoint } from '../apis';

export type RetrieveBookingProps = {
  recordLocator?: string;
  sourceConfig?: ISourceConfig;
};

export type TypeOfStatus = 'IN_FETCHING' | 'FETCHED' | 'FAILURE';

export type RetrieveBookingResponse = {
  fetchedBooking?: TFF.FlightDetails;
  status?: TypeOfStatus;
  error?: any;
  retrieveloading?: boolean;
  resetRetrieveBookingState: () => void;
};

const checkAccess = (bookingSource?: TypeOfTFFBookingSource | string): string | undefined => {
  if (!bookingSource) {
    return;
  }

  let requiredRole;
  if (bookingSource && (bookingSource.indexOf('NDC') > -1 || bookingSource.indexOf('AMA'))) {
    requiredRole = 'S_NDC';
  } else if (bookingSource.indexOf('NSK') > -1) {
    requiredRole = 'S_NSK';
  } else {
    return 'PERMISSION_ERROR:no_valid_access_rule_found';
  }

  if (!requiredRole || !keycloak.hasRealmRole(requiredRole)) {
    return 'PERMISSION_ERROR:no_permission';
  } else {
    return undefined;
  }
};

//
export const useRetrieveBooking = (retrieveBookingProps?: RetrieveBookingProps): RetrieveBookingResponse => {
  const [status, setStatus] = React.useState<TypeOfStatus | undefined>(undefined);
  const [fetchedBooking, setFetchedBooking] = React.useState<TFF.FlightDetails>();
  const [error, setError] = React.useState<unknown | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const { showLoading, closeLoading } = useContext(LoadingContext);
  const queryClient = useQueryClient();

  const loadBookingFromApigee = async (
    source: string,
    recordlocator: string,
    url: string,
  ): Promise<AxiosResponse<RetrieveFlightLambdaResponse & { body?: any; errorMessage?: string }>> => {
    setLoading(true);
    const dataKey = `get:booking:${source}:${recordlocator}`;
    const response: AxiosResponse<RetrieveFlightLambdaResponse & { body?: any; errorMessage?: string }> =
      await queryClient.fetchQuery(
        dataKey,
        async () => {
          const response = await apigeeEndpoint.get<
            RetrieveFlightLambdaResponse & { body?: any; errorMessage?: string }
          >(url);
          return response;
        },
        {
          retry: 2,
          // staleTime: 5000,
          cacheTime: 10000,
          initialData: () => {
            return queryClient.getQueryData(dataKey);
          },
        },
      );
    setLoading(false);
    return response;
  };

  const resetRetrieveBookingState = (): void => {
    setFetchedBooking(undefined);
    setStatus(undefined);
    setError(undefined);
  };

  useEffect(() => {
    const fetchDataFromBackend = async (recordLocator: string, sourceConfig: ISourceConfig): Promise<void> => {
      try {
        showLoading(`Fetch Booking from source ${sourceConfig.ApiPath}`);
        setStatus('IN_FETCHING');

        if (sourceConfig.SourceGroup === 'TUI_NDC') {
          const url = `/retrieve/${sourceConfig.ApiPath}/${recordLocator}`;

          const response = await loadBookingFromApigee(sourceConfig.SourceGroup, recordLocator, url);

          if (response.data.errorMessage) {
            setStatus('FAILURE');
            setError(response.data.errorMessage);
          }

          if (response.data.body?.Error && !response.data.Error) {
            response.data.Error = response.data.body.Error;
          }

          if (response.data.Status === 'SUCCESS') {
            setStatus('FETCHED');

            /**
             * checking segments within unconfirmed changes
             * if there are segments in states TK, UN, HX, US or UC a alert has to comeup, This Journey has to be marked and
             * this segments needs to be removed because of problems during displaying them.
             * https://support.travelport.com/webhelp/uapi/Content/Air/Shared_Air_Topics/PNR_Status_Codes.htm
             * TK : Schedule Change. Advise passenger of new scheduled times.
             * HX : Cancel confirm hold
             * UN : Unable - no flight
             * US : Unable to sell
             * UC : Unable to confirm or waitlist
             */

            const stateCodes: { [key: string]: string } = {
              TK: 'Schedule Change. Advise passenger of new scheduled times.',
              HX: 'Cancel confirm hold',
              UN: 'Unable - no flight',
              US: 'Unable to sell',
              UC: 'Unable to confirm or waitlist',
            };

            const unConfirmedStates = ['TK', 'HX', 'US', 'UC', 'UN'];
            //const statesToIgnoreSegments = ['HX', 'US', 'UC', 'UN'];
            const statesToIgnoreSegments = ['XYZ'];
            response.data.Booking?.OrderDetails.Journeys.forEach(j => {
              j.Segments.forEach((segment, index) => {
                if (segment.State && unConfirmedStates.indexOf(segment.State) > -1) {
                  j.AdditionalParams = j.AdditionalParams ?? {};
                  // adding an info to mark this journey
                  j.AdditionalParams[
                    `TFF_ALERT:UNCONFIRMED_STATE:${segment.State}`
                  ] = `SEGMENT_WITH_UNCONFIRMED_STATE:${stateCodes}:${stateCodes[segment.State]}`;
                  if (statesToIgnoreSegments.indexOf(segment.State) > -1) {
                    // remove this segment
                    j.Segments.splice(index, 1);
                  }
                }
              });
            });

            const preparedBooking = response.data.Booking ? CreateJourneyExtras(response.data.Booking) : undefined;
            setFetchedBooking(preparedBooking);
          } else {
            setStatus('FAILURE');
            setError(response.data.errorMessage || response.data.Error[0].Error || response.data.Error);
          }
        } else if (sourceConfig.SourceGroup === 'HV_NSK') {
          const url = `/retrieve/${sourceConfig.ApiPath}/${recordLocator}`;
          const response = await loadBookingFromApigee(sourceConfig.SourceGroup, recordLocator, url);
          if (response.data.errorMessage) {
            setStatus('FAILURE');
            setError(response.data.errorMessage);
          }

          const retrieveResponse: RetrieveFlightLambdaResponse = response.data;
          if (retrieveResponse.Error) {
            setStatus('FAILURE');
            setError(retrieveResponse.Error[0].Error);
          } else if (retrieveResponse.Status === 'SUCCESS') {
            setStatus('FETCHED');
            setFetchedBooking(retrieveResponse.Booking);
          }
        } else {
          const url = `/retrieve/${sourceConfig.ApiPath}/${recordLocator}`;
          const response = await loadBookingFromApigee(sourceConfig.SourceGroup, recordLocator, url);
          if (response.data.errorMessage) {
            setStatus('FAILURE');
            setError(response.data.errorMessage);
          }

          const retrieveResponse: RetrieveFlightLambdaResponse = response.data;
          if (retrieveResponse.Error) {
            setStatus('FAILURE');
            setError(retrieveResponse.Error);
          } else if (retrieveResponse.Status === 'SUCCESS') {
            setStatus('FETCHED');
            setFetchedBooking(retrieveResponse.Booking);
          }
        }
      } catch (error) {
        setError(error);
        setStatus('FAILURE');
      } finally {
        closeLoading();
      }
    };

    if (retrieveBookingProps?.recordLocator && retrieveBookingProps?.sourceConfig && !status) {
      const accessError = checkAccess(retrieveBookingProps?.sourceConfig.BookingSource);
      if (accessError) {
        setStatus('FAILURE');
        setError(accessError);
      } else {
        void fetchDataFromBackend(retrieveBookingProps.recordLocator, retrieveBookingProps.sourceConfig);
      }
    }
  }, [retrieveBookingProps]);

  return { error, fetchedBooking, status, resetRetrieveBookingState, retrieveloading: loading };
};
