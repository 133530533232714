import * as React from 'react';
import { memo, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

interface QueryContextProviderProps {
  children?: React.ReactNode;
}

const QueryContextProvider: React.FC<QueryContextProviderProps> = props => {
  const [queryClient] = useState(new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      {process.env.NODE_ENV === 'development' ? <ReactQueryDevtools initialIsOpen={false} /> : <></>}
    </QueryClientProvider>
  );
};

export default memo(QueryContextProvider);
