import * as React from 'react';
import Card from '@mui/material/Card';
import { Typography, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TFF } from '@tff/types';
import CardContent from '@mui/material/CardContent';
import RoomIcon from '@mui/icons-material/Room';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CallIcon from '@mui/icons-material/Call';

const useStyles = makeStyles(() =>
  createStyles({
    cardClass: {
      width: '100%',
      margin: '10px 0',
    },
    addressContent: {
      textOverflow: 'ellipsis',
      verticalAlign: 'top',
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingLeft: 27,
    },
    nameWrapper: {
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      verticalAlign: 'bottom',
    },
    top: {
      verticalAlign: 'top',
      paddingRight: '5px',
    },
  }),
);

type AddressBlockProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
  title?: string;
};

const AddressBlock = ({ icon, children, title }: AddressBlockProps) => {
  const classes = useStyles();
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', left: 0 }}>{icon}</div>
      <Typography title={title} className={classes.addressContent} component={'div'} color="textSecondary" noWrap>
        {children}
      </Typography>
    </div>
  );
};

interface CardProps {
  address: TFF.Address;
  headline: string;
}

const AddressTile = (props: CardProps) => {
  const { address, headline } = props;
  const classes = useStyles();

  return (
    <Card className={classes.cardClass} variant="outlined">
      <CardContent>
        <Typography component={'span'} color="textSecondary" gutterBottom>
          <strong>{headline}</strong> {`Type ${address.Type ?? '-'}`}
        </Typography>

        <table style={{ marginBottom: 10 }}>
          <tbody>
            <tr>
              <td className={classes.top}>
                <Typography variant="h5" component="h2" noWrap>
                  <span style={{ fontSize: '12px' }}>{address.Title || address.Salutation}</span>
                </Typography>
              </td>
              <td className={classes.nameWrapper} title={`${address.FirstName} ${address.LastName}`}>
                <Typography variant="h5" style={{ textOverflow: 'ellipsis' }}>
                  {address.FirstName} {address.LastName}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <Grid container spacing={2}>
          {address.Street && (
            <Grid item style={{ maxWidth: '100%' }}>
              <AddressBlock
                title={`${address.Street}\n${address.CountryCode}-${address.ZipCode} ${address.City}`}
                icon={<RoomIcon fontSize="small" color="primary" />}
              >
                {address.Street}
                <br />
                {address.CountryCode}-{address.ZipCode} {address.City}
              </AddressBlock>
            </Grid>
          )}
          {address.Mail && (
            <Grid item>
              <AddressBlock title={address.Mail} icon={<AlternateEmailIcon fontSize="small" color="primary" />}>
                {address.Mail}
              </AddressBlock>
            </Grid>
          )}
          {address.Telephone?.PhoneNumber && (
            <Grid item>
              <AddressBlock title={address.Telephone?.PhoneNumber} icon={<CallIcon fontSize="small" color="primary" />}>
                {address.Telephone?.CountryCode} {address.Telephone?.AreaCode} {address.Telephone?.PhoneNumber}
              </AddressBlock>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AddressTile;
