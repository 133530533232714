import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: '5px',
      margin: '10px',
      paddingTop: '25px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    iconWrapper: {
      height: 40,
      width: 40,
      backgroundColor: theme.palette.text.primary,
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconWrapperReversed: {
      height: 40,
      width: 40,
      backgroundColor: theme.palette.text.primary,
      borderRadius: 20,
      transform: 'rotate(180deg)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    infoIcon: {
      position: 'absolute',
      right: -10,
      top: -10,
      height: 20,
      width: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#D40E14',
      color: 'white',
      borderRadius: 10,
    },
    seatsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      padding: '5px 20px',
      backgroundColor: '#E2F3FE',
    },
    menuButtonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 400,
      color: '#0076B5',
      textDecoration: 'underline',
    },
    flightDetailRoot: {
      margin: '5px 0',
      witdh: '100%',
      '& .MuiCollapse-root': {
        width: '100%',
      },
      '& .MuiCollapse-entered': {
        width: '100%',
        marginBottom: '-20px',
      },
    },
    flightTrackLine: {
      height: '2px',
      backgroundColor: theme.palette.text.primary,
      width: '60%',
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    flightTrackDot: {
      height: '12px',
      width: '12px',
      borderRadius: '6px',
      backgroundColor: theme.palette.text.primary,
    },
    seatsPriceWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    priceWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'end',
      width: '100%',
      padding: '5px 20px',
      backgroundColor: '#E2F3FE',
    },
    original: {
      width: '100%',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '10px',
      borderColor: '#D6D1C2',
      margin: '10px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: '#F3F0EC',
    },
    flexible: {
      width: '100%',
      borderColor: '#1A7EAD',
      margin: '20px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'row',
      backgroundColor: '#FFFFFF',
    },
    footerButton: {
      padding: '5px 16px 6px 16px',
    },
  }),
);
