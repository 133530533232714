import { useEffect, useState } from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

// @ts-ignore
import * as logo from '../../../assets/images/logo.png';
import { useTypedSelector } from '../../../reducers';
import { IntlMessages } from '../../../util';
import { useNavigate } from 'react-router-dom';
import { renderTFFSelectInput, renderTFFTextInput } from '../Fields/TUILightThemeFields';
import TFFAlert from '../../../styled-components/TFFAlert';
import TFFButton from '../../../styled-components/TFFButton';

export interface ISearchOrderFormData {
  idType?: 'bookingID' | 'clientPnr' | 'tfmPnr' | 'midocoID' | 'AF' | 'KL' | 'EK' | 'NSK' | 'SQ' | 'NDCX' | 'AMADEUS';
  crsBookingId?: string;
  forename?: null | string;
  surname?: null | string;
}

const SearchOrderForm = ({ handleSubmit, pristine, reset, submitting }: InjectedFormProps<ISearchOrderFormData>) => {
  const { searchOrderForm } = useTypedSelector(({ form }) => form);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [disableId, setDisableID] = useState<boolean>(false);
  const navigate = useNavigate();

  const intl = useIntl();

  const resetForm = () => {
    reset();
    navigate(`/tff/order/search`);
  };

  const formValues: ISearchOrderFormData = searchOrderForm?.values;

  useEffect(() => {
    setDisabled(!!formValues?.crsBookingId);
    // const disableID = formValues?.forename || formValues?.surname || formValues?.city ? true : false;
    const disableID = !!(formValues?.forename || formValues?.surname);
    setDisableID(disableID);
  }, [formValues]);

  return (
    <Form onSubmit={handleSubmit} className="tff-root">
      <div className="tff-paper">
        <Link className="logo-lg" style={{ width: 90, height: 40 }} to="/" title="Flight Order Fulfillment">
          <img src={logo.default} width={'100%'} alt="Flight Order Fulfillment" title="Flight Order Fulfillment" />
        </Link>
        <div className="tff-search-title">
          <p className="text lead large semibold nospace">TUI Flight Fulfillment</p>
        </div>
        <div className="tff-formWrapper">
          <div className="row">
            <div className="col-md-6">
              <Field
                name="idType"
                inputId="idType"
                labelText="ID Type"
                component={renderTFFSelectInput}
                disabled={disableId}
              >
                <option value="bookingID">
                  <IntlMessages id={'pages.orderSearchPage.label.recordLocator'} />
                </option>
                <option value="midocoID">
                  <IntlMessages id={'pages.orderSearchPage.label.midocoid'} />
                </option>
                <option value="NSK">NSK</option>
                <option value="AF">Air France</option>
                <option value="KL">KLM</option>
                <option value="EK">Emirates</option>
                <option value="SQ">Singapore Airlines</option>
                <option value="NDCX">NDCX</option>
                <option value="AMADEUS">AMADEUS</option>
                <option value="clientPnr">AtComRes-BookingId</option>
                <option value="tfmPnr">TFM-Recordlocator</option>
              </Field>
            </div>
            <div className="col-md-6">
              <Field
                name="crsBookingId"
                inputId="tff-crsBookingId"
                labelText="Id"
                component={renderTFFTextInput}
                isBookingId
                disabled={disableId}
              />
            </div>
          </div>
          <div className="tff-spacer" />
          <Field
            name="surname"
            inputId="surname"
            labelText={intl.formatMessage({ id: 'pages.orderSearchPage.label.lastname' })}
            component={renderTFFTextInput}
            disabled={disabled}
          />
          <div className="tff-spacer" />
          <Field
            name="forename"
            inputId="forename"
            labelText={intl.formatMessage({ id: 'pages.orderSearchPage.label.firstname' })}
            component={renderTFFTextInput}
            disabled={!formValues?.surname}
          />
          <div className="tff-spacer" />

          <TFFAlert variant="info" text={intl.formatMessage({ id: 'pages.orderSearchPage.hint' })} />
        </div>

        <div className="tff-spacer" />

        <div className="tff-btnWrapper">
          <TFFButton
            buttonText={intl.formatMessage({ id: 'pages.orderSearchPage.button.reset' })}
            handleClick={resetForm}
            disabled={pristine || submitting}
            outline
            variant="secondary"
          />
          <TFFButton
            buttonText={intl.formatMessage({ id: 'pages.orderSearchPage.button.search' })}
            disabled={pristine || submitting}
            type="submit"
          />
        </div>
      </div>
    </Form>
  );
};

const validate = (searchOrderFormData: ISearchOrderFormData) => {
  const errors: any = {};
  if (
    searchOrderFormData.idType === 'midocoID' &&
    searchOrderFormData.crsBookingId &&
    isNaN(+searchOrderFormData.crsBookingId)
  ) {
    errors.crsBookingId = true;
  }

  return errors;
};

export default reduxForm<ISearchOrderFormData>({
  form: 'searchOrderForm',
  validate,
  enableReinitialize: false,
  initialValues: {
    idType: 'bookingID',
  },
})(SearchOrderForm);
