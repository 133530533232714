import * as React from 'react';
import { useEffect, useContext } from 'react';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';
import { TFF } from '@tff/types';
import { apigeeEndpoint } from '../apis';

export type MessagesProps = {
  bookingSource?: TFF.TypeOfTFFBookingSource;
  recordLocator?: string;
};

export type TypeOfStatus = 'IN_FETCHING' | 'FETCHED' | 'FAILURE';

export type MessagesResponse = {
  messageServiceItems?: TFF.Message[];
  messageServiceStatus?: TypeOfStatus;
  messageServiceError?: string;
  resetMessageServiceState: () => void;
  addMessage: (Message) => void;
};

//
export const useMessages = (messagesProps?: MessagesProps): MessagesResponse => {
  const [messageServiceStatus, setMessageServiceStatus] = React.useState<TypeOfStatus | undefined>(undefined);
  const [messageServiceItems, setMessageServiceItems] = React.useState<TFF.Message[] | undefined>(undefined);
  const [messageServiceError, setMessageServiceError] = React.useState<string | undefined>(undefined);
  const [messageToAdd, setMessageToAdd] = React.useState<TFF.Message | undefined>(undefined);
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const queryClient = useQueryClient();

  const addMessage = (messageToAdd: TFF.Message): void => {
    setMessageToAdd(messageToAdd);
  };

  const resetMessageServiceState = (): void => {
    setMessageServiceStatus(undefined);
    setMessageServiceItems(undefined);
    setMessageServiceError(undefined);
    setMessageToAdd(undefined);
  };

  useEffect(() => {
    const storeMessageAtBackend = async (message: TFF.Message): Promise<void> => {
      try {
        await apigeeEndpoint.post(`/message`, message);
        setMessageServiceItems(old => {
          return [...(old ?? []), message];
        });
      } catch (error) {
        if (error.isAxiosError) {
          setMessageServiceError(error.toJSON());
        } else {
          setMessageServiceError(error);
        }
        setMessageServiceStatus('FAILURE');
      }
    };

    if (messageToAdd) {
      void storeMessageAtBackend(messageToAdd);
    }
  }, [messageToAdd]);

  useEffect(() => {
    const fetchDataFromBackend = async (
      recordLocator: string,
      bookingSource?: TFF.TypeOfTFFBookingSource | 'TUI-NDC-SQ',
    ): Promise<void> => {
      try {
        showLoading('Search messages for RecordLocator: ');
        setMessageServiceStatus('IN_FETCHING');

        let response: AxiosResponse<TFF.Message[]>;

        // search only by recordLocator
        response = await queryClient.fetchQuery(
          `get:messages:recordLocator:${recordLocator}`,
          async () => {
            return await apigeeEndpoint.get<TFF.Message[]>(`/message/${bookingSource}/${recordLocator}`);
          },
          {
            retry: 2,
            // staleTime: 30000,
            cacheTime: 1000,
            initialData: () => {
              return queryClient.getQueryData(`get:messages:recordLocator:${recordLocator}`);
            },
          },
        );

        if ('errorMessage' in response.data) {
          throw response.data['errorMessage'];
        }

        const retrievedMessages: TFF.Message[] = response.data;

        setMessageServiceItems(retrievedMessages);
        setMessageServiceStatus('FETCHED');
      } catch (error) {
        setMessageServiceError(error);
        setMessageServiceStatus('FAILURE');
      } finally {
        closeLoading();
      }
    };

    if (messagesProps?.recordLocator && messagesProps?.bookingSource && !messageServiceStatus) {
      void fetchDataFromBackend(messagesProps.recordLocator, messagesProps.bookingSource);
    }
  }, [messagesProps]);

  return { messageServiceItems, messageServiceStatus, messageServiceError, resetMessageServiceState, addMessage };
};
