import React from 'react';
import CostOverviewTable from '../containers/CostOverviewTable/CostOverviewTable';
import { TFF } from '@tff/types';
import { CostOverviewInput } from '../models/CostOverviewInput';

interface CostOverviewProps {
  ssrDisplayData?: {
    ssrsToAdd: { [paxId: string]: TFF.SsrDetails[] };
    includedSsrs: { [paxId: string]: TFF.SsrDetails[] };
    metaValues: {
      type: string;
      ancillaries: TFF.MetaValue[];
    }[];
  };
  flightDisplayData?: {
    Journeys: TFF.Journey[];
  };
  asrDisplayData?: {
    // TODO: ASRs ought to be displayed in the table
  };
}

const CostOverview: React.FC<CostOverviewProps> = ({ ssrDisplayData, flightDisplayData, asrDisplayData }) => {
  return <div></div>;
};

export default CostOverview;
