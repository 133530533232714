import * as React from 'react';
import { useEffect, useContext } from 'react';
import { TFF } from '@tff/types';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { apigeeEndpoint } from '../apis';

export type TypeOfStatus = 'IN_CANCELLATION' | 'CANCELLED' | 'FAILURE';

export type CancelBookingProps = {
  recordLocator?: string;
  cancelFlightRequest?: TFF.CancelFlightRequest;
};

export type CancelBookingResponse = {
  cancelledBooking?: TFF.CancelFlightResponse;
  cancelBookingStatus?: TypeOfStatus;
  cancelBookingError?: any;
};

//
export const useCancelBooking = (cancelBookingProps: CancelBookingProps): CancelBookingResponse => {
  const [cancelBookingStatus, setCancelBookingStatus] = React.useState<TypeOfStatus>();
  const [cancelledBooking, setCancelledBooking] = React.useState<TFF.CancelFlightResponse>();
  const [cancelBookingError, setCancelBookingError] = React.useState<unknown | Error | undefined>();
  const { showLoading, closeLoading } = useContext(LoadingContext);

  useEffect(() => {
    const cancelBookingAtBackend = async (cancelRequest: TFF.CancelFlightRequest): Promise<void> => {
      try {
        setCancelBookingStatus('IN_CANCELLATION');
        showLoading(`Cancel ${cancelRequest.bookingSource} Booking`);

        const response = await apigeeEndpoint.post(`/cancel/${cancelRequest.bookingSource}`, cancelRequest);

        if (response.data.status === 'FAILURE') {
          let errorMsg = response.data.error;
          if (Array.isArray(errorMsg)) {
            errorMsg = errorMsg.map(e => e.StatusText).join(', ');
          }
          setCancelBookingError(errorMsg);
          setCancelBookingStatus('FAILURE');
        }
        if (response.data.status === 'SUCCESS') {
          setCancelBookingStatus('CANCELLED');
        }

        setCancelledBooking(response.data.data ?? response.data);
      } catch (error) {
        setCancelBookingError(error);
        setCancelBookingStatus('FAILURE');
      } finally {
        closeLoading();
      }
    };

    if (cancelBookingProps && cancelBookingProps.cancelFlightRequest && !cancelBookingStatus) {
      void cancelBookingAtBackend(cancelBookingProps.cancelFlightRequest);
    }
  }, [cancelBookingProps]);

  return { cancelBookingError, cancelledBooking, cancelBookingStatus };
};
