import { useEffect, useState } from 'react';
import { TFF } from '@tff/types';
import { apigeeEndpoint } from '../apis';

export type RetrieveAirportsProps = {
  OndList: string[];
};

export type TypeOfStatus = 'IN_FETCHING' | 'FETCHED' | 'FAILURE';

export type RetrieveAirportsResponse = {
  fetchedAirports?: TFF.IAirport[];
  status?: TypeOfStatus;
  error?: any;
};

export const useRetrieveAirports = (retrieveAirportsProps: RetrieveAirportsProps): RetrieveAirportsResponse => {
  const [status, setStatus] = useState<TypeOfStatus>();
  const [fetchedAirports, setFetchedAirports] = useState<TFF.IAirport[]>();
  const [error, setError] = useState<unknown | undefined>();

  useEffect(() => {
    const fetchedDataFromBackend = async (OndList: string[]): Promise<void> => {
      try {
        setError(undefined);
        setStatus('IN_FETCHING');
        setFetchedAirports(undefined);

        const response = await apigeeEndpoint.get(`/airport/${OndList.join(',')}`);
        if (response.status === 200) {
          setStatus('FETCHED');
          setFetchedAirports(JSON.parse(response.data.body));
        } else {
          setStatus('FAILURE');
          setError(response.data.errorMessage || response.data.Error[0].Error);
        }
      } catch (error) {
        setError(error);
        setStatus('FAILURE');
      }
    };

    if (retrieveAirportsProps.OndList && !status) {
      void fetchedDataFromBackend(retrieveAirportsProps.OndList);
    }
  }, [retrieveAirportsProps]);

  return { error, fetchedAirports, status };
};
