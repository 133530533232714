import { Box, Button, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import SeatsReservation from '../../../util/Seats6M';
import { useMutation } from 'react-query';
import { map6MResults } from '../../../util/map6MResults';
import { TFF } from '@tff/types';
import { useNavigate } from 'react-router-dom';
import { LoadingContext } from '../../../contexts/LoadingContextProvider';
import { Seat6MComponent } from '../../../util';
import { apigeeEndpoint } from '../../../apis';
import { create6MSeatComponentConfig } from '../../../util/map6MSeats';

interface props {
  orderDetails: TFF.OrderDetails;
  recordLocator: string;
  offerFlights: TFF.Journey[];
  setAncillarySeats: (value: any) => void;
  setSeatRows: (value: any) => void;
  setOpenSeats: (value: boolean) => void;
  openSeats: boolean;
}

type SeatMap = { [paxId: string]: TFF.SeatDetails };

export const AddSeats: React.FC<props> = ({
  orderDetails,
  offerFlights,
  recordLocator,
  openSeats,
  setOpenSeats,
  setAncillarySeats,
  setSeatRows,
}) => {
  const [scope, setScope] = useState<string>('*');
  const navigate = useNavigate();
  const { showLoading, closeLoading } = React.useContext(LoadingContext);

  const seatsConfig = useMemo(
    () => create6MSeatComponentConfig(offerFlights, orderDetails),
    [offerFlights, orderDetails],
  );

  const ancillaries: TFF.Ancillary[] | undefined = orderDetails?.Ancillaries
    ? Object.values(orderDetails.Ancillaries)
    : [];

  const updateAncillarySeats = (paxData: { paxId: string; addSeats?: TFF.SeatDetails[] }[]) => {
    const updatedSeats: SeatMap = paxData.reduce((acc, item) => {
      if (item.addSeats && item.addSeats.length > 0) {
        acc[item.paxId] = item.addSeats[0];
      }
      return acc;
    }, {} as SeatMap);

    setAncillarySeats(updatedSeats);
  };

  const updateAncillaries = async data => {
    const seatAncillaries = ancillaries.filter(
      (a: TFF.Ancillary) => orderDetails.Journeys.map(i => i.Ond).includes(a.JourneyOnd) && a.Type === 'SEAT',
    );
    const mapData = map6MResults(data.flights, recordLocator, seatAncillaries);
    updateAncillarySeats(mapData.paxData);

    setSeatRows({
      outboundRows: data.flights[0].passengers.map(passenger => ({
        label: 'SEAT',
        originalPrice: 0,
        newPrice: passenger.seat.price.amount,
        surcharge: passenger.seat.price.amount,
        original: `0 x ${passenger.seat.seatCategory}`,
        new: `1 x ${passenger.seat.seatCategory} - ${passenger.seat.row}${passenger.seat.column}`,
        code: `${offerFlights[0].Ond}-${passenger.seat.row}${passenger.seat.column}`,
      })),
      inboundRows:
        data.flights.length > 1
          ? data.flights[1].passengers.map(passenger => ({
              label: 'SEAT',
              originalPrice: 0,
              newPrice: passenger.seat.price.amount,
              surcharge: passenger.seat.price.amount,
              original: `0 x ${passenger.seat.seatCategory}`,
              new: `1 x ${passenger.seat.seatCategory}-${passenger.seat.row}${passenger.seat.column}`,
              code: `${offerFlights[1].Ond}-${passenger.seat.row}${passenger.seat.column}`,
            }))
          : [],
    });
  };

  const { mutate, isLoading } = useMutation(updateAncillaries);

  useEffect(() => {
    isLoading ? showLoading('Saving Changes') : closeLoading();
  }, [isLoading]);

  const handleCloseSeatsReservation = (seatReservationResult?: Seat6MComponent) => {
    setOpenSeats(false);
    setScope(prev => (prev === '*' ? '+' : '*'));
    mutate(seatReservationResult);
  };

  return (
    <>
      <SeatsReservation
        initialOpen={openSeats}
        onClose={handleCloseSeatsReservation}
        scope={scope}
        configs={seatsConfig}
      />
    </>
  );
};
