import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@mui/material';
import { useState } from 'react';

interface SearchFormProps {
  handleSearch: (searchLabel: string, searchValue: string) => void;
}

const SearchForm: React.FC<SearchFormProps> = ({ handleSearch }) => {
  const [searchLabel, setSearchLabel] = useState('');
  const [searchValue, setSearchValue] = useState('');
  return (
    <Grid item xs={12} container justifyContent="center" gap="40px" margin="20px">
      <Grid item xs={3}>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="search-label">Select Label</InputLabel>
          <Select name="searchLabel" value={searchLabel} onChange={e => setSearchLabel(e.target.value)}>
            <MenuItem value="atcom-id">AtCom ID</MenuItem>
            <MenuItem value="booking-id">Booking ID</MenuItem>
            <MenuItem value="tfm-pnr">TFM PNR</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <TextField
          type="text"
          name="searchValue"
          variant="filled"
          label={searchLabel || 'Select search label'}
          fullWidth
          inputProps={{ style: { textTransform: 'uppercase' } }}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value.toUpperCase())}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          id="submit"
          onClick={() => handleSearch(searchLabel, searchValue)}
          style={{ marginRight: '20px', marginLeft: '20px', height: '56px' }}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchForm;
