import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { TFF } from '@tff/types';
import { FlightLand, FlightTakeoff } from '@mui/icons-material';
import StepConnector from '@mui/material/StepConnector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '70%',
      margin: 0,
      padding: 0,
      '& .MuiStepper-root': {
        backgroundColor: 'transparent',
      },
    },
    redIcon: {
      color: 'red',
      width: '15px',
    },

    primaryIcon: {
      color: theme.palette.primary.main,
      width: '15px',
    },
    stepLabel: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiStepLabel-iconContainer': {
        paddingRight: 0,
      },
    },
  }),
);

const useConnectorStyles = makeStyles({
  stepConnector: (labels: { label: string; currentColor: string }[]) => {
    const styles = {};

    labels.forEach(({ currentColor }, index) => {
      if (index < labels.length - 1) {
        styles[`&:nth-child(${2 * index + 2})`] = { color: currentColor };
      }
    });

    return styles;
  },
  stepConnectorLine: {
    borderColor: 'currentColor',
  },
});

interface IFlightTrackProps {
  journey: TFF.Journey;
}

const HorizontalFlightTrack: React.FC<IFlightTrackProps> = ({ journey }) => {
  const classes = useStyles();
  const theme = useTheme();
  const segments: TFF.Segment[] = journey.Segments;
  const lastSegment = segments[segments.length - 1];

  const getLabels = () => {
    const labels = segments.map((s, index) => ({
      label: s.Origin,
      currentColor:
        (s.Replacements && s.Replacements?.length > 0) || s.State === 'UN' ? 'red' : theme.palette.primary.main,
      icon: index === 0 ? <FlightTakeoff color="primary" /> : null,
    }));

    labels.push({
      label: lastSegment.Destination,
      currentColor:
        (lastSegment.Replacements && lastSegment.Replacements?.length > 0) || lastSegment.State === 'UN'
          ? 'red'
          : theme.palette.primary.main,
      icon: <FlightLand color="primary" />,
    });

    return labels;
  };

  const labels = getLabels();

  const connectorClasses = useConnectorStyles(labels);

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={-1}
        connector={
          <StepConnector
            classes={{
              root: connectorClasses.stepConnector,
              line: connectorClasses.stepConnectorLine,
            }}
          />
        }
        style={{ padding: 0 }}
      >
        {labels.map(({ label, icon, currentColor }, index) => (
          <Step key={index}>
            <StepLabel
              StepIconProps={{
                icon: '',
                classes: { root: currentColor === 'red' ? classes.redIcon : classes.primaryIcon },
              }}
              className={classes.stepLabel}
              style={{ marginTop: icon ? '-45px' : '-18px' }}
            >
              {icon && icon}
              <div>{label}</div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default HorizontalFlightTrack;
