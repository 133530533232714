import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { apigeeEndpoint } from '../apis';
import {
  Body,
  SearchAncillariesLambdaRequest,
  SearchAncillariesLambdaResponse,
} from '@tff/types/TFF/tfa-amend-ancillary-service';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { resetCheckboxStates } from '../reducers/Ancillary/ancillarySlice';

export type TypeOfStatus = 'IN_ANCILLARY' | 'CONFIRMED' | 'FAILURE';

export type UseAncillaryResponse = {
  ancillaryResponse?: SearchAncillariesLambdaResponse;
  ancillaryStatus: TypeOfStatus;
  ancillaryError?: Error | undefined;
  setSearchAncillariesLambdaRequest: (ancillaryBookingRequest: SearchAncillariesLambdaRequest) => void;
};

export const useAncillaryBooking = (): UseAncillaryResponse => {
  const { showLoading, closeLoading } = useContext(LoadingContext);

  const [ancillaryStatus, setAncillaryStatus] = React.useState<TypeOfStatus>('IN_ANCILLARY');
  const [ancillaryResponse, setAncillaryResponse] = React.useState<SearchAncillariesLambdaResponse>();
  const [ancillaryError, setAncillaryError] = React.useState<any>();
  const [ancillaryBookingLambdaRequest, setSearchAncillariesLambdaRequest] = useState<SearchAncillariesLambdaRequest>();

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  useEffect(() => {
    const ancillaryBookingFromBackend = async (
      ancillaryBookingRequest: SearchAncillariesLambdaRequest,
    ): Promise<void> => {
      try {
        const queryKey = `ssrTable#${
          ancillaryBookingLambdaRequest?.locale
        }#${ancillaryBookingLambdaRequest?.journeyIdentifiers.map<string>(j => j.journeyIdentifier).join('#')}`;
        const cachedData = queryClient.getQueryData(queryKey);

        if (!cachedData) {
          dispatch(resetCheckboxStates());
        }

        if (ancillaryStatus === 'IN_ANCILLARY') {
          showLoading('Please Wait');
        }

        const response = await queryClient.fetchQuery(
          queryKey,
          async () => {
            return apigeeEndpoint.post('/ancillary-search', ancillaryBookingRequest).then(response => response.data);
          },
          {
            retry: 2,
            cacheTime: 5 * 60 * 1000,
            staleTime: 5 * 60 * 1000,
            initialData: () => {
              return queryClient.getQueryData<SearchAncillariesLambdaResponse>(queryKey);
            },
          },
        );

        if (response.status === 'SUCCESS') {
          setAncillaryStatus('CONFIRMED');
          // order tfa response by directions. Sometimes the response is in the wrong order.
          const ondOrder = ancillaryBookingRequest.journeyIdentifiers.map<string>(
            i => i.journeyIdentifier.split('-').pop()!,
          );
          const newAncillaryBodyOrder: Body[] = [];
          if (
            response.body &&
            response.body.length > 1 &&
            response.body[0].segment.endsWith(ondOrder[1]) &&
            response.body[1].segment.endsWith(ondOrder[0])
          ) {
            newAncillaryBodyOrder[0] = response.body[1];
            newAncillaryBodyOrder[1] = response.body[0];
            response.body = newAncillaryBodyOrder;
          }

          setAncillaryResponse(response);
          closeLoading();
        } else {
          setAncillaryStatus('FAILURE');
          setAncillaryError(response.body);
          closeLoading();
          queryClient.removeQueries(queryKey);
        }
      } catch (error) {
        setAncillaryStatus('FAILURE');
        closeLoading();
      }
    };

    if (
      ancillaryBookingLambdaRequest &&
      ancillaryBookingLambdaRequest.journeyIdentifiers.length > 0 &&
      ancillaryBookingLambdaRequest.currency &&
      ancillaryBookingLambdaRequest.locale
    ) {
      void ancillaryBookingFromBackend(ancillaryBookingLambdaRequest);
    }
  }, [ancillaryBookingLambdaRequest, queryClient]);

  return {
    setSearchAncillariesLambdaRequest,
    ancillaryError,
    ancillaryResponse,
    ancillaryStatus,
  };
};
