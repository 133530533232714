import { timestampMillisToLocalDateString } from '../util';
declare const window: any;
const Footer = () => {
  return (
    <footer className="app-footer" style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 999 }}>
      <span className="d-inline-block">Copyright TUI AG 2021</span>
      {window.ENV?.BUILD_VERSION} - {window.ENV?.STAGE} - {timestampMillisToLocalDateString(window.ENV?.BUILD_TS)} -{' '}
      {(window.ENV?.COMMIT_ID || '').substring(0, 6)}
    </footer>
  );
};

export default Footer;
