import { Avatar, Button, Chip, Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomDialog from './Dialogs';
import FeesConfig, { EnhancedFee } from './FeesConfig';
import CampaignForm from './forms/CampaignForm';
import moment from 'moment';
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ICampaign, IFee } from '@tff/types/TFF';
import { setCampaign, saveCampaign, loadCampaigns, CAMPAIGN_STATES } from '../reducers/Campaign/campaignSlice';
import { IntlMessages } from '../util';

interface IProps {
  campaign: ICampaign;
  index: number;
}

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      width: '80%',
      padding: '20px 40px',
      marginBottom: '20px',
    },
    headerRoot: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export const CampaignConfig: React.FC<IProps> = ({ campaign, index }) => {
  const classes = useStyle();
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [campaignFees, setCampaignFees] = useState<IFee[]>();
  const dispatch = useDispatch();
  const sources = campaign.bookingSources?.map(bs => (bs === 'TUI-NDCX' ? 'TUI-NDCX-BA' : bs)).join(',');

  useEffect(() => {
    if (campaign.fees) {
      setCampaignFees(
        campaign.fees.filter((fee: IFee) => {
          return !fee.deleted;
        }),
      );
    }
  }, [campaign]);

  useEffect(() => {
    if (campaign.priority !== index + 1) {
      const updatePriority: ICampaign = { ...campaign, priority: index + 1 };
      dispatch(saveCampaign(updatePriority));
    }
  }, [index, campaign, dispatch]);

  const handleOpenEdit = () => {
    setOpenEditDialog(true);
    dispatch(
      setCampaign({
        campaign: campaign,
        state: CAMPAIGN_STATES.FINISHED,
      }),
    );
  };

  const handleEdit = (values: ICampaign) => {
    setOpenEditDialog(false);
    dispatch(saveCampaign(values));
    setTimeout(() => dispatch(loadCampaigns()), 100);
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
    ...draggableStyle,

    ...(isDragging && {
      background: 'rgb(235,235,235)',
    }),
  });

  return (
    <Draggable draggableId={campaign.itemKey as string} index={index} key={campaign.itemKey}>
      {(provided, snapshot) => (
        <Accordion
          className={classes.root}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <div className="row" style={{ width: '100%' }}>
              <div className="col-md-6 d-flex flex-row align-item-center">
                <Typography variant="h5" color="primary" style={{ fontWeight: 'bold', fontSize: 18 }}>
                  {campaign.name}
                </Typography>
                <Chip
                  style={{ marginLeft: '5px', marginTop: '-5px' }}
                  avatar={<Avatar style={{ backgroundColor: 'rgb(112, 203, 244)' }}>P</Avatar>}
                  label={campaign.priority}
                />
              </div>
              <div className="col-md-2">
                <Typography variant="h6" color="primary" style={{ fontWeight: 'bold', fontSize: 14 }}>
                  {sources}
                </Typography>
              </div>
              <div className="col-md-4">
                <Typography variant="h6" color="primary" style={{ fontWeight: 'bold', fontSize: 14 }}>
                  <IntlMessages id="pages.fees.campaign-config.runTime" />
                  {': '}
                  {campaign.startDate ? moment(campaign.startDate).format('DD.MM.YYYY') : <span>&infin;</span>} -{' '}
                  {campaign.endDate ? moment(campaign.endDate).format('DD.MM.YYYY') : <span>&infin;</span>}
                </Typography>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <Grid container spacing={2} style={{ borderBottom: 'groove', marginBottom: 10 }}>
              <Grid item xs={9} className={classes.headerRoot}>
                <div className="row" style={{ marginTop: 20 }}>
                  <div className="col-md-12">
                    <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: 14 }}>
                      {campaign.description}
                    </Typography>
                  </div>
                </div>
                <div className="row" style={{ marginTop: 20 }}>
                  <div className="col-md-6">
                    <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: 14 }}>
                      <IntlMessages id="pages.fees.campaign-config.bookingDate" />
                      {': '}
                      {campaign.bookingStartDate ? (
                        moment(campaign.bookingStartDate).format('DD.MM.YYYY')
                      ) : (
                        <span>&infin;</span>
                      )}{' '}
                      -{' '}
                      {campaign.bookingEndDate ? (
                        moment(campaign.bookingEndDate).format('DD.MM.YYYY')
                      ) : (
                        <span>&infin;</span>
                      )}
                    </Typography>
                  </div>
                  <div className="col-md-4">
                    <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: 14 }}>
                      <IntlMessages id="pages.fees.campaign-config.travelDate" />
                      {': '}
                      {campaign.travelStartDate ? (
                        moment(campaign.travelStartDate).format('DD.MM.YYYY')
                      ) : (
                        <span>&infin;</span>
                      )}{' '}
                      -{' '}
                      {campaign.travelEndDate ? (
                        moment(campaign.travelEndDate).format('DD.MM.YYYY')
                      ) : (
                        <span>&infin;</span>
                      )}
                    </Typography>
                  </div>
                  <div className="col-md-2">
                    <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: 14 }}>
                      <IntlMessages id="pages.fees.campaign-config.status" />
                      {': '}
                      {campaign.status}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '70%', color: 'white' }}
                  onClick={handleOpenEdit}
                >
                  <IntlMessages id="pages.fees.campaign-config.edit" />
                </Button>
              </Grid>
            </Grid>
            <FeesConfig campaignKey={campaign.itemKey} fees={campaignFees as EnhancedFee[]} />
            <CustomDialog
              width="lg"
              form
              initialOpen={openEditDialog}
              onCancel={() => setOpenEditDialog(false)}
              onConfirm={() => setOpenEditDialog(false)}
            >
              <CampaignForm
                isNew={false}
                onCancel={() => setOpenEditDialog(false)}
                onSubmit={(values: ICampaign) => handleEdit(values)}
              />
            </CustomDialog>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
};

export default CampaignConfig;
