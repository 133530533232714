import * as React from 'react';
import RetrieveList from '../../components/retrieves/RetrieveList';
import { Button, Grid, Paper, TextField } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    paperRoot: {
      marginTop: '20px',
      marginBottom: '20px',
      padding: '20px',
    },
  }),
);

const _retrieveList = () => {
  const classes = useStyles();
  const [tfmPnr, setTfmPnr] = React.useState<string>();
  const navigate = useNavigate();
  const { url } = useParams();

  const handleSearch = () => {
    if (tfmPnr) {
      navigate(`/tff/retrieve/tfm/${tfmPnr}`);
    }
  };

  return (
    <div className="app-wrapper">
      <Paper elevation={2} hidden={true} className={classes.paperRoot}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <TextField
                variant="standard"
                label="TFMPnr"
                type="text"
                inputProps={{ maxLength: 6, style: { textTransform: 'uppercase' } }}
                onChange={event => setTfmPnr(event.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ marginLeft: '20px' }}
                onClick={handleSearch}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <RetrieveList match={{ url: url! }} />
    </div>
  );
};

export default _retrieveList;
