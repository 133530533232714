import { setIn } from 'final-form';

const validateFormValues = schema => async values => {
  if (typeof schema === 'function') {
    schema = schema();
  }
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (error: any) {
    return error.inner.reduce((formError, innerError) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});
  }
};

export default validateFormValues;
