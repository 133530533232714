import { Typography } from '@mui/material';
import * as React from 'react';
import { useContext } from 'react';
import planeLoading from '../assets/images/plane_loading.gif';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import CustomDialog from './Dialogs';

const Loader: React.FC = () => {
  const { isLoading, message } = useContext(LoadingContext);

  return (
    <>
      {isLoading ? (
        <CustomDialog width="xs" initialOpen={isLoading !== undefined} form>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={planeLoading} alt="loading..." style={{ height: 100, width: 100 }} />
          </div>
          {message && message?.length > 0 && (
            <Typography variant="h6" color="primary" style={{ textAlign: 'center' }}>
              {message}
            </Typography>
          )}
        </CustomDialog>
      ) : null}
    </>
  );
};

export default Loader;
