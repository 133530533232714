import { TFF } from '@tff/types';

interface GroupedAncillaries {
  type: string;
  ancillaries: { outbound: TFF.MetaValue[]; inbound: TFF.MetaValue[] };
  matchedPassengers: {};
}

/*
    merges outbound and inbound accordion data into single object
    returns an array containing both outbound and inbound arrays
  */

export const mergeByType = (outboundRows, inboundRows): GroupedAncillaries[] => {
  const grouped = {};

  const processRows = (rows, direction) => {
    rows.forEach(row => {
      const { type, ancillaries } = row;
      if (!grouped[type]) {
        grouped[type] = { type, ancillaries: { outbound: [], inbound: [] } };
      }
      grouped[type].ancillaries[direction].push(...ancillaries);
    });
  };

  processRows(outboundRows, 'outbound');
  processRows(inboundRows, 'inbound');

  return Object.values(grouped);
};
