import { Routes } from 'react-router-dom';
import { withRouter } from '../withRouter';

export const InvoluntarySimulationFormRoutes = () => {
  return (
    <div className="app-wrapper h-100">
      <Routes>{/*<CompatRoute path={`${match.url}/`} exact component={InvoluntarySimulationForm}/>*/}</Routes>
    </div>
  );
};

export default withRouter(InvoluntarySimulationFormRoutes);
