import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { RemoveDuplicateRows } from './removeDuplicateRows';
import { Paper, Typography, Box, Alert, Button, TextField, InputAdornment, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { TFF } from '@tff/types';
import React, { useEffect, useState } from 'react';
import useStyles from './AccordionStyles';
import { RefundWarningDialog } from '../AncillaryTable/RefundWarningDialog';
import { CloseRounded, DoneRounded, ErrorOutline } from '@mui/icons-material';

const commonStyles = {
  cellHeader: {
    color: '#4D4D4D',
    fontSize: '18px',
    lineHeight: '20.59px',
    fontWeight: 700,
    width: '364px',
  },
  cellBody: {
    color: '#4D4D4D',
    fontSize: '18px',
    lineHeight: '20.59px',
    fontWeight: 700,
  },
  typography: {
    textAlign: 'left',
  },
  errorTypography: {
    textAlign: 'left',
    fontWeight: 'bold',
    color: '#D30D14',
  },
};

interface props {
  flightDetails: TFF.FlightDetails;
  ssrsToAdd: { [paxId: string]: TFF.SsrDetails[] };
  includedSsrs: { [paxId: string]: TFF.SsrDetails[] };
  metaValues: any[];
  rebooking?: boolean;
  rebookOffers?: any;
  setDisplayTable: (value: boolean) => void;
  submitChanges: () => void;
  setAdjustableSurcharge: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  amendBalanceDue?: number;
}

type PaxObject = Record<string, TFF.SsrDetails[]>;

type ClassifiedFlights = {
  outbound: TFF.SsrDetails[];
  return: TFF.SsrDetails[];
};

const AncillaryCostTable: React.FC<props> = ({
  flightDetails,
  ssrsToAdd,
  includedSsrs,
  metaValues,
  rebooking,
  rebookOffers,
  setDisplayTable,
  submitChanges,
  setAdjustableSurcharge,
  amendBalanceDue,
}) => {
  const intl = useIntl();
  const numberFormatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 });
  const outboundFlight = rebooking
    ? rebookOffers?.outboundOnd || ''
    : flightDetails?.OrderDetails?.Journeys[0]?.Ond || '';
  const returnFlight = rebooking ? rebookOffers?.returnOnd || '' : flightDetails?.OrderDetails?.Journeys[1]?.Ond || '';
  const ondArray: string[] = [outboundFlight, returnFlight].filter(str => str !== '').sort();
  const originalAncillaries: TFF.Ancillaries = flightDetails.OrderDetails.Ancillaries!;
  const [showWarningNotification, setShowWarningNotification] = useState<boolean>(false);
  const [totalCost, setTotalCost] = useState<number>(0);

  const classes = useStyles();

  let rows: any = [
    { label: 'Outbound', isSection: true },
    //below is inbound data
    { label: 'Inbound', isSection: true },
    //below is sum total
    { original: '', new: '', surcharge: '' },
    { original: 'ORIGINAL (incl. TAX)', new: 'NEW (incl. TAX)', surcharge: 0.0 },
    { original: 'NON REFUNDABLE TAX', new: 'AIRLINE FEES', surcharge: 0.0 },
    { original: 'NON REFUNDABLE EXTRAS', new: 'SERVICE FEE', surcharge: 0.0 },
    { sumCancelled: 'SUM CANCELLED', sumNew: 'SUM NEW', total: 'TOTAL TO PAY', surcharge: 0.0, isSummary: true },
  ];

  const [surchargeValues, setSurchargeValues] = useState(
    rows.reduce((acc, row) => {
      acc[row.id] = row.surcharge;
      return acc;
    }, {}),
  );

  const handleInputChange = (id, value) => {
    setSurchargeValues(prevValues => ({
      ...prevValues,
      [id]: value,
    }));
  };

  useEffect(() => {
    setAdjustableSurcharge(surchargeValues);
  }, [surchargeValues]);

  useEffect(() => {
    setAdjustableSurcharge(surchargeValues);
    if (amendBalanceDue !== undefined && totalCost > 0) {
      console.log(`amendBalanceDue ${amendBalanceDue} -  totalCost ${totalCost} = ${amendBalanceDue - totalCost}`);
    }
  }, [amendBalanceDue, totalCost]);

  const sumSurchargeValues = (surchargeValues: { [key: string]: string | number }) => {
    return Object.values(surchargeValues).reduce(
      (sum: number, value: string | number) => sum + parseFloat(value.toString().replace(',', '.')),
      0,
    );
  };

  const findParentByCode = (data, code) =>
    data.find(item => item.ancillaries.some(ancillary => ancillary.code === code.replace('-remove', '')));

  const findAmountByCode = (
    array: {
      type: string;
      ancillaries: { code: string; price: { amount: number; regularAmount: number; currency: string } }[];
    }[],
    code: string,
  ): number | undefined => {
    const parentObject = array.find(obj => obj.ancillaries.some(anc => anc.code === code));

    const ancillary = parentObject?.ancillaries.find(anc => anc.code === code);

    return ancillary?.price.amount;
  };

  const findOriginalPrice = (code: string) => {
    if (Object.values(originalAncillaries).length !== 0) {
      for (const key in originalAncillaries) {
        if (key.includes(code)) {
          return originalAncillaries[key].TotalAmount;
        } else {
          return findAmountByCode(metaValues, code)!;
        }
      }
    } else {
      return findAmountByCode(metaValues, code)!;
    }
  };

  const sumByKey = (data: any[], keys: string[]): { [key: string]: number } => {
    return data.reduce((acc, curr) => {
      keys.forEach(key => {
        if (typeof curr[key] === 'number') {
          if (!acc[key]) acc[key] = 0;
          acc[key] += curr[key];
        }
      });
      return acc;
    }, {});
  };

  const findUniqueItems = (obj1: PaxObject, obj2: PaxObject) => {
    const uniqueItems: PaxObject = {};

    if (rebooking) {
      if (rebookOffers.offerOnd.length == ondArray.length) {
        return obj1;
      } else {
        Object.keys(obj1).forEach(key => {
          const obj2ItemsStringified = obj2[key].map(item => JSON.stringify(item));
          return (uniqueItems[key] = obj1[key].filter(i => {
            if (i.segmentOnd == rebookOffers.offerOnd) {
              return true;
            }
            return !obj2ItemsStringified.includes(JSON.stringify(i));
          }));
        });
      }
    } else {
      Object.keys(obj1).forEach(key => {
        if (!obj2[key]) {
          uniqueItems[key] = obj1[key];
        } else {
          const obj2ItemsStringified = obj2[key].map(item => JSON.stringify(item));
          uniqueItems[key] = obj1[key].filter(item => {
            return !obj2ItemsStringified.includes(JSON.stringify(item));
          });
        }
      });
    }

    return uniqueItems;
  };

  const classifyFlights = (data: PaxObject): ClassifiedFlights => {
    const result: ClassifiedFlights = {
      outbound: [],
      return: [],
    };

    const addItem = (item: TFF.SsrDetails, flightType: 'outbound' | 'return') => {
      const items = result[flightType];
      const existingItemIndex = items.findIndex(
        existingItem => existingItem.segmentOnd === item.segmentOnd && existingItem.code === item.code,
      );

      if (existingItemIndex !== -1) {
        items[existingItemIndex].amount += item.amount;
      } else {
        items.push({ ...item });
      }
    };

    Object.values(data).forEach(paxArray => {
      paxArray.forEach(item => {
        if (item.segmentOnd === outboundFlight && !item.code.includes('BA0')) {
          addItem(item, 'outbound');
        } else if (item.segmentOnd === returnFlight && !item.code.includes('BA0')) {
          addItem(item, 'return');
        }
      });
    });

    return result;
  };

  const insertFlightData = (data, rows) => {
    const outboundIndex = rows.findIndex(item => item.label === 'Outbound') + 1;
    const inboundIndex = rows.findIndex(item => item.label === 'Inbound') + 1;

    data.outbound.forEach((flight, index) => {
      const ssrType = findParentByCode(metaValues, flight.code);
      const formattedFlight = {
        label: ssrType ? ssrType.type : '',
        original: '0.00',
        new: `${flight.amount} x ${flight.code}`,
        surcharge: '0.00',
        ond: flight.segmentOnd,
        code: `${flight.segmentOnd}-${flight.code}`,
      };
      rows.splice(outboundIndex + index, 0, formattedFlight);
    });

    data.return.forEach((flight, index) => {
      const ssrType = findParentByCode(metaValues, flight.code);
      const formattedFlight = {
        label: ssrType ? ssrType.type : '',
        original: '0.00',
        new: `${flight.amount} x ${flight.code}`,
        surcharge: '0.00',
        ond: flight.segmentOnd,
        code: `${flight.segmentOnd}-${flight.code}`,
      };
      rows.splice(inboundIndex + data.outbound.length + index, 0, formattedFlight);
    });
  };

  insertFlightData(classifyFlights(findUniqueItems(ssrsToAdd, includedSsrs)), rows);

  const updateRowsWithOriginalBasedOnPaxData = (rows, paxData) => {
    let outboundAmount = {};
    let inboundAmount = {};
    let codeCounts = {};
    Object.values(paxData).forEach((pax: any) => {
      pax.forEach(({ code, segmentOnd, amount }) => {
        if (code !== 'BA0') {
          codeCounts[code] = (codeCounts[code] || 0) + 1;

          if (segmentOnd === outboundFlight) {
            if (code.includes('BX')) {
              outboundAmount[code] = (outboundAmount[code] || 0) + amount;
            } else {
              outboundAmount[code] = (outboundAmount[code] || 0) + 1;
            }
          } else {
            if (code.includes('BX')) {
              inboundAmount[code] = (inboundAmount[code] || 0) + amount;
            } else {
              inboundAmount[code] = (inboundAmount[code] || 0) + 1;
            }
          }
        }
      });
    });

    rows.forEach(row => {
      if (typeof row.new === 'string') {
        const match = row.new.match(/(\d+) x ([\w]+)/);

        if (match) {
          const [, amount, code] = match;
          const rowSplit = row.new.split('x');
          const sign = rowSplit[1].indexOf('-remove') > -1;
          const ssrAmount = row.ond === outboundFlight ? outboundAmount[code] ?? 0 : inboundAmount[code] ?? 0;
          const isNewSsrPresent: boolean = findParentByCode(metaValues, code) !== undefined;

          if (codeCounts[code]) {
            row.original = `${ssrAmount} x ${intl.formatMessage({
              id: `ssr.${code}.shortDescription`,
            })}`;
          } else {
            row.original = `0 x ${intl.formatMessage({
              id: `ssr.${code}.shortDescription`,
            })}`;
          }
          row.new = isNewSsrPresent
            ? `${
                !rebooking
                  ? sign
                    ? parseInt(ssrAmount) - parseInt(rowSplit[0].trim() ?? 0)
                    : code.includes('BX')
                    ? parseInt(rowSplit[0].trim() ?? 0)
                    : parseInt(rowSplit[0].trim() ?? 0) + parseInt(ssrAmount)
                  : sign
                  ? parseInt(ssrAmount) - parseInt(rowSplit[0].trim() ?? 0)
                  : rowSplit[0].trim() ?? 0
              } x ${intl.formatMessage({
                id: `ssr.${rowSplit[1].replace('-remove', '').trim()}.shortDescription`,
              })}`
            : 'This Ssr is Not Available For This Flight!';

          row.originalPrice = ssrAmount * findOriginalPrice(code)!;
          row.newPrice = isNewSsrPresent
            ? parseInt(row.new.split('x')[0].trim()) * findAmountByCode(metaValues, code)!
            : 0;

          row.surcharge = parseInt(row.newPrice) - parseInt(row.originalPrice);
        }
      }
    });
  };

  updateRowsWithOriginalBasedOnPaxData(rows, includedSsrs);

  if (rebooking) {
    const outboundSeats =
      rebookOffers?.seatRows?.outboundRows?.length > 0 ? [...rebookOffers.seatRows.outboundRows] : [];

    rows.splice(1, 0, rebookOffers.outbound, ...outboundSeats);

    const index = rows.findIndex(row => row.label === 'Inbound');
    if (Object.values(rebookOffers.inbound).length > 0) {
      if (index !== -1) {
        const inboundSeats =
          rebookOffers?.seatRows?.inboundRows?.length > 0 ? [...rebookOffers.seatRows.inboundRows] : [];

        rows.splice(index + 1, 0, rebookOffers.inbound, ...inboundSeats);
      }
    }
  }

  const getCellStyles = (row, key) => ({
    ...commonStyles.cellHeader,
    backgroundColor: row !== '' ? (key === 'original' ? '#F3F0EC' : '#E2F5FD') : 'white',
    borderTop: '1px solid #737373',
  });

  //this is a temporary solution --- find out why duplication happens
  const uniqueRows = RemoveDuplicateRows(rows);

  const keys = ['surcharge', 'originalPrice', 'newPrice'];
  const totals = sumByKey(uniqueRows, keys);
  const negativeTotal = (totals?.newPrice ?? 0) - (totals?.originalPrice ?? 0) < 0;

  useEffect(() => {
    uniqueRows.forEach(row => {
      setSurchargeValues(prevValues => ({
        ...prevValues,
        [row.code]: numberFormatter.format(row.surcharge),
      }));
    });
  }, []);

  const getAdjustedSurchargeValues = (surchargeValues: { [key: string]: string | number }) => {
    const adjustedSurchargeValues = {};
    // adjusted surcharge values are saved with a -1 next to the usual surcharge values, watch out for bugs
    Object.entries(surchargeValues).forEach(([key, value]) => {
      if (!key.includes('-1')) {
        const adjustedKey = key + '-1';
        if (surchargeValues.hasOwnProperty(adjustedKey)) {
          adjustedSurchargeValues[key] = surchargeValues[adjustedKey];
        } else {
          adjustedSurchargeValues[key] = surchargeValues[key];
        }
      }
    });
    return adjustedSurchargeValues;
  };

  useEffect(() => {
    const seatPrices =
      rebookOffers?.seatRows?.outboundRows?.length > 0
        ? eval(rebookOffers.seatRows.outboundRows.map(i => i.surcharge).join('+'))
        : 0 + rebookOffers?.seatRows?.inboundRows?.length > 0
        ? eval(rebookOffers.seatRows.inboundRows.map(i => i.surcharge).join('+'))
        : 0;

    setTotalCost(
      sumSurchargeValues(getAdjustedSurchargeValues(surchargeValues)) +
        (rebookOffers?.outbound?.surcharge ?? 0) +
        (rebookOffers?.inbound?.surcharge ?? 0) +
        seatPrices,
    );
  }, [surchargeValues]);

  const renderRow = (row, index) => {
    if (row.isSection) {
      return (
        <TableRow key={index}>
          <TableCell
            align="left"
            sx={{
              ...commonStyles.cellBody,
              fontWeight: 700,
              paddingLeft: 0,
              borderBottom: '1px solid #737373',
            }}
          >
            {row.label}
          </TableCell>
        </TableRow>
      );
    } else if (row.isSummary) {
      return (
        <TableRow key={index}>
          <TableCell align="left" sx={{ borderTop: '2px solid #737373', borderBottom: 'none' }}></TableCell>
          <TableCell sx={{ ...commonStyles.cellHeader, backgroundColor: '#F3F0EC', borderTop: '2px solid #737373' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={commonStyles.typography}>{row.sumCancelled}</Typography>
              <Typography sx={commonStyles.typography}>
                {numberFormatter.format(totals?.originalPrice ?? '0')}
              </Typography>
            </Box>
          </TableCell>
          <TableCell sx={{ ...commonStyles.cellHeader, backgroundColor: '#E2F5FD', borderTop: '2px solid #737373' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={commonStyles.typography}>{row.sumNew}</Typography>
              <Typography sx={commonStyles.typography}>{numberFormatter.format(totals?.newPrice ?? '0')}</Typography>
            </Box>
          </TableCell>
          <TableCell sx={{ width: '240px', backgroundColor: '#AAE0F9', borderTop: '2px solid #737373' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={commonStyles.typography}>{row.total}</Typography>
              {!rebooking && (
                <Typography
                  sx={negativeTotal ? commonStyles.errorTypography : commonStyles.typography}
                  style={{ textAlign: 'right' }}
                >
                  {numberFormatter.format((totals?.newPrice ?? '0') - (totals?.originalPrice ?? '0'))}
                </Typography>
              )}
            </Box>
          </TableCell>
          {rebooking && (
            <TableCell sx={{ backgroundColor: '#AAE0F9', borderTop: '2px solid #737373' }}>
              <Typography
                sx={negativeTotal ? commonStyles.errorTypography : commonStyles.typography}
                style={{ textAlign: 'right' }}
              >
                {numberFormatter.format(totalCost)}
              </Typography>
            </TableCell>
          )}
        </TableRow>
      );
    } else {
      // remove 'row.label &&' after the backend changes
      const boughtAmount = parseInt(row.new.split(' x ')[0]); // not sure if this string is consistent across all SSRs, if not, an alternative approach might be needed
      return (
        <TableRow key={index}>
          <TableCell align="left" sx={{ ...commonStyles.cellBody, whiteSpace: 'nowrap', border: 'none' }}>
            <Typography>{row.label}</Typography>
          </TableCell>
          <TableCell align="left" sx={getCellStyles(row.original, 'original')}>
            <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {row.rebooking ? (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <div style={{ fontWeight: 'bold' }}>{row.original.split('/')[0]}</div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{row.original.split('/')[1]}</span>
                    <span>{numberFormatter.format(row.originalPrice)}</span>
                  </div>
                </div>
              ) : (
                <>
                  <span>{row.original}</span>
                  <span>{row.label && numberFormatter.format(row.originalPrice)}</span>
                </>
              )}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={getCellStyles(row.original, 'new')}>
            <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {row.rebooking ? (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <div style={{ fontWeight: 'bold' }}>{row.new.split('/')[0]}</div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{row.new.split('/')[1]}</span>
                    <span>{numberFormatter.format(row.newPrice)}</span>
                  </div>
                </div>
              ) : (
                <>
                  <span>{row.new}</span>
                  {row.new === 'SERVICE FEE' ? (
                    <TextField
                      inputProps={{
                        style: {
                          width: '100px',
                          height: '31px',
                          padding: '4px',
                          lineHeight: '23.4px',
                          fontSize: '18px',
                          textAlign: 'right',
                          backgroundColor: '#FFFFFF',
                        },
                      }}
                      disabled={!rebooking}
                      value={surchargeValues['Service Fee'] ?? '0,00'}
                      onChange={e => handleInputChange('Service Fee', e.target.value)}
                    />
                  ) : (
                    <span>{row.label && numberFormatter.format(row.newPrice)}</span>
                  )}
                </>
              )}
            </Typography>
          </TableCell>
          <TableCell align="right" sx={{ borderTop: '1px solid #737373' }}>
            <Typography sx={{ textAlign: rebooking ? 'center' : 'right' }}>
              {row.label && numberFormatter.format(row.surcharge)}
            </Typography>
          </TableCell>
          {rebooking && (
            <TableCell align="right" sx={{ borderTop: '1px solid #737373' }}>
              {row.label !== 'FLIGHT' && row.code ? (
                <>
                  <TextField
                    inputProps={{
                      style: {
                        width: '100px',
                        height: '31px',
                        padding: '4px',
                        lineHeight: '23.4px',
                        fontSize: '18px',
                        textAlign: 'right',
                        borderRadius: '4px',
                        border: surchargeValues[`${row.code}-${row.new[0]}`]
                          ? parseInt(surchargeValues[`${row.code}-${row.new[0]}`]) !== parseInt(row.surcharge)
                            ? '1px solid #30B575'
                            : 'none'
                          : 'none',
                      },
                    }}
                    value={surchargeValues[`${row.code}-${row.new[0]}`] ?? surchargeValues[row.code]}
                    onChange={e => {
                      const numericValue = e.target.value.replace(/[^0-9,]/g, '');
                      handleInputChange(`${row.code}-${row.new[0]}`, numericValue);
                    }}
                    onBlur={e => {
                      const numericValue = e.target.value.replace(/[^0-9,]/g, '');
                      const formattedValue = parseFloat(numericValue.replace(',', '.')).toFixed(2).replace('.', ',');
                      handleInputChange(`${row.code}-${row.new[0]}`, formattedValue);
                    }}
                  />
                  {(surchargeValues[`${row.code}-${row.new[0]}`]
                    ? parseInt(surchargeValues[`${row.code}-${row.new[0]}`]) !== parseInt(row.surcharge)
                    : false) && (
                    <InputAdornment position="end" sx={{ marginTop: '-20px', position: 'absolute' }}>
                      {parseFloat(surchargeValues[`${row.code}-${row.new[0]}`]) % boughtAmount === 0 ? (
                        <DoneRounded style={{ color: 'green' }} />
                      ) : (
                        <Tooltip title={`Indivisable by bought SSR amount ${boughtAmount}`} placement="top">
                          <CloseRounded style={{ color: 'red' }} />
                        </Tooltip>
                      )}
                    </InputAdornment>
                  )}
                </>
              ) : (
                <Typography sx={{ textAlign: 'right' }}>
                  {row.label && numberFormatter.format(row.surcharge)}
                </Typography>
              )}
            </TableCell>
          )}
        </TableRow>
      );
    }
  };

  const handleConfirmChanges = () => {
    if (!rebooking) {
      if (negativeTotal) {
        setShowWarningNotification(true);
      } else {
        submitChanges();
      }
    }
  };

  return (
    <>
      <Paper sx={{ marginTop: 2, padding: 3, minWidth: 650, width: '100%', overflow: 'hidden' }}>
        <Typography
          sx={{
            fontSize: '27px',
            color: '#09295D',
            fontWeight: 700,
            lineHeight: '30.89px',
            paddingTop: '16px',
            paddingBottom: 3,
          }}
        >
          COST OVERVIEW
        </Typography>
        <TableContainer>
          <Table aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: '#E7E2DA',
                    ...commonStyles.cellHeader,
                  }}
                >
                  ORIGINAL BOOKING
                </TableCell>
                <TableCell align="center" sx={{ ...commonStyles.cellHeader, backgroundColor: '#E2F5FD' }}>
                  NEW BOOKING
                </TableCell>
                <TableCell align="center" sx={{ ...commonStyles.cellBody }}>
                  SURCHARGE
                </TableCell>
                {rebooking && (
                  <TableCell align="center" sx={{ ...commonStyles.cellBody }}>
                    ADJUSTED SURCHARGE
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>{uniqueRows.map(renderRow)}</TableBody>
          </Table>
        </TableContainer>
        {!rebooking && negativeTotal && (
          <Box sx={{ paddingTop: 2 }}>
            <Alert
              color="warning"
              sx={{ color: '#09295D' }}
              icon={<ErrorOutline fontSize="inherit" sx={{ color: '#09295D' }} />}
            >
              Refund is not applicable via Flightmanager. To refund the amount, please leave the page without
              confirmation and proceed to NSK/Midoco
            </Alert>
          </Box>
        )}
        <caption style={{ marginTop: '16px', width: '137px', fontSize: '12px' }}>All Prices are in EURO</caption>
      </Paper>
      {!rebooking && (
        <>
          <Box className={classes.ancillaryFooter}>
            <Button color="info" className={classes.ancillaryCancel} onClick={() => setDisplayTable(false)}>
              {intl.formatMessage({ id: 'ancillaries.action.cancel' })}
            </Button>
            <Button
              className={classes.ancillaryAccept}
              onClick={() => {
                handleConfirmChanges();
              }}
            >
              {/* TODO - update confirmChanges DE message */}
              {intl.formatMessage({ id: 'ancillaries.action.confirmChanges' })}
            </Button>
          </Box>
          {showWarningNotification && (
            <RefundWarningDialog setShowWarning={v => setShowWarningNotification(v)} onConfirm={submitChanges} />
          )}
        </>
      )}
    </>
  );
};
export default AncillaryCostTable;
