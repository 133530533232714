// Going to update after divide booking api endpoint is done.
import * as React from 'react';
import { useEffect, useContext, useState } from 'react';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { apigeeEndpoint } from '../apis';
import { DivideBookingRequest, DivideBookingResponse } from '@tff/types/TFF/divide-booking-service';

export type TypeOfStatus = 'IN_DIVIDING' | 'CONFIRMED' | 'FAILURE';

export type UseDivideResponse = {
  divideResponse?: DivideBookingResponse;
  divideStatus?: TypeOfStatus;
  divideError?: Error | undefined;
  setDivideBookingRequest: (divideBookingRequest: DivideBookingRequest) => void;
};

const requiredParamsShouldBeThere = (obj: DivideBookingRequest) => {
  return Object.keys(obj).every(field => {
    if (field !== 'tfmPnr') return Boolean(field);
    return true;
  });
};

//
export const useDivideBooking = (): UseDivideResponse => {
  const [divideStatus, setDivideStatus] = React.useState<TypeOfStatus>();
  const [divideResponse, setDivideResponse] = React.useState<DivideBookingResponse>();
  const [divideError, setDivideError] = React.useState<Error | undefined>();
  const [divideBookingRequest, setDivideBookingRequest] = useState<DivideBookingRequest | undefined>(undefined);
  const { showLoading, closeLoading } = useContext(LoadingContext);

  useEffect(() => {
    const divideBookingAtBackend = async (divideRequest: DivideBookingRequest): Promise<void> => {
      try {
        setDivideStatus('IN_DIVIDING');
        showLoading(`Splitting ${divideRequest.bookingSource} Booking`);

        const response = await apigeeEndpoint.post(`/divide-booking`, divideRequest);

        if (response.data.status === 'SUCCESS') {
          setDivideStatus('CONFIRMED');
          setDivideResponse(response.data.data ?? response.data);
        } else if (!response || response.data.status === 'FAILURE') {
          setDivideStatus('FAILURE');
          setDivideError(response.data.error);
        }
      } catch (error) {
        setDivideStatus('FAILURE');
      } finally {
        closeLoading();
      }
    };

    if (divideBookingRequest && requiredParamsShouldBeThere(divideBookingRequest)) {
      void divideBookingAtBackend(divideBookingRequest);
    }
  }, [divideBookingRequest]);

  return { setDivideBookingRequest, divideError, divideResponse, divideStatus };
};
