import { TFF } from '@tff/types';

type SeatSegment = {
  code: string;
  segmentOnd: string;
};

type SeatObject = {
  [key: string]: SeatSegment;
};

/*
        Returns ssr "code" value from Metavalues array
    */

const findAirlineCode = (code: string, rows: TFF.MetaValue[]): string => {
  const foundItem = rows.find(i => i.code === code);
  return foundItem ? foundItem.airlineSsrCode : code;
};

/*
    Generates removeSsrIds object for ssr-update request payload
    */

const generateSsrPaxToRemove = (ancillaries: TFF.Ancillaries, paxId: string): string => {
  const foundKey = Object.keys(ancillaries).find((ancillaryKey): boolean => {
    const processedAncillaryKey: string = ancillaryKey.replace(/~\d+$/, '').replaceAll('~', '').replaceAll('-', '');
    return paxId === processedAncillaryKey;
  });
  return foundKey as string;
};

/*
        Generates paxData array for ssr-update request payload
        forms the array and its objects in the requested structure
    */

export const parsePaxData = (
  ssrsToAdd: { [paxId: string]: TFF.SsrDetails[] },
  outboundRaw: TFF.MetaValue[],
  inboundRaw: TFF.MetaValue[],
  Ancillaries: TFF.Ancillaries,
  seatAncillary?: { [paxId: string]: TFF.SeatDetails[] },
  surchargeValues?: {
    [key: string]: string;
  },
) => {
  const paxData: TFF.PaxData[] = [];
  const seats = seatAncillary ?? {};
  Object.keys(ssrsToAdd).forEach(paxId => {
    if (ssrsToAdd[paxId].length > 0) {
      let addedSsrs: TFF.SsrDetails[] = [];
      let addedSeats: TFF.SeatDetails[] = [];
      let removedSsrs: string[] = [];
      ssrsToAdd[paxId].forEach(ssr => {
        if (ssr.code.includes('remove') && !ssr.code.includes('BA0')) {
          const processPaxId = `${ssr.segmentOnd}${paxId}${findAirlineCode(
            ssr.code.replace('-remove', ''),
            outboundRaw ?? inboundRaw,
          )}`.replaceAll('-', '');
          removedSsrs.push(generateSsrPaxToRemove(Ancillaries, processPaxId));
        } else if (!ssr.code.includes('BA0')) {
          if (surchargeValues) {
            const ancillaryPrice = surchargeValues[`${ssr.segmentOnd}-${ssr.code}`];
            const regexForKeys = new RegExp(`^${ssr.segmentOnd}-${ssr.code}-\\d+$`);
            const keyWithNumber = Object.keys(surchargeValues).find(key => regexForKeys.test(key));
            if (ancillaryPrice) {
              if (keyWithNumber) {
                const amount = keyWithNumber.match(/-(\d+)$/)![1];
                ssr.priceToCharge = Number(
                  (parseInt(surchargeValues[keyWithNumber]) / (amount != '0' ? parseInt(amount) : 1)).toFixed(2),
                );
              } else {
                ssr.priceToCharge = parseInt(ancillaryPrice);
              }
            }
          }
          addedSsrs.push(ssr);
        }
      });
      if (seats[paxId]) {
        if (surchargeValues) {
          //@ts-ignore
          seats[paxId].priceToCharge = parseInt(surchargeValues[`${seats[paxId].segmentOnd}-${seats[paxId].code}`]);
        }
        //@ts-ignore
        addedSeats.push(seats[paxId] ?? {});
      }

      paxData.push({
        paxId,
        removeSsrIds: removedSsrs,
        addSsrs: addedSsrs,
        addSeats: addedSeats,
      });
    }
  });
  return paxData;
};
