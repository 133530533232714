import * as React from 'react';
import {
  TypeOfCancellationReasonNSK,
  TypeOfCancellationReasonNDC,
  TypeOfRefund,
  TypeOfTFFBookingSource,
} from '@tff/types/TFF';
import SelectField, { FieldType } from './SelectField';
import { useIntl } from 'react-intl';

export const refundConfigNSK: { [key in TypeOfRefund]: TypeOfCancellationReasonNSK[] } = {
  VOID: ['VOID', 'GOODWILL', 'INVOL_CANCEL', 'TECHNICAL_ISSUE', 'OTHER'],
  TAX_REFUND: ['TECHNICAL_ISSUE', 'OTHER'],
  FULL_REFUND: ['GOODWILL', 'INVOL_CHANGE', 'INVOL_CANCEL', 'TECHNICAL_ISSUE', 'OTHER'],
};

export const refundConfigNDC: { [key in 'TAX_REFUND' | 'VOID']: TypeOfCancellationReasonNDC[] } = {
  VOID: ['FARE_RULES', 'POLICY', 'INVOL_CHANGE', 'INVOL_CANCEL'],
  TAX_REFUND: ['FARE_RULES', 'POLICY', 'INVOL_CHANGE', 'INVOL_CANCEL'],
};

interface IProps {
  refundType: TypeOfRefund;
  bookingSource: TypeOfTFFBookingSource | 'TUI-NDC-SQ';
  onChange: (selectedItem: FieldType) => void;
}

const RefundSelector: React.FC<IProps> = ({ refundType, bookingSource, onChange }) => {
  const intl = useIntl();
  const items = bookingSource === 'TUI-NSK' ? refundConfigNSK[refundType] : refundConfigNDC[refundType];
  const fieldValues: FieldType[] = [];
  if (!items) {
    return null;
  }
  for (const cancelReason of items) {
    fieldValues.push({ key: cancelReason, value: cancelReason });
  }

  return (
    <SelectField
      fieldValues={fieldValues}
      fieldName={intl.formatMessage({ id: 'cancel.reason' })}
      onChange={onChange}
    />
  );
};

export default RefundSelector;
