import Order from './order';
import Retrieve from './retrieve';
import Settings from './settings';
import ManuallyBooking from '../components/forms/ManuallyBooking';
import Administration from './administration';
import Reservation from './reservation';
import Registration from './registration';
import DeferedPayment from './deferedPayment';
import InvoluntaryChangesAtCom from './involuntaryChangesAtCom';
// import ShowBookingPage from './booking/ShowBookingPage';
import { CommonRoutes } from './common-routes';
import AmendFailures from './amend-failures';
import WelcomePage from './WelcomePage';
import { Routes, Route } from 'react-router-dom';
import InvoluntaryChanges from './involuntary-changes/involuntary-changes';
import { withRouter } from './withRouter';

const AppRoutes = () => {
  return (
    <Routes>
      {/* Welcome  */}
      {<Route path="welcome" element={<WelcomePage />} />}

      {/* Order sub routings */}
      <Route path="order/*" element={<Order />} />

      {/* Order sub routings */}
      {/*<Route path={`${match.url}/booking/`} element={booking} />*/}

      {/* Retreive sub routings */}
      <Route path="retrieve/*" element={<Retrieve />} />

      {/* settings purposes */}
      <Route path="settings/*" element={<Settings />} />

      {/* user administration */}
      <Route path="administration/*" element={<Administration />} />

      {/* registration */}
      <Route path="registration/*" element={<Registration />} />

      {/* involuntary-changes */}
      <Route path="involuntary-changes" element={<InvoluntaryChanges />} />

      <Route path="amend-failures/*" element={<AmendFailures />} />

      <Route path="manually-booking" element={<ManuallyBooking />} />

      <Route path="common/*" element={<CommonRoutes />} />

      <Route path="reservation" element={<Reservation />} />

      <Route path="defered-payment/*" element={<DeferedPayment />} />

      <Route path="involuntary-changes-atCom/*" element={<InvoluntaryChangesAtCom />} />

      {/*<Route path={`${match.url}/booking/:id`} element={ShowBookingPage} />*/}

      {/*
      <Route path={`${match.url}/booking/:id`} element={ShowBookingPage}/>
      <Route path={`${match.url}/retrieve`} exact element={AsyncComponent(() => import('../../components/retrieves/RetrieveListShow'))}/>
      <Route path={`${match.url}/retrieve/:id`} exact element={AsyncComponent(() => import('../../components/retrieves/RetrieveShow'))}/>

    */}
    </Routes>
  );
};

export default withRouter(AppRoutes);
