/**
 * replacing titles in and out
 */
export const mapTitlesToSource = (title: string): string => {
  const titlesMap = {
    MR: 'Herr',
    MRS: 'Frau',
  };
  return titlesMap[title] ?? title;
};

export const mapTitlesFromSource = (title: string): string => {
  const titlesMap = {
    Herr: 'MR',
    Frau: 'MRS',
    DAME: 'MRS',
  };
  return titlesMap[title] ?? title;
};
