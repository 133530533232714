import { FC } from 'react';

const TUICheckbox: FC<{ isChecked: boolean; shouldDisabled: boolean }> = ({
  isChecked,
  shouldDisabled,
}): JSX.Element => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '20.5px', height: '20.5px', marginTop: '-5px' }}
    >
      <path
        d="M1.6665 3C1.6665 1.89543 2.56193 1 3.6665 1H15.6665C16.7711 1 17.6665 1.89543 17.6665 3V15C17.6665 16.1046 16.7711 17 15.6665 17H3.6665C2.56193 17 1.6665 16.1046 1.6665 15V3Z"
        fill={`${shouldDisabled ? '#DCDCDC' : 'white'}`}
        stroke={'#BABABA'}
      />

      <path
        d={isChecked ? 'M13.0834 5.125L8.70837 12.625L5.58337 9.5' : ''}
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default TUICheckbox;
