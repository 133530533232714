import * as React from 'react';
import { CSSProperties } from 'react';

type Props = {
  style?: CSSProperties;
};

export const PlaneIcon: React.FC<Props> = ({ style }) => (
  <svg
    style={{
      fill: '#ffffff',
      width: '23.02px',
      height: '24px',
      ...style,
    }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 15L1 17V14L10 9V3C10 1.921 10.922 1 12 1C13.08 1 14 1.92 14 3V9L23 14V17L14 15V20L16 23L12 22L8 23L10 20V15Z"
      stroke="#09295D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
