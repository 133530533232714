import { Message as TFFMessage, MessageType } from '@tff/types/TFF';
import { AdditionalChannelType, ContentType } from '@tff/types/TFF/message';

export default class Message implements TFFMessage {
  public partitionKey?: string;
  public itemKey?: string;
  public action: string;
  public content?: string;
  public source: string;
  public type?: MessageType;
  public contentType?: ContentType;
  public channel: string;
  public recordLocator: string;
  public createdAt?: Date | string;
  public createdBy: string;
  public additionalChannel?: AdditionalChannelType | undefined;

  constructor(
    createdBy: string,
    recordLocator: string,
    channel: string,
    messageType: MessageType,
    source: string,
    action: string,
    additionalChannel?: AdditionalChannelType,
  ) {
    this.type = messageType;
    this.recordLocator = recordLocator;
    this.source = source;
    this.channel = channel;
    this.action = action;
    this.createdBy = createdBy;
    this.additionalChannel = additionalChannel;
  }
}
