import * as React from 'react';
import { Box, Button, Checkbox, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomDialog from './Dialogs';
import FeesForm from './forms/FeesForm';
import { IFee } from '@tff/types/TFF';
import { saveFee, setFee, FEE_STATES } from '../reducers/Fee/feeSlice';
import { loadCampaigns } from '../reducers/Campaign/campaignSlice';
import { IntlMessages } from '../util';

interface IProps {
  fees: EnhancedFee[];
  campaignKey?: string;
}

export interface EnhancedFee extends IFee {
  selected: boolean;
}

const WhiteTextTypography = withStyles({
  root: {
    color: '#FFFFFF',
    fontWeight: 'normal',
  },
})(Typography);

const GreenCheckbox = withStyles({
  root: {
    color: '#269e34 !important',
  },
})(Checkbox);

const RedCheckbox = withStyles({
  root: {
    color: '#ff0000 !important',
  },
})(Checkbox);

const LowerCaseButton = withStyles({
  root: {
    textTransform: 'none',
  },
})(Button);

const FeesConfig: React.FC<IProps> = ({ fees, campaignKey }) => {
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleAddFee = (values: IFee) => {
    setOpenAddDialog(false);
    dispatch(saveFee(values));
    setTimeout(() => dispatch(loadCampaigns()), 100);
  };

  return (
    <>
      <Box className="row" style={{ marginBottom: 10, backgroundColor: '#70CBF4' }}>
        <div className="col-md-3">
          <WhiteTextTypography variant="subtitle1">
            <IntlMessages id="pages.fees.campaign-config.action" />
          </WhiteTextTypography>
        </div>
        <div className="col-md-3">
          <WhiteTextTypography variant="subtitle1">
            <IntlMessages id="pages.fees.campaign-config.channel" />
          </WhiteTextTypography>
        </div>
        <div className="col-md-2">
          <WhiteTextTypography variant="subtitle1">
            <IntlMessages id="pages.fees.campaign-config.fee" />
          </WhiteTextTypography>
        </div>
        <div className="col-md-2">
          <WhiteTextTypography variant="subtitle1">
            <IntlMessages id="pages.fees.campaign-config.flexible" />
          </WhiteTextTypography>
        </div>
        <div className="col-md-2"></div>
      </Box>
      {/*Fees Content */}
      {fees?.length > 0 ? (
        fees.map((fee: EnhancedFee, index: number) => {
          const handleDelete = () => {
            setOpenAlertDialog(false);
            const deletedFee: EnhancedFee = { ...fee, deleted: true };
            dispatch(saveFee(deletedFee));
            setTimeout(() => dispatch(loadCampaigns()), 100);
          };
          const handleOpenEdit = () => {
            setOpenEditDialog(true);
            dispatch(
              setFee({
                fee: fee,
                state: FEE_STATES.FINISHED,
                feesLoading: false,
              }),
            );
          };
          const handleEdit = (values: IFee) => {
            setOpenEditDialog(false);
            dispatch(saveFee(values));
            setTimeout(() => dispatch(loadCampaigns()), 100);
          };
          const actions = fee.actions?.join(',');
          return (
            <div key={index}>
              <div className="row" style={{ height: 24 }}>
                <div className="col-md-3">
                  <Typography variant="subtitle1">
                    {fee.selected && <RedCheckbox checked={fee.selected} />}
                    {actions}
                  </Typography>
                </div>
                <div className="col-md-3">
                  <Typography variant="subtitle1">{fee.channel}</Typography>
                </div>
                <div className="col-md-2">
                  <Typography variant="subtitle1">
                    {fee.fee} {fee.currency}
                  </Typography>
                </div>
                <div className="col-md-2">{fee.flexibel && <GreenCheckbox checked={fee.flexibel} />}</div>
                <div className="col-md-2">
                  <LowerCaseButton variant="text" color="secondary" onClick={() => setOpenAlertDialog(true)}>
                    <IntlMessages id="pages.fees.campaign-config.delete" />
                  </LowerCaseButton>
                  <LowerCaseButton variant="text" color="primary" onClick={handleOpenEdit}>
                    <IntlMessages id="pages.fees.campaign-config.edit" />
                  </LowerCaseButton>
                </div>
              </div>
              {/* alert Dialog */}
              <CustomDialog
                initialOpen={openAlertDialog}
                onConfirm={handleDelete}
                onCancel={() => setOpenAlertDialog(false)}
                width="xs"
              >
                <Typography variant="subtitle1">
                  <IntlMessages id="pages.fees.campaign-config.confirmation-dialog" />
                </Typography>
              </CustomDialog>
              {/* edit Dialog */}
              <CustomDialog
                initialOpen={openEditDialog}
                onConfirm={() => setOpenEditDialog(false)}
                onCancel={() => setOpenEditDialog(false)}
                width="lg"
                form
              >
                <FeesForm
                  isNew={false}
                  campaignKey={campaignKey}
                  onSubmit={(values: IFee) => handleEdit(values)}
                  onCancel={() => setOpenEditDialog(false)}
                />
              </CustomDialog>
            </div>
          );
        })
      ) : (
        <Typography variant="h6" style={{ margin: '20px 0px' }}>
          <IntlMessages id="pages.fees.campaign-config.no-fee" />
        </Typography>
      )}
      {/* add fees */}
      <div>
        {fees?.length > 0 ? (
          <LowerCaseButton variant="text" color="primary" onClick={() => setOpenAddDialog(true)}>
            <IntlMessages id="pages.fees.campaign-config.add-more-fee" />
          </LowerCaseButton>
        ) : (
          <Button variant="contained" color="primary" style={{ color: 'white' }} onClick={() => setOpenAddDialog(true)}>
            <IntlMessages id="pages.fees.campaign-config.add-fee" />
          </Button>
        )}
        {/* add Dialog */}
        <CustomDialog
          initialOpen={openAddDialog}
          onCancel={() => setOpenAddDialog(false)}
          onConfirm={() => setOpenAddDialog(false)}
          width="lg"
          form
        >
          <FeesForm
            isNew
            campaignKey={campaignKey}
            onSubmit={(values: IFee) => handleAddFee(values)}
            onCancel={() => setOpenAddDialog(false)}
          />
        </CustomDialog>
      </div>
    </>
  );
};

export default FeesConfig;
