import React from 'react';
import Header from './Header';
import { Box, Container, Paper, Typography } from '@mui/material';
import RenderFlightTrack from '../RenderFlightTrack';
import { ErrorOutline } from '@mui/icons-material';
import { MuiAccordion, MuiAccordionDetails, MuiAccordionSummary } from './MuiAccordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStyles } from './use-styles';
import IntlMessages from '../../../util/IntlMessages';
import { TFF } from '@tff/types';
import { useIntl } from 'react-intl';
import FlightSegment from '../../FlightDetailCard/FlightSegment';

interface props {
  isFirstJourney: boolean;
  journey: TFF.Journey;
  airports: TFF.IAirport[];
  orderDetails: TFF.OrderDetails;
}
const OriginalBooking: React.FC<props> = ({ isFirstJourney, journey, airports, orderDetails }) => {
  const classes = useStyles();
  const intl = useIntl();
  const segments: TFF.Segment[] = journey.Segments;
  return (
    <>
      {<Header isFirstJourney={isFirstJourney} />}
      <Paper elevation={0} className={classes.original}>
        <Container sx={{ padding: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            <Typography sx={{ color: '#00308F', fontWeight: 'bold', padding: 1 }}>
              {intl.formatMessage({ id: 'rebook.Original' }).toUpperCase()}
            </Typography>
            <RenderFlightTrack journey={journey} airports={airports} />
            <Typography sx={{ marginLeft: 12 }}>
              <ErrorOutline sx={{ color: '#00308F', fontSize: '24px', marginBottom: 1 }} /> {journey?.FareFamily}
            </Typography>
          </Box>
          <div className={classes.flightDetailRoot}>
            <MuiAccordion>
              <MuiAccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#0076B5' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  padding: '0px 0px 0px 5px',
                }}
              >
                <Typography component={'span'} className={classes.heading} style={{ fontSize: '18px' }}>
                  <IntlMessages id="pages.flightDetails.flightDetails" />
                </Typography>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                {segments.map((segment: TFF.Segment, index: number) => {
                  return (
                    <FlightSegment
                      key={index}
                      tffSegment={segment as TFF.Segment}
                      orderDetails={orderDetails}
                      isNewOffer={false}
                    />
                  );
                })}
              </MuiAccordionDetails>
            </MuiAccordion>
          </div>
        </Container>
      </Paper>
    </>
  );
};

export default OriginalBooking;
