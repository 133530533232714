import React from 'react';
import { useIntl } from 'react-intl';

import { TFF } from '@tff/types';
import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { PlaneIcon } from '../icons';
import useStyles from './AccordionStyles';
import AncillaryTableCell from './FlightAncillaryTableCell';
import { CreatePassengerAlias } from './CreatePassengerAlias';
import { MissingAncillaries } from './Utils/AncillariesPage/missingAncillaries';
import { isAncillaryIncludedForAllPassengers } from './Utils/AncillariesPage/ancillariesAvailability';
import { ValidatedSsrDetails } from '@tff/types/TFF';

interface props {
  flightOnd: string;
  isOutbound: boolean;
  passengers: TFF.Passenger[];
  ancillaries: TFF.MetaValue[];
  validatedAncillaries?: ValidatedSsrDetails[];
  type: string;
  displayTable: boolean;
  rebooking?: boolean;
  ssrsToAdd: { [paxId: string]: TFF.SsrDetails[] };
  addSsrsToAdd: (paxId: string, addSsrs: TFF.SsrDetails, setSsrsToAdd, ssrsToAdd, includedSsrs) => void;
  removeSsrsToAdd: (paxId: string, addSsrs: TFF.SsrDetails, setSsrsToAdd) => void;
  findSsr: (paxId: string, ssrDetails: TFF.SsrDetails) => TFF.SsrDetails | undefined;
  updateSectionPrice: (price: number) => void;
  fareFamily?: string;
  setSsrsToAdd: React.Dispatch<React.SetStateAction<{ [paxId: string]: TFF.SsrDetails[] }>>;
  includedSsrs: {
    [paxId: string]: TFF.SsrDetails[];
  };
  missingAncillaries: MissingAncillaries;
  ssrAvailability: Record<string, number>;
  setSsrAvailability: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  isFlightRebooked: boolean | undefined;
}

const FlightAncillaryTable: React.FC<props> = ({
  updateSectionPrice,
  findSsr,
  removeSsrsToAdd,
  addSsrsToAdd,
  passengers,
  flightOnd,
  isOutbound,
  ancillaries,
  type,
  displayTable,
  rebooking,
  fareFamily,
  ssrsToAdd,
  setSsrsToAdd,
  includedSsrs,
  missingAncillaries,
  ssrAvailability,
  validatedAncillaries,
  setSsrAvailability,
  isFlightRebooked,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    passenger: TFF.Passenger,
    code: string,
    airlineCode?: string,
    price?: number,
  ) => {
    const ssrDetails: TFF.SsrDetails = {
      airlineSsrCode: airlineCode,
      code: code,
      amount: 1,
      segmentOnd: flightOnd,
      price: price,
      priceToCharge: price,
      tuiRelated: true,
    };
    const ssrDetails2: TFF.SsrDetails = {
      airlineSsrCode: airlineCode,
      code: code,
      amount: 1,
      segmentOnd: flightOnd.split('-').reverse().join('-'),
      price: price,
      priceToCharge: price,
      tuiRelated: true,
    };
    const existingAncillaries = passenger.JourneyExtras?.flatMap(i => (Array.isArray(i.Ssrs) ? i.Ssrs : [])).map(str =>
      str.substring(0, str.lastIndexOf('~')),
    );

    //Update SSR availability
    const isChecked = event.target.checked;
    setSsrAvailability(prevAvailability => {
      const newAvailability = { ...prevAvailability };
      if (isChecked) {
        newAvailability[code] = Math.max(newAvailability[code] - 1, 0);
      } else {
        newAvailability[code] = newAvailability[code] + 1;
      }
      return newAvailability;
    });

    if (event.target.checked) {
      if (existingAncillaries?.includes(`${flightOnd}~${passenger.Id}~${airlineCode}`)) {
        if (code !== 'BA0') {
          removeSsrsToAdd(
            passenger.Id,
            {
              code: `${code}-remove`,
              amount: 1,
              segmentOnd: flightOnd,
            },
            setSsrsToAdd,
          );
          addSsrsToAdd(
            passenger.Id,
            {
              code: `${code}`,
              amount: 1,
              segmentOnd: flightOnd,
            },
            setSsrsToAdd,
            ssrsToAdd,
            includedSsrs,
          );
        }
      } else {
        addSsrsToAdd(passenger.Id, ssrDetails, setSsrsToAdd, ssrsToAdd, includedSsrs);
      }
      if (!displayTable) {
        addSsrsToAdd(passenger.Id, ssrDetails2, setSsrsToAdd, ssrsToAdd, includedSsrs);

        if (existingAncillaries?.includes(`${flightOnd}~${passenger.Id}~${airlineCode}`)) {
          if (code !== 'BA0') {
            removeSsrsToAdd(
              passenger.Id,
              {
                code: `${code}-remove`,
                amount: 1,
                segmentOnd: flightOnd.split('-').reverse().join('-'),
              },
              setSsrsToAdd,
            );
          }
        }
      }
    } else {
      if (existingAncillaries?.includes(`${flightOnd}~${passenger.Id}~${airlineCode}`)) {
        if (code !== 'BA0') {
          addSsrsToAdd(
            passenger.Id,
            {
              code: `${code}-remove`,
              amount: 1,
              segmentOnd: flightOnd,
            },
            setSsrsToAdd,
            ssrsToAdd,
            includedSsrs,
          );
        }
      }
      removeSsrsToAdd(passenger.Id, ssrDetails, setSsrsToAdd);
      if (!displayTable) {
        removeSsrsToAdd(passenger.Id, ssrDetails2, setSsrsToAdd);

        if (existingAncillaries?.includes(`${flightOnd}~${passenger.Id}~${airlineCode}`)) {
          if (code !== 'BA0') {
            addSsrsToAdd(
              passenger.Id,
              {
                code: `${code}-remove`,
                amount: 1,
                segmentOnd: flightOnd.split('-').reverse().join('-'),
              },
              setSsrsToAdd,
              ssrsToAdd,
              includedSsrs,
            );
          }
        }
      }
    }
  };

  const handleRadioChange = (checked: boolean, passenger: TFF.Passenger, code: string, airlineCode?: string) => {
    const ssrDetails: TFF.SsrDetails = {
      airlineSsrCode: airlineCode,
      code: code,
      amount: 1,
      segmentOnd: flightOnd,
    };
    const ssrDetails2: TFF.SsrDetails = {
      airlineSsrCode: airlineCode,
      code: code,
      amount: 1,
      segmentOnd: flightOnd.split('-').reverse().join('-'),
    };

    if (!displayTable) {
      if (!checked) {
        addSsrsToAdd(passenger.Id, ssrDetails, setSsrsToAdd, ssrsToAdd, includedSsrs);
        addSsrsToAdd(passenger.Id, ssrDetails2, setSsrsToAdd, ssrsToAdd, includedSsrs);
      } else {
        removeSsrsToAdd(passenger.Id, ssrDetails, setSsrsToAdd);
        removeSsrsToAdd(passenger.Id, ssrDetails2, setSsrsToAdd);
      }
    } else {
      if (!checked) {
        addSsrsToAdd(passenger.Id, ssrDetails, setSsrsToAdd, ssrsToAdd, includedSsrs);
      } else {
        removeSsrsToAdd(passenger.Id, ssrDetails, setSsrsToAdd);
      }
    }
  };

  const toggleChecked = (passenger: TFF.Passenger, code: string, airlineCode?: string) => {
    const ssrDetails: TFF.SsrDetails = {
      code: code,
      amount: 1,
      segmentOnd: flightOnd,
    };
    if (findSsr(passenger.Id, ssrDetails)) {
      return true;
    } else {
      return false;
    }
  };

  const passengerLength = passengers.filter(obj => obj.Type !== 'INF').length;
  // sort from lower to bigger via comparing prices
  ancillaries.sort((a, b) => a.price.amount - b.price.amount);

  const isAncillaryUnavailable = (ancillary: TFF.MetaValue): boolean => {
    if (ssrAvailability && ssrAvailability[ancillary.code] === 0) {
      return !isAncillaryIncludedForAllPassengers(ssrAvailability, ssrsToAdd, flightOnd);
    }
    return false;
  };

  const hasMissingAncillary = (ancillary: TFF.MetaValue): boolean => {
    let foundMissingAncillary = false;
    const { outbound, inbound } = missingAncillaries;
    [...outbound, ...inbound].forEach(item => {
      if (flightOnd === item.JourneyOnd && ancillary.airlineSsrCode === item.Type) {
        foundMissingAncillary = true;
      }
    });
    return foundMissingAncillary;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '91px',
        minWidth: '320px',
        gap: '16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
        }}
      >
        <PlaneIcon
          style={{
            height: 22,
            width: 22,
            transform: isOutbound ? 'rotate(90deg)' : 'rotate(-90deg)',
          }}
        />
        <Typography
          sx={{
            gap: '8px',
            color: 'primary.main',
            fontSize: '18px',
            lineHeight: '20.59px',
            fontWeight: '600',
          }}
        >
          <strong>{flightOnd}</strong>
        </Typography>
        {!displayTable && (
          <>
            <Typography
              sx={{
                gap: '8px',
                color: 'primary.main',
                fontSize: '18px',
                lineHeight: '20.59px',
                fontWeight: '600',
              }}
            >
              /
            </Typography>
            <PlaneIcon
              style={{
                height: 22,
                width: 22,
                transform: 'rotate(-90deg)',
              }}
            />
            <Typography
              sx={{
                gap: '8px',
                color: 'primary.main',
                fontSize: '18px',
                lineHeight: '20.59px',
                fontWeight: '600',
              }}
            >
              <strong>{flightOnd.split('-').reverse().join('-')}</strong>
            </Typography>
          </>
        )}
      </Box>
      {fareFamily === 'Perfect' && type === 'HAND_LUGGAGE' && <Typography>Small Hand Luggage Included</Typography>}
      {type === 'BAGGAGE' ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
          }}
        >
          <Typography
            sx={{
              gap: '8px',
              color: '#1A7EAD',
              fontSize: '18px',
              lineHeight: '19.36px',
              fontWeight: '700',
            }}
          >
            {intl.formatMessage({ id: 'ancillaries.edit.firstLuggage' })}
          </Typography>
        </Box>
      ) : null}
      <TableContainer
        sx={{
          width: passengerLength === 1 ? '40%' : passengerLength > 5 ? '100%' : '80%',
        }}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderBottom: '1px solid #737373' }}></TableCell>
              {passengers.map((passenger, index) => {
                if (passenger.Type !== 'INF') {
                  return (
                    <TableCell key={`${index}-${passenger.Id}`} align="center" className={classes.tablePassengers}>
                      {CreatePassengerAlias(passenger.Id)}
                    </TableCell>
                  );
                }
                return null;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {ancillaries.map(ancillary =>
              type === 'BAGGAGE' && ancillary.code.includes('BX') ? null : type === 'HAND_LUGGAGE' &&
                ancillary.code !== 'HBAG' ? null : (
                <TableRow key={ancillary.code}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      width: type === 'ANIMALS' || type === 'ADDITIONAL_BAGGAGE' ? '384px' : '280px',
                      background: '#E2F5FD',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '280px' }}>
                      {type === 'ANIMALS' ? (
                        ancillary.shortDescription.includes('cabin') ||
                        ancillary.shortDescription.includes('Tragetasche') ? (
                          intl.formatMessage({ id: 'ancillaries.edit.pets.onboard' })
                        ) : (
                          intl.formatMessage({ id: 'ancillaries.edit.pets.onCargo' })
                        )
                      ) : (
                        <>
                          <Typography>
                            {ancillary.shortDescription}
                            {(hasMissingAncillary(ancillary) || isAncillaryUnavailable(ancillary)) && '*'}
                          </Typography>
                          <Typography>
                            {ancillary.price.amount === 0
                              ? ''
                              : displayTable
                              ? `${ancillary.price.amount} €`
                              : `${ancillary.price.amount * 2} €`}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </TableCell>
                  {passengers.map((passenger, index) => {
                    if (passenger.Type !== 'INF') {
                      const isTuiRelated = ssrsToAdd[passenger.Id]?.find(
                        a => a.code === ancillary.code && a.segmentOnd === flightOnd,
                      )?.tuiRelated;
                      return (
                        <AncillaryTableCell
                          key={`${ancillary.code}-${passenger.FirstName}`}
                          handleCheckboxChange={handleCheckboxChange}
                          ancillary={ancillary}
                          type={type}
                          passenger={passenger}
                          toggleChecked={toggleChecked}
                          handleRadioChange={handleRadioChange}
                          updateSectionPrice={updateSectionPrice}
                          flightOnd={flightOnd}
                          displayTable={displayTable}
                          rebooking={rebooking}
                          missingAncillaries={missingAncillaries}
                          validatedAncillaries={validatedAncillaries}
                          ssrAvailability={ssrAvailability}
                          isTuiRelated={isTuiRelated}
                          isFlightRebooked={isFlightRebooked}
                        />
                      );
                    }
                    return null;
                  })}
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FlightAncillaryTable;
