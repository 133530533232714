import Routes from '../../routes';
import Vertical from './Vertical';

const AppLayout = () => {
  return (
    <Vertical>
      <Routes />
    </Vertical>
  );
};

export default AppLayout;
