export const resetSsrs = (ssrType: string, setSsrsToAdd, groupedRows, includedSsrs) => {
  setSsrsToAdd(prevState => {
    const matchedRow = groupedRows.find(row => row.type === ssrType);
    const initalSsrs = matchedRow ? Object.keys(matchedRow.matchedPassengers) : [];

    const newSsrs = Object.keys(prevState).reduce((acc, paxId) => {
      acc[paxId] = prevState[paxId].filter(ssr => {
        if (ssr.code.includes('remove')) {
          return false;
        }
        return !initalSsrs.includes(ssr.code);
      });

      initalSsrs.forEach(ssrCode => {
        includedSsrs[paxId]?.forEach(includedSsr => {
          if (includedSsr.code === ssrCode) {
            acc[paxId].push(includedSsr);
          }
        });
      });
      prevState[paxId].forEach(ssr => {
        if (ssr.code.includes('remove')) {
          const ssrCode = ssr.code.replace('-remove', '');
          if (!initalSsrs.includes(ssrCode)) {
            acc[paxId].push({
              ...ssr,
              code: ssrCode,
            });
          }
        }
      });

      return acc;
    }, {});

    return newSsrs;
  });
};
