import * as React from 'react';
import { useEffect, useContext } from 'react';
import { TFF } from '@tff/types';
import { RetrieveFlightLambdaResponse } from '@tff/types/TFF';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import {
  IMidocoBookingInfo,
  IMidocoBookingInfoAttributeValue,
  IMidocoSearchOrderCriteria,
  OrderServiceLambdaOutput,
} from '@tff/types/Midoco/model';
import { useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { apigeeEndpoint } from '../apis';

export type UseMidocoProps = {
  recordLocator?: string;
  /**
   * OrderNumber this number is known by midoco workers
   */
  orderNo?: number;

  midocoSearchOrderCriteria?: IMidocoSearchOrderCriteria;
};

export type TypeOfStatus =
  | 'FETCHING_MIDOCO_ORDER'
  | 'MIDOCO_ORDER_FETCHED'
  | 'MIDOCO_ORDER_FETCHED_LIST'
  | 'MIDOCO_ORDER_FAILURE';

export type UseMidocoResponse = {
  midocoFetchedOrderList?: IMidocoBookingInfoAttributeValue[];
  midocoFetchedOrder?: TFF.FlightDetails;
  midocoStatus?: TypeOfStatus;
  midocoError?: any;

  midocoWarning?: any;
  resetMidocoState: () => void;
  midocoLoading?: boolean;
};

//
export const useMidoco = (midocoProps?: UseMidocoProps): UseMidocoResponse => {
  const [midocoStatus, setMidocoStatus] = React.useState<TypeOfStatus>();
  const [midocoFetchedOrder, setMidocoFetchedOrder] = React.useState<TFF.FlightDetails>();
  const [midocoFetchedOrderList, setMidocoFetchedOrderList] = React.useState<IMidocoBookingInfoAttributeValue[]>();
  const [midocoError, setMidocoError] = React.useState<any>();
  const [midocoWarning, setMidocoWarning] = React.useState<any>();
  const [midocoLoading, setMidicoLoading] = React.useState<boolean>(false);

  const { showLoading, closeLoading } = useContext(LoadingContext);
  const queryClient = useQueryClient();

  const resetMidocoState = (): void => {
    setMidocoFetchedOrder(undefined);
    setMidocoFetchedOrderList(undefined);
    setMidocoError(undefined);
    setMidocoWarning(undefined);
    setMidocoStatus(undefined);
  };

  useEffect(() => {
    const fetchOrderFromMidoco = async (midocoProps): Promise<void> => {
      try {
        setMidocoStatus('FETCHING_MIDOCO_ORDER');
        setMidicoLoading(true);
        let midocoOrderNo = midocoProps.orderNo;
        let midocoBookingInfoResponse: IMidocoBookingInfo | IMidocoBookingInfo[] | undefined;

        // if recordLocator is given, we have to search first by recordLocator and source to find out the midoco orderNumber,
        if (!midocoOrderNo && (midocoProps.recordLocator || midocoProps.midocoSearchOrderCriteria)) {
          let midocoSearchOrderCriteria: IMidocoSearchOrderCriteria = midocoProps.midocoSearchOrderCriteria;

          if (midocoProps.recordLocator) {
            showLoading(`Loading Midoco Order for RecordLocator: ${midocoProps.recordLocator}`);
            midocoSearchOrderCriteria = {
              idType: 'bookingID',
              crsBookingId: `${midocoProps.recordLocator}`,
            };
          } else {
            showLoading(`Search Midoco Orders`);
          }

          const midocoSearchResponse = await apigeeEndpoint.post<OrderServiceLambdaOutput>(`/midoco/order/search`, {
            maxReturned: 30,
            MidocoSearchOrderCriteria: {
              ...midocoSearchOrderCriteria,
              orgunitName: window.ENV?.TFF_MIDOCO_ORGUNIT_NAME,
            },
          });

          midocoBookingInfoResponse = midocoSearchResponse?.data?.body?.MidocoBookingInfo;

          if (!midocoBookingInfoResponse) {
            setMidocoStatus('MIDOCO_ORDER_FAILURE');
            setMidocoError('MIDOCO_SEARCH_RETURNS_NO_MATCH');
            return;
          }

          if (Array.isArray(midocoBookingInfoResponse)) {
            setMidocoStatus('MIDOCO_ORDER_FETCHED_LIST');
            setMidocoFetchedOrderList(midocoBookingInfoResponse.map(mbi => mbi.attributes));
            return;
          } else {
            midocoOrderNo = midocoBookingInfoResponse?.attributes.orderNo;
          }
        }

        if (!midocoOrderNo) {
          setMidocoStatus('MIDOCO_ORDER_FAILURE');
          setMidocoError('MIDOCO_ORDER_NUMBER_NOT_FOUND');
        } else {
          showLoading(`Loading Midoco Order for OrderNumber: ${midocoOrderNo}`);

          const response: AxiosResponse<RetrieveFlightLambdaResponse> = await queryClient.fetchQuery(
            `midocoOrderNo::${midocoOrderNo}`,
            async () => {
              const response = await apigeeEndpoint
                .get<RetrieveFlightLambdaResponse>(`/midoco/order/orderno/${midocoOrderNo}`)
                .catch(e => {
                  return undefined;
                });
              return response;
            },
            {
              retry: 2,
              // staleTime: 3000,
              cacheTime: 10000,
              initialData: () => {
                return queryClient.getQueryData(`midocoOrderNo::${midocoOrderNo}`);
              },
            },
          );

          if (!response) {
            setMidocoStatus('MIDOCO_ORDER_FAILURE');
            setMidocoError('MIDOCO_ORDER_NOT_FOUND');
          }
          const retrieveResponse: RetrieveFlightLambdaResponse = response?.data;
          setMidocoStatus('MIDOCO_ORDER_FETCHED');

          setMidocoFetchedOrder(retrieveResponse?.Booking);

          // retrieveResponse?.Warning && setMidocoWarning(retrieveResponse?.Warning);
        }
      } catch (error) {
        setMidocoError(error);
        setMidocoStatus('MIDOCO_ORDER_FAILURE');
        setMidicoLoading(false);
      } finally {
        setMidicoLoading(false);
        closeLoading();
      }
    };

    if (
      (midocoProps?.orderNo || midocoProps?.recordLocator || midocoProps?.midocoSearchOrderCriteria) &&
      !midocoStatus
    ) {
      void fetchOrderFromMidoco(midocoProps);
    }
  }, [midocoProps, midocoStatus]);

  console.log('Fetched Order List', midocoFetchedOrderList);

  return {
    midocoError,
    midocoWarning,
    midocoFetchedOrder,
    midocoFetchedOrderList,
    midocoStatus,
    resetMidocoState,
    midocoLoading,
  };
};
