import * as React from 'react';
import { TUI_LIGHT } from '../values/style-values';

interface TFFIconButtonProps {
  label: string;
  iconName: string;
  disabled?: boolean;
  variant?: 'primary' | 'primary-contained' | 'secondary' | 'secondary-contained' | 'outline';
  handleClick: () => void;
}

export const TFFIconButton: React.FC<TFFIconButtonProps> = ({
  label,
  iconName,
  disabled,
  handleClick,
  variant = 'primary',
}) => {
  return (
    <button
      className={`icon-button ${variant} medium`}
      type="button"
      disabled={disabled}
      aria-label={label}
      onClick={handleClick}
    >
      <span className={`icon medium ${iconName}`} data-theme={TUI_LIGHT}></span>
    </button>
  );
};

export default TFFIconButton;
