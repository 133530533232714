const DEFAULT_LOCALE_PARAM = 'en-EN';

const localeFormat = (locale: string | null): string => {
  if (locale) {
    return `${locale}-${locale.toUpperCase()}`;
  }
  return DEFAULT_LOCALE_PARAM;
};

export default localeFormat;
