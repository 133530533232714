import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { keycloackApi } from '../../apis';
import { keycloak } from '../../keycloak';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

export enum USERS_STATES {
  UNKOWN = 'UNKOWN',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

export interface IUsersStoreState {
  user?: any;
  users?: any[];
  state: USERS_STATES;
  error?: any;
  usersLoading?: boolean;
}

const initialState: IUsersStoreState = {
  user: undefined,
  users: [],
  state: USERS_STATES.UNKOWN,
  error: undefined,
  usersLoading: false,
};

const TFF_CLIENT_ID = '739f3cdc-42a8-4fb4-8973-c07a4605f083';

export const TFF_ACCESS_ROLE = {
  id: '7c05f7ed-0420-4879-8f70-fc0e2cef8fa1',
  name: 'tff-access',
  composite: false,
  clientRole: true,
  containerId: TFF_CLIENT_ID,
};

const loadUserClientRoles = async (userId: string) => {
  const result = await keycloackApi.get(`admin/realms/tff/users/${userId}/role-mappings/clients/${TFF_CLIENT_ID}`, {
    headers: {
      Authorization: 'Bearer ' + keycloak.token,
    },
  });
  return result.data;
};

const loadUserRoles = (userId: string) => {
  return _loadUserRoles(userId);
};

const _loadUserRoles = _.memoize(async id => {
  const result = await keycloackApi.get(`admin/realms/tff/users/${id}/role-mappings/realm`, {
    headers: {
      Authorization: 'Bearer ' + keycloak.token,
    },
  });

  const userWithRoles = { ...result.data, userId: id };

  return userWithRoles;
});

export const getUser = createAsyncThunk('users/getUser', async (userId: string, thunkAPI) => {
  let result: any = {};
  let user: any = {};
  const response = await keycloackApi.get(`admin/realms/tff/users/${userId}`, {
    headers: {
      Authorization: 'Bearer ' + keycloak.token,
    },
  });

  if (response.status === 200) {
    result = response.data;
    const userRoles = await keycloackApi.get(`admin/realms/tff/users/${userId}/role-mappings/realm`, {
      headers: {
        Authorization: 'Bearer ' + keycloak.token,
      },
    });

    if (userRoles.status === 200) {
      const roles = userRoles.data;
      user = { ...result, roles };
    }
    const clientRoles = await keycloackApi.get(
      `admin/realms/tff/users/${userId}/role-mappings/clients/${TFF_CLIENT_ID}`,
      {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
      },
    );

    if (clientRoles.status === 200) {
      const accessRole = clientRoles.data.find((role: any) => role.name === 'tff-access');
      if (accessRole) {
        const userClientRolesArr = clientRoles.data ? Object.values(clientRoles.data).map((r: any) => r.name) : [];
        user = { ...user, accessRole, clientRoles: userClientRolesArr };
      }
    }
  }

  return user;
});

export const updateUserRole = createAsyncThunk('users/updateUserRole', async (user: any, thunkAPI) => {
  const rolesToAdd = user.rolesToAdd;
  const rolesToRemove = user.rolesToRemove;
  const accessRole = user.accessRole;

  if (_.isEmpty(accessRole)) {
    const response = await keycloackApi.delete(
      `/admin/realms/tff/users/${user.id}/role-mappings/clients/${TFF_CLIENT_ID}`,
      {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
        data: [TFF_ACCESS_ROLE],
      },
    );
    if (response.status === 201) {
      toast.success('User is now inactive');
    }
  } else {
    const response = await keycloackApi.post(
      `/admin/realms/tff/users/${user.id}/role-mappings/clients/${TFF_CLIENT_ID}`,
      [TFF_ACCESS_ROLE],
      {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
      },
    );
    if (response.status === 201) {
      toast.success('User is now active');
    }
  }

  if (rolesToAdd) {
    const response = await keycloackApi.post(`/admin/realms/tff/users/${user.id}/role-mappings/realm`, rolesToAdd, {
      headers: {
        Authorization: 'Bearer ' + keycloak.token,
      },
    });
    if (response.status === 201) {
      toast.success('Role has been added successfully');
    }
  }

  if (rolesToRemove) {
    const response = await keycloackApi.delete(`/admin/realms/tff/users/${user.id}/role-mappings/realm`, {
      headers: {
        Authorization: 'Bearer ' + keycloak.token,
      },
      data: rolesToRemove,
    });
    if (response.status === 201) {
      toast.success('Role has been deleted successfully');
    }
  }

  return user;
});

export const getUsers = createAsyncThunk('users/getUsers', async (_, { dispatch, getState }) => {
  try {
    let result: any = [];
    const users: any = [];

    const response = await keycloackApi.get('admin/realms/tff/users', {
      headers: {
        Authorization: 'Bearer ' + keycloak.token,
      },
    });

    if (response.status === 200) {
      result = response.data;
    }

    const promiseArr: any = [];

    result.forEach(user => {
      promiseArr.push(loadUserRoles(user.id));
    });

    const userRolesArr = await Promise.all(promiseArr);

    for (const user of result) {
      const userRoles = userRolesArr.find((u: any) => u.userId === user.id);
      const userClientRoles = await loadUserClientRoles(user.id);

      if (userRoles) {
        delete userRoles.userId;
        const mapToArr = Object.values(userRoles);
        const userClientRolesArr = userClientRoles ? Object.values(userClientRoles).map((r: any) => r.name) : [];
        const userWithRoles = { ...user, roles: mapToArr, clientRoles: userClientRolesArr };

        users.push(userWithRoles);
      }
    }

    return users;
  } catch (e) {
    throw e;
  }
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setState(state, action: PayloadAction<USERS_STATES>) {
      state.state = action.payload;
    },
    setUser(
      state,
      action: PayloadAction<{ user?: IUsersStoreState; state: USERS_STATES; error?: any; usersLoading?: boolean }>,
    ) {
      state.user = action.payload.user;
      state.state = action.payload.state;
      state.error = action.payload.error;
      state.usersLoading = action.payload.usersLoading;
    },
    updateUser(
      state,
      action: PayloadAction<{ user: IUsersStoreState; state: USERS_STATES; error?: any; usersLoading?: boolean }>,
    ) {
      state.user = action.payload.user;
      state.state = action.payload.state;
      state.error = action.payload.error;
      state.usersLoading = action.payload.usersLoading;
    },
    setUsersList(
      state,
      action: PayloadAction<{ users?: IUsersStoreState[]; state: USERS_STATES; error?: any; usersLoading?: boolean }>,
    ) {
      state.users = action.payload.users;
      state.state = action.payload.state;
      state.error = action.payload.error;
      state.usersLoading = action.payload.usersLoading;
    },
    resetError(state) {
      state.error = null;
    },
    resetUser(state) {
      state.user = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(getUser.pending, (state, action) => {
      state.state = USERS_STATES.UNKOWN;
      state.usersLoading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.state = USERS_STATES.FINISHED;
      state.usersLoading = false;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.error = action.error.message;
      state.state = USERS_STATES.ERROR;
      state.usersLoading = false;
    });
    builder.addCase(getUsers.pending, (state, action) => {
      state.users = action.payload;
      state.state = USERS_STATES.UNKOWN;
      state.usersLoading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action: PayloadAction<any[]>) => {
      state.users = action.payload;
      state.state = USERS_STATES.FINISHED;
      state.usersLoading = false;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.error = action.error.message;
      state.state = USERS_STATES.ERROR;
      state.usersLoading = false;
    });
  },
});

export const { setState, setUser, updateUser, setUsersList, resetError, resetUser } = usersSlice.actions;

export default usersSlice.reducer;
