import * as React from 'react';
import { useEffect, useContext } from 'react';
import { Retrieve, RetrieveFlightLambdaResponse } from '@tff/types/TFF';
import { ISourceConfig } from '../models';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';
import { apigeeEndpoint } from '../apis';

export type RetrieveDbServiceProps = {
  sourceConfig?: ISourceConfig;
  recordLocator?: string;
  tfmPnr?: string;
  clientPnr?: string;
  product?: string;
};

export type TypeOfStatus = 'IN_FETCHING' | 'FETCHED' | 'FAILURE';

export type RetrieveDbServiceResponse = {
  fetchedRetrieveDbItemList?: Retrieve[];
  fetchedRetrieveDbItem?: Retrieve;
  fetchedRetrieveDbStatus?: TypeOfStatus;
  fetchedRetrieveDbError?: any;
  resetRetrieveDbState: () => void;
};

//
export const useRetrieveDbService = (retrieveBookingProps?: RetrieveDbServiceProps): RetrieveDbServiceResponse => {
  const [fetchedRetrieveDbStatus, setFetchedRetrieveDbStatus] = React.useState<TypeOfStatus | undefined>(undefined);
  const [fetchedRetrieveDbItem, setFetchedRetrieveDbItem] = React.useState<Retrieve | undefined>(undefined);
  const [fetchedRetrieveDbItemList, setFetchedRetrieveDbItemList] = React.useState<Retrieve[] | undefined>(undefined);
  const [fetchedRetrieveDbError, setFetchedRetrieveDbError] = React.useState(undefined);
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const queryClient = useQueryClient();

  const resetRetrieveDbState = (): void => {
    setFetchedRetrieveDbItem(undefined);
    setFetchedRetrieveDbError(undefined);
    setFetchedRetrieveDbStatus(undefined);
    setFetchedRetrieveDbItemList(undefined);
  };

  useEffect(() => {
    const fetchDataFromBackend = async (
      recordLocator?: string,
      sourceConfig?: ISourceConfig,
      tfmPnr?: string,
      clientPnr?: string,
      product?: string,
      _count?: number,
    ): Promise<void> => {
      try {
        recordLocator
          ? showLoading(`Search item at retrieve DB, RecordLocator: ${recordLocator}`)
          : showLoading(`Search items at retrieve DB`);
        setFetchedRetrieveDbStatus('IN_FETCHING');
        let retrieveDbResponse: Retrieve[] = [];
        let response: AxiosResponse<Retrieve[]> | undefined;
        if (sourceConfig) {
          response = await queryClient.fetchQuery(
            `get:retrieveDbItem:recordLocator:${recordLocator}`,
            async () => {
              return await apigeeEndpoint.get<RetrieveFlightLambdaResponse>(
                `/retrieve-db/${sourceConfig.SourceName}/${recordLocator}`,
              );
            },
            {
              retry: 2,
              // staleTime: 2000,
              cacheTime: 10000,
              initialData: () => {
                return queryClient.getQueryData(`get:retrieveDbItem:recordLocator:${recordLocator}`);
              },
            },
          );
          retrieveDbResponse = response?.data;
        } else if (clientPnr || recordLocator || product) {
          let key =
            recordLocator === 'ALL' ? 'get:retrieveDbItem:LIST' : `post:retrieveDbItem:recordLocator:${recordLocator}`;

          const searchCriteria: any = {};
          if (clientPnr) {
            searchCriteria.clientPnr = clientPnr;
            key = `post:retrieveDbItem:clientPnr:${clientPnr}`;
          } else if (recordLocator && recordLocator !== 'ALL') {
            searchCriteria.recordLocator = recordLocator;
          }
          if (product) {
            searchCriteria.product = product;
          }

          // search only by recordLocator
          response = await queryClient.fetchQuery(
            key,
            async () => {
              return recordLocator === 'ALL'
                ? await apigeeEndpoint.get<Retrieve[]>(`/retrieve-db`)
                : await apigeeEndpoint.post<Retrieve[]>(`/retrieve-db`, searchCriteria);
            },
            {
              retry: 2,
              staleTime: recordLocator === 'ALL' ? 100000 : 10000,
              cacheTime: recordLocator === 'ALL' ? 100000 : 10000,
              initialData: () => {
                return queryClient.getQueryData(key);
              },
            },
          );

          if (recordLocator === 'ALL' || (!recordLocator && !clientPnr && product)) {
            const sortedRetrieveList = response.data.sort(
              (a: Retrieve, b: Retrieve) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime(),
            );
            retrieveDbResponse = sortedRetrieveList;
          } else if (recordLocator || clientPnr) {
            if (response.data.length > 1) {
              throw new Error('MULTIPLE_RECORDLOCATORS_FOUND');
            } else {
              retrieveDbResponse = response.data;
            }
          }
        } else if (tfmPnr) {
          // search only by recordLocator
          response = await queryClient.fetchQuery(
            `post:retrieveDbItem:tfmPnr:${recordLocator}`,
            async () => await apigeeEndpoint.get<Retrieve>(`/retrieve-db/${tfmPnr}`),
            {
              retry: 2,
              staleTime: 1.0,
              cacheTime: 1000,
              initialData: () => {
                return queryClient.getQueryData(`post:retrieveDbItem:tfmPnr:${recordLocator}`);
              },
            },
          );
          retrieveDbResponse = response.data;
        }

        if (response?.data && 'errorMessage' in response?.data) {
          throw response?.data['errorMessage'];
        }

        if (clientPnr || tfmPnr || (recordLocator && recordLocator !== 'ALL')) {
          if (Array.isArray(retrieveDbResponse)) {
            setFetchedRetrieveDbItem(retrieveDbResponse[0]);
          } else {
            setFetchedRetrieveDbItem(retrieveDbResponse);
          }
        } else setFetchedRetrieveDbItemList(retrieveDbResponse);
        setFetchedRetrieveDbStatus('FETCHED');
      } catch (error) {
        setFetchedRetrieveDbError(error);
        setFetchedRetrieveDbStatus('FAILURE');
      } finally {
        closeLoading();
      }
    };

    if (
      (retrieveBookingProps?.product ||
        retrieveBookingProps?.recordLocator ||
        retrieveBookingProps?.tfmPnr ||
        retrieveBookingProps?.clientPnr) &&
      !fetchedRetrieveDbStatus
    ) {
      void fetchDataFromBackend(
        retrieveBookingProps.recordLocator,
        retrieveBookingProps.sourceConfig,
        retrieveBookingProps.tfmPnr,
        retrieveBookingProps.clientPnr,
        retrieveBookingProps.product,
        30,
      );
    }
  }, [retrieveBookingProps]);

  return {
    fetchedRetrieveDbItemList,
    fetchedRetrieveDbStatus,
    fetchedRetrieveDbItem,
    fetchedRetrieveDbError,
    resetRetrieveDbState,
  };
};
