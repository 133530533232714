import * as React from 'react';

export const BaggageIcon: React.FC = () => (
  <svg
    viewBox="0 0 21 21.287"
    style={{
      fill: 'none',
      width: '21px',
      height: '21.287px',
      stroke: '#092a5e',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '2px',
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-14 -14)">
      <rect width="19" height="15" rx="2" transform="translate(15 18)" />
      <path d="M30,18.215V34.287M42.858,18.215V34.287M33.215,15h6.429v2.828H33.215Z" transform="translate(-11.785)" />
    </g>
  </svg>
);
