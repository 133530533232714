import Table from '../Table';
import { ITableColumns } from '../../models';
import { useAncillaryBooking } from '../../hooks/use-ancillary-booking';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Journey } from '@tff/types/TFF';
import { Checkbox, Box } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import sortAndNormalize from '../../util/sortAndNormalizePassengers';
import { TFF } from '@tff/types';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useIntl } from 'react-intl';
import { toggleCheckbox } from '../../reducers/Ancillary/ancillarySlice';
import { useRebook } from '../RebookFlight/RebookContext';

interface IProps {
  journeys: Journey;
  orderDetails: TFF.OrderDetails;
  recordLocator: string;
  setTableOpen: (value: boolean) => void;
}

const AncillaryTable: React.FC<IProps> = ({ journeys, orderDetails, recordLocator, setTableOpen }) => {
  const [outboundAncillary, setOutboundAncillary] = useState<TFF.MetaValue[]>([]);
  const [returnAncillary, setReturnAncillary] = useState<TFF.MetaValue[]>([]);
  const [groupedRows, setGroupedRows] = useState<any[]>([]);
  const [outboundRows, setOutboundRows] = useState<any[]>([]);

  const [outboundPassenger, setOutboundPassenger] = useState<string[]>([]);
  const [returnPassenger, setReturnPassenger] = useState<string[]>([]);

  const { setSearchAncillariesLambdaRequest, ancillaryResponse, ancillaryStatus } = useAncillaryBooking();
  const { toggleAncillaryPaxData } = useRebook();

  const [passengers] = useState(sortAndNormalize(orderDetails.Passengers));
  const Ancillaries: TFF.Ancillaries = orderDetails.Ancillaries!;
  const IncludedAncillaries = Object.values(Ancillaries).map((i): string => `${i.JourneyOnd}~${i.PaxID}~${i.Value}`);

  const intl = useIntl();

  const dispatch = useDispatch();
  const checkboxStates = useSelector((state: any) => state.checkboxes.checkboxStates);

  const isRowDisabled = (arr: TFF.MetaValue[], rowData: string) => {
    return arr.some((obj: any) => obj.airlineSsrCode === rowData);
  };

  const filterAncillaries = (ancillaries, paxIdWithRemove) => {
    const processedPaxId = paxIdWithRemove.split('-remove')[0].replaceAll('~', '').replaceAll('-', '');

    return Object.keys(ancillaries).find(ancillaryKey => {
      const processedAncillaryKey = ancillaryKey.replace(/~\d+$/, '').replaceAll('~', '').replaceAll('-', '');
      return processedPaxId === processedAncillaryKey;
    });
  };

  const generatePaxData = (passengerList: string[], journeyIndex: number, ancillaries: TFF.Ancillaries) =>
    passengerList.map(paxId => {
      const hasRemove = paxId.includes('remove');

      const removeSsrId = hasRemove ? filterAncillaries(ancillaries, paxId) : null;

      return {
        paxId: removeSsrId ? paxId.split('-')[2] : paxId.split('-')[0],
        removeSsrIds: removeSsrId ? [removeSsrId] : [],
        addSsrs: hasRemove
          ? []
          : [
              {
                segmentOnd: journeys[journeyIndex].Ond,
                code: paxId.split('-')[1],
                amount: 1,
              },
            ],
      };
    });

  const mergePaxData = (paxData: TFF.PaxData[]): TFF.PaxData[] => {
    const mergedData = paxData.reduce((acc: Record<string, TFF.PaxData>, item: TFF.PaxData) => {
      const { paxId, removeSsrIds, addSsrs } = item;

      if (!acc[paxId]) {
        acc[paxId] = { ...item, removeSsrIds: removeSsrIds || [], addSsrs: addSsrs || [] };
      } else {
        acc[paxId].removeSsrIds = [...(acc[paxId].removeSsrIds || []), ...(removeSsrIds || [])];
        acc[paxId].addSsrs = [...(acc[paxId].addSsrs || []), ...(addSsrs || [])];
      }

      return acc;
    }, {});

    return Object.values(mergedData);
  };

  const handlePassengers = (passengerId: string, direction: string, rowData: string) => {
    const idString = `${passengerId}-${rowData}`;
    const ancillaryIncludedString = `${direction}~${passengerId}~${rowData}`;

    const isAncillaryIncluded = IncludedAncillaries.includes(ancillaryIncludedString);
    const updatedIdString = isAncillaryIncluded ? `${direction}-${idString}-remove` : idString;
    const fullIdString = isAncillaryIncluded
      ? `${recordLocator}-${passengerId}-${direction}-${rowData}-remove`
      : `${recordLocator}-${passengerId}-${direction}-${rowData}`;

    // Dispatch to redux-store
    dispatch(toggleCheckbox({ id: fullIdString }));

    const passengerSetter = (passengers: string[]) =>
      passengers.includes(idString) || passengers.includes(`${direction}-${idString}-remove`)
        ? passengers.filter((id: string) => id !== idString && id !== `${direction}-${idString}-remove`)
        : [...passengers, updatedIdString];

    // Update state based on direction and whether the ancillary is included
    if (direction === journeys[0].Ond) {
      setOutboundPassenger(passengerSetter);
    } else {
      setReturnPassenger(passengerSetter);
    }
  };

  const handlePassengerClick = useCallback(
    (passengerId, ond, rowData) => {
      handlePassengers(passengerId, ond, rowData);
    },
    [handlePassengers],
  );

  const updateLocalStateArrays = () => {
    const updatedOutboundPassenger: string[] = [];
    const updatedReturnPassenger: string[] = [];

    Object.keys(checkboxStates).forEach((checkboxId: string) => {
      if (checkboxStates[checkboxId]) {
        const parts = checkboxId.split('-');
        const passengerId = parts[1];
        const rowData = parts[4];
        const direction = `${parts[2]}-${parts[3]}`; // extracts journey ond e.g. HAJ-PMI
        const removeFlag = parts.length > 5 && parts[5] === 'remove'; // checks if 'remove' is present in the checkboxId
        const fullPassengerId = removeFlag
          ? `${direction}-${passengerId}-${rowData}-remove`
          : `${passengerId}-${rowData}`;

        if (direction === journeys[0].Ond) {
          updatedOutboundPassenger.push(fullPassengerId);
        } else if (journeys[1] && direction === journeys[1].Ond) {
          updatedReturnPassenger.push(fullPassengerId);
        }
      }
    });

    setOutboundPassenger(updatedOutboundPassenger);
    setReturnPassenger(updatedReturnPassenger);
  };

  useEffect(() => {
    updateLocalStateArrays();
  }, []);

  const isCheckboxChecked = (passenger: TFF.Passenger, ancillary: TFF.Journey, rowData: string) => {
    const checkboxKey = `${recordLocator}-${passenger.Id}-${ancillary.Ond}-${rowData}`;
    const removeKey = `${checkboxKey}-remove`;

    if (checkboxStates[removeKey]) {
      return false;
    }

    return checkboxStates[checkboxKey] || IncludedAncillaries.includes(`${ancillary.Ond}~${passenger.Id}~${rowData}`);
  };

  const tableOptions: MUIDataTableOptions = {
    viewColumns: false,
    filter: false,
    expandableRows: true,
    renderExpandableRow: (rowData: any[]) => {
      return (
        <TableRow>
          <TableCell colSpan={outboundAncillary.length + returnAncillary.length}>
            <Box>
              <table>
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">Price</TableCell>
                    {passengers.map((passenger, index) => {
                      if (passenger.Type !== 'CHD' && passenger.Type !== 'INF') {
                        return (
                          <TableCell key={index} align="right">
                            {`${passenger.FirstName} ${passenger.LastName}`}
                          </TableCell>
                        );
                      }
                      return null; // This will skip rendering for 'CHD' or 'INF' types
                    })}
                  </TableRow>
                </TableBody>
                {rowData[1].map((i: TFF.MetaValue) => (
                  <TableBody key={i.code} sx={{ borderBottom: 2 }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '16px' }}>{i.shortDescription}</TableCell>
                    </TableRow>
                    {[
                      ...(orderDetails.Journeys.length > 1
                        ? [outboundAncillary, returnAncillary]
                        : [outboundAncillary]),
                    ].map(
                      (ancillary, ancillaryIndex) =>
                        ancillary && (
                          <TableRow
                            key={`${i.airlineSsrCode}-${ancillaryIndex}`}
                            sx={{
                              pointerEvents: isRowDisabled(ancillary, i.airlineSsrCode) ? 'auto' : 'none',
                              opacity: isRowDisabled(ancillary, i.airlineSsrCode) ? '1' : '0.5',
                            }}
                          >
                            <TableCell>{journeys[ancillaryIndex].Ond}</TableCell>
                            <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                              {`${ancillary.find(ssr => ssr.code === i.code)?.price.amount ?? '0'} EUR`}
                            </TableCell>
                            {passengers.map((passenger, passengerIndex) => {
                              if (passenger.Type !== 'CHD' && passenger.Type !== 'INF') {
                                return (
                                  <TableCell key={`${ancillaryIndex}-${passengerIndex}`} align="right">
                                    <Checkbox
                                      key={`${i.airlineSsrCode}-${ancillaryIndex}-${passengerIndex}`}
                                      checked={isCheckboxChecked(passenger, journeys[ancillaryIndex], i.airlineSsrCode)}
                                      onClick={() => {
                                        handlePassengerClick(
                                          passenger.Id,
                                          journeys[ancillaryIndex].Ond,
                                          i.airlineSsrCode,
                                        );
                                      }}
                                      color={
                                        IncludedAncillaries.includes(
                                          `${journeys[ancillaryIndex].Ond}~${passenger.Id}~${i.airlineSsrCode}`,
                                        )
                                          ? 'success'
                                          : 'primary'
                                      }
                                    />
                                  </TableCell>
                                );
                              }
                              return null; // This will skip rendering for 'CHD' or 'INF' types
                            })}
                          </TableRow>
                        ),
                    )}
                  </TableBody>
                ))}
              </table>
            </Box>
          </TableCell>
        </TableRow>
      );
    },
  };

  const handleSsr = () => {
    const combinedPaxData = [
      ...generatePaxData(outboundPassenger, 0, Ancillaries),
      ...generatePaxData(returnPassenger, 1, Ancillaries),
    ];

    const mergedPaxData = mergePaxData(combinedPaxData as TFF.PaxData[]);

    toggleAncillaryPaxData(mergedPaxData);
  };

  useEffect(() => {
    handleSsr();
  }, [outboundPassenger, returnPassenger]);

  const getUniqueObjects = (arr1: TFF.MetaValue[], arr2: TFF.MetaValue[]) => {
    const uniqueCodes = new Set();

    const uniqueObjectsInArr1 = arr1?.filter(obj1 => {
      if (!arr2?.some(obj2 => obj2.code === obj1.code)) {
        uniqueCodes.add(obj1.code);
        return true;
      }
      return false;
    });

    const uniqueObjectsInArr2 = arr2?.filter(obj2 => {
      if (!uniqueCodes.has(obj2.code)) {
        return true;
      }
      return false;
    });

    return [...uniqueObjectsInArr1, ...uniqueObjectsInArr2];
  };

  const groupByType = (array: TFF.MetaValue[]) => {
    return array.reduce((acc, obj) => {
      const key = obj.type;
      if (!acc[key]) {
        acc[key] = { type: key, ancillaries: [] };
      }
      acc[key].ancillaries.push(obj);
      return acc;
    }, {});
  };

  useEffect(() => {
    if (ancillaryStatus === 'FAILURE') {
      return;
    }

    if (ancillaryStatus === 'IN_ANCILLARY') {
      setSearchAncillariesLambdaRequest({
        journeyIdentifiers: Object.values(journeys)
          .map(
            journey =>
              journey && {
                journeyIdentifier: journey.JourneyIdentifier,
                productClass: journey.AdditionalParams?.ProductClass,
                promotionCode: '2019',
              },
          )
          .filter(Boolean),
        currency: 'EUR',
        locale: intl.formatMessage({ id: 'rebook.ancillaryLanguage' }),
      });
    }

    if (ancillaryStatus === 'CONFIRMED') {
      const journeyOnd = orderDetails.Journeys[0].Ond?.split('-').join('');
      const segment = ancillaryResponse?.body && ancillaryResponse!.body[0].segment.split('-')[3];

      if (
        ancillaryResponse &&
        orderDetails.Journeys.length < 2 &&
        ancillaryResponse.body &&
        ancillaryResponse.body[0].errors
      ) {
        if (ancillaryResponse.body[0].errors.length < 1) {
          setOutboundAncillary(ancillaryResponse!.body[0].metaValues);
          setOutboundRows(Object.values(groupByType(ancillaryResponse!.body[0].metaValues)).map(i => i));
        }
      } else {
        if (
          ancillaryResponse &&
          ancillaryResponse.body &&
          ancillaryResponse.body[0].errors &&
          ancillaryResponse.body[1].errors
        ) {
          const metaValues0 = ancillaryResponse!.body[0].metaValues;
          const metaValues1 = ancillaryResponse!.body[1].metaValues;

          if (
            journeys[1] &&
            ancillaryResponse.body[0].errors.length < 1 &&
            ancillaryResponse.body[1].errors.length < 1
          ) {
            setGroupedRows(
              Object.values(
                groupByType(
                  getUniqueObjects(ancillaryResponse!.body[0].metaValues, ancillaryResponse!.body[1].metaValues),
                ),
              ).map(i => i),
            );
            setOutboundAncillary(ancillaryResponse!.body[0].metaValues);
            setReturnAncillary(ancillaryResponse!.body[1].metaValues);
          }

          if (metaValues0) {
            if (journeyOnd === segment) {
              setOutboundAncillary(metaValues0);
            } else {
              setReturnAncillary(metaValues0);
            }
            setGroupedRows(Object.values(groupByType(metaValues0)).map(i => i));
          } else if (metaValues1) {
            if (journeyOnd !== segment) {
              setOutboundAncillary(metaValues1);
            } else {
              setReturnAncillary(metaValues1);
            }
            setGroupedRows(Object.values(groupByType(metaValues1)).map(i => i));
          }
        }
      }
    }
  }, [ancillaryStatus]);

  const columnsData: ITableColumns[] = [
    {
      name: 'type',
      label: 'Category',
      options: {
        display: true,
        filter: true,
        filterList: [],
        customBodyRender: (value: string) => value,
      },
    },
    {
      name: 'ancillaries',
      label: 'Ancillaries',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
  ];

  return (
    <Table
      data={(groupedRows.length < 1 ? outboundRows : groupedRows) ?? []}
      columnsData={columnsData}
      tableId="ancillaryTable"
      tableName="SSR Table"
      otherOptions={tableOptions}
    />
  );
};

export default AncillaryTable;
