import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '@media (max-width: 1599px)': {
        width: '100%',
      },
      width: '100%',
      border: '1px solid #D6D1C2',
      borderRadius: '8px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 2px 2px 0px #00000029',
      paddingTop: '10px',
      paddingRight: '30px',
      paddingBottom: '10px',
      paddingLeft: '30px',
      margin: 10,
    },
    ancillaryHeader: {
      '@media (max-width: 1599px)': {
        width: '100%',
      },
      width: '100%',
    },
    accordionSummary: {
      display: 'flex',
      padding: '0',
      height: '27px',
    },
    accordionDetails: {
      padding: '0',
    },
    accordionInfo: {
      height: '27px',
      color: '#09295D',
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '27.46px',
      padding: '0',
      whiteSpace: 'nowrap',
      flexShrink: 0,
      marginRight: '5px',
    },
    accordionRemove: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'underline',
      color: '#737373',
      size: '18px',
      lineHeight: '30px',
      fontWeight: 400,
      gap: '6px',
    },
    accordionHeader: {
      display: 'flex',
      flexDirection: 'row',
      width: '480px',
      alignItems: 'center',
    },
    accordionSummaryContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      marginRight: '10px',
    },
    accordionSummaryPrice: {
      color: 'primary',
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '27.46px',
      padding: '0',
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      height: '23px',
      width: '100%',
      gap: '8px',
      marginLeft: -3,
    },
    detailsCheckbox: {
      padding: '0',
    },
    detailText: {
      width: '133px',
      height: '23px',
      fontWeight: 400,
      flexWrap: 'wrap',
      display: 'flex',
      alignContent: 'center',
      fontSize: '18px',
    },
    divider: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    passengerBox: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      gap: '40px',
      paddingBottom: '16px',
    },
    passengerText: {
      fontSize: '18px',
      '@media (max-width: 1799px)': {
        fontSize: '16px',
      },
      wordWrap: 'break-word',
    },
    passengerArrow: {
      display: 'flex',
      width: '100%',
      justifyContent: 'right',
    },
    tablePassengers: {
      fontWeight: 700,
      fontSize: '16px',
      borderBottom: '1px solid #737373',
    },
    ancillaryFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      '@media (max-width: 1599px)': {
        width: '100%',
      },
      width: '100%',
      height: '100%',
      marginTop: '32px',
      padding: '0',
    },
    ancillaryCancel: {
      backgroundColor: '#F4F4F7',
      color: '#09295D',
      borderColor: '#09295D',
      border: '1px solid',
      height: '100%',
      padding: '5px 16px 6px 16px',
    },
    ancillaryAccept: {
      backgroundColor: '#092A5E',
      '&:hover': {
        backgroundColor: '#092A5E',
      },
      color: 'white',
      paddingLeft: 16,
      paddingRight: 16,
      height: '40px',
      padding: '5px 16px 6px 16px',
    },
  }),
);

export default useStyles;
