export const RemoveDuplicateRows = rows => {
  let outboundIndex = rows.findIndex(obj => obj.label === 'Outbound');
  let inboundIndex = rows.findIndex(obj => obj.label === 'Inbound');

  let outboundArray = rows.slice(outboundIndex, inboundIndex);
  let inboundArray = rows.slice(inboundIndex);

  let removeDuplicates = arr => {
    let seen = new Set();
    return arr.filter(obj => {
      let hash = JSON.stringify(obj);
      return seen.has(hash) ? false : seen.add(hash);
    });
  };

  let uniqueOutboundArray = removeDuplicates(outboundArray);
  let uniqueInboundArray = removeDuplicates(inboundArray);

  uniqueOutboundArray.push(...uniqueInboundArray);

  return uniqueOutboundArray;
};
