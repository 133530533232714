import { useContext, useEffect, useState } from 'react';
import { apigeeEndpoint } from '../apis';
import { TypeOfResponseStatus } from '@tff/types/TFF';
import { TypeOfNdcBookingSource } from '@tff/types/NDC';
import { toast } from 'react-toastify';
import { LoadingContext } from '../contexts/LoadingContextProvider';

export type RerunInvoluntaryChangeProps = {
  recordLocator: string;
  bookingSource: TypeOfNdcBookingSource;
};

export type TypeOfStatus = 'IN_FETCHING' | 'FETCHED' | 'FAILURE';

export type RerunInvoluntaryChangeResponse = {
  rerunInvoluntaryChangeFetchedResult?: RerunInvoluntaryChangeFetchedResult;
  rerunInvoluntaryChangeStatus?: TypeOfStatus;
  rerunInvoluntaryChangeError?: any;
};

export type RerunInvoluntaryChangeFetchedResult = {
  status: TypeOfResponseStatus;
  classification: string;
  channel: string;
  messageType: string;
};

export const useRerunInvoluntaryChange = (
  rerunInvoluntaryChangeProps: RerunInvoluntaryChangeProps,
): RerunInvoluntaryChangeResponse => {
  const [rerunInvoluntaryChangeStatus, setRerunInvoluntaryChangeStatus] = useState<TypeOfStatus>();
  const [rerunInvoluntaryChangeError, setRerunInvoluntaryChangeError] = useState<unknown | undefined>();
  const [rerunInvoluntaryChangeFetchedResult, setRerunInvoluntaryChangeFetchedResult] =
    useState<RerunInvoluntaryChangeFetchedResult>();

  const { showLoading, closeLoading } = useContext(LoadingContext);

  useEffect(() => {
    const fetchedDataFromBackend = async (props: RerunInvoluntaryChangeProps): Promise<void> => {
      try {
        setRerunInvoluntaryChangeError(undefined);
        setRerunInvoluntaryChangeStatus('IN_FETCHING');
        setRerunInvoluntaryChangeFetchedResult(undefined);

        const req = {
          recordLocator: props.recordLocator,
          bookingSource: props.bookingSource,
          ocnMessageType: 'Manual-Rerun',
        };

        showLoading(`The manual rerun has been started in the Backend.`);

        const response = await apigeeEndpoint.post(`ndc/involuntarychange`, req);

        if (response.status === 200) {
          toast.warning(
            response.data.status === 'SUCCESS'
              ? `Result of the rerun: ${response.data.status}.
         Classification: ${response.data.classification}`
              : `Result of the rerun: ${response.data.errorType}.
         Classification: ${response.data.errorMessage}`,
            {
              toastId: 'ocn_rerun_response',
              position: 'top-center',
              autoClose: 5000,
              draggable: true,
              progress: undefined,
            },
          );

          setRerunInvoluntaryChangeStatus('FETCHED');
          setRerunInvoluntaryChangeFetchedResult(JSON.parse(response.data.result));
        } else {
          setRerunInvoluntaryChangeStatus('FAILURE');
          setRerunInvoluntaryChangeError(response.data.errorMessage || response.data.Error[0].Error);
        }
      } catch (error) {
        setRerunInvoluntaryChangeError(error);
        setRerunInvoluntaryChangeStatus('FAILURE');
      } finally {
        closeLoading();
      }
    };

    if (
      rerunInvoluntaryChangeProps?.recordLocator &&
      rerunInvoluntaryChangeProps?.bookingSource &&
      !rerunInvoluntaryChangeStatus
    ) {
      void fetchedDataFromBackend(rerunInvoluntaryChangeProps);
    }
  }, [rerunInvoluntaryChangeProps]);

  return { rerunInvoluntaryChangeError, rerunInvoluntaryChangeFetchedResult, rerunInvoluntaryChangeStatus };
};
