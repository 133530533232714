import * as React from 'react';
import { CSSProperties } from 'react';

type Props = {
  style?: CSSProperties;
};

export const TrashIcon: React.FC<Props> = ({ style }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
    <path
      d="M10 16V7H15H1H6V16M5 4H1H15H11V1H5V4Z"
      stroke="#737373"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7H14V17C14 18.1046 13.1046 19 12 19H4C2.8954 19 2 18.1046 2 17V7Z"
      stroke="#737373"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
