import { makeStyles, createStyles } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Theme, styled } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

export const MuiAccordion = withStyles({
  root: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',

    alignItems: 'flex-end',
    '&::before': {
      display: 'none',
    },
  },
})(Accordion);

export const MuiAccordionSummary = withStyles({
  root: {
    width: 'fit-content',
    // backgroundColor: '#F3F0EC',
    borderRadius: '5px',
    marginRight: '3px',
    minHeight: '28px',
    maxHeight: '28px',
  },
  content: {
    marginRight: '5px',
  },
  expanded: {
    borderRadius: '0px',
    borderTopRightRadius: '5px ',
    borderTopLeftRadius: '5px',
  },
  expandIcon: {},
})(AccordionSummary);

export const MuiAccordionDetails = withStyles({
  root: {
    backgroundColor: '#F9F6EE',
    display: 'block',
    width: '100%',
    marginBottom: '15px',
  },
})(AccordionDetails);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '8px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 400,
      color: '#0076B5',
      textDecoration: 'underline',
    },
    flightDetailRoot: {
      '& .MuiCollapse-root': {
        width: '100%',
      },
      '& .MuiCollapse-entered': {
        width: '100%',
        marginBottom: '-20px',
      },
    },
  }),
);

export const SelectRadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 18,
  height: 18,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#FFF',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

export const SelectRadioCheckedIcon = styled(SelectRadioIcon)({
  backgroundColor: '#1A7EAD',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
    margin: 1,
  },
});
