import * as React from 'react';

export const UserFamilyIcon: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    style={{
      fill: '#092A5E',
      width: '24px',
      height: '24px',
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.653,36.589l5.388-.047V31.473a3.819,3.819,0,0,0-2.067-2.295c-.19-.115-.6-.432-1.311-1.011-.259-.21-.533-.434-.8-.658l-.263-.217-.1-.083a.975.975,0,0,1-.2-1.282c1.168-1.8,1.429-4.4.7-5.593a2.907,2.907,0,0,0-2.528-1.381,2.543,2.543,0,0,0-2.45,1.244,6.549,6.549,0,0,0-.22.725c-.064.247-.132.533-.2.85s-.147.682-.218,1.037a3.447,3.447,0,0,1,1.89,1.493,5.588,5.588,0,0,1-.161,4.777c.061.022.3.11.411.16a3.9,3.9,0,0,1,.761.436,3.34,3.34,0,0,1,1.365,2.812Zm0,1.953v1.484a.975.975,0,0,1-.977.974H17.364a.975.975,0,0,1-.977-.978l.007-1.471H11.98a.978.978,0,0,1-.98-.979l.014-5.667a.974.974,0,0,1,.024-.211,5.5,5.5,0,0,1,2.6-3.493,6.707,6.707,0,0,1,1.107-.508c.1-.038.2-.076.343-.127l.375-.133c-1.113-1.835-1.61-4.77-.759-6.17a4.384,4.384,0,0,1,7.111-.439q.049-.206.1-.391a8.027,8.027,0,0,1,.3-.972A4.414,4.414,0,0,1,26.478,17a4.867,4.867,0,0,1,4.2,2.324c1.032,1.7.863,4.608-.3,6.906l.559.456c.55.446.976.778,1.048.822a5.564,5.564,0,0,1,2.988,3.638.97.97,0,0,1,.024.213V37.51a.978.978,0,0,1-.971.977ZM16.4,36.6l.018-4.1a.972.972,0,0,1,.023-.207,4.4,4.4,0,0,1,2.078-2.8,5.111,5.111,0,0,1,.872-.4c.078-.031.16-.061.27-.1-.761-1.472-1.068-3.6-.412-4.683A3.437,3.437,0,0,1,20.8,22.94l-.371-.684a2.415,2.415,0,0,0-4.053.019c-.5.822.19,3.741,1.183,4.705a.975.975,0,0,1,.252.99,1.629,1.629,0,0,1-.864.947,5.886,5.886,0,0,1-.863.369l-.178.063-.167.059c-.123.044-.208.075-.281.1a5.381,5.381,0,0,0-.81.362,3.574,3.574,0,0,0-1.676,2.151L12.962,36.6Zm9.291.975V32.487a1.406,1.406,0,0,0-.573-1.244,1.968,1.968,0,0,0-.383-.217c-.053-.023-.105-.044-.164-.065l-.165-.06a2.776,2.776,0,0,1-.784-.491,5.276,5.276,0,0,1-.56-.87v-.413a4.985,4.985,0,0,0,.535-3.806,1.594,1.594,0,0,0-2.678,0,1.187,1.187,0,0,0-.084.495,4.324,4.324,0,0,0,.127,1.037,3.976,3.976,0,0,0,.816,1.745.97.97,0,0,1,.251.987,1.447,1.447,0,0,1-.764.851,4.667,4.667,0,0,1-.686.295l-.263.093c-.09.032-.153.055-.212.078a3.8,3.8,0,0,0-.575.254,2.475,2.475,0,0,0-1.158,1.461l-.029,6.423h7.347V37.573Z"
      transform="translate(-11 -17)"
    />
  </svg>
);
