import OrderSearchPage from './OrderSearchPage';
import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../withRouter';

export const OrderRoutes = () => {
  return (
    <div className="app-wrapper h-100">
      <Routes>
        <Route path="search" element={<OrderSearchPage />} />
        <Route path=":recordLocator" element={<OrderSearchPage />} />
      </Routes>
    </div>
  );
};

export default withRouter(OrderRoutes);
