import { SetStateAction } from 'react';
import { useStyles } from './use-styles';
import { Box, Button } from '@mui/material';

interface props {
  rebookCloser: (fn: SetStateAction<boolean>) => void;
  setShowTable: (fn: SetStateAction<boolean>) => void;
  showTable: boolean;
  setAncillaryRequest: (fn: SetStateAction<boolean>) => void;
  setDebugDialog: (value: boolean) => void;
  cancelHandler: () => void;
}

const ConfirmPageFooter: React.FC<props> = ({
  rebookCloser,
  setAncillaryRequest,
  setShowTable,
  showTable,
  setDebugDialog,
  cancelHandler,
}) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxHeight: '64px',
        height: '100%',
        marginTop: '32px',
        padding: '24px 0px 0px 0px',
      }}
    >
      <Button
        color="info"
        style={{
          backgroundColor: '#F4F4F7',
          color: '#09295D',
          borderColor: '#09295D',
          border: '1px solid',
          height: '100%',
        }}
        className={classes.footerButton}
        onClick={() => {
          rebookCloser(false);
          cancelHandler();
        }}
      >
        CANCEL
      </Button>
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
        }}
      >
        {!showTable && (
          <>
            {window.ENV?.DEBUG_MODE && (
              <Button
                color="info"
                style={{
                  backgroundColor: '#F4F4F7',
                  color: '#09295D',
                  borderColor: '#09295D',
                  border: '1px solid',
                  height: '100%',
                }}
                onClick={() => setDebugDialog(true)}
                className={classes.footerButton}
              >
                DEBUG
              </Button>
            )}
            <Button
              color="info"
              style={{
                backgroundColor: '#092A5E',
                color: 'white',
                paddingLeft: 16,
                paddingRight: 16,
                height: '40px',
              }}
              onClick={() => setShowTable(true)}
              className={classes.footerButton}
            >
              Next
            </Button>
          </>
        )}
        {showTable && (
          <Button
            color="info"
            style={{
              backgroundColor: '#092A5E',
              color: 'white',
              paddingLeft: 16,
              paddingRight: 16,
              height: '40px',
            }}
            onClick={() => setAncillaryRequest(true)}
            className={classes.footerButton}
          >
            CONFIRM CHANGES
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ConfirmPageFooter;
